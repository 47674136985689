import React from 'react'
import BranchUsers from "../../containers/branches/branchUsers"

export default function BranchUsersPage() {
    return (
        <>
            <BranchUsers />
        </>
    )
}
