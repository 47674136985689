import { axiosInstance } from "../store";
import { toast } from 'react-toastify';
import IBranch from "../dtos/IBranch"
import ICity from "../dtos/ICity"
import ICountry from "../dtos/ICountry"
import { handlStatusCodeMessage } from "../helpers";
const SERVICE_ACC_ID = localStorage.getItem("service_account_id");

export function fetchBranches(page = 0, size = 10, sort = ''): Promise<{ branches?: IBranch[], totalBranchesCount?: number }> {
    const branches_comma_separated = localStorage.getItem("branches_comma_separated");
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/${SERVICE_ACC_ID}/branch?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => res.status == 200 ? { branches: res.data.map((b: any) => { return { ...b, selected: false } }), totalBranchesCount: res.headers['x-total-count'] } : {})
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return {
                branches: [], totalBranchesCount: 0
            }

        })
}
export function fetchRemoteUserBranches(branchesIds: string) {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/branch?branches_ids=${branchesIds}`)
        .then((res) => { return res.data })
        .catch((err) => { console.log(err) })
}

export function fetchBranch(branchId = 0): Promise<IBranch> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/branch/${branchId}`)
        .then((res) => {
            return res.data ? { ...res.data, selected: false } : {}
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return {}
        })
}

export function addNewBranch(branch: any): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/branch`, branch)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'branch' });
            return res.status == 201;
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return false
        })
}

export function updateBranch(branchId: number, branch: IBranch): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/branch?id=${branchId}`, branch)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'branch' });
            return res.status == 201;
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return false
        })
}
export function deleteBranch(branchId = 0): Promise<number> {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/smart-storage/branch?id=${branchId}`)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'branch' });
            if (res.status == 200) {
                return branchId
            }
            else {
                return 0
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return 0
        })
}
export function fetchCountires(): Promise<ICountry[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/lookup/countries`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return []
        })
}
export function fetchCities(uuid = ''): Promise<ICity[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/lookup/cities?country_uuid=${uuid}`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return []
        })
}