import { LOAD_ASSETS, LOAD_ASSET, LOAD_ASSET_USAGE, UPDATE_ASSET, LOAD_ASSETS_STATUS, UPDATE_IS_LOADING_ASSETS } from "../../sagas/asset/types";
import IAsset from "../../dtos/IAsset"
import IAssetTransaction from "../../dtos/IAssetTransaction"

export interface ILoadAssetsAction {
    readonly type: typeof LOAD_ASSETS;
    payload: {
        assets: IAsset[],
        dismissedAssets: IAsset[],
        totalAssetsCount: number
    }
}
export const loadAssetsProps = (payload: ILoadAssetsAction['payload']): ILoadAssetsAction => {
    return {
        type: LOAD_ASSETS,
        payload: payload
    }
};

export interface ILoadAssetAction {
    readonly type: typeof LOAD_ASSET;
    payload: {
        asset: IAsset
    }
}
export const loadAssetProps = (payload: ILoadAssetAction['payload']): ILoadAssetAction => {
    return {
        type: LOAD_ASSET,
        payload: payload
    }
};

export const loadAssetUsageProps = (payload: ILoadAssetUsageAction['payload']): ILoadAssetUsageAction => {
    return {
        type: LOAD_ASSET_USAGE,
        payload: payload
    }
};
export interface ILoadAssetUsageAction {
    readonly type: typeof LOAD_ASSET_USAGE;
    payload: {
        assetTransactions: IAssetTransaction[],
        assetTransactionsCount: number
    }
}

export const updateAssetProps = (payload: IUpdateAssetAction['payload']): IUpdateAssetAction => {
    return {
        type: UPDATE_ASSET,
        payload: payload
    }
};
export interface IUpdateAssetAction {
    readonly type: typeof UPDATE_ASSET;
    payload: {
        assetUpdated: number
    }
}

export interface ILoadAssetsStatusAction {
    readonly type: typeof LOAD_ASSETS_STATUS;
    payload: {
        assetsStatus: any,
    }
}
export const loadAssetsStatusProps = (payload: ILoadAssetsStatusAction['payload']): ILoadAssetsStatusAction => {
    return {
        type: LOAD_ASSETS_STATUS,
        payload: payload
    }
};

export interface IUpdateIsLoadingAssetsAction {
    readonly type: typeof UPDATE_IS_LOADING_ASSETS;
    payload: {
        isLoadingAssets: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingAssetsProps = (payload: IUpdateIsLoadingAssetsAction['payload']): IUpdateIsLoadingAssetsAction => {
    return {
        type: UPDATE_IS_LOADING_ASSETS,
        payload: payload
    }
};
export type AssetActions = ILoadAssetsAction | ILoadAssetAction | ILoadAssetUsageAction | IUpdateAssetAction | ILoadAssetsStatusAction | IUpdateIsLoadingAssetsAction