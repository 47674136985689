import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader, MDBTabPane, MDBTabContent,
    MDBNav, MDBNavItem, MDBNavLink, MDBBtn
} from 'mdbreact';
import Select from '../../components/select';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import {
    loadProductsPropsSaga, filterProductsPropsSaga,
    IFilterProductsActionSaga, deleteProductPropsSaga,
    updateProductPropsSaga
} from '../../sagas/products/types';
import IProduct from '../../dtos/IProduct';
import Constants from '../../constants';
import { addQueryString } from '../../helpers';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import productImg from '../../assets/img/product.jpg';
import { addProduct, addProductsBranch, fetchProduct, fetchProducts, getMasterProducts } from '../../api/products';
import MainBlueButton from "../../components/blueButton"
import IconContainer from "../../components/iconContainer"
import constants from "../../constants"
import TableMenu from "../../components/tableMenu";
import AsyncSelect from 'react-select/async';
import * as Yup from "yup";
import { useFormik } from "formik";
import { BeatLoader } from 'react-spinners';
import cartIcon from '../../assets/img/svg/basket.svg';
import quotation from '../../assets/img/svg/quotation.svg';
import { addToCart } from '../../api/cart';
import { toast } from 'react-toastify';
import { isProductDisabled, isProductInStock } from './helpers';
import { Image } from 'antd';

const branch_id = localStorage.getItem("branch_id");
const service_account_id = localStorage.getItem("service_account_id");

interface IProductsProps { };

function Products(props: IProductsProps) {
    const routerReducer = useSelector((state: AppState) => state.router)
    const { searchkeyword, category, sort, page } = queryString.parse(routerReducer.location.search);
    const [productDetailsOpen, toggleProductDetails] = useState(false);
    const [productDetail, updateProductDetails] = useState<IProduct>();
    const [productAddOpen, toggleProductAdd] = useState(false);
    const [searchText, updateSearchText] = useState(searchkeyword ? searchkeyword.toString() : '');
    const [selectedCategory, selectCategory] = useState(category ? category.toString() : '');
    const [activeItem, toggleTab] = useState("1");
    //new ui
    const [view, selectView] = useState(10)
    const [isOpen, toggleModal] = useState(false)

    let headers: any = [
        { name: "SKU", width: 1 },
        { name: "", width: 1 },
        { name: "Name", width: 3 },
        { name: "Features", width: 2 },
        { name: "Price", width: 1 },
        { name: "", width: 3 },
        { name: "", width: 1 },
    ];
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const dispatch = useDispatch();
    const productsReducer = useSelector((state: AppState) => state.productsReducer)


    // container logic
    useEffect(() => {
        const { searchkeyword, category, sort, page } = queryString.parse(routerReducer.location.search);
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : ['alias', 'ascending'];
        const filters: IFilterProductsActionSaga['filters'] = {
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: view,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc').replace('name', 'alias') : 'alias,asc',
            name: searchkeyword ? searchkeyword.toString() : '',
            categoryId: category ? category.toString() : ''
        }
        dispatch(loadProductsPropsSaga(filters));
    }, []);


    useEffect(() => {
        const { searchkeyword, category, sort, page } = queryString.parse(routerReducer.location.search);
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : ['alias', 'ascending'];
        const filters: IFilterProductsActionSaga['filters'] = {
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: view,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc').replace('name', 'alias') : 'alias,asc',
            name: searchkeyword ? searchkeyword.toString() : '',
            categoryId: category ? category.toString() : ''
        }
        dispatch(filterProductsPropsSaga(filters));
        // updating components values
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        updateSearchText(searchkeyword ? searchkeyword.toString() : '');
        selectCategory(category ? category.toString() : '');
    }, [routerReducer.location.search, view]);

    // headers and soring
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }

    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "" && colName != "category" && colName != "sku") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    // body rows data
    const menuItems = [
        {
            title: "Remove Product",
            icon: "trash",
            handleChange: async (rowValue: IProduct) => {
                dispatch(deleteProductPropsSaga(rowValue.id))
            }
        }
    ]
    const [addToCartLoader, setAddToCartLoader] = useState<ICartLoader>({ isLoading: false, product_id: 0 });

    const handleAddTocart = (product_id: number, cart_type: string) => {
        setAddToCartLoader({ isLoading: true, product_id: product_id });
        addToCart(product_id, cart_type)
            .then(() => {
                dispatch(updateProductPropsSaga((product_id).toString(), cart_type));
                if (cart_type == "cart") toast["success"]("Product added to cart.");
                if (cart_type == "quotation") toast["success"]("Product added to quotation.");
                setAddToCartLoader({ isLoading: false, product_id: 0 });
            })
            .catch((err) => {
                if (err.response.status == 409) {
                    if (cart_type == "cart") {
                        toast["error"]("Failed to Add to cart, you can request quotation for it.");
                        dispatch(updateProductPropsSaga((product_id).toString(), "to_be_quotation"));
                    } else if (cart_type == "quotation") {
                        toast["warning"]("Failed request quotation, you can add it to cart now.");
                        dispatch(updateProductPropsSaga((product_id).toString(), "to_be_cart"));
                    }
                } else if (err.response.status == 400) {
                    toast["error"](`Failed to Add to ${cart_type}, it is not available.`);
                    dispatch(updateProductPropsSaga((product_id).toString(), "to_be_disabled"));
                }
                setAddToCartLoader({ isLoading: false, product_id: 0 });
            })
    }

    const cartButtonHandler = (product: IProduct) => {
        if (product.sac_status == "InCart") {
            return <MDBBtn disabled className='in-cart'>
                <p>In Cart</p>
            </MDBBtn>
        }
        if (isProductDisabled(product) || product.button_status === "disabled") {
            return <MDBBtn disabled className='in-cart'>
                <p>Not Available</p>
            </MDBBtn>
        }
        if (isProductInStock(product) || product.button_status === "add_to_cart") {
            return (<div>
                {addToCartLoader.isLoading && product.id == addToCartLoader.product_id ? <BeatLoader loading={addToCartLoader.isLoading && product.id == addToCartLoader.product_id} color="#2D2ACE" />
                    : <MDBBtn onClick={() => { handleAddTocart(product?.id, 'cart') }} className='add-to-cart'>
                        <img src={cartIcon} alt="" />
                        <p>Add to cart</p>
                    </MDBBtn>}
            </div>)
        } else if (!isProductInStock(product) || product.button_status === "ask_quotation") {
            return (<div>
                {addToCartLoader.isLoading && product.id == addToCartLoader.product_id ? <BeatLoader loading={addToCartLoader.isLoading && product.id == addToCartLoader.product_id} color="#2D2ACE" />
                    : <MDBBtn onClick={() => { handleAddTocart(product?.id, 'quotation') }} className='quotation'>
                        <img src={quotation} alt="" />
                        <p>Requst quotation</p>
                    </MDBBtn>}
            </div>)
        }
    }

    const buildRows = (products: IProduct[]): IRow[] => {
        return products.map((product: IProduct, i) => {
            let row: IRow = {
                value: product,
                props: [
                    'sku',
                    {
                        edit: (rowValue: IProduct) => {
                            if (rowValue.product_images)
                                return <div className='product-image'><Image src={rowValue?.product_images[0]?.image_path} /></div>
                        }
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            return (<div>{rowValue.name}</div>);
                        },
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            if (rowValue?.feature_values)
                                return (rowValue?.feature_values.length > 0 ? <p>{`( ${rowValue?.feature_values.map(({ featureVariant }: any) => featureVariant.variant_name).join(', ')} )`}</p> : <></>)

                        },
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            if (!isProductInStock(product) || isProductDisabled(rowValue)) return <></>
                            return <p>{rowValue.price} LE</p>
                        }
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            return cartButtonHandler(rowValue);
                        },
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    },
                ]
            };
            return row;
        });
    };

    const rows = buildRows(productsReducer.products);

    // pagination
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }

    // search select
    const asyncSearchOptions = (inputValue: string, callback: any) => {
        fetchProducts(inputValue, inputValue).then((results) => {
            const mappedOptions = results.map((product: IProduct, i) => {
                return {
                    product: product,
                    value: product.id, label: <>
                        <div className='displayFlexColumn'>
                            <section className='search-result'>
                                <p>{product.name}</p>
                                <img className='product-image' src={product?.product_images ? `${product?.product_images[0]?.image_path}` : ""} alt='' />
                            </section>
                            {product?.feature_values?.length > 0 ? <p className='featureStyle'>{`(${product.feature_values.map(({ featureVariant }: any) => featureVariant.variant_name).join(', ')})`}</p> : <></>}
                        </div>
                    </>
                }
            });
            callback(mappedOptions);
        })
    }

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        return inputValue;
    };

    const handleSelectOption = (option: any) => {
        editProductformik.setFieldValue('newProductValue', option.value as number);
    }

    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalHeader toggle={() => toggleModal(false)} >Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >
                    <input className="search-input form-control input-rounded" type="text"
                        value={searchText}
                        onChange={(e) => updateSearchText(e.target.value)}
                        placeholder="Search" aria-label="Search" />
                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }

    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (selectedCategory != '') {
            filters.push({ filterName: 'category', value: selectedCategory });
        }
        if (searchText != '') {
            filters.push({ filterName: 'searchkeyword', value: searchText });
        }
        handleFilter(filters);
        toggleModal(false);
    }


    // edit product form
    const editProductvalidationSchema = Yup.object().shape({
        alias: Yup.string()
            .required('Required')
            .min(2, 'must be at least 2 characters'),
    });
    const editProductformik = useFormik({
        initialValues: {
            alias: '',
        },
        validationSchema: editProductvalidationSchema,
        onSubmit: async (values) => {
            dispatch(updateProductPropsSaga(
                productDetail?.id ? productDetail?.id.toString() : '',
                values.alias.toString(),
            ))
            toggleProductDetails(false);
        },
    });

    /*Add product list*/

    const [products, setProducts] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [saveCatalogLoader, setSaveCatalogLoader] = useState<boolean>(false);
    const handleAddToProductList = (item: any) => {
        setProducts((prev: any) => {
            return [...prev, item]
        })
    }
    const handleDeleteItem = (idx: number) => {
        setProducts((prev: any[]) => {
            return prev.filter((item, i: number) => i != idx)
        })
    }

    const handleSaveProductsCatalog = () => {
        let constructedArray: any[] = [];
        setSaveCatalogLoader(true);
        products.map((item) => {
            constructedArray.push({ product_id: item.product.id, service_account_id: service_account_id, branch_id: branch_id })
        })
        addProductsBranch(constructedArray).then((res) => {
            setSaveCatalogLoader(false);
            toggleProductAdd(false);
            setProducts([]);
            dispatch(filterProductsPropsSaga({}))
        }).catch((err) => { setSaveCatalogLoader(false); })
    }

    useEffect(() => {
        const { searchkeyword, category, sort, page } = queryString.parse(routerReducer.location.search);
        const filters: IFilterProductsActionSaga['filters'] = {
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: view,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc').replace('name', 'alias') : 'alias,asc',
            name: searchkeyword ? searchkeyword.toString() : '',
            categoryId: category ? category.toString() : ''
        }
        if (productsReducer.products.length == 0 && (productsReducer.totalCountPages - view)) {
            dispatch(filterProductsPropsSaga(filters));
        }
    }, [productsReducer.products.length])

    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={productsReducer.loadingProducts.isLoadingProducts} errorMessage={productsReducer.loadingProducts.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="2" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-3 pl-3 mr-2"
                                title="+ add product" className="d-contents" onClick={() => { toggleProductAdd(true) }} />
                        </MDBCol>
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                id="view-select"
                                className="mr-2 d-inline"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => { selectView(e.target.value); dispatch(push({ search: '' })); }}
                            />
                            <IconContainer className="mr-2 d-inline" onClick={() => toggleModal(true)} >
                                <MDBIcon icon="filter" />
                            </IconContainer>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                productsReducer.products.length > 0 ?
                                    <div className="celled-table">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            headerOnClick={handleSort}
                                            rows={rows}
                                            data={productsReducer.products}
                                            totalPages={productsReducer.totalCountPages / view}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                    </div>
                                    :
                                    <div>No products found.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                    <MDBModal isOpen={productDetailsOpen} toggle={() => { toggleProductDetails(!productDetailsOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="12" md="7" className="text-left offblue-text">
                                    <h2 className="ml-2 font-weight-bold">{editProductformik.values.alias}</h2>
                                </MDBCol>
                                <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">
                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleProductDetails(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => {
                                                editProductformik.submitForm();
                                            }} />
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="m-0 p-0">
                                <MDBCol className="m-0 p-0" md="4" sm="12">
                                    <img className="product-img" src={productImg} alt="product-img" />
                                </MDBCol>
                                <MDBCol className="m-0 p-0">
                                    <MDBRow className="m-0 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">name</label>
                                            <input
                                                type="text"
                                                placeholder="name"
                                                onChange={(e) => { editProductformik.setFieldValue('alias', e.target.value); }}
                                                value={editProductformik.values.alias}
                                                className="form-control styled-input"
                                            />
                                            {editProductformik.errors.alias && <p className='red-text'>{editProductformik.errors.alias}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-upperCase">sku</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="sku"
                                                value={productDetail?.sku}
                                                className="form-control styled-input"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 mt-4 justify-content-around">
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">price</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="price"
                                                value={productDetail?.price}
                                                className="form-control styled-input"
                                            />
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">category name</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="category name"
                                                value={productDetail?.category?.name}
                                                className="form-control styled-input"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5 text-left">
                                <MDBCol>
                                    <h4>Description</h4>
                                    <p className="lightGrey-text">
                                        {productDetail?.description}
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                    {/*add product*/}
                    <MDBModal isOpen={productAddOpen} toggle={() => { toggleProductAdd(!productAddOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="8" className="text-left offblue-text">
                                    <h2 className="font-weight-bold">Add products</h2>
                                </MDBCol>

                                {saveCatalogLoader ? <BeatLoader loading={saveCatalogLoader} color="#2D2ACE" /> : <div className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">
                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleProductAdd(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            disabled={!products.length}
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => { handleSaveProductsCatalog() }} />
                                    </MDBRow>
                                </div>}
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="mt-2 text-left">
                                <MDBCol>
                                    <MDBNav className="nav-tabs">
                                        <MDBNavItem>
                                            <MDBNavLink link to="#" active={activeItem === "1"} onClick={() => toggleTab("1")} role="tab" >
                                                Search
                                            </MDBNavLink>
                                        </MDBNavItem>
                                    </MDBNav>
                                    <MDBTabContent activeItem={activeItem} >
                                        <MDBTabPane tabId="1" role="tabpanel">
                                            <MDBRow className="m-0 p-0 mt-3">
                                                <MDBCol className="p-0 text-left" size="12">
                                                    <label className="text-capitalize">Search a Product</label>
                                                    <AsyncSelect
                                                        placeholder="Select Products"
                                                        value={null}
                                                        className="select"
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={asyncSearchOptions}
                                                        onInputChange={(value) => { setSearchValue(value); handleInputChange(value) }}
                                                        onChange={(value: any) => { handleAddToProductList(value); handleSelectOption(value) }}
                                                    />
                                                </MDBCol>
                                                <MDBCol className='products-container'>
                                                    {products.map((product: any, idx: number) => {
                                                        console.log("acdc", product)
                                                        return <MDBRow className='product-item'>
                                                            <MDBCol><p>{product.product.sku}</p></MDBCol>
                                                            <MDBCol><img className='product-img' src={product.product?.product_images[0]?.image_path} /></MDBCol>
                                                            <MDBCol><p>{product.product.name}</p></MDBCol>
                                                            <MDBCol><p>{product.product.feature_values.map(({ featureVariant }: any) => featureVariant.variant_name).join(',')}</p></MDBCol>
                                                            <MDBCol><p>{product.product.product_categories.map(({ category }: any) => category.name).join(',')}</p></MDBCol>
                                                            <MDBCol><MainBlueButton className='button' title='Delete' onClick={() => handleDeleteItem(idx)} /></MDBCol>
                                                        </MDBRow>
                                                    })}
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBTabPane>
                                    </MDBTabContent>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </Loader>
                {renderModal()}
            </MDBCol>
        </MDBRow >
    )
}

export default Products;