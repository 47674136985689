import { LOAD_UNITS, LOAD_UNIT, LOAD_UNIT_STATUS, ADD_UNIT, UPDATE_UNIT } from "../../sagas/units/types";
import { UnitsActions, IUpdateIsLoadingUnitsAction } from "./types"
import IUnit from "../../dtos/IUnit";
import IStatus from "../../dtos/IStatus";

interface IUnitsState {
    units: IUnit[],
    totalUnitsCount: number,
    unit: IUnit,
    unitStatus: IStatus[],
    unitAdded: boolean,
    unitUpdated: boolean,
    loadingUnits: IUpdateIsLoadingUnitsAction['payload']
}
const UnitsState: IUnitsState = {
    units: [],
    totalUnitsCount: 0,
    unit: {
        id: "",
        name: "",
        status: {
            id: 0,
            name: ""
        },
        is_active: true
    },
    unitStatus: [],
    unitAdded: false,
    unitUpdated: false,
    loadingUnits: {
        isLoadingUnits: false,
        errorMessage: undefined
    }
}
const unitsReducer = (state = UnitsState, action: UnitsActions): IUnitsState => {
    switch (action.type) {
        case LOAD_UNITS:
            console.log("LOAD_UNITS action", action)
            return {
                ...state,
                units: action.payload.units,
                totalUnitsCount: action.payload.totalUnitsCount
            }
        case LOAD_UNIT:
            return {
                ...state,
                unit: action.payload.unit
            }
        case LOAD_UNIT_STATUS:
            return {
                ...state,
                unitStatus: action.payload.unitStatus
            }
        case ADD_UNIT:
            return {
                ...state,
                unitAdded: action.payload.unitAdded
            }
        case UPDATE_UNIT:
            return {
                ...state,
                unitUpdated: action.payload.unitUpdated
            }
        default:
            return state;
    }
}
export default unitsReducer
