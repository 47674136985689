import React, { useEffect, useState } from 'react';
import { IQuotation, IQuotationItem } from '../../dtos/IQuotation';
import { MDBCol, MDBModal, MDBModalBody, MDBRow } from 'mdbreact';
import { statusColorCode, statusHandler } from './helpers';
import moment from 'moment';
import DataTable, { IRow } from '../../components/dataTable';
import busketIcon from '../../assets/img/basketblue.svg';
import Loader from "../../components/loader";
import { quotationItem } from '../../api/quotation';
import { toast } from 'react-toastify';

interface IQuotationDetailsModal {
  selectedQuotation: IQuotation | undefined;
  isDetailsOpen: boolean;
  setIsDetailsOpen: any;
}
const QuotationDetailsModal = (props: IQuotationDetailsModal) => {
  const { selectedQuotation, isDetailsOpen, setIsDetailsOpen } = props;
  const [detailsStatus, setDetailsStatus] = useState<string>("Accepted");
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedQuotation) {
      setIsDetailsLoading(true);
      quotationItem(selectedQuotation?.id).then((res: IQuotationItem[]) => {
        setQuotationDetails(res);
        setIsDetailsLoading(false);
      }).catch((err) => {
        setIsDetailsLoading(false);
        toast.error('Error loading quotation details.')
      });
    }
  }, []);
  const [quotationDetails, setQuotationDetails] = useState<IQuotationItem[]>([]);
  const [fillteredQuotationDetails, setFillteredQuotationDetails] = useState<IQuotationItem[]>(quotationDetails);

  useEffect(() => {
    filterHandler("Accepted");
  }, [quotationDetails])

  const filterHandler = (itemStatus: string) => {
    setDetailsStatus(itemStatus);
    let fillteredQuotation = quotationDetails.filter(item => item.status == itemStatus);
    setFillteredQuotationDetails(fillteredQuotation);
  }
  const [detailsheaders, setDetailsHeaders] = useState<any[]>([
    { name: "SKU", width: 2 },
    { name: "Name", width: 4 },
    { name: "Requested Quantity", width: 4 },
    { name: "New Quantity", width: 4 },
    { name: "Price", width: 3 }
  ]);

  const buildDetailsRows = (quotationProducts: IQuotationItem[]): IRow[] => {
    return quotationProducts?.map((quotationProduct: IQuotationItem, i: any) => {
      return {
        value: quotationProduct,
        props: [
          {
            edit: (rowValue: any) => {
              return <p >{rowValue.product.sku}</p>
            }
          },
          {
            edit: (rowValue: any) => {
              return <p>{rowValue.product.name}</p>;
            }
          },
          {
            edit: (rowValue: any) => {
              return <p>{rowValue.requested_quantity}</p>;
            },
          },
          {
            edit: (rowValue: any) => {
              // this return was for filteration uncomment it if filteration on products feature needed
              // return detailsStatus != "Partially Accepted" ? <></> : <p>{rowValue.available_quantity}</p>;
              return <p>{rowValue.available_quantity}</p>;
            },
          },
          {
            edit: (rowValue: any) => {
              return <p>{rowValue.total} LE</p>;
            }
          }
        ]
      }
    });

  };


  // if needed with filters its already implemented pass the filtered quotationItems
  const detailsRows = buildDetailsRows(quotationDetails);

  return selectedQuotation ? <MDBModal isOpen={isDetailsOpen} toggle={() => { setIsDetailsOpen(!isDetailsOpen) }}
    fullHeight={true} position="right"
    inline={false}
    noClickableBodyWithoutBackdrop={false}
    overflowScroll={true}
    className="full-height-modal full-height-modal-right">
    <div className="custom-modal-header">
      <MDBRow className="m-0 pt-5 pb-5 flex-row">
        <MDBCol size="12" md="7" className="text-left offblue-text flex-row">
          <div className="icon-container"><img src={busketIcon} alt='icon' /></div>
          <h1 className="modal-title">Qoutation #{selectedQuotation.id}</h1>
          {selectedQuotation.order_id ? <h1 className="modal-title">Order #{selectedQuotation.order_id}</h1> : <></>}
        </MDBCol>
        <MDBCol className="">
          <div className='quotation-status-modal' style={{ backgroundColor: statusHandler(selectedQuotation.status) }}><p>{selectedQuotation.status}</p></div>
        </MDBCol>
      </MDBRow>
    </div>
    <MDBModalBody className='details-container'>
      <MDBRow>
        <MDBCol size="4">
          <p className="label">order creation date</p>
          <p className="date">{moment(selectedQuotation.created_at).format("DD/MM/YYYY, hh:mm A")}</p>
        </MDBCol>
      </MDBRow>
      {/* this Row for Quotation filteration just uncommnt this row and pass to  buildDetailsRows(fillteredQuotationDetails)*/}
      {/* <MDBRow className='p-t-20 mb-2'>
        <MDBCol><button onClick={() => filterHandler("Accepted")} className="details-fillter-button" style={{ backgroundColor: detailsStatus == "Accepted" ? statusColorCode.FILTER : "" }}>Accepted</button></MDBCol>
        <MDBCol><button onClick={() => filterHandler("Partially Accepted")} className="details-fillter-button" style={{ backgroundColor: detailsStatus == "Partially Accepted" ? statusColorCode.FILTER : "" }}>Partially Accepted</button></MDBCol>
        <MDBCol><button onClick={() => filterHandler("Not Accepted")} className="details-fillter-button" style={{ backgroundColor: detailsStatus == "Not Accepted" ? statusColorCode.FILTER : "" }}>Not Accepted</button></MDBCol>
      </MDBRow> */}
      <Loader isLoading={isDetailsLoading} >
        <div className='scroll'>
          <DataTable
            className="quotation-details-table"
            headerColumns={detailsheaders.map((header) => { return { ...header } })}
            rows={detailsRows}
          />
        </div>
      </Loader>
    </MDBModalBody>
  </MDBModal> : <></>
}

export default QuotationDetailsModal;