import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import * as Yup from "yup";
import FormModal from "../../components/form"
import IOperator from "../../dtos/IOperator";
import { loadOperatorStatusPropsSaga } from "../../sagas/operators/types"


interface IOperatorsFormProps {
    onSubmit: any,
    operator?: IOperator
}

export default function OperatorsForm(props: IOperatorsFormProps) {
    const dispatch = useDispatch();
    const operatorsReducer = useSelector((state: AppState) => state.operatorsReducer)
    useEffect(() => {
        dispatch(loadOperatorStatusPropsSaga())
    }, [operatorsReducer.operator])
    const operator: any = {
        id: operatorsReducer.operator.id ? operatorsReducer.operator.id : null,
        name: operatorsReducer.operator.name ? operatorsReducer.operator.name : '',
        title: operatorsReducer.operator.title ? operatorsReducer.operator.title : '',
        status: operatorsReducer.operator.status.name != "" ? JSON.stringify(operatorsReducer.operator.status) : "",
        is_dismissed: false,
    }
    console.log("operatorsReducer", operatorsReducer.operator)
    const fields: any = [
        {
            display: 'col',
            type: 'text',
            label: 'Name',
            required: true,
            placeholder: "Operator Name",
            name: "FormValues.operator.name",
            object_name: "operator",
            field_name: "name"
        },
        {
            display: 'col',
            type: 'text',
            label: 'Title',
            required: true,
            placeholder: "Operator Title",
            name: "FormValues.operator.title",
            object_name: "operator",
            field_name: "title"
        },
        {
            display: 'col',
            type: 'select',
            label: 'Status',
            required: true,
            containerStyle: "select-container",
            options: operatorsReducer.operatorStatus.map((option, i) => {
                return { text: option.name, value: JSON.stringify(option) }
            }),
            name: "FormValues.operator.status",
            object_name: "operator",
            field_name: "status"

        }
    ]
    const onSubmit = (values: any) => {
        values.operator.status = JSON.parse(values.operator.status)
        values.operator.branch_id = parseInt(values.operator.branch_id)
        props.onSubmit(values.operator);
    }
    const validationSchema: object = Yup.object({
        FormValues: Yup.object({
            operator: Yup.object({
                name: Yup.string().required("Required!"),
                title: Yup.string().required("Required!"),
                status: Yup.string().required("Required!"),
            })
        })
    })
    return (
        <FormModal
            id="operator-form"
            initialValues={{ operator }}
            validationSchema={validationSchema}
            fields={fields}
            onSubmit={onSubmit}
        />
    )
}
