import { LOAD_VENDORS, LOAD_VENDOR, ADD_VENDOR, UPDATE_VENDOR } from "../../sagas/vendors/types";
import { VendorsActions, IUpdateIsLoadingVendorsAction } from "./types"
import IVendor from "../../dtos/IVendor";

interface IVendorsState {
    vendors: IVendor[],
    totalVendorsCount: number,
    vendor: IVendor,
    vendorAdded: boolean,
    vendorUpdated: boolean,
    loadingVendors: IUpdateIsLoadingVendorsAction['payload']
}
const VendorsState: IVendorsState = {
    vendors: [],
    totalVendorsCount: 0,
    vendor: {
        id: 0,
        name: "",
        contact_name: "",
        contact_number_1: "",
        contact_number_2: "",
        address: null,
        is_dismissed: false
    },
    vendorAdded: false,
    vendorUpdated: false,
    loadingVendors: {
        isLoadingVendors: false,
        errorMessage: undefined
    }
}
const vendorsReducer = (state = VendorsState, action: VendorsActions): IVendorsState => {
    switch (action.type) {
        case LOAD_VENDORS:
            return {
                ...state,
                vendors: action.payload.vendors,
                totalVendorsCount: action.payload.totalVendorsCount
            }
        case LOAD_VENDOR:
            return {
                ...state,
                vendor: action.payload.vendor
            }
        case ADD_VENDOR:
            return {
                ...state,
                vendorAdded: action.payload.vendorAdded
            }
        case UPDATE_VENDOR:
            return {
                ...state,
                vendorUpdated: action.payload.vendorUpdated
            }
        default:
            return state;
    }
}
export default vendorsReducer
