import React from 'react'
import BranchStorages from "../../containers/branches/branchStorages"

export default function BranchStoragesPage() {
    return (
        <>
            <BranchStorages />
        </>
    )
}
