import React, { useState } from 'react';
import './styles/index.scss'
import { MDBContainer, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { useDispatch } from "react-redux";
import { addToCart } from '../../api/cart';
import IProduct from '../../dtos/IProduct';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

interface ILoaderProps {
  isOpen: boolean,
  close?: any,
  confirm?: () => void,
  title?: string,
  body?: React.ReactNode,
  isInfo?: boolean,
  closebtnText?: string
  savebtnText?: string,
  setQuotationNewQty: any,
  quotationQty: number,
  product: IProduct | undefined,
}


const QuotationModal = (props: ILoaderProps) => {
  const { isOpen, close, confirm, title, body, isInfo, closebtnText, savebtnText, quotationQty, setQuotationNewQty, product } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleQuotationReq = (qty: number) => {
    setIsLoading(true);
    addToCart(product?.id as number, "quotation", quotationQty)
      .then(() => {
        toast["success"]("Product added to quotation.");
        close(!isOpen);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 400) {
          toast["error"](`Failed to Add to quotation, it is not available.`);
        }
        toast["error"](`Failed to Add to quotation.`);
      })
  }
  return (
    <MDBContainer className="confirmation-modal-container">
      <MDBModal size='lg' isOpen={isOpen} toggle={() => { close(!isOpen); setQuotationNewQty(1); }} inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
        <MDBModalHeader className='modal-header'>
          {title}
        </MDBModalHeader>
        <MDBModalBody>
          {body}
        </MDBModalBody>
        <MDBModalFooter>
          {
            <>
              {isLoading ? <BeatLoader loading={isLoading} color="#2D2ACE" /> : <MDBBtn className="confirm-btn mr-2" onClick={() => handleQuotationReq(quotationQty)}>{savebtnText ? savebtnText : 'Save changes'}</MDBBtn>}
              <MDBBtn className="cancel-btn" onClick={() => { setQuotationNewQty(1); close(!isOpen) }}>{closebtnText ? closebtnText : 'Close'}</MDBBtn>
            </>
          }
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer >
  )
}

export default QuotationModal;
