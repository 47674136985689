import React, { useEffect, useMemo, useState } from 'react';
// firebase
import { initializeApp } from "firebase/app";
import { getRemoteConfig, getValue, getString, fetchAndActivate } from "firebase/remote-config";
const firebaseConfig = {
    apiKey: "AIzaSyC_q_bLCR-APQB5BsxY0L_tOBjnN-KrCFM",
    authDomain: "debtacartsapp.firebaseapp.com",
    databaseURL: "https://debtacartsapp.firebaseio.com",
    projectId: "debtacartsapp",
    storageBucket: "debtacartsapp.appspot.com",
    messagingSenderId: "619043821855",
    appId: "1:619043821855:web:25f916eb110df6609e382a",
    measurementId: "G-F5RMQXCHKJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.defaultConfig = {
    disabled_modules: ""
};
interface IView {
    children: React.ReactNode
    view: string
}
export default function View(props: IView) {
    const [disabledModules, setDisabledModules] = useState<any>([]);
    useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(async () => {
                const val = await getString(remoteConfig, "disabled_modules");
                console.log("vaaaal", JSON.parse(val))
                setDisabledModules(JSON.parse(val))
            })
            .catch((err) => {
                console.log("firebase error", err)
            });

    }, []);
    const checkDisabled = () => {
        let disabled = disabledModules.find((module: any) => module == props.view)
        return disabled ? false : true
    }
    return (
        <>
            {
                checkDisabled() && props.children
            }
        </>
    )
}
