declare global {
    let SockJS: any;
    let StompJs: any;
}
export let stompClient: any = null;
export function connect(subscriptions: (stompClient: any) => void) {
    var socket = new SockJS(`${process.env.REACT_APP_API_URL}/smart-storage/notifications`);
    stompClient = StompJs.Stomp.over(socket);
    stompClient.connect({}, function (frame: any) {
        console.log('socket Connected: ' + frame);
        subscriptions(stompClient);
    });
}
export function disconnect() {
    if (stompClient !== null) {
        stompClient.disconnect();
    }
    console.log("Disconnected from websocket");
}
