import React, { useEffect } from 'react';
import './styles/styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { loadCountriesPropsSaga, loadCitiesPropsSaga } from "../../sagas/branch/types";
import { AppState } from "../../store";
import IBranch from "../../dtos/IBranch"
import IAddress from "../../dtos/IAddress";
import FormModal from "../../components/form"
import * as Yup from "yup";

interface IBranchFormProps {
    existingBranch?: IBranch,
    handleSave?: any,
}

function BranchForm(props: IBranchFormProps) {
    const dispatch = useDispatch();
    const branchReducer = useSelector((state: AppState) => state.branchReducer)
    useEffect(() => {
        dispatch(loadCountriesPropsSaga())
        let country = props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.country ? props.existingBranch.address_id.country : '' : ''
        let findCountry = branchReducer.countries.find((c: any) => c.name.toLowerCase() == country.toLowerCase())
        if (findCountry) {
            dispatch(loadCitiesPropsSaga(findCountry.uuid))
        }
    }, [])
    const address: IAddress = {
        id: props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.id : 0,
        building_number: props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.building_number?.toString() : '',
        street: props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.street : '',
        city: props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.city : '',
        state: props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.state : '',
        country: props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.country : '',
        floor: props.existingBranch && props.existingBranch.address_id ? props.existingBranch.address_id.floor?.toString() : '',
        service_account_id: localStorage.getItem("service_account_id")
    }
    const branch: IBranch = {
        id: props.existingBranch && props.existingBranch.id ? props.existingBranch.id : 0,
        name: props.existingBranch && props.existingBranch.name ? props.existingBranch.name : '',
        service_account_id: props.existingBranch && props.existingBranch.service_account_id ? props.existingBranch.service_account_id : localStorage.getItem("service_account_id"),
        is_active: props.existingBranch && props.existingBranch.is_active ? props.existingBranch.is_active : true,
        longitude: props.existingBranch && props.existingBranch.longitude ? props.existingBranch.longitude : 0.0,
        latitude: props.existingBranch && props.existingBranch.latitude ? props.existingBranch.latitude : 0.0,
        phone: props.existingBranch && props.existingBranch.phone ? props.existingBranch.phone : '',
        selected: false
    }
    const handleChangeCountry = (e: any) => {
        let countryUuid = e.target.selectedOptions[0].getAttribute('data-key')
        dispatch(loadCitiesPropsSaga(countryUuid))
    }
    const fields: any = [
        {
            display: 'col',
            type: 'text',
            label: 'Name',
            required: true,
            placeholder: "Branch Name",
            name: "FormValues.branch.name",
            object_name: "branch",
            field_name: "name",
        },
        {
            display: 'col',
            type: 'text',
            label: 'Phone Number',
            required: true,
            placeholder: "Phone Number",
            name: "FormValues.branch.phone",
            object_name: "branch",
            field_name: "phone",

        },
        {
            display: 'row',
            rowFields: [
                {
                    type: 'text',
                    label: 'Building Number',
                    required: true,
                    placeholder: "Building Number",
                    name: "FormValues.address.building_number",
                    object_name: "address",
                    field_name: "building_number"
                },
                {
                    type: 'text',
                    label: 'Floor Number',
                    required: true,
                    placeholder: "Floor Number",
                    name: "FormValues.address.floor",
                    object_name: "address",
                    field_name: "floor"
                }
            ]
        },
        {
            display: 'row',
            rowFields: [
                {
                    type: 'text',
                    label: 'Street',
                    required: true,
                    placeholder: "Street Name",
                    name: "FormValues.address.street",
                    object_name: "address",
                    field_name: "street"
                },
                {
                    type: 'text',
                    label: 'City',
                    required: true,
                    placeholder: "City Name",
                    name: "FormValues.address.city",
                    object_name: "address",
                    field_name: "city"
                }
            ]
        },
        {
            display: 'row',
            rowFields: [
                {
                    type: 'select',
                    label: 'Country',
                    required: true,
                    containerStyle: "select-container",
                    options: branchReducer.countries.map((option, i) => {
                        return { text: option.name, value: option.name, keyValue: option.uuid }
                    }),
                    name: "FormValues.address.country",
                    customHandleChange: handleChangeCountry,
                    object_name: "address",
                    field_name: "country"
                },
                {
                    type: 'select',
                    label: 'State',
                    required: true,
                    containerStyle: "select-container",
                    options: branchReducer.cities.map((option, i) => {
                        return { text: option.name, value: option.name, keyValue: option.uuid }
                    }),
                    name: "FormValues.address.state",
                    object_name: "address",
                    field_name: "state"
                }
            ]
        }
    ]
    const validationSchema: object = () => Yup.lazy((values) => {
        let countryCheck = Yup.string().required("Required!");
        if(values.FormValues.address.country?.toLowerCase().trim() === 'egypt'){
            countryCheck = countryCheck.matches(/^01[0-2]\d{1,8}$/, "Must be a valid phone number");
        }else{
            countryCheck = countryCheck.matches(/^((?:[+?0?0?966]+)(?:\s?\d{2})(?:\s?\d{7}))$/, "Must be a valid phone number");
        }
        return Yup.object({
            FormValues: Yup.object({
                branch: Yup.object({
                    name: Yup.string().required("Required!"),
                    phone: countryCheck,
                    longitude: Yup.number(),
                    latitude: Yup.number()
                }),
                address: Yup.object({
                    building_number: Yup.string().required("Required!"),
                    floor: Yup.string().required("Required!"),
                    street: Yup.string().required("Required!"),
                    city: Yup.string().required("Required!"),
                    state: Yup.string().required("Required!"),
                    country: Yup.string().required("Required!"),
                })
            })
        });
    });

    const onSubmit = (values: any) => {
        values.branch.address_id = values.address
        values.branch.address = values.address
        const branch = values.branch
        props.handleSave(branch);
    }
    return (
        <FormModal
            id="branch-form"
            initialValues={{ branch, address }}
            validationSchema={validationSchema}
            fields={fields}
            onSubmit={onSubmit}
        />

    )
}

export default BranchForm;