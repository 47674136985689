import {put, takeLatest, call} from 'redux-saga/effects'
import {
    LOAD_PRODUCT_TRANSACTIONS_SAGA, ILoadProductTransactionsActionSaga,
    FILTER_PRODUCT_TRANSACTIONS_SAGA, IFilterProductTransactionsActionSaga,
} from "./types";
import {loadProductTransactionsProps, loadProductTransactionsFiltersProps,
    updateIsLoadingProductTransactionsProps,
} from "../../reducers/product_transactions/types";
import { fetchDataTableProductTreansactions, getOperations } from '../../api/product_transactions';
import { getStorages } from '../../api/inventory'
import {displayErrorMessageProps} from "../../reducers/global/types";
import { IDropdown } from '../../dtos/global';

function* filterProductTransactions(action: IFilterProductTransactionsActionSaga) {
    try {
        const {productId, page, size, sort, operation, storage, from, to, userId} = action.filters; 
        const dataTableProductTransactions: {
            productTransactions: any[],
            totalCountPages: number
        } = yield call(fetchDataTableProductTreansactions, productId, page, size, sort, operation, storage, from, to, userId);
        yield put(loadProductTransactionsProps({productTransActions: dataTableProductTransactions.productTransactions, totalCountPages: dataTableProductTransactions.totalCountPages}))
    } catch (error) {
        yield put(displayErrorMessageProps({message: error.message}))
    }
}

export function* watchFilterProductTransactions() {
    yield takeLatest(FILTER_PRODUCT_TRANSACTIONS_SAGA, filterProductTransactions)
}


function* loadProductTransactions(action: ILoadProductTransactionsActionSaga) {
    try {
        yield put(updateIsLoadingProductTransactionsProps({isLoadingProductTransactions: true, errorMessage: undefined}));
        const storageList: IDropdown[] = yield call(getStorages);
        const operationList: IDropdown[] = yield call(getOperations);
        yield put(loadProductTransactionsFiltersProps({
            storageList: storageList,
            operationList: operationList,
            sortCol: '',
            sortOrder: 'ascending'
        }));
        yield put(updateIsLoadingProductTransactionsProps({isLoadingProductTransactions: false, errorMessage: undefined}));
    } catch (error) {
        yield put(updateIsLoadingProductTransactionsProps({isLoadingProductTransactions: false, errorMessage: error.message}));
        yield put(displayErrorMessageProps({message: error.message}))
    }
}

export function* watchLoadProductTransactions() {
    yield takeLatest(LOAD_PRODUCT_TRANSACTIONS_SAGA, loadProductTransactions)
}

export default [
    watchLoadProductTransactions(),
    watchFilterProductTransactions(),
]