import React, { useEffect } from 'react';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { loadDashboardPropsSaga } from "../../sagas/dashboard/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { Link } from 'react-router-dom';
import { MDBCol, MDBRow, MDBBtn } from 'mdbreact';
import { loadMyAccountPropsSaga } from '../../sagas/my_account/types';
import IUser, { ACCESSCARDSTATUS } from '../../dtos/IUser';
import { updateGlobalModalPropsSaga } from '../../sagas/global/types';
import { deactivateAuthUserCard, requestUserCard } from '../../api/users';
import { toast } from 'react-toastify';

interface IMyAccountProps {
}

function MyAccount(props: IMyAccountProps) {
    const dispatch = useDispatch();
    const myAccountReducer = useSelector((state: AppState) => state.myAccountReducer)
    const globalReducer = useSelector((state: AppState) => state.globalReducer)
    useEffect(() => {
        dispatch(loadMyAccountPropsSaga(globalReducer.authUser.user_id))
    }, []);
    // request user card
    const handleCardRequest = async (userId: IUser['user_id']) => {
        await requestUserCard(userId);
        // toast.success("Card requested successfully.");
    }
    // user details deactivate card
    const handleCardDeactivation = (userId: IUser['user_id'], cardId: number) => {
        dispatch(updateGlobalModalPropsSaga({
            isOpen: true,
            title: 'Card Deactivation',
            body: 'Are you sure you want to preform this operation?',
            savebtnText: 'Yes',
            closebtnText: 'No',
            confirm: async () => {
                await deactivateAuthUserCard(userId, cardId);
                dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
                window.location.reload();
            },
            close: () => {
                dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
            },
        }));
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={myAccountReducer.loadingMyAccount.isLoadingMyAccount} errorMessage={myAccountReducer.loadingMyAccount.errorMessage}>
                    {
                        myAccountReducer.user ?
                            <>
                                <MDBRow start className="mt-4">
                                    <MDBCol>
                                        <h3 className="font-weight-bold">User info</h3>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow start className="mt-4 justify-content-around">
                                    <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                        <label className="text-capitalize cursor-disabled">first name</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="name"
                                            value={myAccountReducer.user.first_name}
                                            className="form-control styled-input"
                                        />
                                    </MDBCol>
                                    <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                        <label className="text-capitalize cursor-disabled">last name</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="name"
                                            value={myAccountReducer.user.last_name}
                                            className="form-control styled-input"
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow start className="mt-4 justify-content-around">
                                    <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                        <label className="text-capitalize cursor-disabled">email</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="name"
                                            value={myAccountReducer.user.email}
                                            className="form-control styled-input"
                                        />
                                    </MDBCol>
                                    <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                        <label className="text-capitalize cursor-disabled">status</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="name"
                                            value={myAccountReducer.user.status}
                                            className="form-control styled-input"
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow start className="mt-4">
                                    <MDBCol>
                                        <h3 className="font-weight-bold">Access card</h3>
                                    </MDBCol>
                                </MDBRow>
                                {
                                    myAccountReducer.user.access_card ?
                                        <>
                                            {
                                                (myAccountReducer.user.access_card.status.name.toLowerCase() == ACCESSCARDSTATUS.Active.toString().toLowerCase()) &&
                                                <MDBRow start className="mt-4 justify-content-around">
                                                    <MDBCol className="p-0 text-left" size="5">
                                                        <MDBBtn color="danger"
                                                            onClick={
                                                                () => myAccountReducer.user ?
                                                                    handleCardDeactivation(myAccountReducer.user.user_id, myAccountReducer.user.access_card.id) : undefined
                                                            }>Deactivate</MDBBtn>
                                                    </MDBCol>
                                                    <MDBCol className="p-0 text-left" size="5">

                                                    </MDBCol>
                                                </MDBRow>
                                            }
                                            <MDBRow start className="mt-4 justify-content-around">
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">tag number</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="tag number"
                                                        value={myAccountReducer.user.access_card.tag_number}
                                                        className="form-control styled-input"
                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">status</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="status"
                                                        value={myAccountReducer.user.access_card.status.name}
                                                        className="form-control styled-input"
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow start className="mt-4 justify-content-around">
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">last changed by</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="last changed by"
                                                        value={myAccountReducer.user.access_card.last_changed_by.first_name}
                                                        className="form-control styled-input"
                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">change reason</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="change reason"
                                                        value={myAccountReducer.user.access_card.change_reason.name}
                                                        className="form-control styled-input"
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow start className="mt-4 justify-content-around">
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">last changed at</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="last changed at"
                                                        value={myAccountReducer.user.access_card.last_changed_at}
                                                        className="form-control styled-input"
                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">requested date</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="requested date"
                                                        value={myAccountReducer.user.access_card.requested_date}
                                                        className="form-control styled-input"
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow start className="mt-4 justify-content-around">
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">activation date</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="activation date"
                                                        value={myAccountReducer.user.access_card.activation_date}
                                                        className="form-control styled-input"
                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 text-left" size="5">

                                                </MDBCol>
                                            </MDBRow>
                                        </> :
                                        <MDBRow start className="mt-4 justify-content-around">
                                            <MDBCol className="p-0 text-left" size="5">
                                                <MDBBtn color="success"
                                                    onClick={
                                                        () => myAccountReducer.user ?
                                                            handleCardRequest(myAccountReducer.user.user_id) : undefined
                                                    }>Request card</MDBBtn>
                                            </MDBCol>
                                            <MDBCol className="p-0 text-left" size="5">

                                            </MDBCol>
                                        </MDBRow>
                                }
                            </> : ''
                    }
                </Loader>
            </MDBCol>
        </MDBRow >
    )
}

export default MyAccount;