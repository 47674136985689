import React from "react";
import './index.scss'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import UnitsPage from "../units"
import OperatorsPage from "../operators"
import TabsButtons from "../../components/tabsButtons/index"
import { MDBRow, MDBCardText, MDBCol } from 'mdbreact';
import Can from "../../containers/can";
import branchesIcon from "../../assets/img/pages-icons/inventory-icon.png";
import OrdersListPage from "./ordersList";
interface IBranchesPageProps {
}

export default function OrdersPage(props: IBranchesPageProps) {
    let { path } = useRouteMatch();
    return (
        <>
            <MDBRow className="m-0">
                <MDBRow className="ml-2 mt-0 mb-0 w-100">
                    <MDBCol size={`12`} md={`6`}>
                        <img className="page-image d-inline-block" src={branchesIcon} />
                        <MDBCardText className="page-title d-inline-block">Orders</MDBCardText>
                        <span className="page-line d-inline-block" />
                    </MDBCol>
                </MDBRow>
            </MDBRow>
            <Switch>
                <Route exact path={path} component={OrdersListPage} />
            </Switch>
        </>
    )
}