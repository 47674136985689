import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';
import {
    LOAD_PRODUCTS_FILTERS, LOAD_PRODUCTS,
    UPDATE_IS_LOADING_PRODUCTS, DELETE_PRODUCT,
    UPDATE_PRODUCT
} from '../../sagas/products/types';

export interface ILoadProductsFiltersAction {
    readonly type: typeof LOAD_PRODUCTS_FILTERS;
    payload: {
        // categoryList: IDropdown[],
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    }
}

export const loadProductsFiltersProps = (payload: ILoadProductsFiltersAction['payload']): ILoadProductsFiltersAction => {
    return {
        type: LOAD_PRODUCTS_FILTERS,
        payload: payload
    }
};

export interface ILoadProductsAction {
    readonly type: typeof LOAD_PRODUCTS;
    payload: {
        products: IProduct[],
        totalCountPages: number
    }
}

export const loadProductsProps = (payload: ILoadProductsAction['payload']): ILoadProductsAction => {
    return {
        type: LOAD_PRODUCTS,
        payload: payload
    }
};

export interface IUpdateIsLoadingProductsAction {
    readonly type: typeof UPDATE_IS_LOADING_PRODUCTS;
    payload: {
        isLoadingProducts: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingProductsProps = (payload: IUpdateIsLoadingProductsAction['payload']): IUpdateIsLoadingProductsAction => {
    return {
        type: UPDATE_IS_LOADING_PRODUCTS,
        payload: payload
    }
};

export interface IDeleteProductsAction {
    readonly type: typeof DELETE_PRODUCT;
    payload: {
        productId: IProduct['id'],
    }
}

export const deleteProductsProps = (payload: IDeleteProductsAction['payload']): IDeleteProductsAction => {
    return {
        type: DELETE_PRODUCT,
        payload: payload
    }
};

export interface IUpdateProductsAction {
    readonly type: typeof UPDATE_PRODUCT;
    payload: {
        productId: string,
        cart_type: string
    }
}

export const updateProductsProps = (payload: IUpdateProductsAction['payload']): IUpdateProductsAction => {
    return {
        type: UPDATE_PRODUCT,
        payload: payload
    }
};

export type ProductsActions = ILoadProductsFiltersAction | ILoadProductsAction |
    IUpdateIsLoadingProductsAction | IDeleteProductsAction | IUpdateProductsAction;