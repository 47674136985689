import IUserGroup, { IPermission } from '../../dtos/IUserGroup';
import {LOAD_USER_GROUP, UPDATE_IS_LOADING_USER_GROUP, DELETE_USER_GROUP, LOAD_USER_GROUP_PERMISSIONS} from '../../sagas/user-group/types';

export interface ILoadUserGroupAction {
    readonly type: typeof LOAD_USER_GROUP;
    payload: {
      userGroups: IUserGroup[],
      totalCountPages: number
    }
}

export const loadUserGroupProps = (payload: ILoadUserGroupAction['payload']): ILoadUserGroupAction => {
    return {
        type: LOAD_USER_GROUP,
        payload: payload
    }
};

export interface IUpdateIsLoadingUserGroupAction {
    readonly type: typeof UPDATE_IS_LOADING_USER_GROUP;
    payload:{
        isLoadingUserGroup: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingUserGroupProps = (payload: IUpdateIsLoadingUserGroupAction['payload']): IUpdateIsLoadingUserGroupAction => {
    return {
        type: UPDATE_IS_LOADING_USER_GROUP,
        payload: payload
    }
};

export interface IDeleteUserGroupAction {
    readonly type: typeof DELETE_USER_GROUP;
    payload: {
      userGroupId: IUserGroup['id']
    }
}

export const deleteUserGroupProps = (payload: IDeleteUserGroupAction['payload']): IDeleteUserGroupAction => {
    return {
        type: DELETE_USER_GROUP,
        payload: payload
    }
};

export interface ILoadUserGroupPermissionsAction {
    readonly type: typeof LOAD_USER_GROUP_PERMISSIONS;
    payload: {
      userGroupPermissions: IPermission[],
    }
}

export const loadUserGroupPermissionsProps = (payload: ILoadUserGroupPermissionsAction['payload']): ILoadUserGroupPermissionsAction => {
    return {
        type: LOAD_USER_GROUP_PERMISSIONS,
        payload: payload
    }
};

export type UserGroupActions = ILoadUserGroupAction | IUpdateIsLoadingUserGroupAction | IDeleteUserGroupAction | ILoadUserGroupPermissionsAction;