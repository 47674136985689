import {
    LOAD_MANUAL_STORAGES,
    LOAD_ADJUSTMENT_REASONS, SEND_REQUEST,
    LOAD_MY_REQUESTS, LOAD_RECEIVED_REQUESTS, LOAD_REQUEST, REQUEST_RESPONSE
} from "../../sagas/manual-storage/types";
import { IUpdateIsLoadingRequestsAction, ManualStorageActions } from "./types"
import IVendor from "../../dtos/IVendor"
import IStorage from "../../dtos/IStorage"
import IReason from "../../dtos/IReason"

interface IManualStorageState {
    vendors: IVendor[],
    manualStorages: IStorage[],
    adjustmentReasons: IReason[],
    requestSent: boolean,
    //requests
    myRequests: any[],
    totalMyRequestsCount: number,
    receivedRequests: any[],
    totalReceivedRequestsCount: number,
    request: any,
    responseSent: boolean,
    loadingRequests: IUpdateIsLoadingRequestsAction['payload']
}
const ManualStorageState: IManualStorageState = {
    vendors: [],
    manualStorages: [],
    adjustmentReasons: [],
    requestSent: false,
    myRequests: [],
    totalMyRequestsCount: 0,
    receivedRequests: [],
    totalReceivedRequestsCount: 0,
    request: {},
    responseSent: false,
    loadingRequests: {
        isLoadingRequests: false,
        errorMessage: undefined
    }
}
const manualStorageReducer = (state = ManualStorageState, action: ManualStorageActions): IManualStorageState => {
    switch (action.type) {
        case LOAD_MANUAL_STORAGES:
            return {
                ...state,
                manualStorages: action.payload.manualStorages
            }
        case LOAD_ADJUSTMENT_REASONS:
            return {
                ...state,
                adjustmentReasons: action.payload.adjustmentReasons

            }
        case SEND_REQUEST:
            return {
                ...state,
                requestSent: action.payload.requestSent
            }
        case LOAD_MY_REQUESTS:
            return {
                ...state,
                myRequests: action.payload.myRequests,
                totalMyRequestsCount: action.payload.totalMyRequestsCount
            }
        case LOAD_RECEIVED_REQUESTS:
            return {
                ...state,
                receivedRequests: action.payload.receivedRequests,
                totalReceivedRequestsCount: action.payload.totalReceivedRequestsCount
            }
        case LOAD_REQUEST:
            return {
                ...state,
                request: action.payload.request
            }
        case REQUEST_RESPONSE:
            return {
                ...state,
                responseSent: action.payload.responseSent
            }
        default:
            return state;
    }
}
export default manualStorageReducer
