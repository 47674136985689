import { Button, Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAreas, fetchCities, fetchShippingCountries } from "../../api/address";
import { addressFormStyles, buttonLeft, buttonStyle, colorRed, formItemStyle, itemStyle, labelStyle, titleStyle } from "./styles";
import IArea from "../../dtos/IArea";
import ICity from "../../dtos/ICity";
import ICountry from "../../dtos/ICountry";
import { addNewBranch } from "../../api/branches";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface IAddressForm {
  toggleBranch: any;
}

export default function AddressForm({ toggleBranch }: IAddressForm) {
  const [shippingCountries, setShippingCountries] = useState<ICountry[]>([]);
  const [shippingCities, setShippingCities] = useState<ICity[]>([]);
  const [shippingAreas, setShippingAreas] = useState<IArea[]>([]);
  const branchReducer = useSelector((state: AppState) => state.branchReducer)
  const [mobileCode, setMobileCode] = useState("");
  const { Option } = Select;
  const [form] = Form.useForm();

  const initialAddress: any = {
    id: undefined,
    name: "",
    first_name: "",
    last_name: "",
    mobile: "",
    custom_area: null,
    street: "",
    building: "",
    floor: "",
    apartment: "",
    landmark: "",
    notes: null,
    is_verified: true,
    is_default: true,
    created_at: "",
    country: null,
    city: null,
    area: null,
  };
  const handleSumbitAddressForm = (values: any) => {
    values.country = JSON.parse(values.country);
    values.city = JSON.parse(values.city);
    values.area = JSON.parse(values.area);
    values.is_verified = true;
    values.is_default = true;
    const branch: any = {
      name: values.first_name + " " + values.last_name,
      service_account_id: branchReducer.branch && branchReducer.branch.service_account_id ? branchReducer.branch.service_account_id : localStorage.getItem("service_account_id"),
      longitude: branchReducer.branch && branchReducer.branch.longitude ? branchReducer.branch.longitude : 0.0,
      latitude: branchReducer.branch && branchReducer.branch.latitude ? branchReducer.branch.latitude : 0.0,
      phone: "0" + values.mobile,
    }
    addNewBranch({ ...branch, service_account_id: parseInt(branch.service_account_id), address_id: values }).then((res) => {
      toggleBranch(false);
      form.resetFields();
      setMobileCode("");
    }).catch((err) => {

    });
  };

  useEffect(() => {
    const countriesCall: Promise<ICountry[]> = fetchShippingCountries();
    countriesCall.then((res) => setShippingCountries(res));
  }, []);

  const onChooseCountry = (country: ICountry) => {
    setShippingCities([]);
    const citiesCall: Promise<ICity[]> = fetchCities(parseInt(country?.id));
    citiesCall.then((res) => setShippingCities(res));
    setMobileCode(country.mobile_code);
  };

  const onChooseCity = (city: ICity) => {
    setShippingAreas([]);
    const areasCall: Promise<IArea[]> = fetchAreas(parseInt(city?.id));
    areasCall.then((res) => setShippingAreas(res));
  };

  return (
    <Form
      form={form} initialValues={initialAddress} name="addressForm" style={addressFormStyles} onFinish={handleSumbitAddressForm} scrollToFirstError>
      <Typography style={titleStyle}>Branch Information</Typography>
      <Row>
        <Col span={12}>
          <Form.Item name="first_name" rules={[
            {
              message: "Please Enter First name",
              required: true,
            },
          ]}>
            <div style={formItemStyle}>
              <label htmlFor="first_name" style={labelStyle}>
                First Name<span style={colorRed}>*</span>
              </label>
              <Input style={itemStyle} />
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="last_name">
            <div style={formItemStyle}>
              <label htmlFor="last_name" style={labelStyle}>
                Last Name
              </label>
              <Input style={itemStyle} />
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item name="email" rules={[
            {
              message: "Please Enter Email",
              required: true,
            },
          ]}>
            <div style={formItemStyle}>
              <label htmlFor="email" style={labelStyle}>
                Email<span style={colorRed}>*</span>
              </label>
              <Input type="email" style={itemStyle} />
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Typography style={titleStyle}>Address Details</Typography>
      <Row>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="country" style={labelStyle}>
              Country<span style={colorRed}>*</span>
            </label>
            <Form.Item
              name="country"
              style={itemStyle}
              rules={[
                {
                  message: "Please select country name",
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                onChange={(value: string) => {
                  onChooseCountry(JSON.parse(value));
                  form.setFieldsValue({ city: null });
                  form.setFieldsValue({ area: null });
                }}
              >
                {shippingCountries.map((country, index) => (
                  <Option key={index.toString()} value={JSON.stringify(country)}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="phone_number" style={labelStyle}>
              Mobile<span style={colorRed}>*</span>
            </label>
            <Form.Item
              name="mobile"
              style={itemStyle}
              rules={[
                {
                  message: "Phone number should be 10 digits only",
                  len: 10,
                },
                {
                  message: "Phone number is required",
                  required: true,
                },
              ]}
            >
              <Input type="phone" addonBefore={mobileCode} />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="city" style={labelStyle}>
              City<span style={colorRed}>*</span>
            </label>
            <Form.Item
              name="city"
              style={itemStyle}
              rules={[
                {
                  message: "Please Select City",
                  required: true,
                },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                onChange={(value: string) => {
                  onChooseCity(JSON.parse(value))
                  form.setFieldsValue({ area: null });
                }}>
                {shippingCities.map((city, index) => (
                  <Option key={index.toString()} value={JSON.stringify(city)}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="area" style={labelStyle}>
              Area<span style={colorRed}>*</span>
            </label>
            <Form.Item
              name="area"
              style={itemStyle}
              rules={[
                {
                  message: "Please Select Area",
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
              >
                {shippingAreas.map((area, index) => (
                  <Option key={index.toString()} value={JSON.stringify(area)}>
                    {area.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="street" style={labelStyle}>
              Street<span style={colorRed}>*</span>
            </label>
            <Form.Item
              name="street"
              style={itemStyle}
              rules={[
                {
                  message: "Street name is required",
                  required: true,
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="building" style={labelStyle}>
              Building Number<span style={colorRed}>*</span>
            </label>
            <Form.Item
              name="building"
              style={itemStyle}
              rules={[
                {
                  message: "Building number is required",
                  required: true,
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="floor" style={labelStyle}>
              Floor Number
            </label>
            <Form.Item name="floor" style={itemStyle}>
              <Input type="text" />
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="apartment" style={labelStyle}>
              Apartment Number
            </label>
            <Form.Item name="apartment" style={itemStyle}>
              <Input type="text" />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="landmark" style={labelStyle}>
              Landmark
            </label>
            <Form.Item name="landmark" style={itemStyle}>
              <Input type="text" />
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div style={formItemStyle}>
            <label htmlFor="name" style={labelStyle}>
              Address Name
            </label>
            <Form.Item name="name" style={itemStyle}>
              <Input type="text" />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Form.Item style={buttonLeft}>
        <Button style={buttonStyle} type="primary" htmlType="submit">
          Create Branch
        </Button>
      </Form.Item>
    </Form>
  );
}
