export const LOAD_MANUAL_STORAGES = "LOAD_MANUAL_STORAGES"
export const LOAD_MANUAL_STORAGES_SAGA = "LOAD_MANUAL_STORAGES_SAGA"

export const LOAD_ADJUSTMENT_REASONS = "LOAD_ADJUSTMENT_REASONS"
export const LOAD_ADJUSTMENT_REASONS_SAGA = "LOAD_ADJUSTMENT_REASONS_SAGA"


export const SEND_REQUEST = "SEND_REQUEST"
export const SEND_REQUEST_SAGA = "SEND_REQUEST_SAGA"

//requests
export const LOAD_MY_REQUESTS = "LOAD_MY_REQUESTS"
export const LOAD_MY_REQUESTS_SAGA = "LOAD_MY_REQUESTS_SAGA"

export const LOAD_RECEIVED_REQUESTS = "LOAD_RECEIVED_REQUESTS"
export const LOAD_RECEIVED_REQUESTS_SAGA = "LOAD_RECEIVED_REQUESTS_SAGA"

export const LOAD_REQUEST = "LOAD_REQUEST"
export const LOAD_REQUEST_SAGA = "LOAD_REQUEST_SAGA"

export const REQUEST_RESPONSE = "REQUEST_RESPONSE"
export const REQUEST_RESPONSE_SAGA = "REQUEST_RESPONSE_SAGA"

export const UPDATE_IS_LOADING_REQUESTS = "UPDATE_IS_LOADING_REQUESTS";


export interface ILoadManualStoragesActionSaga {
    readonly type: typeof LOAD_MANUAL_STORAGES_SAGA;
}
export const loadManualStoragesPropsSaga = (): ILoadManualStoragesActionSaga => {
    return {
        type: LOAD_MANUAL_STORAGES_SAGA,
    }
};

export interface ILoadAdjustmentReasonsActionSaga {
    readonly type: typeof LOAD_ADJUSTMENT_REASONS_SAGA;
}
export const loadAdjustmentReasonsPropsSaga = (): ILoadAdjustmentReasonsActionSaga => {
    return {
        type: LOAD_ADJUSTMENT_REASONS_SAGA,
    }
};

export interface ISendRequestActionSaga {
    readonly type: typeof SEND_REQUEST_SAGA;
    request: any;
    requestType: string


}
export const sendRequestPropsSaga = (request: any, requestType: string): ISendRequestActionSaga => {
    return {
        type: SEND_REQUEST_SAGA,
        request: request,
        requestType: requestType
    }
};

//requests
export interface ILoadMyRequestsActionSaga {
    readonly type: typeof LOAD_MY_REQUESTS_SAGA;
    page: number,
    size: number,
    sort: string,
}
export const loadMyRequestsPropsSaga = (page = 0, size = 10, sort = ''): ILoadMyRequestsActionSaga => {
    return {
        type: LOAD_MY_REQUESTS_SAGA,
        page: page,
        size: size,
        sort: sort,
    }
};
export interface ILoadReceivedRequestsActionSaga {
    readonly type: typeof LOAD_RECEIVED_REQUESTS_SAGA;
    page: number,
    size: number,
    sort: string,
}
export const loadReceivedRequestsPropsSaga = (page = 0, size = 10, sort = ''): ILoadReceivedRequestsActionSaga => {
    return {
        type: LOAD_RECEIVED_REQUESTS_SAGA,
        page: page,
        size: size,
        sort: sort,
    }
};
export interface ILoadRequestActionSaga {
    readonly type: typeof LOAD_REQUEST_SAGA;
    requestId: number,
}
export const loadRequestPropsSaga = (requestId: number): ILoadRequestActionSaga => {
    return {
        type: LOAD_REQUEST_SAGA,
        requestId: requestId
    }
};
export interface IRequestResponseActionSaga {
    readonly type: typeof REQUEST_RESPONSE_SAGA;
    response: any;
}
export const requestResponsePropsSaga = (response: any): IRequestResponseActionSaga => {
    return {
        type: REQUEST_RESPONSE_SAGA,
        response: response,
    }
};