import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_OPERATORS_SAGA, ILoadOperatorsActionSaga,
    LOAD_OPERATOR_SAGA, ILoadOperatorActionSaga,
    LOAD_OPERATOR_STATUS_SAGA, ILoadOperatorStatusActionSaga,
    ADD_OPERATOR_SAGA, IAddOperatorActionSaga,
    UPDATE_OPERATOR_SAGA, IUpdateOperatorActionSaga
} from "./types";
import { fetchOperators, fetchOperator, fetchOperatorStatus, addOperator, updateOperator } from "../../api/operators";
import {
    loadOperatorsProps, loadOperatorProps, loadOperatorStatusProps,
    addOperatorProps, updateOperatorProps,
    updateIsLoadingOperatorsProps
} from "../../reducers/operators/types";
import IOperator from "../../dtos/IOperator";
import IStatus from "../../dtos/IStatus";

function* loadOperators(action: ILoadOperatorsActionSaga) {
    try {
        yield put(updateIsLoadingOperatorsProps({ isLoadingOperators: true, errorMessage: undefined }));
        const operatorsData: { operators: IOperator[], totalOperatorsCount: number } = yield call(fetchOperators, action.page, action.size, action.sort, action.name, action.status);
        yield put(loadOperatorsProps({ operators: operatorsData.operators, totalOperatorsCount: operatorsData.totalOperatorsCount }))
    } catch (error) {
        yield put(updateIsLoadingOperatorsProps({ isLoadingOperators: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadOperators() {
    yield takeLatest(LOAD_OPERATORS_SAGA, loadOperators)
}

function* loadOperator(action: ILoadOperatorActionSaga) {
    try {
        const operator: IOperator = yield call(fetchOperator, action.operatorId);
        yield put(loadOperatorProps({ operator }))
    } catch (error) {
        yield put(updateIsLoadingOperatorsProps({ isLoadingOperators: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadOperator() {
    yield takeLatest(LOAD_OPERATOR_SAGA, loadOperator)
}

function* loadOperatorStatus(action: ILoadOperatorStatusActionSaga) {
    try {
        const operatorStatus: IStatus[] = yield call(fetchOperatorStatus);
        yield put(loadOperatorStatusProps({ operatorStatus }))
    } catch (error) {
        yield put(updateIsLoadingOperatorsProps({ isLoadingOperators: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadOperatorStatus() {
    yield takeLatest(LOAD_OPERATOR_STATUS_SAGA, loadOperatorStatus)
}

function* addOperatorData(action: IAddOperatorActionSaga) {
    try {
        const operatorAdded: boolean = yield call(addOperator, action.operator);
        yield put(addOperatorProps({ operatorAdded }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchAddOperatorData() {
    yield takeLatest(ADD_OPERATOR_SAGA, addOperatorData)
}

function* updateOperatorData(action: IUpdateOperatorActionSaga) {
    try {
        const operatorUpdated: boolean = yield call(updateOperator, action.operatorId, action.operator);
        yield put(updateOperatorProps({ operatorUpdated }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchUpdateOperatorData() {
    yield takeLatest(UPDATE_OPERATOR_SAGA, updateOperatorData)
}

export default [
    watchLoadOperators(),
    watchLoadOperator(),
    watchAddOperatorData(),
    watchUpdateOperatorData(),
    watchLoadOperatorStatus()
]
