import React from "react";
import './index.scss'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListUserGroup from "./list";
import AddEditUserGroup from "./addEdit";

interface IUserGroupPageProps {
}

export default function UserGroupPage(props: IUserGroupPageProps) {
    const { path, url } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/add`}><AddEditUserGroup title="Add new user group" type="create"/></Route>
                <Route path={`${path}/:id/edit`}><AddEditUserGroup title="Edit user group" type="update"/></Route>
                <Route exact path={path}><ListUserGroup/></Route>
            </Switch>
        </>
    )
}