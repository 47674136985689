export const LOAD_DASHBOARD_SAGA = 'LOAD_DASHBOARD_SAGA';
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';
export const UPDATE_IS_LOADING_DASHBOARD = 'UPDATE_IS_LOADING_DASHBOARD';


export interface ILoadDashboardActionSaga {
    readonly type: typeof LOAD_DASHBOARD_SAGA;
}

export const loadDashboardPropsSaga = (): ILoadDashboardActionSaga => {
    return {
        type: LOAD_DASHBOARD_SAGA,
    }
};
