import { LOAD_DASHBOARD, UPDATE_IS_LOADING_DASHBOARD } from '../../sagas/dashboard/types';
export interface ILoadDashboardAction {
    readonly type: typeof LOAD_DASHBOARD;
    payload: {
        itemsCount: number,
        productsCount: number,
        warningsCount: number,
        criticalsCount: number,
    }
}

export const loadDashboardProps = (payload: ILoadDashboardAction['payload']): ILoadDashboardAction => {
    return {
        type: LOAD_DASHBOARD,
        payload: payload
    }
};

export interface IUpdateIsLoadingDashboardAction {
    readonly type: typeof UPDATE_IS_LOADING_DASHBOARD;
    payload: {
        isLoadingDashboard: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingDashboardProps = (payload: IUpdateIsLoadingDashboardAction['payload']): IUpdateIsLoadingDashboardAction => {
    return {
        type: UPDATE_IS_LOADING_DASHBOARD,
        payload: payload
    }
};

export type DashboardActions = ILoadDashboardAction | IUpdateIsLoadingDashboardAction;