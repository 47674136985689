import {SMOPreviewActions, IUpdateIsLoadingSMOPreviewAction} from './types'
import {LOAD_SMO_PREVIEW, UPDATE_IS_LOADING_SMO_PREVIEW} from "../../sagas/smart_ordering_preview/types";

interface ISMOPreviewState {
    orders: any[]
    filters: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    },
    totalCountPages: number,
    loadingSMOPreview: IUpdateIsLoadingSMOPreviewAction['payload'],
}

const SMOPreviewState: ISMOPreviewState = {
    orders: [],
    filters: {
        sortCol: '',
        sortOrder: 'ascending'
    },
    totalCountPages: 0,
    loadingSMOPreview: {
        isLoadingSMOPreview: false,
        errorMessage: undefined
    }
};

const smoPreviewReducer = (state = SMOPreviewState, action: SMOPreviewActions): ISMOPreviewState => {
    switch (action.type) {
        case LOAD_SMO_PREVIEW:
            return {
                ...state,
                orders: action.payload.orders,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_SMO_PREVIEW:
            return {
                ...state,
                loadingSMOPreview: action.payload,
            };
        default:
            return state;
    }
};

export default smoPreviewReducer;
