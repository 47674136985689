import IBranch from "../../dtos/IBranch";

export const LOAD_BRANCHES = "LOAD_BRANCHES";
export const LOAD_BRANCHES_SAGA = "LOAD_BRANCHES_SAGA";

export const LOAD_BRANCH = "LOAD_BRANCH";
export const LOAD_BRANCH_SAGA = "LOAD_BRANCH_SAGA";

export const ADD_NEW_BRANCH = "ADD_NEW_BRANCH"
export const ADD_NEW_BRANCH_SAGA = "ADD_NEW_BRANCH_SAGA"

export const UPDATE_BRANCH = "UPDATE_BRANCH"
export const UPDATE_BRANCH_SAGA = "UPDATE_BRANCH_SAGA"

export const DELETE_BRANCH = "DELETE_BRANCH"
export const DELETE_BRANCH_SAGA = "DELETE_BRANCH_SAGA"

export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const LOAD_COUNTRIES_SAGA = "LOAD_COUNTRIES_SAGA";

export const LOAD_CITIES = "LOAD_CITIES";
export const LOAD_CITIES_SAGA = "LOAD_CITIES_SAGA";

export const UPDATE_IS_LOADING_BRANCHES = "UPDATE_IS_LOADING_BRANCHES";

export interface ILoadBranchesActionSaga {
    readonly type: typeof LOAD_BRANCHES_SAGA;
    page: number,
    size: number,
    sort: string
}
export const loadBranchesPropsSaga = (page = 0, size = 0, sort = ''): ILoadBranchesActionSaga => {
    return {
        type: LOAD_BRANCHES_SAGA,
        page: page,
        sort: sort,
        size: size
    }
};

export interface ILoadBranchActionSaga {
    readonly type: typeof LOAD_BRANCH_SAGA;
    branchId: number
}
export const loadBranchPropsSaga = (branchId = 0): ILoadBranchActionSaga => {
    return {
        type: LOAD_BRANCH_SAGA,
        branchId: branchId
    }
};

export interface IAddNewBranchActionSaga {
    readonly type: typeof ADD_NEW_BRANCH_SAGA;
    branch: IBranch
}
export const addNewBranchPropsSaga = (branch: IBranch): IAddNewBranchActionSaga => {
    return {
        type: ADD_NEW_BRANCH_SAGA,
        branch: branch
    }
};

export interface IUpdateBranchActionSaga {
    readonly type: typeof UPDATE_BRANCH_SAGA;
    branchId: number
    branch: IBranch | any;
}
export const updateBranchPropsSaga = (branchId: number, branch: IBranch): IUpdateBranchActionSaga => {
    return {
        type: UPDATE_BRANCH_SAGA,
        branchId: branchId,
        branch: branch
    }
};
export interface IDeleteBranchActionSaga {
    readonly type: typeof DELETE_BRANCH_SAGA;
    branchId: number
}
export const deleteBranchPropsSaga = (branchId: number): IDeleteBranchActionSaga => {
    return {
        type: DELETE_BRANCH_SAGA,
        branchId: branchId,
    }
};

export interface ILoadCountriesActionSaga {
    readonly type: typeof LOAD_COUNTRIES_SAGA;
}
export const loadCountriesPropsSaga = (): ILoadCountriesActionSaga => {
    return {
        type: LOAD_COUNTRIES_SAGA,
    }
};
export interface ILoadCitiesActionSaga {
    readonly type: typeof LOAD_CITIES_SAGA;
    uuid: string;
}
export const loadCitiesPropsSaga = (uuid: string): ILoadCitiesActionSaga => {
    return {
        type: LOAD_CITIES_SAGA,
        uuid: uuid
    }
};