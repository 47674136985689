import INotification from "../../dtos/INotification";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS"
export const LOAD_NOTIFICATIONS_SAGA = "LOAD_NOTIFICATIONS_SAGA"
export const UPDATE_IS_LOADING_NOTIFICATIONS = "UPDATE_IS_LOADING_NOTIFICATIONS"

export interface ILoadNotificationsActionSaga {
    readonly type: typeof LOAD_NOTIFICATIONS_SAGA;
    page: number,
    size: number,
    isDismissed: boolean,
}

export const loadNotificationsPropsSaga = (isDismissed= false, page = 0, size = 10): ILoadNotificationsActionSaga => {
    return {
        type: LOAD_NOTIFICATIONS_SAGA,
        page: page,
        size: size,
        isDismissed: isDismissed
    }
};

export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION"
export const DISMISS_NOTIFICATION_SAGA = "DISMISS_NOTIFICATION_SAGA"
export interface IDismissNotificationActionSaga {
    readonly type: typeof DISMISS_NOTIFICATION_SAGA;
    uuid: string
}

export const dismissNotificationPropsSaga = (uuid = ''): IDismissNotificationActionSaga => {
    return {
        type: DISMISS_NOTIFICATION_SAGA,
        uuid: uuid
    }
};

export const UNDISMISS_NOTIFICATION = "UNDISMISS_NOTIFICATION"
export const UNDISMISS_NOTIFICATION_SAGA = "UNDISMISS_NOTIFICATION_SAGA"
export interface IUndismissNotificationActionSaga {
    readonly type: typeof UNDISMISS_NOTIFICATION_SAGA;
    uuid: string
}

export const undismissNotificationPropsSaga = (uuid = ''): IUndismissNotificationActionSaga => {
    return {
        type: UNDISMISS_NOTIFICATION_SAGA,
        uuid: uuid
    }
};

export const PUSH_INCOMING_NOTIFICATION = "PUSH_INCOMING_NOTIFICATION"
export const PUSH_INCOMING_NOTIFICATION_SAGA = "PUSH_INCOMING_NOTIFICATION_SAGA"
export interface IPushIncomingNotificationActionSaga {
    readonly type: typeof PUSH_INCOMING_NOTIFICATION_SAGA;
    notification: INotification
}

export const pushIncomingNotificationPropsSaga = (notification: IPushIncomingNotificationActionSaga['notification']): IPushIncomingNotificationActionSaga => {
    return {
        type: PUSH_INCOMING_NOTIFICATION_SAGA,
        notification: notification
    }
};