import {
    GET_ORDERS, GET_ORDER, GET_ORDER_ITEMS, UPDATE_IS_LOADING_ORDERS
} from "../../sagas/orders/types";
import { IOrder } from '../../dtos/order';

export interface ILoadOrdersAction {
    readonly type: typeof GET_ORDERS;
    payload: {
        data: any,
        totalOrdersCount: number
    }
}
export const loadOrdersProps = (payload: ILoadOrdersAction['payload']): ILoadOrdersAction => {
    return {
        type: GET_ORDERS,
        payload: payload
    }
};
export interface ILoadOrderAction {
    readonly type: typeof GET_ORDER;
    payload: {
        order: IOrder | null,
    }
}
export const loadOrderProps = (payload: ILoadOrderAction['payload']): ILoadOrderAction => {
    return {
        type: GET_ORDER,
        payload: payload
    }
};
export interface ILoadOrderItemsAction {
    readonly type: typeof GET_ORDER_ITEMS;
    payload: {
        data: any,
        totalOrderItemsCount: number
    }
}
export const loadOrderItemsProps = (payload: ILoadOrderItemsAction['payload']): ILoadOrderItemsAction => {
    return {
        type: GET_ORDER_ITEMS,
        payload: payload
    }
};

export interface IUpdateIsLoadingOrdersAction {
    readonly type: typeof UPDATE_IS_LOADING_ORDERS;
    payload: {
        isLoadingOrders: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingOrdersProps = (payload: IUpdateIsLoadingOrdersAction['payload']): IUpdateIsLoadingOrdersAction => {
    return {
        type: UPDATE_IS_LOADING_ORDERS,
        payload: payload
    }
};
export type OrdersActions = ILoadOrdersAction | ILoadOrderAction | ILoadOrderItemsAction | IUpdateIsLoadingOrdersAction;