import React from 'react'
import { MDBIcon, MDBDropdown, MDBDropdownItem, MDBDropdownToggle, MDBDropdownMenu } from 'mdbreact';
import "./styles.scss"
interface ITableMenuProps {
    menuItems: any,
    rowValue: any
}
export default function TableMenu(props: ITableMenuProps) {
    return (
        <MDBDropdown className="table-dropdown" dropleft>
            <MDBDropdownToggle className="menuButton" color="transparent">
                <MDBIcon className="menuIcon" icon="ellipsis-v" />
            </MDBDropdownToggle>
            <MDBDropdownMenu className="menu" basic>
                {props.menuItems.map((m: any, index: any) => {
                    return <div>
                        <MDBDropdownItem className="p-3 menuItem" onClick={() => m.handleChange(props.rowValue)}>
                            <MDBIcon className="pr-2 pl-2\ menuItem" icon={m.icon} />
                            {m.title}</MDBDropdownItem>
                        {index < props.menuItems.length - 1 && <MDBDropdownItem divider />}
                    </div>
                })}
            </MDBDropdownMenu>
        </MDBDropdown>
    )
}
