import React, { useEffect } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import UserGroupForm from '../../containers/user-group/form';

interface IAddEditUserGroupProps {
    title: string,
    type: 'create' | 'update'
}

function AddEditUserGroup(props: IAddEditUserGroupProps) {
    return (<>
        <MDBRow className="m-0">
            <MDBCol className="p-0">
                    <h4 className="ml-3">{props.title}</h4>
            </MDBCol>
        </MDBRow>
        <MDBRow className="usergroup-form-container m-0 bg-white mt-3">
            <MDBCol className="p-0">
                <UserGroupForm type={props.type}/>
            </MDBCol>
        </MDBRow>
    </>)
}

export default AddEditUserGroup;