import React, { useEffect, useState, useMemo, useCallback } from 'react';
import './styles/styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { getOrdersPropsSaga } from "../../sagas/orders/types";
import { updateGlobalModalPropsSaga } from "../../sagas/global/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBModal, MDBModalBody } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import TableMenu from "../../components/tableMenu"
import CustomCheckbox from "../../components/customCheckbox"
import MainBlueButton from "../../components/blueButton";
import Select from "../../components/select"
import constants from "../../constants";
import OrderDetails from "./orderDetails"
import { fetchOrderIds } from '../../api/orders';
import moment from "moment";
import { orderStatusMap } from '../../assets/constants';

interface IOrdersListProps {
}

function OrdersList(props: IOrdersListProps) {
    const dispatch = useDispatch();
    const ordersReducer = useSelector((state: AppState) => state.ordersReducer)
    const routerReducer = useSelector((state: AppState) => state.router)
    const authReducer = useSelector((state: AppState) => state.globalReducer.authUser);
    const { sort, page } = queryString.parse(routerReducer.location.search);
    const [view, selectView] = useState(10)
    const [orderDetailsModalOpen, toggleOrderDetailsModal] = useState(false);
    const [selectedOrder, selectOrder] = useState<any>(null);

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        fetchOrderIds(pageNum, view).then((res: any) => {
            if (res.ids.length > 0) {
                dispatch(getOrdersPropsSaga(res.ids, authReducer.token, res.totalOrdersCount));
            }
        });

    }, [routerReducer.location.search, view]);

    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            if (headerColumn.name.toLowerCase() == 'name' || headerColumn.name.toLowerCase() == 'id') {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    const headers: any = [
        // { name: "check", width: 1 },
        { name: "ID", width: 2 },
        { name: "Status", width: 3 },
        { name: "Created at", width: 3 },
        { name: "Total", width: 3 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }
    const buildRows = (orders: any[]): IRow[] => {
        return orders.map((b: any, i) => {
            return {
                value: b,
                props: [
                    {
                        edit: (rowValue: any) => {
                            return (<div className="cursor offblue-text table-content" onClick={async () => {
                                // load order details
                                selectOrder(rowValue);
                                toggleOrderDetailsModal(!orderDetailsModalOpen)
                            }}>#{rowValue.order_id}</div >);
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (<div className={`status-container ${rowValue.status == 5 ? "delivered" : rowValue.status == 2 || rowValue.status == 3 || rowValue.status == 4 ? "shipping" : rowValue.status == 1 || rowValue.status == 11 ? "inprogress" : "incomplete"} `}>{orderStatusMap[parseInt(rowValue.status)]}</div >);
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (<div className="table-content">{moment(rowValue.ordered_at).format("MMMM DD, yyyy")}</div >);
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (<div className="table-content">{rowValue.total} EGP</div >);
                        }
                    }]
            }
        });
    };
    const rows = useMemo(() => buildRows(ordersReducer.orders.data), [ordersReducer.orders.data]);
    const renderOrderDetailsModal = () => {
        return <MDBModal isOpen={orderDetailsModalOpen} toggle={() => { toggleOrderDetailsModal(!orderDetailsModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="w-100 m-0 pt-5 pb-5 d-flex justify-content-space-between">
                    <MDBCol size="8" className="text-left offblue-text">
                        <h2 className="ml-2 font-weight-bold">{`Order #${selectedOrder?.order_id}`}</h2>
                    </MDBCol>
                    <MDBCol size="4" className="text-left offblue-text justify-content-center">
                        <div className={`status-container ${selectedOrder?.status == 5 ? "delivered" : selectedOrder?.status == 2 || selectedOrder?.status == 3 || selectedOrder?.status == 4 ? "shipping" : selectedOrder?.status == 1 || selectedOrder?.status == 11 ? "inprogress" : "incomplete"} `}>{orderStatusMap[parseInt(selectedOrder?.status)]}</div >
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBModalBody>
                {selectedOrder ? <OrderDetails key={selectedOrder.order_id} order={selectedOrder} /> : undefined}
            </MDBModalBody>
        </MDBModal>
    }

    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={ordersReducer.loadingOrders.isLoadingOrders} errorMessage={ordersReducer.loadingOrders.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                className="mr-2 d-inline"
                                id="view-select"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                ordersReducer.orders.data.length > 0 ?

                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        data={ordersReducer.orders.data}
                                        headerOnClick={() => { }}
                                        totalPages={ordersReducer.orders.totalOrdersCount / view}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                    />
                                    : <div className="mt-5">You don't have any orders yet.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
                {renderOrderDetailsModal()}
            </MDBCol>
        </MDBRow>

    )
}

export default OrdersList;