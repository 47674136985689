import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader,
} from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import {
    loadProductStoragesPropsSaga, filterProductStoragesPropsSaga,
    IFilterProductStoragesActionSaga,
} from '../../sagas/product_storages/types';
import Constants from '../../constants';
import { addQueryString } from '../../helpers';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton"
import { useParams } from 'react-router-dom'

interface IProductStoragesProps {
}

function ProductStorages(props: IProductStoragesProps) {
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page } = queryString.parse(routerReducer.location.search);
    const params: any = useParams();
    //new ui
    const [isOpen, toggleModal] = useState(false)

    let headers: IHeaderColumn[] = [
        { name: "Storage", width: 2, handleClick: () => { } },
        { name: "Quantity", width: 2, handleClick: () => { } },
        { name: "Opened Quantity", width: 2, handleClick: () => { } },
        { name: "Sealed Quantity", width: 2, handleClick: () => { } },
    ];
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const dispatch = useDispatch();
    const IProductStoragesState = useSelector((state: AppState) => state.productStoragesReducer)

    // container logic
    useEffect(() => {
        dispatch(loadProductStoragesPropsSaga());
    }, []);
    useEffect(() => {
        const { sort, page } = queryString.parse(routerReducer.location.search);
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : ['id', 'ascending'];
        const filters: IFilterProductStoragesActionSaga['filters'] = {
            productId: params.productId,
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: 10,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '',
        }
        dispatch(filterProductStoragesPropsSaga(filters));
        // updating components values
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
    }, [routerReducer.location.search]);

    // headers and soring
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && (headerColumn.name.toLowerCase() == columnName.toLowerCase() || headerColumn.backendName?.toLowerCase() == columnName.toLowerCase())) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }

    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending', backendName?: string) => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            if (headerColumn.name.toLowerCase() == columnName.toLowerCase() || headerColumn.backendName?.toLowerCase() == backendName?.toLowerCase()) {
                sorting = `${backendName ? backendName : columnName.toLowerCase()},`;
                switch (columnSorted) {
                    case undefined:
                        sorting += 'ascending';
                        break;
                    case 'ascending':
                        sorting += 'descending';
                        break;
                    default:
                        sorting = '';
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    // body rows data
    const buildRows = (productStorages: any[]): IRow[] => {
        return productStorages.map((productStorage: any, i) => {
            let row: IRow = {
                value: productStorage,
                props: [
                    'storage.name', 'quantity',
                    'opened_quantity',
                    'sealed_quantity',
                ]
            };
            return row;
        });
    };

    const rows = buildRows(IProductStoragesState.productStorages);

    // pagination
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    //filters
    const handleFilter = (filterName: string, value: any) => {
        const newQueryString = addQueryString(routerReducer.location.search, filterName, value);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }

    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalHeader toggle={() => toggleModal(false)} >Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >
                    <div className="w-100 d-flex mt-3 justify-content-between">
                        <MDBRow className="p-0 m-0">
                            <MDBCol className="p-0 m-0" size="12">
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }

    const handleShowResult = () => {
        toggleModal(false);
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={IProductStoragesState.loadingProductStorages.isLoadingProductStorages} errorMessage={IProductStoragesState.loadingProductStorages.errorMessage}>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                IProductStoragesState.productStorages.length > 0 ?
                                    <div className="celled-table">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            headerOnClick={handleSort}
                                            rows={rows}
                                            data={IProductStoragesState.productStorages}
                                            totalPages={IProductStoragesState.totalCountPages / Constants.pagination.productsPerPage}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                    </div>
                                    :
                                    <div>No storages found.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
            </MDBCol>
            {renderModal()}
        </MDBRow>
    )
}

export default ProductStorages;