import React from "react";
import './index.scss';
import BranchesList from "../../containers/branches/branchesList"

interface IBranchesListPageProps {
}

export default function BranchesListPage(props: IBranchesListPageProps) {
    return (
        <>
            <BranchesList />
        </>
    )
}