import React from "react";
import UnitsList from '../../containers/units/unitsList';

interface IUnitsPageProps {
}

export default function UnitsPage(props: IUnitsPageProps) {
    return (
            <UnitsList />
    )
}