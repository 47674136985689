import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_UNITS_SAGA, ILoadUnitsActionSaga,
    LOAD_UNIT_SAGA, ILoadUnitActionSaga,
    LOAD_UNIT_STATUS_SAGA, ILoadUnitStatusActionSaga,
    ADD_UNIT_SAGA, IAddUnitActionSaga,
    UPDATE_UNIT_SAGA, IUpdateUnitActionSaga
} from "./types";
import { fetchUnits, fetchUnit, fetchUnitStatus, addUnit, updateUnit } from "../../api/units";
import {
    loadUnitsProps, loadUnitProps, loadUnitStatusProps,
    addUnitProps, updateUnitProps,
    updateIsLoadingUnitsProps
} from "../../reducers/units/types";
import IUnit from "../../dtos/IUnit";
import IStatus from '../../dtos/IStatus';

function* loadUnits(action: ILoadUnitsActionSaga) {
    try {
        yield put(updateIsLoadingUnitsProps({ isLoadingUnits: true, errorMessage: undefined }));
        const unitsData: { units: IUnit[], totalUnitsCount: number } = yield call(fetchUnits, action.page, action.size, action.sort, action.name, action.status);
        yield put(loadUnitsProps({ units: unitsData.units, totalUnitsCount: unitsData.totalUnitsCount }))
    } catch (error) {
        yield put(updateIsLoadingUnitsProps({ isLoadingUnits: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadUnits() {
    yield takeLatest(LOAD_UNITS_SAGA, loadUnits)
}

function* loadUnit(action: ILoadUnitActionSaga) {
    try {
        const unit: IUnit = yield call(fetchUnit, action.unitId);
        yield put(loadUnitProps({ unit }))
    } catch (error) {
        yield put(updateIsLoadingUnitsProps({ isLoadingUnits: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadUnit() {
    yield takeLatest(LOAD_UNIT_SAGA, loadUnit)
}

function* loadUnitStatus(action: ILoadUnitStatusActionSaga) {
    try {
        const unitStatus: IStatus[] = yield call(fetchUnitStatus);
        yield put(loadUnitStatusProps({ unitStatus }))
    } catch (error) {
        yield put(updateIsLoadingUnitsProps({ isLoadingUnits: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadUnitStatus() {
    yield takeLatest(LOAD_UNIT_STATUS_SAGA, loadUnitStatus)
}


function* addUnitData(action: IAddUnitActionSaga) {
    try {
        const unitAdded: boolean = yield call(addUnit, action.unit);
        yield put(addUnitProps({ unitAdded }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchAddUnitData() {
    yield takeLatest(ADD_UNIT_SAGA, addUnitData)
}

function* updateUnitData(action: IUpdateUnitActionSaga) {
    try {
        const unitUpdated: boolean = yield call(updateUnit, action.unitId, action.unit);
        yield put(updateUnitProps({ unitUpdated }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchUpdateUnitData() {
    yield takeLatest(UPDATE_UNIT_SAGA, updateUnitData)
}

export default [
    watchLoadUnits(),
    watchLoadUnit(),
    watchAddUnitData(),
    watchUpdateUnitData(),
    watchLoadUnitStatus()
]
