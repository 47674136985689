import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { MDBRow } from 'mdbreact';
import { AppState } from "../../store";
import { loadBranchesPropsSaga } from "../../sagas/branch/types";
import { loadUserGroupPropsSaga } from "../../sagas/user-group/types";
import { inviteNewUserProps } from "../../sagas/user/types"
import FormModal from "../../components/form"
import * as Yup from "yup";
import IInvitation from '../../dtos/IInvitation';
const SERVICE_ACC_ID = localStorage.getItem("service_account_id");

interface IInvitationFormProps {
}
const invitation: IInvitation = {
    email: '',
    branch_ids_list: [],
    user_group_ids_list: [],
    tenant_id: SERVICE_ACC_ID
}
function InvitationForm(props: IInvitationFormProps) {
    const dispatch = useDispatch();
    const branchReducer = useSelector((state: AppState) => state.branchReducer)
    const userGroupReducer = useSelector((state: AppState) => state.userGroupReducer)
    const userReducer = useSelector((state: AppState) => state.userReducer)
    const authReducer = useSelector((state: AppState) => state.globalReducer.authUser);

    useEffect(() => {
        dispatch(loadUserGroupPropsSaga(0, 1000))
    }, [])
    useMemo(() => {
        if (userReducer.invitationSent) {
            dispatch(push({ pathname: '/users' }))
        }
    }, [userReducer.invitationSent])
    const fields: any = [
        {
            display: 'col',
            type: 'text',
            label: 'Email',
            required: true,
            placeholder: "User Email",
            name: "FormValues.invitation.email",
            object_name: "invitation",
            field_name: "email"
        },
        {
            display: 'col',
            type: 'multiSelect',
            label: 'Branches',
            required: true,
            name: "FormValues.invitation.branch_ids_list",
            options: authReducer.my_branches.map((b: any) => { return { label: b.name, value: b.id } }),
            onChange: (e: any, values: any) => values.FormValues.invitation.branch_ids_list = e.map((x: any) => x.value),
            multi: true,
            closeMenuOnSelect: false,
            object_name: "invitation",
            field_name: "branch_ids_list"
        },
        {
            display: 'col',
            type: 'multiSelect',
            label: 'User Groups',
            required: true,
            name: "FormValues.invitation.user_group_ids_list",
            options: userGroupReducer.userGroups.map((b: any) => { return { label: b.name, value: b.id } }),
            onChange: (e: any, values: any) => values.FormValues.invitation.user_group_ids_list = e.map((x: any) => x.value),
            multi: true,
            clearable: true,
            closeMenuOnSelect: false,
            object_name: "invitation",
            field_name: "user_group_ids_list"
        }
    ]
    const onSubmit = (values: any) => {
        dispatch(inviteNewUserProps(values.invitation))
    }
    const validationSchema: object = Yup.object({
        FormValues: Yup.object({
            invitation: Yup.object({
                email: Yup.string().email("Invalid email").required("Required!"),
                branch_ids_list: Yup.array().min(1, "Required!").required("Required!"),
                user_group_ids_list: Yup.array().min(1, "Required!").required("Required!")
            })
        })
    })
    return (
        <MDBRow className="m-0">
            <FormModal
                id="invitation-form"
                initialValues={{ invitation }}
                validationSchema={validationSchema}
                fields={fields}
                onSubmit={onSubmit}
            />
        </MDBRow>
    )
}

export default InvitationForm;