import { axiosInstance } from "../store";
import IVendor from "../dtos/IVendor";
import { handlStatusCodeMessage } from "../helpers";

export function fetchVendors(page = 0, size = 10, sort = '', name = ''): Promise<{ vendors: IVendor[], totalVendorsCount: 0 }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/vendor?page=${page}&size=${size}&sort=${sort}&name=${name}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    vendors: res.data,
                    totalVendorsCount: res.headers['x-total-count']
                }
            }
            else {
                return {
                    vendors: [],
                    totalVendorsCount: 0
                }
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'vendor' });
            return {
                vendors: [],
                totalVendorsCount: 0
            }
        })
}

export function fetchVendor(vendorId: number): Promise<IVendor> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/vendor/${vendorId}`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return {}
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'vendor' });
            return {}
        })
}
export function addVendor(vendor: IVendor): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/vendor`, vendor)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
            return (res.status == 200)
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'vendor' });
            return false
        })

}
export function updateVendor(vendorId: number, vendor: IVendor): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/vendor/${vendorId}`, vendor)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
            return (res.status == 200)
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'vendor' });
            return false
        })

}