import IUnit from "../../dtos/IUnit"

export const LOAD_UNITS = "LOAD_UNITS"
export const LOAD_UNITS_SAGA = "LOAD_UNITS_SAGA"

export const LOAD_UNIT = "LOAD_UNIT"
export const LOAD_UNIT_SAGA = "LOAD_UNIT_SAGA"

export const LOAD_UNIT_STATUS = "LOAD_UNIT_STATUS"
export const LOAD_UNIT_STATUS_SAGA = "LOAD_UNIT_STATUS_SAGA"

export const ADD_UNIT = "ADD_UNIT"
export const ADD_UNIT_SAGA = "ADD_UNIT_SAGA"

export const UPDATE_UNIT = "UPDATE_UNIT"
export const UPDATE_UNIT_SAGA = "UPDATE_UNIT_SAGA"

export const UPDATE_IS_LOADING_UNITS = "UPDATE_IS_LOADING_UNITS";


export interface ILoadUnitsActionSaga {
    readonly type: typeof LOAD_UNITS_SAGA;
    page: number;
    size: number;
    sort: string;
    name: string;
    status: string;
}
export const loadUnitsPropsSaga = (page = 0, size = 10, sort = '', name = '', status = ''): ILoadUnitsActionSaga => {
    return {
        type: LOAD_UNITS_SAGA,
        page: page,
        size: size,
        sort: sort,
        name: name,
        status: status
    }
};

export interface ILoadUnitActionSaga {
    readonly type: typeof LOAD_UNIT_SAGA;
    unitId: string;
}
export const loadUnitPropsSaga = (unitId: string): ILoadUnitActionSaga => {
    return {
        type: LOAD_UNIT_SAGA,
        unitId: unitId
    }
};

export interface ILoadUnitStatusActionSaga {
    readonly type: typeof LOAD_UNIT_STATUS_SAGA;
}
export const loadUnitStatusPropsSaga = (): ILoadUnitStatusActionSaga => {
    return {
        type: LOAD_UNIT_STATUS_SAGA,
    }
};

export interface IAddUnitActionSaga {
    readonly type: typeof ADD_UNIT_SAGA;
    unit: IUnit
}
export const addUnitPropsSaga = (unit: IUnit): IAddUnitActionSaga => {
    return {
        type: ADD_UNIT_SAGA,
        unit: unit
    }
};

export interface IUpdateUnitActionSaga {
    readonly type: typeof UPDATE_UNIT_SAGA;
    unitId: string
    unit: IUnit
}
export const updateUnitPropsSaga = (unitId: string, unit: IUnit): IUpdateUnitActionSaga => {
    return {
        type: UPDATE_UNIT_SAGA,
        unitId: unitId,
        unit: unit
    }
};

