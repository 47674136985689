import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_STORAGES_SAGA, ILoadStoragesActionSaga,
    LOAD_STORAGE_SAGA, ILoadStorageActionSaga,
    LOAD_STORAGE_TRANSACTIONS_SAGA, ILoadStorageTransactionsActionSaga

} from "./types"
import { fetchStorage, fetchStorages, fetchStorageTransactions } from "../../api/storages";
import { loadStoragesProps, loadStorageProps, loadStorageTransactionsProps, updateIsLoadingStoragesProps, updateIsLoadingStorageTransactionsProps } from "../../reducers/storages/types"
import IStorage from "../../dtos/IStorage"
import IStorageTransaction from "../../dtos/IStorageTransaction"

function* loadStorages(action: ILoadStoragesActionSaga) {
    try {
        yield put(updateIsLoadingStoragesProps({ isLoadingStorages: true, errorMessage: undefined }));
        const storagesData: {
            storages: IStorage[],
            totalStoragesCount: number
        } = yield call(fetchStorages, action.page, action.size, action.sort, action.name, action.storageType);
        yield put(loadStoragesProps({ storages: storagesData.storages, totalStoragesCount: storagesData.totalStoragesCount }))
        yield put(updateIsLoadingStoragesProps({ isLoadingStorages: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingStoragesProps({ isLoadingStorages: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadStorages() {
    yield takeLatest(LOAD_STORAGES_SAGA, loadStorages)
}

function* loadStorage(action: ILoadStorageActionSaga) {
    const storage: IStorage = yield call(fetchStorage, action.storageId);
    yield put(loadStorageProps({ storage }))
}
export function* watchLoadStorage() {
    yield takeLatest(LOAD_STORAGE_SAGA, loadStorage)
}

function* loadStorageTranscations(action: ILoadStorageTransactionsActionSaga) {
    try {
        yield put(updateIsLoadingStorageTransactionsProps({ isLoadingStorageTransactions: true, errorMessage: undefined }));
        const storageTransactionsData: {
            storageTransactions: IStorageTransaction[],
            totalStorageTransactionsCount: number
        } = yield call(fetchStorageTransactions, action.storageId, action.page, action.size, action.sort, action.userId, action.from, action.to);
        yield put(loadStorageTransactionsProps({ storageTransactions: storageTransactionsData.storageTransactions, totalStorageTransactionsCount: storageTransactionsData.totalStorageTransactionsCount }))
        yield put(updateIsLoadingStorageTransactionsProps({ isLoadingStorageTransactions: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingStorageTransactionsProps({ isLoadingStorageTransactions: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadStorageTransactions() {
    yield takeLatest(LOAD_STORAGE_TRANSACTIONS_SAGA, loadStorageTranscations)
}

export default [
    watchLoadStorages(),
    watchLoadStorage(),
    watchLoadStorageTransactions(),
]