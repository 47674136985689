import {LOAD_SMO_PREVIEW, UPDATE_IS_LOADING_SMO_PREVIEW} from '../../sagas/smart_ordering_preview/types';

export interface ILoadSMOPreviewAction {
    readonly type: typeof LOAD_SMO_PREVIEW;
    payload: {
        orders: any[],
        totalCountPages: number
    }
}

export const loadSMOPreviewProps = (payload: ILoadSMOPreviewAction['payload']): ILoadSMOPreviewAction => {
    return {
        type: LOAD_SMO_PREVIEW,
        payload: payload
    }
};

export interface IUpdateIsLoadingSMOPreviewAction {
    readonly type: typeof UPDATE_IS_LOADING_SMO_PREVIEW;
    payload:{
        isLoadingSMOPreview: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingSMOPreviewProps = (payload: IUpdateIsLoadingSMOPreviewAction['payload']): IUpdateIsLoadingSMOPreviewAction => {
    return {
        type: UPDATE_IS_LOADING_SMO_PREVIEW,
        payload: payload
    }
};

export type SMOPreviewActions = ILoadSMOPreviewAction | IUpdateIsLoadingSMOPreviewAction;