import { put, takeLatest, call } from 'redux-saga/effects'
import {
    LOAD_DASHBOARD_SAGA, ILoadDashboardActionSaga,
} from "./types";
import { loadDashboardProps, updateIsLoadingDashboardProps } from "../../reducers/dashboard/types";
import { fetchItemsCount, fetchProductsCount, fetchWarningsCount, fetchCriticalsCount } from '../../api/dashboard'
import { displayErrorMessageProps } from "../../reducers/global/types";

function* loadDashboard(action: ILoadDashboardActionSaga) {
    try {
        yield put(updateIsLoadingDashboardProps({ isLoadingDashboard: true, errorMessage: undefined }));
        const itemsCount: number = yield call(fetchItemsCount);
        const productsCount: number = yield call(fetchProductsCount);
        const warningsCount: number = yield call(fetchWarningsCount);
        const criticalsCount: number = yield call(fetchCriticalsCount);
        yield put(loadDashboardProps({ itemsCount, productsCount, warningsCount, criticalsCount }))
        yield put(updateIsLoadingDashboardProps({ isLoadingDashboard: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingDashboardProps({ isLoadingDashboard: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadDashboard() {
    yield takeLatest(LOAD_DASHBOARD_SAGA, loadDashboard)
}

export default [
    watchLoadDashboard(),
]