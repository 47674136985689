import { IUpdateIsLoadingUserGroupAction, UserGroupActions } from "./types";
import {
  LOAD_USER_GROUP,
  UPDATE_IS_LOADING_USER_GROUP,
  DELETE_USER_GROUP,
  LOAD_USER_GROUP_PERMISSIONS,
} from "../../sagas/user-group/types";
import IUserGroup, { IPermission } from "../../dtos/IUserGroup";

interface IUserGroupState {
  userGroups: IUserGroup[];
  userGroupPermissions: IPermission[];
  totalCountPages: number;
  loadingUserGroups: IUpdateIsLoadingUserGroupAction["payload"];
}

const UserGroupState: IUserGroupState = {
  userGroups: [],
  userGroupPermissions: [],
  totalCountPages: 0,
  loadingUserGroups: {
    isLoadingUserGroup: false,
    errorMessage: undefined,
  },
};

const userGroupReducer = (
  state = UserGroupState,
  action: UserGroupActions
): IUserGroupState => {
  switch (action.type) {
    case UPDATE_IS_LOADING_USER_GROUP:
      return {
          ...state,
          loadingUserGroups: action.payload,
      };
    case LOAD_USER_GROUP:
      return {
        ...state,
        userGroups: action.payload.userGroups,
        totalCountPages: action.payload.totalCountPages
      };
      case DELETE_USER_GROUP:
        return {
          ...state,
          userGroups: state.userGroups.filter((userGroup, i) => {
               return userGroup.id !== action.payload.userGroupId
          }),
        };
      case LOAD_USER_GROUP_PERMISSIONS:
        return {
          ...state,
          userGroupPermissions: action.payload.userGroupPermissions
        }  
    default:
      return state;
  }
};

export default userGroupReducer;
