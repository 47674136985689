import React, { useState } from 'react';
import './index.scss';
import { Table, Pagination, PaginationProps } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { MDBCol, MDBRow } from 'mdbreact';

import CustomCheckbox from "../customCheckbox"

export interface IHeaderColumn {
    name: string,
    backendName?: string,
    width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8,
    sorted?: 'ascending' | 'descending',
    handleClick?: Function
}

interface IEditableProp {
    edit: (rowValue: any) => any,
    onClick?: (e: React.MouseEvent<HTMLElement>, row: IRow) => void,
}

// interface IAddToCart {
//     button: (rowValue: any) => any,
//     onClick?: (e: React.MouseEvent<HTMLElement>, row: IRow) => void,
// }

export interface IRow {
    onClick?: (e: React.MouseEvent<HTMLElement>, row: IRow) => void
    value: any
    props: (string | IEditableProp)[]
}

interface IDataTableProps {
    headerColumns: IHeaderColumn[]
    headerOnClick?: (columnName: string, columnSorted?: 'ascending' | 'descending', backendName?: string) => void
    rows: IRow[]
    data?: any
    activePage?: string | number
    totalPages?: number | undefined
    className?: any
    onPageChange?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps) => void)
    actionsBar?: React.ReactNode
    footerActionsBar?: React.ReactNode
    scrollClassName?: string
}

function DataTable(props: IDataTableProps) {
    const [checked, changeCheck] = useState(false);
    const handleCheckbox = (data: any, checked: boolean) => {
        changeCheck(checked)
        if (checked) { data.map((d: any) => d.selected = true) }
        else { data.map((d: any) => d.selected = false) }
    }
    return (
        <div className={props.className ? `uncelled-table ${props.className}` : "uncelled-table"
        }>
            {
                props.actionsBar && props.actionsBar
            }
            < MDBRow start className="justify-content-center" >
                <MDBCol className={`text-center horizontal-scroll vertical-scroll ${props.scrollClassName}`}>
                    <Table celled selectable sortable>
                        <Table.Header>
                            <Table.Row>
                                {
                                    props.headerColumns.map((column, i) =>
                                        <Table.HeaderCell key={i} onClick={() => {
                                            if (column.name != "check") {
                                                if (column.handleClick) {
                                                    column.handleClick(column);
                                                } else if (props.headerOnClick)
                                                    props.headerOnClick(column.name, column.sorted, column.backendName);
                                            }
                                        }}
                                            sorted={column.sorted}
                                            className={props.className}
                                            width={column.width}>
                                            {column.name == 'check' ? <CustomCheckbox key={i} id="header"
                                                checked={checked}
                                                handleChange={() => handleCheckbox(props.data, !checked)} /> : column.name}
                                        </Table.HeaderCell>)
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                props.rows.map((row, i) => <Table.Row key={i} className="cursor" onClick={
                                    (e: React.MouseEvent<HTMLElement>) => row.onClick ? row.onClick(e, row) : ''
                                }>
                                    {
                                        row.props.map((prop, i) => {
                                            let displayedProp = row.value;
                                            if (typeof prop === 'string') {
                                                const nestedProps = prop.split('.');
                                                nestedProps.forEach((currentProp, i) => {
                                                    if (displayedProp != null)
                                                        displayedProp = displayedProp[currentProp]
                                                })
                                            } else {
                                                displayedProp = prop.edit(row.value);
                                            }
                                            return <Table.Cell key={i}
                                                onClick={
                                                    (e: React.MouseEvent<HTMLElement>) => {
                                                        if (typeof prop !== 'string' && prop.onClick) {
                                                            e.stopPropagation();
                                                            prop.onClick(e, row);
                                                        }
                                                    }
                                                }>
                                                {displayedProp}
                                            </Table.Cell>
                                        })
                                    }
                                </Table.Row>)
                            }
                        </Table.Body>
                        {props.totalPages && <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="9">
                                    <Pagination
                                        boundaryRange={0}
                                        activePage={props.activePage}
                                        totalPages={Math.ceil(props.totalPages)}
                                        onPageChange={props.onPageChange}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>}
                    </Table>
                </MDBCol>
            </MDBRow >
            {
                props.footerActionsBar && props.footerActionsBar
            }
        </div >
    );
}

export default DataTable;