import { call, put, takeLatest } from 'redux-saga/effects'
import {
    LOAD_CATEGORIES_SAGA, ILoadCategoriesActionSaga
} from "./types";
import { fetchDataTableCategories } from '../../api/products'
import { loadCategoriesProps, updateIsLoadingCategoriesProps } from "../../reducers/categories/types";
import ICategory from '../../dtos/ICategory';
import { displayErrorMessageProps } from "../../reducers/global/types";

function* loadCategories(action: ILoadCategoriesActionSaga) {
    try {
        if (action.filters.firstLoad) {
            yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: true, errorMessage: undefined }));
        }
        const { page, size, sort } = action.filters;
        const dataTableCategories: {
            categories: ICategory[],
            totalCountPages: number
        } = yield call(fetchDataTableCategories, page, size, sort);
        yield put(loadCategoriesProps({ categories: dataTableCategories.categories, totalCountPages: dataTableCategories.totalCountPages }))
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadCategories() {
    yield takeLatest(LOAD_CATEGORIES_SAGA, loadCategories)
}

export default [
    watchLoadCategories(),
]