import { put, takeLatest, call } from 'redux-saga/effects'
import {
    LOAD_PRODUCTS_SAGA, ILoadProductsActionSaga, FILTER_PRODUCTS_SAGA, IFilterProductsActionSaga,
    DELETE_PRODUCT_SAGA, IDeleteProductsActionSaga, UPDATE_PRODUCT_SAGA, IUpdateProductsActionSaga
} from "./types";
import {
    loadProductsFiltersProps, loadProductsProps,
    updateIsLoadingProductsProps, deleteProductsProps,
    updateProductsProps,
} from "../../reducers/products/types";
import { fetchDataTableProducts, getCategories, removeProduct, putProduct, disableCatalogItem, getBranchCatalog, getProductsByIds } from '../../api/products'
import { displayErrorMessageProps } from "../../reducers/global/types";
import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';

function* filterProducts(action: IFilterProductsActionSaga) {
    try {
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: true, errorMessage: undefined }));

        const { page, size, sort, name, categoryId } = action.filters;
        const { products, totalCountPages } = yield call(getBranchCatalog, page, size);

        yield put(updateIsLoadingProductsProps({ isLoadingProducts: false, errorMessage: undefined }));
        yield put(loadProductsProps({ products: products, totalCountPages: totalCountPages }))
    } catch (error: any) {
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchFilterProducts() {
    yield takeLatest(FILTER_PRODUCTS_SAGA, filterProducts)
}


function* loadProducts(action: ILoadProductsActionSaga) {
    try {
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: true, errorMessage: undefined }));
        // const categoryList: IDropdown[] = yield call(getCategories);
        yield put(loadProductsFiltersProps({
            // categoryList: categoryList,
            sortCol: '',
            sortOrder: 'ascending'
        }));
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: false, errorMessage: undefined }));
    } catch (error: any) {
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadProducts() {
    yield takeLatest(LOAD_PRODUCTS_SAGA, loadProducts)
}

function* deleteProducts(action: IDeleteProductsActionSaga) {
    yield call(disableCatalogItem, action.productId);
    yield put(deleteProductsProps({ productId: action.productId }));
}

export function* watchDeleteProducts() {
    yield takeLatest(DELETE_PRODUCT_SAGA, deleteProducts);
}

function* updateProduct(action: IUpdateProductsActionSaga) {
    yield put(updateProductsProps({ productId: action.productId, cart_type: action.cart_type }))
}

export function* watchUpdateProduct() {
    yield takeLatest(UPDATE_PRODUCT_SAGA, updateProduct);
}

export default [
    watchLoadProducts(),
    watchFilterProducts(),
    watchDeleteProducts(),
    watchUpdateProduct(),
]