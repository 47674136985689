import { axiosInstance } from "../store";
import Constants from '../constants';
import IProduct from "../dtos/IProduct";
import ICategory from "../dtos/ICategory";
import { handlStatusCodeMessage } from "../helpers";
import { toast } from "react-toastify";

export function addProductsBranch(productData: any): Promise<any> {
    return new Promise((resolve, reject) => {
        axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/service-account-catalog/save?branch_id=${localStorage.getItem('branch_id')}&service_account_id=${localStorage.getItem('service_account_id')}`, productData)
            .then((res) => {
                resolve(res);
                toast["success"]("Products added to catalog successfully.");
            }).catch((err) => {
                reject(err);
                toast["error"]("Failed to add products to catalog.");
            });
    })
}

export function getBranchCatalog(page = 0, size = 10): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/service-account-catalog/${localStorage.getItem('service_account_id')}?branch_id=${localStorage.getItem('branch_id')}&disabled=false&page=${page}&size=${size}`)
        .then((res) => {
            return { products: res.data, totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return { products: [], totalCountPages: 0 }
        });
}

export function disableCatalogItem(product_id: any): Promise<any> {
    return new Promise((resolve, reject) => {
        return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/service-account-catalog/${localStorage.getItem('branch_id')}/disable/${product_id}?disabled=${false}`)
            .then((res) => {
                toast["success"]("Product deleted successfully.");
                resolve(res.data)
            })
            .catch((err) => {
                toast["error"]("Product deletion faild!");
                console.log(err);
            });
    })
}

export function getProductsByIds(ids: number[]): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_PRODUCTS_URI}/v3/product/products`, ids)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return false
        });
}

export function fetchProducts(name = '', sku = ''): Promise<IProduct[]> {
    return axiosInstance.get(`${process.env.REACT_APP_PRODUCTS_URI}/product?page=0&size=20&name=${name}&sku=${sku}&price_nullable=true&status=A&vendor_status=A&search_engine_type=elastic&storefront_id=1`)
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
            else {
                return [];
            }

        }).catch((err) => {
            // handlStatusCodeMessage({ code: err.response?.status, entity: 'product' });
            return [];
        })
}

export function getMasterProducts(page = 0, sort = '', name = ''): Promise<{ products: IProduct[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_PRODUCTS_URI}/product?page=${page}&size=10&sort=${sort}&name=${name}`)
        .then((res) => {
            return { products: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return {
                products: [],
                totalCountPages: 0
            }
        })
}

export function getCategories(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/category`)
        .then((res) => res.status === 200 ? res.data.map((category: any, i: number) => {
            return {
                id: category.id,
                name: category.name
            }
        }) : [])
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return []
        })
}

export function fetchDataTableCategories(page = 0, size = 10, sort = ''): Promise<{ categories: ICategory[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/category?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            return { categories: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return {
                categories: [],
                totalCountPages: 0
            }
        })
}

export function fetchDataTableProducts(page = 0, size = 10, sort = '', name = '', categoryId = ''): Promise<{ products: IProduct[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/product?page=${page}&size=${size}&sort=${sort}&name=${name}&master_category_id=${categoryId}`)
        .then((res) => {
            return { products: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return {
                products: [],
                totalCountPages: 0
            }
        })
}

export function fetchProduct(id: string | number): Promise<IProduct> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/product/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return {}
        })
}

export function addProduct(productData: { alias: string } | { master_product_id: number }): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/product`, {
        ...productData,
    }).then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return false
        });
}

export function putProduct(id: string, alias: string): Promise<IProduct> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/product/${id}`, {
        id: id,
        alias: alias,
    }).then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return false
        });
}

export function removeProduct(id: IProduct['id']): Promise<boolean> {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/smart-storage/product/${id}`)
        .then((res) => res.status === 200)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product' });
            return false
        });
}