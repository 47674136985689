import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import { ILoadQuotationActionSaga, LOAD_QUOTATION_LIST_SAGA } from "./types"
import { quotationList, quotations } from '../../api/quotation';
import { loadQuotationProps } from '../../reducers/quotation/types';

function* loadQuotation(action: ILoadQuotationActionSaga) {
  try {
    // yield put(updateIsLoadingOrderProductsProps({ isLoadingOrderProducts: true, errorMessage: undefined }));
    const quotationList: {
      quotation_list: any[],
      total_count: number
    } = yield call(quotations, action.page, action.size);
    yield put(loadQuotationProps({ quotationList: quotationList.quotation_list, totalQuotationCount: quotationList.total_count }))
    // yield put(loadQuotationProps({
    //   quotationList: [{
    //     quotation_id: 1,
    //     branch_id: 152,
    //     service_account_id: 15,
    //     created_at: "2023-06-14 12:53:29",
    //     status: "Approved",
    //     subject_id: "11651849",
    //     address_id: 15,
    //     total_price: 1588,
    //   }, {
    //     quotation_id: 561,
    //     branch_id: 152,
    //     service_account_id: 15,
    //     created_at: "2023-06-14 12:53:29",
    //     status: "Partialy Approved",
    //     subject_id: "11651849",
    //     address_id: 15,
    //     total_price: 1588,
    //   },
    //   {
    //     quotation_id: 9912,
    //     branch_id: 152,
    //     service_account_id: 15,
    //     created_at: "2023-06-14 12:53:29",
    //     status: "Not Approved",
    //     subject_id: "11651849",
    //     address_id: 15,
    //     total_price: 1588,
    //   }], totalQuotationCount: 3
    // }))
    // yield put(updateIsLoadingOrderProductsProps({ isLoadingOrderProducts: false, errorMessage: undefined }));
  } catch (error: any) {
    // yield put(updateIsLoadingOrderProductsProps({ isLoadingOrderProducts: false, errorMessage: error.message }));
    yield put(displayErrorMessageProps({ message: error.message }))
  }
}
export function* watchLoadQuotation() {
  yield takeLatest(LOAD_QUOTATION_LIST_SAGA, loadQuotation)
}

export default [
  watchLoadQuotation(),
]
