import {
    OrdersActions,
    IUpdateIsLoadingOrdersAction
} from './types';
import {
    GET_ORDERS, GET_ORDER, GET_ORDER_ITEMS, UPDATE_IS_LOADING_ORDERS
} from "../../sagas/orders/types";
import { IOrder } from '../../dtos/order';

interface IOrdersState {
    orders: { data: IOrder[], totalOrdersCount: number }
    orderItems: { data: any[], totalOrderItemsCount: number }
    order: IOrder | null
    loadingOrders: IUpdateIsLoadingOrdersAction['payload'],

}
const OrdersState: IOrdersState = {
    orders: { data: [], totalOrdersCount: 0 },
    orderItems: { data: [], totalOrderItemsCount: 0 },
    order: null,
    loadingOrders: {
        isLoadingOrders: false,
        errorMessage: undefined
    },
}


const ordersReducer = (state = OrdersState, action: OrdersActions): IOrdersState => {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload,

            }
        case GET_ORDER_ITEMS:
            return {
                ...state,
                orderItems: action.payload
            }
        case GET_ORDER:
            return {
                ...state,
                order: action.payload.order
            }
        case UPDATE_IS_LOADING_ORDERS:
            return {
                ...state,
                loadingOrders: action.payload,
            };
        default:
            return state;
    }
}
export default ordersReducer