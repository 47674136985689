import { put, takeLatest, call } from "redux-saga/effects";
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
  LOAD_USERS_SAGA,
  ILoadUsersActionSaga,
  INVITE_NEW_USER_SAGA,
  IInviteNewUserActionSaga,
  VALIDATE_INVITATION_SAGA,
  IValidateInvitationActionSaga,
  LOAD_INVITATIONS_SAGA,
  ILoadInvitationsActionSaga,
} from "./types";
import {
  fetchUsers,
  sendInvitation,
  validateInvitation,
  fetchInvitations,
} from "../../api/users";
import {
  loadUsersProps,
  InviteNewUserProps,
  updateIsLoadingUsersProps,
  validateInvitationProps,
  updateIsLoadingInvitationsProps,
  loadInvitationsProps,
} from "../../reducers/user/types";
import IUser from "../../dtos/IUser";

function* loadUsers(action: ILoadUsersActionSaga) {
  try {
    yield put(
      updateIsLoadingUsersProps({
        isLoadingUsers: true,
        errorMessage: undefined,
      })
    );
    const usersData: {
      users: IUser[];
      totalUsersCount: number;
    } = yield call(fetchUsers, action.page, action.size, action.sort);
    yield put(
      loadUsersProps({
        users: usersData.users,
        totalUsersCount: usersData.totalUsersCount,
      })
    );
    yield put(
      updateIsLoadingUsersProps({
        isLoadingUsers: false,
        errorMessage: undefined,
      })
    );
  } catch (error) {
    yield put(
      updateIsLoadingUsersProps({
        isLoadingUsers: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadUsers() {
  yield takeLatest(LOAD_USERS_SAGA, loadUsers);
}

function* sendInvitationData(action: IInviteNewUserActionSaga) {
  try {
    yield put(
      updateIsLoadingUsersProps({
        isLoadingUsers: true,
        errorMessage: undefined,
      })
    );
    const invitationSent = yield call(sendInvitation, action.invitation);
    yield put(InviteNewUserProps({ invitationSent }));
  } catch (error) {
    yield put(
      updateIsLoadingUsersProps({
        isLoadingUsers: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchSendInvitationData() {
  yield takeLatest(INVITE_NEW_USER_SAGA, sendInvitationData);
}

function* validateInvitationToken(action: IValidateInvitationActionSaga) {
  try {
    yield put(
      updateIsLoadingUsersProps({
        isLoadingUsers: true,
        errorMessage: undefined,
      })
    );
    const valid = yield call(validateInvitation, action.token);
    yield put(validateInvitationProps({ valid }));
  } catch (error) {
    yield put(
      updateIsLoadingUsersProps({
        isLoadingUsers: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchValidateInvitationToken() {
  yield takeLatest(VALIDATE_INVITATION_SAGA, validateInvitationToken);
}

function* loadInvitations(action: ILoadInvitationsActionSaga) {
  try {
    yield put(
      updateIsLoadingInvitationsProps({
        isLoadingInvitations: true,
        errorMessage: undefined,
      })
    );
    const invitationsData: {
      invitations: any[];
      totalInvitationsCount: number;
    } = yield call(fetchInvitations, action.page, action.size, action.sort);
    yield put(
      loadInvitationsProps({
        invitations: invitationsData.invitations,
        totalInvitationsCount: invitationsData.totalInvitationsCount,
      })
    );
    yield put(
      updateIsLoadingInvitationsProps({
        isLoadingInvitations: false,
        errorMessage: undefined,
      })
    );
  } catch (error) {
    yield put(
      updateIsLoadingInvitationsProps({
        isLoadingInvitations: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}

export function* watchLoadInvitations() {
  yield takeLatest(LOAD_INVITATIONS_SAGA, loadInvitations);
}

export default [
  watchLoadUsers(),
  watchSendInvitationData(),
  watchValidateInvitationToken(),
  watchLoadInvitations(),
];
