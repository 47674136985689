import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { MDBRow, MDBAlert, MDBContainer, MDBCardText } from 'mdbreact';
import InvitationsList from "../../containers/usersList/invitationsList";

interface IUsersListPageProps {
}

export default function UsersListPage(props: IUsersListPageProps) {
    const userReducer = useSelector((state: AppState) => state.userReducer)
    return (
        <>
            <MDBRow className="m-0">
                {userReducer.invitationSent && <MDBContainer>
                    <MDBAlert color="success" dismiss>
                        Invitaion has been sent successfully
                    </MDBAlert>
                </MDBContainer>}
                <InvitationsList />
            </MDBRow>
        </>
    )
}