import React from "react";
import './index.scss';
import OrdersList from "../../containers/orders/ordersList";

interface IOrdersListPageProps {
}

export default function OrdersListPage(props: IOrdersListPageProps) {
    return (
        <>
            <OrdersList />
        </>
    )
}