import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_MANUAL_STORAGES_SAGA, ILoadManualStoragesActionSaga,
    //adjust
    LOAD_ADJUSTMENT_REASONS, ILoadAdjustmentReasonsActionSaga,
    //requests
    SEND_REQUEST_SAGA, ISendRequestActionSaga,
    LOAD_MY_REQUESTS_SAGA, ILoadMyRequestsActionSaga,
    LOAD_RECEIVED_REQUESTS_SAGA, ILoadReceivedRequestsActionSaga,
    LOAD_REQUEST_SAGA, ILoadRequestActionSaga,
    REQUEST_RESPONSE_SAGA, IRequestResponseActionSaga, LOAD_ADJUSTMENT_REASONS_SAGA

} from "./types"
import {
    fetchManualStorages, fetchAdjustmentReasons,
    postRequest,
    fetchMyRequests, fetchReceivedRequests, fetchRequest, sendRequestResponse
} from "../../api/manual-storage";
import {
    loadManualStoragesProps, loadAdjustmentReasonsProps,
    sendRequestProps,
    loadMyRequestsProps, loadReceivedRequestsProps, loadRequestProps,
    requestResponseProps,
    updateIsLoadingRequestsProps
} from "../../reducers/manual-storage/types";
import IVendor from "../../dtos/IVendor";
import IStorage from "../../dtos/IStorage";
import IReason from "../../dtos/IReason";


function* loadManualStorages(action: ILoadManualStoragesActionSaga) {
    try {
        const manualStorages: IStorage[] = yield call(fetchManualStorages);
        yield put(loadManualStoragesProps({ manualStorages }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadManualStorages() {
    yield takeLatest(LOAD_MANUAL_STORAGES_SAGA, loadManualStorages)
}

function* loadAdjustmentReasons(action: ILoadAdjustmentReasonsActionSaga) {
    try {
        const adjustmentReasons: IReason[] = yield call(fetchAdjustmentReasons);
        yield put(loadAdjustmentReasonsProps({ adjustmentReasons }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadAdjustmentReasons() {
    yield takeLatest(LOAD_ADJUSTMENT_REASONS_SAGA, loadAdjustmentReasons)
}


function* sendRequestData(action: ISendRequestActionSaga) {
    try {
        const requestSent: boolean = yield call(postRequest, action.request, action.requestType);
        yield put(sendRequestProps({ requestSent }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchSendRequestData() {
    yield takeLatest(SEND_REQUEST_SAGA, sendRequestData)
}
//requests
function* loadMyRequests(action: ILoadMyRequestsActionSaga) {
    try {
        yield put(updateIsLoadingRequestsProps({ isLoadingRequests: true, errorMessage: undefined }));
        const myRequestsData: {
            myRequests: any[],
            totalMyRequestsCount: number
        } = yield call(fetchMyRequests, action.page, action.size, action.sort);
        yield put(loadMyRequestsProps({ myRequests: myRequestsData.myRequests, totalMyRequestsCount: myRequestsData.totalMyRequestsCount }))
    } catch (error) {
        yield put(updateIsLoadingRequestsProps({ isLoadingRequests: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadMyRequests() {
    yield takeLatest(LOAD_MY_REQUESTS_SAGA, loadMyRequests)
}

function* loadReceivedRequests(action: ILoadReceivedRequestsActionSaga) {
    try {
        yield put(updateIsLoadingRequestsProps({ isLoadingRequests: true, errorMessage: undefined }));
        const receivedRequestsData: {
            receivedRequests: any[],
            totalReceivedRequestsCount: number
        } = yield call(fetchReceivedRequests, action.page, action.size, action.sort);
        yield put(loadReceivedRequestsProps({ receivedRequests: receivedRequestsData.receivedRequests, totalReceivedRequestsCount: receivedRequestsData.totalReceivedRequestsCount }))
    } catch (error) {
        yield put(updateIsLoadingRequestsProps({ isLoadingRequests: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadReceivedRequests() {
    yield takeLatest(LOAD_RECEIVED_REQUESTS_SAGA, loadReceivedRequests)
}

function* loadRequest(action: ILoadRequestActionSaga) {
    try {
        yield put(updateIsLoadingRequestsProps({ isLoadingRequests: true, errorMessage: undefined }));
        const request: {} = yield call(fetchRequest, action.requestId);
        yield put(loadRequestProps({ request }))
    } catch (error) {
        yield put(updateIsLoadingRequestsProps({ isLoadingRequests: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadRequest() {
    yield takeLatest(LOAD_REQUEST_SAGA, loadRequest)
}
function* sendRequestResponseData(action: IRequestResponseActionSaga) {
    try {
        const responseSent: boolean = yield call(sendRequestResponse, action.response);
        yield put(requestResponseProps({ responseSent }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchSendRequestResponseData() {
    yield takeLatest(REQUEST_RESPONSE_SAGA, sendRequestResponseData)
}

export default [
    watchLoadManualStorages(),
    watchSendRequestData(),
    watchLoadMyRequests(),
    watchLoadReceivedRequests(),
    watchLoadRequest(),
    watchSendRequestResponseData(),
    watchLoadAdjustmentReasons(),
]