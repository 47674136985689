import React, { useEffect, useState, useMemo } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import "./index.scss"
import MultiSelect from "../../components/multiSelect"
import Select from '../../components/select'
import Input from '../../components/input'
import * as Yup from "yup";
import { Formik, FormikProps, Form } from "formik";

interface IFormModalProps {
    initialValues: any,
    validationSchema: any,
    fields: any,
    onSubmit: any,
    id: any
}
interface FormValues {
    FormValues: any,
}
const renderInput = (f: any, values: any, index: any, handleChange: any) => {
    return <Input
        maxlength={50}
        key={index}
        className={f.className ? f.className : undefined}
        label={f.label}
        required={f.required}
        placeholder={f.placeholder}
        name={f.name}
        defaultValue={values.FormValues[f.object_name][f.field_name]}
        onChange={handleChange}

    />
}
const renderMultiSelect = (f: any, values: any, index: any) => {
    return <MultiSelect
        key={index}
        label={f.label}
        required={f.required}
        name={f.name}
        options={f.options}
        onChange={(e: any) => values.FormValues[f.object_name][f.field_name] = e.map((x: any) => x.value)}
        multi={f.multi}
        clearable={f.clearable}
        closeMenuOnSelect={f.closeMenuOnSelect}
    />
}
const renderSelect = (f: any, values: any, index: any, handleBlur: any, handleChange: any) => {
    return <MDBRow className={`${f.containerStyle ? f.containerStyle : undefined}`}>
        <label htmlFor="address.country" className="font-weight-bold pl-3 pt-3 w-100">{f.label}
            <span className='red-text'>{' *'}</span>
        </label>
        <Select
            key={index}
            id={index}
            name={f.name}
            label={`Select ${f.label}`}
            className="select-div w-100"
            options={f.options}
            onBlur={handleBlur}
            selectedValue={values.FormValues[f.object_name][f.field_name]}
            onChange={(e: any) => {
                handleChange(e)
                f.customHandleChange && f.customHandleChange(e)
            }}
        />
    </MDBRow>
}
const renderFields = (f: any, values: any, index: any, handleBlur: any, handleChange: any) => {
    if (f.type == "text") {
        return renderInput(f, values, index, handleChange)
    }
    else if (f.type == "multiSelect") {
        return renderMultiSelect(f, values, index)
    }
    else if (f.type == 'select') {
        return renderSelect(f, values, index, handleBlur, handleChange)
    }
}
function FormModal(props: IFormModalProps) {
    const initialValues: FormValues = { FormValues: props.initialValues }
    const validationSchema: object = props.validationSchema
    const fields = props.fields
    const formId = props.id

    return (
        <MDBRow className="">
            <MDBCol className="">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        props.onSubmit(values.FormValues);
                        actions.setSubmitting(false);
                    }}>
                    {(props: FormikProps<FormValues | any> & IFormModalProps & any) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange,
                            setFieldValue,
                            isSubmitting,
                        } = props
                        return (<Form id={formId}>
                            {fields.map((f: any, index: any) => {
                                if (f.display == 'row') {
                                    return <div className="d-flex flex-direction-row justify-content-between">
                                        {f.rowFields.map((r: any, index: any) => {
                                            return <div key={index} className="input-div">
                                                {renderFields(r, values, index, handleBlur, handleChange)}
                                                {errors.FormValues && touched.FormValues && errors.FormValues[r.object_name] ? errors.FormValues[r.object_name][r.field_name] && errors.FormValues[r.object_name][r.field_name] &&
                                                    <p className='red-text pl-2'>{errors.FormValues[r.object_name][r.field_name]}</p> : undefined}
                                            </div>
                                        })}
                                    </div>
                                }
                                else {
                                    return <div key={index} className="w-100">
                                        {renderFields(f, values, index, handleBlur, handleChange)}
                                        {errors.FormValues && touched.FormValues && errors.FormValues[f.object_name] ? errors.FormValues[f.object_name][f.field_name] && errors.FormValues[f.object_name][f.field_name] &&
                                            <p className='red-text pl-2'>{errors.FormValues[f.object_name][f.field_name]}</p> : undefined}
                                    </div>
                                }
                            }
                            )}
                        </Form>)
                    }}
                </Formik>
            </MDBCol>
        </MDBRow>
    )
}

export default FormModal;