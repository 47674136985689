import { put, takeLatest, call } from 'redux-saga/effects'
import {
    LOAD_MY_ACCOUNT_SAGA, ILoadMyAccountActionSaga,
} from "./types";
import { loadMyAccountProps, updateIsLoadingMyAccountProps } from "../../reducers/my_account/types";
import { fetchUser } from '../../api/users'
import { displayErrorMessageProps } from "../../reducers/global/types";
import IUser from '../../dtos/IUser';

function* loadMyAccount(action: ILoadMyAccountActionSaga) {
    try {
        yield put(updateIsLoadingMyAccountProps({ isLoadingMyAccount: true, errorMessage: undefined }));
        const user: IUser = yield call(fetchUser, action.userId);
        yield put(loadMyAccountProps({ user }))
        yield put(updateIsLoadingMyAccountProps({ isLoadingMyAccount: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingMyAccountProps({ isLoadingMyAccount: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadMyAccount() {
    yield takeLatest(LOAD_MY_ACCOUNT_SAGA, loadMyAccount)
}

export default [
    watchLoadMyAccount(),
]