import React from "react";
import './index.scss'
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import inventoryIcon from "../../assets/img/pages-icons/inventory-icon.png";
import ProductStorages from "../../containers/product_storages";

interface IProductStoragesPageProps {
}
export default function ProductStoragesPage(props: IProductStoragesPageProps) {
    return (
        <>
            <MDBRow className="m-0">
                <MDBRow className="ml-2 mt-0 mb-0 w-100">
                    <MDBCol size={`12`} md={`6`}>
                        <img className="page-image d-inline-block" src={inventoryIcon} />
                        <MDBCardText className="page-title d-inline-block">Product Storages</MDBCardText>
                        <span className="page-line d-inline-block" />
                    </MDBCol>
                </MDBRow>
                <ProductStorages />
            </MDBRow>
        </>
    )
}