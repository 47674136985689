import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_ASSETS_SAGA, ILoadAssetsActionSaga,
    LOAD_ASSET_SAGA, ILoadAssetActionSaga,
    LOAD_ASSET_USAGE_SAGA, ILoadAssetUsageActionSaga,
    UPDATE_ASSET_SAGA, IUpdateAssetActionSaga,
    LOAD_ASSETS_STATUS_SAGA, ILoadAssetsStatusActionSaga
} from "./types";
import { fetchAssets, fetchAsset, fetchAssetUsage, updateAsset, fetchAssetsStatus } from "../../api/assets"
import { loadAssetsProps, loadAssetProps, loadAssetUsageProps, updateAssetProps, loadAssetsStatusProps, updateIsLoadingAssetsProps } from "../../reducers/asset/types"
import IAsset from "../../dtos/IAsset"
import IAssetTransaction from "../../dtos/IAssetTransaction"
import IStatus from "../../dtos/IStatus"


function* loadAssets(action: ILoadAssetsActionSaga) {
    try {
        yield put(updateIsLoadingAssetsProps({ isLoadingAssets: true, errorMessage: undefined }));
        const assetsData: {
            assets: IAsset[],
            dismissedAssets: IAsset[],
            totalAssetsCount: number
        } = yield call(fetchAssets, action.active, action.page, action.size, action.sort, action.name, action.status, action.storage);
        yield put(loadAssetsProps({ assets: assetsData.assets, dismissedAssets: assetsData.dismissedAssets, totalAssetsCount: assetsData.totalAssetsCount }))
        yield put(updateIsLoadingAssetsProps({ isLoadingAssets: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingAssetsProps({ isLoadingAssets: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadAssets() {
    yield takeLatest(LOAD_ASSETS_SAGA, loadAssets)
}

function* loadAsset(action: ILoadAssetActionSaga) {
    const asset: IAsset = yield call(fetchAsset, action.assetId);
    yield put(loadAssetProps({ asset }))
}
export function* watchLoadAsset() {
    yield takeLatest(LOAD_ASSET_SAGA, loadAsset)
}

function* loadAssetUsageData(action: ILoadAssetUsageActionSaga) {
    const assetTransactionsData:
        {
            assetTransactions: IAssetTransaction[],
            assetTransactionsCount: number
        }
        = yield call(fetchAssetUsage, action.assetId, action.page, action.size, action.sort);
    yield put(loadAssetUsageProps({ assetTransactions: assetTransactionsData.assetTransactions, assetTransactionsCount: assetTransactionsData.assetTransactionsCount }))
}
export function* watchloadAssetUsage() {
    yield takeLatest(LOAD_ASSET_USAGE_SAGA, loadAssetUsageData)
}

function* updateAssetData(action: IUpdateAssetActionSaga) {
    const assetUpdated: number = yield call(updateAsset, action.assetId, action.asset);
    yield put(updateAssetProps({ assetUpdated }))
}
export function* watchUpdateAsset() {
    yield takeLatest(UPDATE_ASSET_SAGA, updateAssetData)
}

function* loadAssetsStatus(action: ILoadAssetsStatusActionSaga) {
    const assetsStatus: IStatus[] = yield call(fetchAssetsStatus);
    yield put(loadAssetsStatusProps({ assetsStatus }))
}

export function* watchLoadAssetsStatus() {
    yield takeLatest(LOAD_ASSETS_STATUS_SAGA, loadAssetsStatus)
}

export default [
    watchLoadAssets(),
    watchLoadAsset(),
    watchloadAssetUsage(),
    watchUpdateAsset(),
    watchLoadAssetsStatus(),
]