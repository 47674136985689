import {
    LOAD_STORAGES, LOAD_STORAGE, LOAD_STORAGE_TRANSACTIONS,
    UPDATE_IS_LOADING_STORAGES, UPDATE_IS_LOADING_STORAGE_TRANSACTIONS
} from "../../sagas/storage/types"
import IStorage from "../../dtos/IStorage"
import IStorageTransaction from "../../dtos/IStorageTransaction"

export interface ILoadStoragesAction {
    readonly type: typeof LOAD_STORAGES;
    payload: {
        storages: IStorage[],
        totalStoragesCount: number
    }
}
export const loadStoragesProps = (payload: ILoadStoragesAction['payload']): ILoadStoragesAction => {
    return {
        type: LOAD_STORAGES,
        payload: payload
    }
};
export interface ILoadStorageAction {
    readonly type: typeof LOAD_STORAGE;
    payload: {
        storage: IStorage
    }
}
export const loadStorageProps = (payload: ILoadStorageAction['payload']): ILoadStorageAction => {
    return {
        type: LOAD_STORAGE,
        payload: payload
    }
};
export interface ILoadStorageTransactionsAction {
    readonly type: typeof LOAD_STORAGE_TRANSACTIONS;
    payload: {
        storageTransactions: IStorageTransaction[],
        totalStorageTransactionsCount: number
    }
}
export const loadStorageTransactionsProps = (payload: ILoadStorageTransactionsAction['payload']): ILoadStorageTransactionsAction => {
    return {
        type: LOAD_STORAGE_TRANSACTIONS,
        payload: payload
    }
};
export interface IUpdateIsLoadingStoragesAction {
    readonly type: typeof UPDATE_IS_LOADING_STORAGES;
    payload: {
        isLoadingStorages: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingStoragesProps = (payload: IUpdateIsLoadingStoragesAction['payload']): IUpdateIsLoadingStoragesAction => {
    return {
        type: UPDATE_IS_LOADING_STORAGES,
        payload: payload
    }
};

export interface IUpdateIsLoadingStorageTransactionsAction {
    readonly type: typeof UPDATE_IS_LOADING_STORAGE_TRANSACTIONS;
    payload: {
        isLoadingStorageTransactions: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingStorageTransactionsProps = (payload: IUpdateIsLoadingStorageTransactionsAction['payload']): IUpdateIsLoadingStorageTransactionsAction => {
    return {
        type: UPDATE_IS_LOADING_STORAGE_TRANSACTIONS,
        payload: payload
    }
};
export type StorageActions = ILoadStoragesAction | ILoadStorageAction | ILoadStorageTransactionsAction | IUpdateIsLoadingStoragesAction | IUpdateIsLoadingStorageTransactionsAction