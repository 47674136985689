import {
  IUpdateIsLoadingNotificationsAction,
  NotificationActions,
} from "./types";
import {
  LOAD_NOTIFICATIONS,
  DISMISS_NOTIFICATION,
  UNDISMISS_NOTIFICATION,
  UPDATE_IS_LOADING_NOTIFICATIONS,
  PUSH_INCOMING_NOTIFICATION,
} from "../../sagas/notifications/types";
import INotification from "../../dtos/INotification";
import _ from "lodash";

interface INotificationsState {
  notifications: INotification[];
  totalNotificationsCount: number;
  dismissedNotifications: INotification[];
  totalDismissedNotificationsCount: number;
  loadingNotifications: IUpdateIsLoadingNotificationsAction["payload"];
}
const NotificationsState: INotificationsState = {
  notifications: [],
  totalNotificationsCount: 0,
  dismissedNotifications: [],
  totalDismissedNotificationsCount: 0,
  loadingNotifications: {
    isLoadingNotifications: false,
    errorMessage: undefined,
  },
};
const notificationsReducer = (
  state = NotificationsState,
  action: NotificationActions
): INotificationsState => {
  switch (action.type) {
    case UPDATE_IS_LOADING_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: action.payload,
      };
    case LOAD_NOTIFICATIONS:
      if (action.payload.isDismissed) {
        let updatedNotifications = [...state.dismissedNotifications];
        action.payload.notifications.forEach((n, i) => {
          if (
            _.find(updatedNotifications, (o, i) => o.uuid === n.uuid) ===
            undefined
          ) {
            updatedNotifications.push(n);
          }
        });
        return {
          ...state,
          dismissedNotifications: updatedNotifications,
          totalDismissedNotificationsCount:
            action.payload.totalNotificationsCount,
        };
      } else {
        let updatedNotifications = [...state.notifications];
        action.payload.notifications.forEach((n, i) => {
          if (
            _.find(updatedNotifications, (o, i) => o.uuid === n.uuid) ===
            undefined
          ) {
            updatedNotifications.push(n);
          }
        });
        return {
          ...state,
          notifications: updatedNotifications,
          totalNotificationsCount: action.payload.totalNotificationsCount,
        };
      }
    case DISMISS_NOTIFICATION:
      let notifications = [...state.notifications];
      _.remove(
        notifications,
        (n, i) => n.uuid === action.payload.notificationId
      );
      return {
        ...state,
        notifications: notifications,
      };
    case UNDISMISS_NOTIFICATION:
      let dismissedNotifications = [...state.dismissedNotifications];
      _.remove(
        dismissedNotifications,
        (n, i) => n.uuid === action.payload.notificationId
      );
      return {
        ...state,
        dismissedNotifications: dismissedNotifications,
      };
    case PUSH_INCOMING_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload.notification, ...state.notifications],
      };
    default:
      return state;
  }
};
export default notificationsReducer;
