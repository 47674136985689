import React from 'react'
import "./styles.scss";
import { MDBBtn, MDBIcon } from 'mdbreact';

interface IMainBlueButtonProps {
    title: string,
    onClick?: any
    className?: any,
    btnClassName?: string,
    type?: any,
    form?: any,
    icon?: string,
    disabled?: boolean,
}
export default function MainBlueButton(props: IMainBlueButtonProps) {
    return (
        <div className={`button-container ${props.className && props.className}`}>
            <MDBBtn disabled={props.disabled} form={props.form && props.form} type={props.type && props.type} className={`text-capitalize ${props.btnClassName}`} onClick={props.onClick}>
                {props.icon && <MDBIcon className="mr-1" icon={props.icon} />}
                {props.title}</MDBBtn>
        </div>
    )
}
