import IStorage from "../../dtos/IStorage";

export const LOAD_STORAGES = "LOAD_STORAGES"
export const LOAD_STORAGES_SAGA = "LOAD_STORAGES_SAGA"

export const LOAD_STORAGE = "LOAD_STORAGE"
export const LOAD_STORAGE_SAGA = "LOAD_STORAGE_SAGA"

export const LOAD_STORAGE_TRANSACTIONS = "LOAD_STORAGE_TRANSACTIONS"
export const LOAD_STORAGE_TRANSACTIONS_SAGA = "LOAD_STORAGE_TRANSACTIONS_SAGA"

export const UPDATE_IS_LOADING_STORAGES = "UPDATE_IS_LOADING_STORAGES"
export const UPDATE_IS_LOADING_STORAGE_TRANSACTIONS = "UPDATE_IS_LOADING_STORAGE_TRANSACTIONS"

export interface ILoadStoragesActionSaga {
    readonly type: typeof LOAD_STORAGES_SAGA;
    page: number,
    size: number,
    sort: string,
    name: string,
    storageType: string,
}
export const loadStoragesPropsSaga = (page = 0, size = 0, sort = '', name = '', storageType = ''): ILoadStoragesActionSaga => {
    return {
        type: LOAD_STORAGES_SAGA,
        page: page,
        sort: sort,
        size: size,
        name: name,
        storageType: storageType
    }
};

export interface ILoadStorageActionSaga {
    readonly type: typeof LOAD_STORAGE_SAGA;
    storageId: string
}
export const loadStoragePropsSaga = (storageId = ''): ILoadStorageActionSaga => {
    return {
        type: LOAD_STORAGE_SAGA,
        storageId: storageId
    }
};

export interface ILoadStorageTransactionsActionSaga {
    readonly type: typeof LOAD_STORAGE_TRANSACTIONS_SAGA;
    storageId: string,
    page: number,
    size: number,
    sort: string,
    userId: string,
    from: string,
    to: string
}
export const loadStorageTransactionsPropsSaga = (storageId = '', page = 0, size = 10, sort = '', userId = '', from = '', to = ''): ILoadStorageTransactionsActionSaga => {
    return {
        type: LOAD_STORAGE_TRANSACTIONS_SAGA,
        storageId: storageId,
        page: page,
        size: size,
        sort: sort,
        userId: userId,
        from: from,
        to: to
    }
};
