export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SAGA = "GET_ORDERS_SAGA";

export const GET_ORDER = "GET_ORDER"
export const GET_ORDER_SAGA = "GET_ORDER_SAGA"

export const GET_ORDER_ITEMS = "GET_ORDER_ITEMS"
export const GET_ORDER_ITEMS_SAGA = "GET_ORDER_ITEMS_SAGA"

export const RESET_ORDERS_SAGA = "RESET_ORDERS_SAGA"


export const UPDATE_IS_LOADING_ORDERS = "UPDATE_IS_LOADING_ORDERS";

export interface IGetOrdersActionSaga {
    readonly type: typeof GET_ORDERS_SAGA
    orderIds: any[]
    token: string
    totalCount: number
}
export function getOrdersPropsSaga(orderIds = [], token = '', totalCount = 0): IGetOrdersActionSaga {
    return {
        type: GET_ORDERS_SAGA,
        orderIds: orderIds,
        token: token,
        totalCount: totalCount
    }
}

export interface IGetOrderActionSaga {
    readonly type: typeof GET_ORDER_SAGA
    orderId: number,
    subjectId: string,
}
export function getOrderPropsSaga(orderId = 0, subjectId: string = ""): IGetOrderActionSaga {
    return {
        type: GET_ORDER_SAGA,
        orderId: orderId,
        subjectId: subjectId
    }
}
export interface IGetOrderItemsActionSaga {
    readonly type: typeof GET_ORDER_ITEMS_SAGA
    orderId: number,
    page: number,
}
export function getOrderItemsPropsSaga(orderId = 0, page = 0): IGetOrderItemsActionSaga {
    return {
        type: GET_ORDER_ITEMS_SAGA,
        orderId: orderId,
        page: page,
    }
}
export interface IUpdateIsLoadingOrdersActionSaga {
    readonly type: typeof UPDATE_IS_LOADING_ORDERS,
}
//reset
export interface IResetOrdersActionSaga {
    readonly type: typeof RESET_ORDERS_SAGA,
}
export function resetOrdersPropsSaga(): IResetOrdersActionSaga {
    return {
        type: RESET_ORDERS_SAGA,
    }
}
