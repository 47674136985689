import { IUpdateIsLoadingStoragesAction, IUpdateIsLoadingStorageTransactionsAction, StorageActions } from './types';
import {
    LOAD_STORAGES, LOAD_STORAGE, LOAD_STORAGE_TRANSACTIONS,
    UPDATE_IS_LOADING_STORAGES, UPDATE_IS_LOADING_STORAGE_TRANSACTIONS
} from "../../sagas/storage/types";
import IStorage from "../../dtos/IStorage"
import IStorageTransaction from "../../dtos/IStorageTransaction"

interface IStorageStatus {
    storages: IStorage[],
    totalStoragesCount: number,
    storage: IStorage,

    storageTransactions: IStorageTransaction[],
    totalStorageTransactionsCount: number,

    loadingStorages: IUpdateIsLoadingStoragesAction['payload'],
    loadingStorageTransactions: IUpdateIsLoadingStorageTransactionsAction['payload']
}
const StorageStatus: IStorageStatus = {
    storages: [],
    totalStoragesCount: 0,
    storage: {
        storage_id: '',
        href: null,
        name: '',
        type: '',
        model: {
            id: 0,
            name: '',
            size: 0
        },
        last_accessed_by_id: null
    },
    storageTransactions: [],
    totalStorageTransactionsCount: 0,

    loadingStorages: {
        isLoadingStorages: false,
        errorMessage: undefined
    },
    loadingStorageTransactions: {
        isLoadingStorageTransactions: false,
        errorMessage: undefined
    }
}
const storageReducer = (state = StorageStatus, action: StorageActions): IStorageStatus => {
    switch (action.type) {
        case LOAD_STORAGES:
            return {
                ...state,
                storages: action.payload.storages,
                totalStoragesCount: action.payload.totalStoragesCount
            }
        case LOAD_STORAGE:
            return {
                ...state,
                storage: action.payload.storage
            }
        case LOAD_STORAGE_TRANSACTIONS:
            return {
                ...state,
                storageTransactions: action.payload.storageTransactions,
                totalStorageTransactionsCount: action.payload.totalStorageTransactionsCount
            }
        default:
            return state;
    }
}
export default storageReducer;

