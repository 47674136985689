import React, { useEffect, useMemo, useState } from 'react';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { deleteUserGroupPropsSaga, loadUserGroupPropsSaga } from "../../sagas/user-group/types";
import { updateGlobalModalPropsSaga } from "../../sagas/global/types";
import { AppState } from "../../store";
import { MDBCol, MDBRow } from 'mdbreact';
import { partialText } from '../../helpers';
import Loader from '../../components/loader';
import CustomCheckbox from "../../components/customCheckbox"
import DataTable, { IRow } from '../../components/dataTable';
import IUserGroup from '../../dtos/IUserGroup';
import Constants from '../../constants';
import { addQueryString } from '../../helpers';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import TableMenu from "../../components/tableMenu";
import MainBlueButton from "../../components/blueButton";


interface IUserGroupProps {
}

function UserGroup(props: IUserGroupProps) {
    const routerReducer = useSelector((state: AppState) => state.router);
    const { page } = queryString.parse(routerReducer.location.search);
    const dispatch = useDispatch();
    const userGroupReducer = useSelector((state: AppState) => state.userGroupReducer);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const [selectedRow, changeSelectedRow] = useState('')
    const headers: any = [
        // { name: "check", width: 1 },
        { name: "Name", width: 2 },
        { name: "Description", width: 4 },
        { name: "", width: 1 },
    ];
    // container logic
    useEffect(() => {
        dispatch(loadUserGroupPropsSaga(0, 10))
    }, []);
    useEffect(() => {
        const { page } = queryString.parse(routerReducer.location.search);
        dispatch(loadUserGroupPropsSaga(page ? parseInt(page.toString()) - 1 : 0))
        // updating componnts values
        changePage(page ? parseInt(page.toString()) : 1);

    }, [routerReducer.location.search]);
    const handleCheckbox = (rowValue: any) => {
        rowValue.selected = !rowValue.selected
        const selectedRow = `${rowValue.id} ${rowValue.selected}`
        changeSelectedRow(selectedRow)
    }
    // body rows data
    const buildRows = (userGroups: IUserGroup[]): IRow[] => {
        return userGroups.map((userGroup: IUserGroup, i) => {
            return {
                value: userGroup,
                props: [
                    // {
                    //     edit: (rowValue: IUserGroup) => {
                    //         return (<CustomCheckbox id="row"
                    //             key={rowValue.id}
                    //             checked={rowValue.selected}
                    //             handleChange={() => handleCheckbox(rowValue)} />);
                    //     }
                    // },
                    {
                        edit: (rowValue: IUserGroup) => {
                            return (<div className="cursor offblue-text" onClick={() => {
                                dispatch(push({ pathname: `/user-group/${rowValue.id}/edit` }))
                            }}>{rowValue.name}</div>);
                        }
                    },
                    {
                        edit: (rowValue: IUserGroup) => {
                            return (<p className="text-break">{partialText(rowValue.description, 100)}</p>);
                        }
                    },
                    {
                        edit: (rowValue: IUserGroup) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />
                            );
                        }
                    },
                ]
            }
        });
    };
    const rows = useMemo(() => buildRows(userGroupReducer.userGroups), [userGroupReducer.userGroups]);
    const menuItems = [
        {
            title: "Edit User Group",
            icon: "edit",
            handleChange: async (rowValue: any) => dispatch(push({ pathname: `/user-group/${rowValue.id}/edit` }))
        },
        {
            title: "Delete User Group",
            icon: "trash",
            handleChange: (rowValue: any) => {
                dispatch(updateGlobalModalPropsSaga({
                    isOpen: true,
                    title: 'Delete User Group',
                    body: <h3>Are you sure you want to delete this user group?</h3>,
                    confirm: () => {
                        dispatch(deleteUserGroupPropsSaga(rowValue.id as string));
                        dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
                    },
                    close: () => {
                        dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
                    },
                }));

            }



        }
    ]
    // pagination
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={userGroupReducer.loadingUserGroups.isLoadingUserGroup}
                    errorMessage={userGroupReducer.loadingUserGroups.errorMessage}>
                    <MDBRow start className="mt-2 mb-4">
                        <MDBCol md="2" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-4 pl-4"
                                title="+ Add user group"
                                onClick={() => dispatch(push({ pathname: 'user-group/add' }))}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                userGroupReducer.userGroups.length > 0 ?
                                    <div className="celled-table">
                                        <DataTable
                                            headerColumns={headers}
                                            rows={rows}
                                            data={userGroupReducer.userGroups}
                                            totalPages={userGroupReducer.totalCountPages / Constants.pagination.userGroupPerPage}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                    </div>
                                    :
                                    <div>No user groups found.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
            </MDBCol>
        </MDBRow>)
}

export default UserGroup;