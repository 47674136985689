import { filter } from "lodash";
import IProduct from "../../dtos/IProduct";

export const LOAD_PRODUCT_STORAGES_SAGA = "LOAD_PRODUCT_STORAGES_SAGA";
export const LOAD_PRODUCT_STORAGES = "LOAD_PRODUCT_STORAGES";
export const LOAD_PRODUCT_STORAGES_FILTERS = "LOAD_PRODUCT_STORAGES_FILTERS";
export const UPDATE_IS_LOADING_PRODUCT_STORAGES = "UPDATE_IS_LOADING_PRODUCT_STORAGES";
export interface ILoadProductStoragesActionSaga {
  readonly type: typeof LOAD_PRODUCT_STORAGES_SAGA;
}

export const loadProductStoragesPropsSaga = (): ILoadProductStoragesActionSaga => {
  return {
    type: LOAD_PRODUCT_STORAGES_SAGA,
  };
};

export const FILTER_PRODUCT_STORAGES_SAGA = "FILTER_PRODUCT_STORAGES_SAGA";
export interface IFilterProductStoragesActionSaga {
  readonly type: typeof FILTER_PRODUCT_STORAGES_SAGA;
  filters: {
    productId: number;
    page: number;
    size: number;
    sort: string;
  };
}

export const filterProductStoragesPropsSaga = (filters: IFilterProductStoragesActionSaga['filters']): IFilterProductStoragesActionSaga => {
  return {
    type: FILTER_PRODUCT_STORAGES_SAGA,
    filters: {
      productId: filters.productId,
      page: filters.page,
      size: filters.size,
      sort: filters.sort,
    },
  };
};