import { CSSProperties } from "react";

export const buttonStyle = {
  border: "none",
  borderRadius: "1px",
  margin: "0.2rem",
  width: "10rem",
};


export const addressFormStyles: CSSProperties = {
  padding: "20px 100px 20px 20px",
};

export const titleStyle: CSSProperties = {
  padding: "20px 0px",
  fontSize: "1.1rem",
  fontWeight: "bold",
};

export const formItemStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: "20px",
};
export const labelStyle: CSSProperties = { width: "30%", flex: "0.3" };
export const itemStyle: CSSProperties = { width: "100%", flex: "0.7" };
export const colorRed: CSSProperties = { color: "red" };

export const buttonLeft: CSSProperties = {
  textAlign: "right",
  marginRight: "20px",
};