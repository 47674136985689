import {axiosInstance} from "../store";
import Constants from '../constants';

export function login(email: string, password: string): Promise<any> {
    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return axiosInstance.post(`${process.env.REACT_APP_KEY_API_GATEWAY}/4.0/auth_tokens`, formData,
    {
        headers: {
            'Storefront-Api-Access-Key': process.env.REACT_APP_KEY_API_GATEWAY_API_KEY
        }
    })
    .then((res) => res.data );
}