export const TOGGLE_SIDEBAR_SAGA = 'TOGGLE_SIDEBAR_SAGA';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export interface IToggleSidebarActionSaga {
    readonly type: typeof TOGGLE_SIDEBAR_SAGA;
}

export const toggleSidebarPropsSaga = (): IToggleSidebarActionSaga => {
    return {
        type: TOGGLE_SIDEBAR_SAGA,
    }
};
