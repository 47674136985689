import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./styles/index.scss"
import { loadNotificationsPropsSaga, undismissNotificationPropsSaga } from "../../sagas/notifications/types";
import { AppState } from "../../store";
import { MDBCol, MDBRow, MDBIcon, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import INotification from "../../dtos/INotification"
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

interface IDismissedNotificationsListProps {
}
export default function DismissedNotificationsList() {
    const dispatch = useDispatch();
    const [page, updatePage] = useState(0);
    const notificationsReducer = useSelector((state: AppState) => state.notificationsReducer)
    const loadMore = () => {
        dispatch(loadNotificationsPropsSaga(true, page + 1, 10))
        updatePage(page + 1)
    }
    useEffect(() => {
        dispatch(loadNotificationsPropsSaga(true, 0, 10))
    }, []);
    useBottomScrollListener(loadMore);
    return (
        <MDBRow className="m-4 justify-content-center w-100">
                {notificationsReducer.dismissedNotifications.length > 0 ?
                    notificationsReducer.dismissedNotifications.map((n: INotification, index: any) => {
                        return <MDBRow className="mr-5 ml-5 cart-card w-100">
                            <MDBCol>
                                <MDBCard className="card-shadow">
                                    <MDBCardBody>
                                        <MDBRow>
                                            <MDBCol>
                                                <MDBCardText tag="h4" className="text-center font-weight-bold cart-text p-1">{n.title}</MDBCardText>
                                            </MDBCol>
                                            <MDBIcon icon="redo-alt" className="justify-content-end pr-3 notification-icon"
                                                onClick={() => dispatch(undismissNotificationPropsSaga(n.uuid))}
                                                title="Restore"
                                            ></MDBIcon>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <MDBCardText tag="h4" className="font-weight-bold p-1">{n.long_description}</MDBCardText>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <MDBCardText tag="h4" className="font-weight-bold p-1">{n.notification_date.replace('T', ' ')}</MDBCardText>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    })
                    : <div>There's no dismissed notifications</div>}
        </MDBRow>

    )
}
