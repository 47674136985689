import React, { useEffect, useState, useMemo } from 'react';
import './styles/styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { updateBranchPropsSaga } from "../../sagas/branch/types";
import { AppState } from "../../store";
import { MDBRow } from 'mdbreact';
import IBranch from "../../dtos/IBranch"
import BranchForm from "./branchForm"

interface IEditBranchProps {
    branchId: any,
    resetBranch: any
    toggleBranchModal: any
}

function EditBranch(props: IEditBranchProps) {
    const dispatch = useDispatch();
    const branchReducer = useSelector((state: AppState) => state.branchReducer)
    useMemo(() => {
        if (branchReducer.branchUpdated) {
            props.toggleBranchModal(false)
            branchReducer.branchUpdated = false;
        }
    }, [branchReducer.branchUpdated])
    const handleSaveBranch = (branch: IBranch) => {
        dispatch(updateBranchPropsSaga(props.branchId, branch))
        props.resetBranch();
    }
    return (
        <MDBRow className="m-0">
            <BranchForm key={branchReducer.branch.id} existingBranch={branchReducer.branch} handleSave={(branch: IBranch) => handleSaveBranch(branch)} />
        </MDBRow>

    )
}

export default EditBranch;