import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';
import {LOAD_INVENTORY_FILTERS, LOAD_INVENTORY_PRODUCTS, UPDATE_IS_LOADING_INVENTORY} from '../../sagas/inventory/types';

export interface ILoadInventoryFiltersAction {
    readonly type: typeof LOAD_INVENTORY_FILTERS;
    payload: {
        categoryList: IDropdown[],
        storageList: IDropdown[],
        statusList: IDropdown[],
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    }
}

export const loadInventoryFiltersProps = (payload: ILoadInventoryFiltersAction['payload']): ILoadInventoryFiltersAction => {
    return {
        type: LOAD_INVENTORY_FILTERS,
        payload: payload
    }
};

export interface ILoadInventoryProductsAction {
    readonly type: typeof LOAD_INVENTORY_PRODUCTS;
    payload: {
        products: IProduct[],
        totalCountPages: number
    }
}

export const loadInventoryProductsProps = (payload: ILoadInventoryProductsAction['payload']): ILoadInventoryProductsAction => {
    return {
        type: LOAD_INVENTORY_PRODUCTS,
        payload: payload
    }
};

export interface IUpdateIsLoadingInventoryAction {
    readonly type: typeof UPDATE_IS_LOADING_INVENTORY;
    payload:{
        isLoadingInventory: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingInventoryProps = (payload: IUpdateIsLoadingInventoryAction['payload']): IUpdateIsLoadingInventoryAction => {
    return {
        type: UPDATE_IS_LOADING_INVENTORY,
        payload: payload
    }
};

export type InventoryActions = ILoadInventoryFiltersAction | ILoadInventoryProductsAction | IUpdateIsLoadingInventoryAction;