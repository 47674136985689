import {HeaderActions} from './types'
import {LOGOUT} from "../../sagas/header/types";

interface IHeaderState {
}

const HeaderState: IHeaderState = {
};

const headerReducer = (state = HeaderState, action: HeaderActions): IHeaderState => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default headerReducer;