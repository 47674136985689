import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader,
} from 'mdbreact';
import Select from '../../components/select';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import {
    loadProductTransactionsPropsSaga, filterProductTransactionsPropsSaga,
    IFilterProductTransactionsActionSaga,
} from '../../sagas/product_transactions/types';
import Constants from '../../constants';
import { addQueryString } from '../../helpers';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton"
import IconContainer from "../../components/iconContainer"
import constants from "../../constants"
import { useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async';
import { fetchUsers } from '../../api/users';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface IProductTransactionsProps {
}

function ProductTransactions(props: IProductTransactionsProps) {
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page, storage, operation, user_id, from, to } = queryString.parse(routerReducer.location.search);
    const [selectedStorage, selectStorage] = useState(storage ? storage.toString() : '');
    const [selectedOperation, selectOperation] = useState(operation ? operation.toString() : '');
    const [newUserValue, updateNewUserValue] = useState<string | number>(user_id ? user_id.toString() : '');
    const [fromDate, setFromDate] = useState<string>(from ? from.toString() : '');
    const [toDate, setToDate] = useState<string>(to ? to.toString() : '');
    const params: any = useParams();
    //new ui
    const [view, selectView] = useState(10)
    const [isOpen, toggleModal] = useState(false)

    let headers: IHeaderColumn[] = [
        { name: "Storage", width: 2, handleClick: () => { } },
        { name: "Quantity", width: 2 },
        { name: "User", width: 2, handleClick: () => { } },
        { name: "Operation", width: 2 },
        { name: "Created at", width: 2, backendName: 'createdAt' },
    ];
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const dispatch = useDispatch();
    const productTransactionsReducer = useSelector((state: AppState) => state.productTransactionsReducer)

    // container logic
    useEffect(() => {
        dispatch(loadProductTransactionsPropsSaga());
    }, []);
    useEffect(() => {
        const { sort, page, operation, storage, from, to, user_id } = queryString.parse(routerReducer.location.search);
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : ['createdAt', 'ascending'];
        const filters: IFilterProductTransactionsActionSaga['filters'] = {
            productId: params.productId,
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: view,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : 'createdAt,asc',
            operation: operation ? operation.toString() : '',
            storage: storage ? storage.toString() : '',
            from: from ? from.toString() : '',
            to: to ? to.toString() : '',
            userId: user_id ? user_id.toString() : '',
        }
        dispatch(filterProductTransactionsPropsSaga(filters));
        // updating components values
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        selectStorage(storage ? storage.toString() : '');
        selectOperation(operation ? operation.toString() : '');
        setFromDate(from ? from.toString() : '');
        setToDate(to ? to.toString() : '');
        updateNewUserValue(user_id ? user_id.toString() : '');
    }, [routerReducer.location.search, view]);

    // headers and soring
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && (headerColumn.name.toLowerCase() == columnName.toLowerCase() || headerColumn.backendName?.toLowerCase() == columnName.toLowerCase())) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }

    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending', backendName?: string) => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            if (headerColumn.name.toLowerCase() == columnName.toLowerCase() || headerColumn.backendName?.toLowerCase() == backendName?.toLowerCase()) {
                sorting = `${backendName ? backendName : columnName.toLowerCase()},`;
                switch (columnSorted) {
                    case undefined:
                        sorting += 'ascending';
                        break;
                    case 'ascending':
                        sorting += 'descending';
                        break;
                    default:
                        sorting = '';
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    // body rows data
    const buildRows = (productTransactions: any[]): IRow[] => {
        return productTransactions.map((productTransaction: any, i) => {
            let row: IRow = {
                value: productTransaction,
                props: [
                    'storage.name', 'quantity',
                    {
                        edit: (rowValue: any) => {
                            return (<div>{`${rowValue.user ? rowValue.user.first_name : ''} ${rowValue.user ? rowValue.user.last_name : ''}`}</div>);
                        },
                    },
                    'operation',
                    'created_at',
                ]
            };
            return row;
        });
    };

    const rows = buildRows(productTransactionsReducer.productTransActions);

    // pagination
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }

    // search select user
    const asyncSearchOptions = (inputValue: string, callback: any) => {
        fetchUsers(0, 10, '', inputValue).then((results) => {
            const mappedOptions = results.users?.map((user, i) => {
                return { value: user.user_id, label: `${user.first_name} ${user.last_name}` }
            });
            callback(mappedOptions);
        })
    }
    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        return inputValue;
    };
    const handleSelectOption = (option: any) => {
        updateNewUserValue(option.value as number);
    }
    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalHeader toggle={() => toggleModal(false)} >Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >
                    <div className="w-100 d-flex mt-3 justify-content-between">
                        <MDBRow className="p-0 m-0">
                            <MDBCol className="p-0 m-0" size="12">
                                <Select name="storage"
                                    label="Select Storage"
                                    options={productTransactionsReducer.filters.storageList.map((option, i) => {
                                        return { text: option.name, value: option.id }
                                    })}
                                    selectedValue={selectedStorage}
                                    onChange={(e: any) => { selectStorage(e.target.value) }}
                                />
                                <Select name="operation"
                                    label="Select Operation"
                                    options={productTransactionsReducer.filters.operationList.map((option, i) => {
                                        return { text: option.name, value: option.id }
                                    })}
                                    selectedValue={selectedOperation}
                                    onChange={(e: any) => selectOperation(e.target.value)}
                                    className="ml-4"
                                />
                            </MDBCol>
                            <MDBCol className="p-0 m-0 request-container" size="5">
                                <label className="font-weight-bold pt-3">From Date</label>
                                <DatePicker wrapperClassName="calendar-input"
                                    dateFormat="yyyy-MM-dd"
                                    selected={fromDate ? new Date(fromDate) : undefined}
                                    onChange={(date: any) => {
                                        setFromDate(date)
                                        setToDate('')
                                    }} />
                            </MDBCol>
                            <MDBCol className="p-0 ml-5 request-container" size="5">
                                <label className="font-weight-bold pt-3">To Date</label>
                                <DatePicker wrapperClassName="calendar-input"
                                    dateFormat="yyyy-MM-dd" minDate={new Date(fromDate)}
                                    selected={toDate ? new Date(toDate) : undefined}
                                    onChange={(date: any) => setToDate(date)} />
                            </MDBCol>
                            <MDBCol className="p-0 mt-3 text-left" size="6">
                                <label className="text-capitalize">Search a User</label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={asyncSearchOptions}
                                    onInputChange={handleInputChange}
                                    onChange={handleSelectOption}
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }

    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (selectedStorage.trim() != '') {
            filters.push({ filterName: 'storage', value: selectedStorage });
        }
        if (selectedOperation.trim() != '') {
            filters.push({ filterName: 'operation', value: selectedOperation });
        }
        if (fromDate.toString().trim() != '') {
            filters.push({ filterName: 'from', value: moment(fromDate).format("yyyy-MM-DD") + "T00:00:00" });
        } else {
            filters.push({ filterName: 'from', value: '' });
        }
        if (toDate.toString().trim() != '') {
            filters.push({ filterName: 'to', value: moment(toDate).format("yyyy-MM-DD") + "T00:00:00" });
        } else {
            filters.push({ filterName: 'to', value: '' });
        }
        if (newUserValue != '') {
            filters.push({ filterName: 'user_id', value: newUserValue });
        }
        handleFilter(filters);
        toggleModal(false);
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={productTransactionsReducer.loadingProductTransactions.isLoadingProductTransactions} errorMessage={productTransactionsReducer.loadingProductTransactions.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                id="view-select"
                                className="mr-2 d-inline"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                            <IconContainer className="mr-2 d-inline" onClick={() => toggleModal(true)} >
                                <MDBIcon icon="filter" />
                            </IconContainer>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                productTransactionsReducer.productTransActions.length > 0 ?
                                    <div className="celled-table">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            headerOnClick={handleSort}
                                            rows={rows}
                                            data={productTransactionsReducer.productTransActions}
                                            totalPages={productTransactionsReducer.totalCountPages / Constants.pagination.productsPerPage}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                    </div>
                                    :
                                    <div>No transactions found.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
            </MDBCol>
            {renderModal()}
        </MDBRow>
    )
}

export default ProductTransactions;