import {
    LOAD_MANUAL_STORAGES,
    LOAD_ADJUSTMENT_REASONS, SEND_REQUEST,
    LOAD_MY_REQUESTS, LOAD_RECEIVED_REQUESTS, LOAD_REQUEST,
    REQUEST_RESPONSE, UPDATE_IS_LOADING_REQUESTS
} from "../../sagas/manual-storage/types";
import IStorage from "../../dtos/IStorage"
import IReason from "../../dtos/IReason"

export interface ILoadManualStoragesAction {
    readonly type: typeof LOAD_MANUAL_STORAGES;
    payload: {
        manualStorages: IStorage[],
    }
}
export const loadManualStoragesProps = (payload: ILoadManualStoragesAction['payload']): ILoadManualStoragesAction => {
    return {
        type: LOAD_MANUAL_STORAGES,
        payload: payload
    }
};
export interface ILoadAdjustmentReasonsAction {
    readonly type: typeof LOAD_ADJUSTMENT_REASONS;
    payload: {
        adjustmentReasons: IReason[],
    }
}
export const loadAdjustmentReasonsProps = (payload: ILoadAdjustmentReasonsAction['payload']): ILoadAdjustmentReasonsAction => {
    return {
        type: LOAD_ADJUSTMENT_REASONS,
        payload: payload
    }
};
export interface ISendRequestAction {
    readonly type: typeof SEND_REQUEST;
    payload: {
        requestSent: boolean
    }
}
export const sendRequestProps = (payload: ISendRequestAction['payload']): ISendRequestAction => {
    return {
        type: SEND_REQUEST,
        payload: payload
    }
};
//requests
export interface ILoadMyRequestsAction {
    readonly type: typeof LOAD_MY_REQUESTS;
    payload: {
        myRequests: any[],
        totalMyRequestsCount: number
    }
}
export const loadMyRequestsProps = (payload: ILoadMyRequestsAction['payload']): ILoadMyRequestsAction => {
    return {
        type: LOAD_MY_REQUESTS,
        payload: payload
    }
};
export interface ILoadReceivedRequestsAction {
    readonly type: typeof LOAD_RECEIVED_REQUESTS;
    payload: {
        receivedRequests: any[],
        totalReceivedRequestsCount: number
    }
}
export const loadReceivedRequestsProps = (payload: ILoadReceivedRequestsAction['payload']): ILoadReceivedRequestsAction => {
    return {
        type: LOAD_RECEIVED_REQUESTS,
        payload: payload
    }
};
export interface ILoadRequestAction {
    readonly type: typeof LOAD_REQUEST;
    payload: {
        request: {},
    }
}
export const loadRequestProps = (payload: ILoadRequestAction['payload']): ILoadRequestAction => {
    return {
        type: LOAD_REQUEST,
        payload: payload
    }
};

export interface IRequestResponseAction {
    readonly type: typeof REQUEST_RESPONSE;
    payload: {
        responseSent: boolean
    }
}
export const requestResponseProps = (payload: IRequestResponseAction['payload']): IRequestResponseAction => {
    return {
        type: REQUEST_RESPONSE,
        payload: payload
    }
};
export interface IUpdateIsLoadingRequestsAction {
    readonly type: typeof UPDATE_IS_LOADING_REQUESTS;
    payload: {
        isLoadingRequests: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingRequestsProps = (payload: IUpdateIsLoadingRequestsAction['payload']): IUpdateIsLoadingRequestsAction => {
    return {
        type: UPDATE_IS_LOADING_REQUESTS,
        payload: payload
    }
};


export type ManualStorageActions = ILoadManualStoragesAction | ILoadAdjustmentReasonsAction | ISendRequestAction | ILoadMyRequestsAction | ILoadReceivedRequestsAction | ILoadRequestAction | IRequestResponseAction | IUpdateIsLoadingRequestsAction