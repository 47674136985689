import { IUpdateIsLoadingProductsAction, ProductsActions } from './types'
import { LOAD_PRODUCTS_FILTERS, LOAD_PRODUCTS, UPDATE_IS_LOADING_PRODUCTS, DELETE_PRODUCT, UPDATE_PRODUCT } from "../../sagas/products/types";
import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';
import _ from 'lodash';

interface IProductsState {
    filters: {
        categoryList: IDropdown[],
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    },
    products: IProduct[],
    totalCountPages: number,
    loadingProducts: IUpdateIsLoadingProductsAction['payload'],
}

const ProductsState: IProductsState = {
    filters: {
        categoryList: [],
        sortCol: '',
        sortOrder: 'ascending'
    },
    products: [],
    totalCountPages: 0,
    loadingProducts: {
        isLoadingProducts: false,
        errorMessage: undefined
    }
};

const productsReducer = (state = ProductsState, action: ProductsActions): IProductsState => {
    switch (action.type) {
        case LOAD_PRODUCTS_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        case LOAD_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_PRODUCTS:
            return {
                ...state,
                loadingProducts: action.payload,
            };
        case DELETE_PRODUCT:
            const updatedProducts = [...state.products];
            _.remove(updatedProducts, (p, i) => p.id === action.payload.productId)
            return {
                ...state,
                products: updatedProducts
            };
        case UPDATE_PRODUCT:
            return {
                ...state,
                products: state.products.map((product: any, i) => {
                    if (product.id == action.payload.productId) {
                        if (action.payload.cart_type == "cart" || action.payload.cart_type == "quotation") {
                            return { ...product, sac_status: "InCart" }
                        } else if (action.payload.cart_type == "to_be_quotation") {
                            return { ...product, button_status: "ask_quotation" }
                        } else if (action.payload.cart_type == "to_be_cart") {
                            return { ...product, button_status: "add_to_cart" }
                        } else if (action.payload.cart_type == "to_be_disabled") {
                            return { ...product, button_status: "disabled" }
                        }
                    } else {
                        return product;
                    }
                })
            };
        default:
            return state;
    }
};

export default productsReducer;
