import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';
import {
    LOAD_PRODUCT_STORAGES_FILTERS, LOAD_PRODUCT_STORAGES,
    UPDATE_IS_LOADING_PRODUCT_STORAGES,
} from '../../sagas/product_storages/types';

export interface ILoadProductStoragesFiltersAction {
    readonly type: typeof LOAD_PRODUCT_STORAGES_FILTERS;
    payload: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending',
    }
}

export const loadProductStoragesFiltersProps = (payload: ILoadProductStoragesFiltersAction['payload']): ILoadProductStoragesFiltersAction => {
    return {
        type: LOAD_PRODUCT_STORAGES_FILTERS,
        payload: payload
    }
};

export interface ILoadProductTransactionAction {
    readonly type: typeof LOAD_PRODUCT_STORAGES;
    payload: {
        productStorages: any[],
        totalCountPages: number
    }
}

export const loadProductStoragesProps = (payload: ILoadProductTransactionAction['payload']): ILoadProductTransactionAction => {
    return {
        type: LOAD_PRODUCT_STORAGES,
        payload: payload
    }
};

export interface IUpdateIsLoadingProductStoragesAction {
    readonly type: typeof UPDATE_IS_LOADING_PRODUCT_STORAGES;
    payload:{
        isLoadingProductStorages: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingProductStoragesProps = (payload: IUpdateIsLoadingProductStoragesAction['payload']): IUpdateIsLoadingProductStoragesAction => {
    return {
        type: UPDATE_IS_LOADING_PRODUCT_STORAGES,
        payload: payload
    }
};

export type ProductStoragesActions = ILoadProductStoragesFiltersAction | ILoadProductTransactionAction |
 IUpdateIsLoadingProductStoragesAction;