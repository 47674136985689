import { LOAD_QUOTATION_LIST } from "../../sagas/quotation/types";

export interface ILoadQuotationAction {
  readonly type: typeof LOAD_QUOTATION_LIST;
  payload: {
    quotationList: any[],
    totalQuotationCount: number
  }
}
export const loadQuotationProps = (payload: ILoadQuotationAction['payload']): ILoadQuotationAction => {
  return {
    type: LOAD_QUOTATION_LIST,
    payload: payload
  }
};


// export interface IUpdateIsLoadingOrderProductsAction {
//   readonly type: typeof UPDATE_IS_LOADING_ORDER_PRODUCTS;
//   payload: {
//     isLoadingOrderProducts: boolean,
//     isLoadingItemQty: boolean,
//     errorMessage: string | undefined
//   }
// }
// export const updateIsLoadingOrderProductsProps = (payload: IUpdateIsLoadingOrderProductsAction['payload']): IUpdateIsLoadingOrderProductsAction => {
//   return {
//     type: UPDATE_IS_LOADING_ORDER_PRODUCTS,
//     payload: payload
//   }
// };

export type QuotationActions = ILoadQuotationAction