import React from "react";
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import NotificationsListPage from "./notificationsPage";
import DismissedNotificationsListPage from "./dismissedNotificationsPage";

interface INotificationsPageProps {
}

export default function NotificationsPage(props: INotificationsPageProps) {
    let { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/dismissed`} component={DismissedNotificationsListPage} />
                <Route exact path={path} component={NotificationsListPage} />
            </Switch>
        </>
    )
}