import { axiosInstance } from "../store";
import Constants from '../constants';
import IProduct from "../dtos/IProduct";
import { handlStatusCodeMessage } from "../helpers";

export function getCategories(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/category`)
        .then((res) => res.status === 200 ? res.data.map((category: any, i: number) => {
            return {
                id: category.id,
                name: category.name
            }
        }) : []).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'inventory' });
            return [];
        });
}

export function getStorages(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/storage`)
        .then((res) => res.status === 200 ? res.data.map((storage: any, i: number) => {
            return {
                id: storage.storage_id,
                name: storage.name
            }
        }) : []).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'inventory' });
            return [];
        });
}

export function getStatus(): any[] {
    return Constants.status;
}

export function fetchDataTableProducts(page = 0, size = 10, sort = '', name = '', storage = ''): Promise<{ products: IProduct[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/product_balance?page=${page}&size=${size}&sort=${sort}&alias=${name}${storage != '' ? `&storage_id=${storage}` : ''}`)
        .then((res) => {
            return {
                products: res.status === 200 ?
                    res.data.map((p: any) => { return { ...p, selected: false } })
                    : []
                , totalCountPages: res.headers['x-total-count']
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'inventory' });
            return {
                products: [],
                totalCountPages: 0
            }
        })
}

export function fetchProductBalance(id: string | number, page = 0, size = 10): Promise<IProduct> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/product/${id}/balance?page=${page}&size=${size}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'inventory' });
            return {}
        })
}