import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import Loader from "../../components/loader";
import { loadBranchPropsSaga } from "../../sagas/branch/types";
import { AppState } from "../../store";
import { MDBCol, MDBRow, MDBBtn, MDBCardText, MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import queryString from 'query-string';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import { addQueryString } from '../../helpers';
import IStorage from "../../dtos/IStorage"
interface IBranchStoragesProps {

}
export default function BranchStorages(props: IBranchStoragesProps) {
    const dispatch = useDispatch();
    const params: any = useParams();
    const routerReducer = useSelector((state: AppState) => state.router)
    const branchReducer = useSelector((state: AppState) => state.branchReducer)
    useEffect(() => {
        dispatch(loadBranchPropsSaga(params.branchId))

    }, []);
    const headers: any = [
        { name: "Storage Name", width: 3 },
        { name: "Type", width: 2 },
    ].map((header) => {
        return { ...header };
    });
    const buildRows = (storages: IStorage[]): IRow[] => {
        return storages.map((s: IStorage, i) => {
            return {
                value: s,
                props: ['name', 'type']
            }
        });
    };
    const rows = useMemo(() => buildRows(branchReducer.branch.storages), [(branchReducer.branch.storages)]);
    const [headerColumns] = useState<IHeaderColumn[]>(headers);
    return (
        <MDBRow className="m-0">
            <Loader isLoading={branchReducer.loadingBranches.isLoadingBranches} errorMessage={branchReducer.loadingBranches.errorMessage}>
                <MDBCol className="text-center">
                    {
                        branchReducer.branch.storages?.length > 0 ?
                            <div className="mt-3 celled-table">
                                <MDBRow start className="justify-content-center">
                                    <MDBCol className="text-center">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            rows={rows}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </div> : <div>There are no storages in this branch.</div>
                    }
                </MDBCol>
            </Loader>
        </MDBRow>
    )
}
