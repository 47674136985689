import { axiosInstance } from "../store";
import IAsset from "../dtos/IAsset"
import IAssetTransaction from "../dtos/IAssetTransaction"
import IStatus from "../dtos/IStatus"
import { handlStatusCodeMessage } from "../helpers";

export function fetchAssets(is_dismissed = '', page = 0, size = 0, sort = '', name = '', status = '', storage = ''): Promise<{ assets?: IAsset[], dismissedAssets?: IAsset[], ÏtotalAssetsCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/assets?is_dismissed=${is_dismissed}&page=${page}&size=${size}&sort=${sort}&name=${name}&status=${status}&storage=${storage}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    assets: res.data.map((a: any) => { if (!a.is_dismissed) return { ...a, selected: false } })
                    , dismissedAssets: res.data.filter((a: any) => a.is_dismissed)
                    , totalAssetsCount: res.headers['x-total-count']
                }
            }
            else {
                return {
                    assets: []
                    , dismissedAssets: []
                    , totalAssetsCount: 0
                }
            }

        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'asset' });
            return {
                assets: []
                , dismissedAssets: []
                , totalAssetsCount: 0
            }
        })
}

export function fetchAsset(assetId = 0): Promise<IAsset> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/assets/${assetId}`)
        .then((res) => {
            return res.status == 200 ? res.data : {}
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'asset' });
            return {}
        })
}
export function fetchAssetUsage(assetId: number, page = 0, size = 10, sort = ''): Promise<{ assetTransactions: IAssetTransaction[], assetTransactionsCount: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/assets/${assetId}/transactions?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    assetTransactions: res.data,
                    assetTransactionsCount: res.headers['x-total-count']
                }
            }
            else {
                return { assetTransactions: [], assetTransactionsCount: 0 }
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'asset' });
            return {
                assetTransactions: [], assetTransactionsCount: 0
            }

        })
}
export function updateAsset(assetId: number, asset: IAsset): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/assets/${assetId}`, asset)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'asset' });
            return res.status == 200 ? res.data.id : 0
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'asset' });
            return 0

        })
}
export function fetchAssetsStatus(): Promise<IStatus[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/assets/status`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'asset' });
            return []

        })
}