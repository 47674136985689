import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { MDBCol, MDBRow } from 'mdbreact';
import { createUpdateUserGroupPropsSaga, loadUserGroupPermissionsPropsSaga } from '../../sagas/user-group/types';
import { useParams } from 'react-router-dom';
import { fetchUserGroup } from '../../api/user-group';
import * as Yup from "yup";
import { useFormik } from "formik";
import { IPermission } from '../../dtos/IUserGroup';
import { arrayContainsArray } from "../../helpers";
import MainBlueButton from '../../components/blueButton';
import { moduleNames, permissionNames } from './namings';
import { PermissionGroup } from './permissionGroup';

export interface IUserGroupModule {
    name: string,
    permissions: IPermission[],
}

interface IUserGroupFormProps {
    type: 'create' | 'update'
}

interface IFormValues {
    name: string,
    description: string
}

function UserGroupForm(props: IUserGroupFormProps) {
    const params: any = useParams();
    const [name, updateName] = useState<string>('');
    const [description, updateDescription] = useState<string>('');
    const [permissions, updatePermissions] = useState<IPermission[]>([]);
    const dispatch = useDispatch();
    const userGroupReducer = useSelector((state: AppState) => state.userGroupReducer)
    useEffect(() => {
        dispatch(loadUserGroupPermissionsPropsSaga('CUSTOMER_PORTAL'));
        const loadUserGroup = async () => {
            const result = await fetchUserGroup(params.id);
            updateName(result.name);
            mainformik.setFieldValue('name', result.name);
            updateDescription(result.description ? result.description : '');
            mainformik.setFieldValue('description', result.description);
            updatePermissions(result.permissions);
        };
        if (params.id) {
            loadUserGroup();
        }
    }, []);
    // displayModules permissions
    const groupingPermissions = (permissions: IPermission[]): IUserGroupModule[] => {
        let modules: IUserGroupModule[] = [];
        _.forEach(permissions, (value, key) => {
            // let accessType: string = (value.name.split('_').pop() as string).toLowerCase();

            // let moduleName: string = _.capitalize(value.name.replace('CREATE', '')
            //     .replace('VIEW', '')
            //     .replace('EDIT', '').replace('UPDATE', '')
            //     .replace('DELETE', '')
            //     .replace(/_/g, ' '));
            let moduleName = value.module.name;
            let permission: IPermission = { id: value.id, name: value.name, module: value.module };
            let exists = _.find(modules, (o) => o.name === moduleName);
            if (exists === undefined) {
                modules.push({
                    name: moduleName,
                    permissions: [permission]
                })
            } else {
                exists.permissions.push(permission)
            }
        });
        return modules;
    }
    const modules = useMemo(() => groupingPermissions(userGroupReducer.userGroupPermissions), [userGroupReducer.userGroupPermissions]);

    // form
    const initialValues: IFormValues = {
        name: name,
        description: description
    }
    const mainvalidationSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Required'),
    });
    const mainformik = useFormik({
        initialValues: initialValues,
        validationSchema: mainvalidationSchema,
        onSubmit: async (values) => {
            // console.log({
            //     id: params.id,
            //     name: values.name,
            //     description: values.description,
            //     permissions: permissions,
            // });
            dispatch(createUpdateUserGroupPropsSaga({
                id: params.id,
                name: values.name,
                description: values.description,
                permissions: permissions,
            }))
        },
    });
    return (
        <>
            <MDBRow className="m-0">
                <MDBCol className="p-4">
                    <div className="form-group">
                        <label htmlFor="name">
                            name
                        </label>
                        <input className="form-control input-rounded"
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => { mainformik.setFieldValue('name', e.target.value); updateName(e.target.value) }}
                            placeholder="name" aria-label="name" />
                        {mainformik.errors.name && mainformik.touched.name ?
                            <p className='red-text'>{mainformik.errors.name}</p>
                            : undefined}
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow className="m-0">
                <MDBCol className="p-4">
                    <div className="form-group">
                        <label htmlFor="description">
                            Description
                        </label>
                        <textarea
                            className="text-area form-control resize-none"
                            id="description"
                            rows={5}
                            value={description}
                            onChange={(e) => { mainformik.setFieldValue('description', e.target.value); updateDescription(e.target.value) }}
                            placeholder="description" aria-label="description"
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow className="m-0">
                {
                    modules.filter((module) => !["ASSET", "BUSINESS_UNIT", "VENDOR", "INVENTORY", "OPERATOR", "STORAGE", "MANUAL_STORAGE_REQEUST", "PURCHASE_ORDER", "ITEM", "ACCESS_CARD", "CATEGORY"].includes(module.name)).map((module, i) => (
                        <MDBCol key={i} className="p-4">
                            <h5 className="text-capitalize">{moduleNames[module.name]}</h5>
                            <div className="custom-control custom-control-inline custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                    id={`${module.name}-full-access`}
                                    checked={arrayContainsArray(permissions as any, module.permissions as any, 'id')}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // Remove All module Permissions to prevent duplicate 
                                            // individual Permissions
                                            let updatedPermissions = [...permissions];
                                            module.permissions.forEach((permission) => {
                                                _.remove(updatedPermissions, (o) => o.id === permission.id)
                                            })
                                            ////////////////////////////////////////
                                            updatePermissions([...updatedPermissions, ...module.permissions])
                                        } else {
                                            let updatedPermissions = [...permissions];
                                            module.permissions.forEach((permission) => {
                                                _.remove(updatedPermissions, (o) => o.id === permission.id)
                                            })
                                            updatePermissions(updatedPermissions)
                                        }
                                    }}
                                />
                                <label className="custom-control-label text-capitalize" htmlFor={`${module.name}-full-access`}>full access</label>
                            </div>
                            <PermissionGroup module={module} permissions={permissions}
                                setPermissions={updatePermissions} />
                            {/* {
                                module.permissions.map((permission, i) => (
                                    <div key={i} className="custom-control custom-control-inline custom-checkbox">
                                        <input type="checkbox" className="custom-control-input"
                                            id={`${permission.id}`}
                                            checked={_.find(permissions, (p, key) =>    p.id === permission.id) ? true : false}
                                            onChange={(e) => {
                                                console.log(permissions)
                                                if (e.target.checked) {
                                                    updatePermissions([...permissions, { ...permission }])
                                                } else {
                                                    let updatedPermissions = [...permissions];
                                                    _.remove(updatedPermissions, (o) => o.id === permission.id)
                                                    updatePermissions(updatedPermissions)
                                                }
                                            }}
                                        />
                                        <label className="custom-control-label text-capitalize" htmlFor={`${permission.id}`}>{permissionNames[permission.name as ReturnType<typeof permissionNames>]}</label>
                                    </div>
                                ))
                            } */}
                        </MDBCol>
                    ))
                }
            </MDBRow>
            <MDBRow className="m-0 justify-content-center">
                <MDBCol md="4" className="p-4 text-center">
                    <MainBlueButton
                        type="submit"
                        btnClassName="pl-4 pr-4"
                        title={props.type}
                        onClick={() => mainformik.submitForm()}
                    />
                </MDBCol>
            </MDBRow>
        </>
    )
}

export default UserGroupForm;