import IInvitation from "../../dtos/IInvitation"

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SAGA = "LOAD_USERS_SAGA";

export const INVITE_NEW_USER = "INVITE_NEW_USER"
export const INVITE_NEW_USER_SAGA = "INVITE_NEW_USER_SAGA"

export const VALIDATE_INVITATION = "VALIDATE_INVITATION"
export const VALIDATE_INVITATION_SAGA = "VALIDATE_INVITATION_SAGA"

export const UPDATE_IS_LOADING_USERS = "UPDATE_IS_LOADING_USERS";

export interface ILoadUsersActionSaga {
    readonly type: typeof LOAD_USERS_SAGA;
    page: number,
    size: number,
    sort: string
}
export const loadUsersPropsSaga = (page = 0, size = 10, sort = ''): ILoadUsersActionSaga => {
    return {
        type: LOAD_USERS_SAGA,
        page: page,
        size: size,
        sort: sort
    }
};

export interface IInviteNewUserActionSaga {
    readonly type: typeof INVITE_NEW_USER_SAGA;
    invitation: IInvitation
}
export const inviteNewUserProps = (invitation: any): IInviteNewUserActionSaga => {
    return {
        type: INVITE_NEW_USER_SAGA,
        invitation: invitation
    }
};

export interface IValidateInvitationActionSaga {
    readonly type: typeof VALIDATE_INVITATION_SAGA;
    token: string
}

export const validateInvitationProps = (token: string): IValidateInvitationActionSaga => {
    return {
        type: VALIDATE_INVITATION_SAGA,
        token: token
    }
};

export const LOAD_INVITATIONS = "LOAD_INVITATIONS";
export const LOAD_INVITATIONS_SAGA = "LOAD_INVITATIONS_SAGA";
export const UPDATE_IS_LOADING_INVITATIONS = "UPDATE_IS_LOADING_INVITATIONS";

export interface ILoadInvitationsActionSaga {
    readonly type: typeof LOAD_INVITATIONS_SAGA;
    page: number,
    size: number,
    sort: string
}
export const loadInvitationsPropsSaga = (page = 0, size = 10, sort = ''): ILoadInvitationsActionSaga => {
    return {
        type: LOAD_INVITATIONS_SAGA,
        page: page,
        size: size,
        sort: sort
    }
};