import React, { useEffect, useState, useMemo } from 'react';
import './styles/styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { addNewBranchPropsSaga } from "../../sagas/branch/types";
import { AppState } from "../../store";
import { MDBRow } from 'mdbreact';
import IBranch from "../../dtos/IBranch"
import BranchForm from "./branchForm"
import Can from '../can';
import AddressForm from '../../components/addressForm/AddressForm';
interface IAddBranchProps {
    toggleBranchModal: any
}

function AddBranch(props: IAddBranchProps) {
    const dispatch = useDispatch();
    const branchReducer = useSelector((state: AppState) => state.branchReducer)
    useMemo(() => {
        if (branchReducer.branchAdded) {
            props.toggleBranchModal(false)
            dispatch(push({ pathname: '/branches' }))
        }
    }, [branchReducer.branchAdded])
    const handleSaveBranch = (branch: IBranch) => {
        dispatch(addNewBranchPropsSaga(branch))
    }
    return (
        <Can check="BRANCH_EDIT">
            {/* <MDBRow className="m-0"> */}
            {/* <BranchForm handleSave={(branch: IBranch) => handleSaveBranch(branch)} /> */}
            <AddressForm toggleBranch={props.toggleBranchModal} />
            {/* </MDBRow> */}
        </Can>
    )
}

export default AddBranch;