import IProduct from "../../dtos/IProduct";

export const isProductInStock = (product: IProduct): boolean => {
  let bool: boolean = false;
  product.product_vendors?.map((product_vendor) => {
    if (product_vendor.fulfilled_by_dc_quantity > 0) bool = true;
    if (product_vendor.status === "A" && product_vendor.quantity > 0) bool = true;
  });

  return bool;
}

export const isProductDisabled = (product: IProduct): boolean => {
  if (product.status == "D") return true;
  let count = 0;
  product.product_vendors?.map((product_vendor) => {
    if (product_vendor.status == 'D' && product_vendor.fulfilled_by_dc_quantity == 0) {
      count++;
    }
  })
  if (count == product.product_vendors.length) return true;
  return false;
}