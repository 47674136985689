import { LOAD_BRANCHES, LOAD_BRANCH, ADD_NEW_BRANCH, UPDATE_BRANCH, DELETE_BRANCH, LOAD_COUNTRIES, LOAD_CITIES, UPDATE_IS_LOADING_BRANCHES } from "../../sagas/branch/types";
import IBranch from "../../dtos/IBranch";
import ICountry from "../../dtos/ICountry";
import ICity from "../../dtos/ICity";

export interface ILoadBranchesAction {
    readonly type: typeof LOAD_BRANCHES;
    payload: {
        branches: IBranch[],
        totalBranchesCount: number
    }
}
export const loadBranchesProps = (payload: ILoadBranchesAction['payload']): ILoadBranchesAction => {
    return {
        type: LOAD_BRANCHES,
        payload: payload
    }
};
export interface ILoadBranchAction {
    readonly type: typeof LOAD_BRANCH;
    payload: {
        branch: IBranch
    }
}
export const loadBranchProps = (payload: ILoadBranchAction['payload']): ILoadBranchAction => {
    return {
        type: LOAD_BRANCH,
        payload: payload
    }
};
export interface IAddNewBranchAction {
    readonly type: typeof ADD_NEW_BRANCH;
    payload: {
        branchAdded: boolean
    }
}
export const addNewBranchProps = (payload: IAddNewBranchAction['payload']): IAddNewBranchAction => {
    return {
        type: ADD_NEW_BRANCH,
        payload: payload
    }
};

export interface IUpdateBranchAction {
    readonly type: typeof UPDATE_BRANCH;
    payload: {
        branchUpdated: boolean
    }
}
export const updateBranchProps = (payload: IUpdateBranchAction['payload']): IUpdateBranchAction => {
    return {
        type: UPDATE_BRANCH,
        payload: payload
    }
};
export interface IDeleteBranchAction {
    readonly type: typeof DELETE_BRANCH;
    payload: {
        branchDeleted: number
    }
}
export const deleteBranchProps = (payload: IDeleteBranchAction['payload']): IDeleteBranchAction => {
    return {
        type: DELETE_BRANCH,
        payload: payload
    }
};

export interface ILoadCountriesAction {
    readonly type: typeof LOAD_COUNTRIES;
    payload: {
        countries: ICountry[]
    }
}
export const loadCountriesProps = (payload: ILoadCountriesAction['payload']): ILoadCountriesAction => {
    return {
        type: LOAD_COUNTRIES,
        payload: payload
    }
};
export interface ILoadCitiesAction {
    readonly type: typeof LOAD_CITIES;
    payload: {
        cities: ICity[]
    }
}
export const loadCitiesProps = (payload: ILoadCitiesAction['payload']): ILoadCitiesAction => {
    return {
        type: LOAD_CITIES,
        payload: payload
    }
};
export interface IUpdateIsLoadingBranchesAction {
    readonly type: typeof UPDATE_IS_LOADING_BRANCHES;
    payload: {
        isLoadingBranches: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingBranchesProps = (payload: IUpdateIsLoadingBranchesAction['payload']): IUpdateIsLoadingBranchesAction => {
    return {
        type: UPDATE_IS_LOADING_BRANCHES,
        payload: payload
    }
};
export type BranchActions = ILoadBranchesAction | ILoadBranchAction | IAddNewBranchAction | IUpdateBranchAction | IDeleteBranchAction | ILoadCountriesAction | ILoadCitiesAction | IUpdateIsLoadingBranchesAction