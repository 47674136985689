import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_VENDORS_SAGA, ILoadVendorsActionSaga,
    LOAD_VENDOR_SAGA, ILoadVendorActionSaga,
    ADD_VENDOR_SAGA, IAddVendorActionSaga,
    UPDATE_VENDOR_SAGA, IUpdateVendorActionSaga
} from "./types";
import { fetchVendors, fetchVendor, addVendor, updateVendor } from "../../api/vendors";
import {
    loadVendorsProps, loadVendorProps, addVendorProps, updateVendorProps,
    updateIsLoadingVendorsProps
} from "../../reducers/vendors/types";
import IVendor from "../../dtos/IVendor";

function* loadVendors(action: ILoadVendorsActionSaga) {
    try {
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: true, errorMessage: undefined }));
        const vendorsData: { vendors: IVendor[], totalVendorsCount: number } = yield call(fetchVendors, action.page, action.size, action.sort, action.name);
        yield put(loadVendorsProps({ vendors: vendorsData.vendors, totalVendorsCount: vendorsData.totalVendorsCount }))
    } catch (error) {
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadVendors() {
    yield takeLatest(LOAD_VENDORS_SAGA, loadVendors)
}

function* loadVendor(action: ILoadVendorActionSaga) {
    try {
        const vendor: IVendor = yield call(fetchVendor, action.vendorId);
        yield put(loadVendorProps({ vendor }))
    } catch (error) {
        yield put(updateIsLoadingVendorsProps({ isLoadingVendors: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadVendor() {
    yield takeLatest(LOAD_VENDOR_SAGA, loadVendor)
}

function* addVendorData(action: IAddVendorActionSaga) {
    try {
        const vendorAdded: boolean = yield call(addVendor, action.vendor);
        yield put(addVendorProps({ vendorAdded }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchAddVendorData() {
    yield takeLatest(ADD_VENDOR_SAGA, addVendorData)
}

function* updateVendorData(action: IUpdateVendorActionSaga) {
    try {
        const vendorUpdated: boolean = yield call(updateVendor, action.vendorId, action.vendor);
        yield put(updateVendorProps({ vendorUpdated }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchUpdateVendorData() {
    yield takeLatest(UPDATE_VENDOR_SAGA, updateVendorData)
}

export default [
    watchLoadVendors(),
    watchLoadVendor(),
    watchAddVendorData(),
    watchUpdateVendorData()
]
