import { put, takeLatest, call } from 'redux-saga/effects'
import {
    LOAD_INVENTORY_SAGA, ILoadInventoryActionSaga, FILTER_INVENTORY_PRODUCTS_SAGA, IFilterInventoryProductsActionSaga,
} from "./types";
import { loadInventoryFiltersProps, loadInventoryProductsProps, updateIsLoadingInventoryProps } from "../../reducers/inventory/types";
import { fetchDataTableProducts, getStorages, getStatus, getCategories } from '../../api/inventory'
import { displayErrorMessageProps } from "../../reducers/global/types";
import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';

function* filterInventoryProducts(action: IFilterInventoryProductsActionSaga) {
    try {
        const { page, size, sort, name, storage, status, category } = action.filters;
        const dataTableProducts: {
            products: IProduct[],
            totalCountPages: number
        } = yield call(fetchDataTableProducts, page, size, sort, name, storage);
        yield put(loadInventoryProductsProps({ products: dataTableProducts.products, totalCountPages: dataTableProducts.totalCountPages }))
    } catch (error) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchFilterInventoryProducts() {
    yield takeLatest(FILTER_INVENTORY_PRODUCTS_SAGA, filterInventoryProducts)
}


function* loadInventory(action: ILoadInventoryActionSaga) {
    try {
        yield put(updateIsLoadingInventoryProps({ isLoadingInventory: true, errorMessage: undefined }));
        const storageList: IDropdown[] = yield call(getStorages);
        const statusList: IDropdown[] = yield call(getStatus);
        // const categoryList: IDropdown[] = yield call(getCategories);
        yield put(loadInventoryFiltersProps({
            storageList: storageList,
            statusList: statusList,
            categoryList: [],
            sortCol: '',
            sortOrder: 'ascending'
        }));
        yield put(updateIsLoadingInventoryProps({ isLoadingInventory: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingInventoryProps({ isLoadingInventory: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadInventory() {
    yield takeLatest(LOAD_INVENTORY_SAGA, loadInventory)
}

export default [
    watchLoadInventory(),
    watchFilterInventoryProducts()
]