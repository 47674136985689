import React, { useEffect, useState } from 'react';
import './styles/index.scss'
import { useDispatch } from "react-redux";
import { loginAuthUserPropsSaga } from '../../sagas/global/types';

interface ILoginProps {

}

function LoginRoute(props: ILoginProps) {
    const [email, updateEmail] = useState('');
    const [password, updatePassword] = useState('');
    const dispatch = useDispatch();
    const login = () => {
        dispatch(loginAuthUserPropsSaga({ email, password }));
    }
    return (
        <>
            <form onSubmit={(e) => {
                e.preventDefault();
                if (email && password) {
                    login()
                } else {
                    alert("Please enter email and password to continue")
                }
            }}>
                <p>Username</p>
                <input type="text" name="email" placeholder="Enter email"
                    value={email}
                    onChange={(e) => { updateEmail(e.target.value) }}
                />
                <p>Password</p>
                <input type="password" name="password" placeholder="Enter password"
                    value={password}
                    onChange={(e) => { updatePassword(e.target.value) }}
                />
                <input type="submit" name="" value="Login" className="loginbutton" />
            </form>
        </>
    )
}

export default LoginRoute;