import { put, takeLatest, call } from "redux-saga/effects";
import {
  LOAD_USER_GROUP_SAGA,
  ILoadUserGroupActionSaga,
  DELETE_USER_GROUP_SAGA,
  IDeleteUserGroupActionSaga,
  CREATE_UPDATE_USER_GROUP_SAGA,
  ICreateUpdateUserGroupActionSaga,
  LOAD_USER_GROUP_PERMISSIONS_SAGA,
  ILoadUserGroupPermissionsActionSaga,
} from "./types";
import {
  loadUserGroupProps,
  updateIsLoadingUserGroupProps,
  deleteUserGroupProps,
  loadUserGroupPermissionsProps,
} from "../../reducers/user-group/types";
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
  fetchUserGroups,
  removeUserGroups,
  addUserGroups,
  putUserGroups,
  fetchUserGroupsPermissions,
} from "../../api/user-group";
import IUserGroup, { IPermission } from "../../dtos/IUserGroup";
import { push } from "connected-react-router";
import { updateGlobalModalPropsSaga } from "../global/types";

function* loadUserGroup(action: ILoadUserGroupActionSaga) {
  try {
    yield put(
      updateIsLoadingUserGroupProps({
        isLoadingUserGroup: true,
        errorMessage: undefined,
      })
    );
    const dataTableuserGroups: {
      userGroups: IUserGroup[];
      totalCountPages: number;
    } = yield call(fetchUserGroups, action.page, action.size);
    yield put(
      loadUserGroupProps({
        userGroups: dataTableuserGroups.userGroups,
        totalCountPages: dataTableuserGroups.totalCountPages,
      })
    );
    yield put(
      updateIsLoadingUserGroupProps({
        isLoadingUserGroup: false,
        errorMessage: undefined,
      })
    );
  } catch (error) {
    yield put(
      updateIsLoadingUserGroupProps({
        isLoadingUserGroup: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}

export function* watchLoadUserGroup() {
  yield takeLatest(LOAD_USER_GROUP_SAGA, loadUserGroup);
}

function* deleteUserGroup(action: IDeleteUserGroupActionSaga) {
  try {
    const userGroupsDeleted: boolean = yield call(removeUserGroups, action.id);
    yield put(deleteUserGroupProps({ userGroupId: action.id }));
  } catch (error) {
    yield put(updateGlobalModalPropsSaga({
      isOpen: true,
      isInfo: true,
      close: (dispatch) => {
        dispatch(updateGlobalModalPropsSaga({ isOpen: false }))
      },
      title: 'Unsuccessful action, reason:',
      body: error.response.data.exceptionResponse.reason,
    }));
  }
}

export function* watchDeleteUserGroup() {
  yield takeLatest(DELETE_USER_GROUP_SAGA, deleteUserGroup);
}

function* createUpdateUserGroup(action: ICreateUpdateUserGroupActionSaga) {
  if (action.userGroupData.id) {
    const userGroup: IUserGroup = yield call(
      putUserGroups,
      action.userGroupData.id,
      action.userGroupData
    );
  } else {
    const userGroup: IUserGroup = yield call(
      addUserGroups,
      action.userGroupData
    );
  }
  yield put(
    push({
      pathname: "/user-group",
    })
  );
}

export function* watchCreateUpdateUserGroup() {
  yield takeLatest(CREATE_UPDATE_USER_GROUP_SAGA, createUpdateUserGroup);
}

function* loadUserGroupPermissions(action: ILoadUserGroupPermissionsActionSaga) {
    const userGroupPermissions: IPermission[] = yield call(fetchUserGroupsPermissions,action.component);
    yield put(loadUserGroupPermissionsProps({ userGroupPermissions: userGroupPermissions }));
}

export function* watchLoadUserGroupPermissions() {
  yield takeLatest(LOAD_USER_GROUP_PERMISSIONS_SAGA, loadUserGroupPermissions);
}

export default [
  watchLoadUserGroup(),
  watchDeleteUserGroup(),
  watchCreateUpdateUserGroup(),
  watchLoadUserGroupPermissions()
]