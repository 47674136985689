import React, { useEffect, useState, useMemo } from 'react';
import './styles/styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import { loadBranchPropsSaga, updateBranchPropsSaga, loadCountriesPropsSaga, loadCitiesPropsSaga } from "../../sagas/branch/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBBtn, MDBCardText, MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import IUser from "../../dtos/IUser"
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';

interface IBranchDetailsProps {
    branch: any,
}

export default function BranchDetails(props: IBranchDetailsProps) {
    // storages short table
    const storagesHeaders: any = [
        { name: "Storage Name", width: 3 },
        { name: "Type", width: 2 },
        // { name: "Date", width: 3 },
    ].map((header) => {
        return { ...header };
    });
    const [storagesHeaderColumns] = useState<IHeaderColumn[]>(storagesHeaders);
    const buildStoragesRows = (storages: any[]): IRow[] => {
        return storages.slice(0, 3).map((s: any, i) => {
            return {
                value: s,
                props: ['name', 'type']
            }
        });
    };
    const storagesRows = useMemo(() => buildStoragesRows(props.branch.storages), [props.branch.storages]);
    // users short table
    const usersHeaders: any = [
        { name: "User Name", width: 2 },
        { name: "Email", width: 3 },
        { name: "Number", width: 2 },
    ].map((header) => {
        return { ...header };
    });
    const [usersHeaderColumns] = useState<IHeaderColumn[]>(usersHeaders);
    const buildUsersRows = (users: IUser[]): IRow[] => {
        return users.slice(0, 3).map((s: any, i) => {
            return {
                value: s,
                props: [
                    {
                        edit: (rowValue: IUser) => {
                            return (<MDBRow className="pl-3">{rowValue.first_name ? `${rowValue.first_name} ${rowValue.last_name}` : ''}</MDBRow >);
                        }
                    }
                    , 'email', 'mobile']
            }
        });
    };
    const usersRows = useMemo(() => buildUsersRows(props.branch.users), [props.branch.users]);
    return (
        <>
            <MDBRow className="">
                <MDBCol className="mr-2">
                    <label className="font-weight-bold pl-2 pt-3">Name</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.name}
                        disabled
                    />
                </MDBCol>
                <MDBCol className="">
                    <label className="font-weight-bold pl-2 pt-3">Phone</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.phone}
                        disabled
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow className="">
                <MDBCol className="w-50 mr-2">
                    <label className="font-weight-bold pl-2 pt-3">Bulding Number</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.address_id?.building_number}
                        disabled
                    />
                </MDBCol>
                <MDBCol className="w-50">
                    <label className="font-weight-bold pl-2 pt-3">Floor</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.address_id?.floor}
                        disabled
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow className="">
                <MDBCol className="w-50 mr-2">
                    <label className="font-weight-bold pl-2 pt-3">Street</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.address_id?.street}
                        disabled
                    />
                </MDBCol>
                <MDBCol className="w-50">
                    <label className="font-weight-bold pl-2 pt-3">City</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.address_id?.city}
                        disabled
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow className="">
                <MDBCol className="w-50 mr-2">
                    <label className="font-weight-bold pl-2 pt-3">Country</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.address_id?.country}
                        disabled
                    />
                </MDBCol>
                <MDBCol className="w-50">
                    <label className="font-weight-bold pl-2 pt-3">State</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={props.branch.address_id?.state}
                        disabled
                    />
                </MDBCol>
            </MDBRow>

            {/* storages */}
            <MDBRow start className="mt-5 justify-content-center">
                <MDBCol size="12" className="text-left transaction-table-title">
                    <h4>Branch Storages</h4>
                </MDBCol>
                <MDBCol size="12" className="text-center transaction-table">
                    <DataTable
                        headerColumns={storagesHeaderColumns}
                        rows={storagesRows}
                    />
                </MDBCol>
                <MDBCol size="6" md="4" className="text-center">
                    <div className="form-control styled-input">
                        <a href={`/branches/${props.branch.id}/storages`}
                            target="_blank" className="view-all-link">
                            view all</a>
                    </div>
                </MDBCol>
            </MDBRow>
            {/* users */}
            <MDBRow start className="mt-5 justify-content-center">
                <MDBCol size="12" className="text-left transaction-table-title">
                    <h4>Branch Users</h4>
                </MDBCol>
                <MDBCol size="12" className="text-center transaction-table">
                    <DataTable
                        headerColumns={usersHeaderColumns}
                        rows={usersRows}
                    />
                </MDBCol>
                <MDBCol size="6" md="4" className="text-center">
                    <div className="form-control styled-input">
                        <a href={`/branches/${props.branch.id}/users`}
                            target="_blank" className="view-all-link">
                            view all</a>
                    </div>
                </MDBCol>
            </MDBRow>
        </>
    )
}
