import { LOAD_OPERATORS, LOAD_OPERATOR_STATUS, LOAD_OPERATOR, ADD_OPERATOR, UPDATE_OPERATOR } from "../../sagas/operators/types";
import { OperatorsActions, IUpdateIsLoadingOperatorsAction } from "./types"
import IOperator from "../../dtos/IOperator";
import IStatus from "../../dtos/IStatus";

interface IOperatorsState {
    operators: IOperator[],
    totalOperatorsCount: number,
    operator: IOperator,
    operatorStatus: IStatus[],
    operatorAdded: boolean,
    operatorUpdated: boolean,
    loadingOperators: IUpdateIsLoadingOperatorsAction['payload']
}
const OperatorsState: IOperatorsState = {
    operators: [],
    totalOperatorsCount: 0,
    operator: {
        id: 0,
        name: "",
        title: "",
        status: {
            id: 0,
            name: ""
        },
        is_dismissed: false
    },
    operatorStatus: [],
    operatorAdded: false,
    operatorUpdated: false,
    loadingOperators: {
        isLoadingOperators: false,
        errorMessage: undefined
    }
}
const operatorsReducer = (state = OperatorsState, action: OperatorsActions): IOperatorsState => {
    switch (action.type) {
        case LOAD_OPERATORS:
            return {
                ...state,
                operators: action.payload.operators,
                totalOperatorsCount: action.payload.totalOperatorsCount
            }
        case LOAD_OPERATOR:
            return {
                ...state,
                operator: action.payload.operator
            }
        case LOAD_OPERATOR_STATUS:
            return {
                ...state,
                operatorStatus: action.payload.operatorStatus
            }
        case ADD_OPERATOR:
            return {
                ...state,
                operatorAdded: action.payload.operatorAdded
            }
        case UPDATE_OPERATOR:
            return {
                ...state,
                operatorUpdated: action.payload.operatorUpdated
            }
        default:
            return state;
    }
}
export default operatorsReducer
