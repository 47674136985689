import globalReducer from './global/globalReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import sidebarReducer from './sidebar/sidebarReducer';
import headerReducer from './header/headerReducer';
import inventoryReducer from './inventory/inventoryReducer';
import userGroupReducer from './user-group/userGroupReducer';
import branchReducer from './branch/branchReducer';
import userReducer from "./user/userReducer";
import assetReducer from "./asset/assetReducer";
import productsReducer from "./products/productsReducer";
import notificationsReducer from "./notifications/notificationsReducer";
import categoriesReducer from "./categories/categoriesReducer";
import manualStorageReducer from "./manual-storage/manualStorageReducer";
import productTransactionsReducer from "./product_transactions/productTransactionsReducer"
import productStoragesReducer from "./product_storages/productStoragesReducer"
import vendorsReducer from "./vendors/vendorsReducer"
import unitsReducer from "./units/unitsReducer"
import operatorsReducer from "./operators/operatorsReducer"
import storageReducer from "./storages/storageReducer"
import myAccountReducer from "./my_account/myaccountReducer"
import smoPreviewReducer from "./smart_ordering_preview/smoPreviewReducer"
import smartOrderReducer from "./smart-ordering/smartOrderingReducer"
import ordersReducer from "./orders/ordersReducer";
import cartReducer from "./smart-ordering/smartOrderingReducer"
import QuotationReducer from "./quotation"
const reducers = {
    globalReducer,
    dashboardReducer,
    sidebarReducer,
    inventoryReducer,
    headerReducer,
    userGroupReducer,
    branchReducer,
    userReducer,
    assetReducer,
    productsReducer,
    notificationsReducer,
    categoriesReducer,
    manualStorageReducer,
    productTransactionsReducer,
    productStoragesReducer,
    vendorsReducer,
    unitsReducer,
    operatorsReducer,
    storageReducer,
    myAccountReducer,
    smoPreviewReducer,
    smartOrderReducer,
    ordersReducer,
    cartReducer,
    QuotationReducer,
}

export default reducers;