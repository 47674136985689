import { IUpdateIsLoadingBranchesAction, BranchActions } from './types';
import { LOAD_BRANCHES, LOAD_BRANCH, ADD_NEW_BRANCH, UPDATE_BRANCH, DELETE_BRANCH, LOAD_COUNTRIES, LOAD_CITIES, UPDATE_IS_LOADING_BRANCHES } from "../../sagas/branch/types"
import IBranch from "../../dtos/IBranch";
import ICountry from "../../dtos/ICountry";
import ICity from "../../dtos/ICity";
interface IBranchState {
    branches: IBranch[],
    totalBranchesCount: number,
    branch: IBranch,
    branchAdded: boolean,
    branchUpdated: boolean,
    branchDeleted: number,
    countries: ICountry[],
    cities: ICity[],
    loadingBranches: IUpdateIsLoadingBranchesAction['payload']
}
const BranchState: IBranchState = {
    branches: [],
    totalBranchesCount: 0,
    branch: {
        id: 0,
        href: null,
        name: '',
        service_account_id: 0,
        address_id: null,
        is_active: true,
        longitude: null,
        latitude: null,
        phone: '',
        selected: false,
        users: [],
        storages: []
    },
    branchAdded: false,
    branchUpdated: false,
    branchDeleted: 0,
    countries: [],
    cities: [],
    loadingBranches: {
        isLoadingBranches: false,
        errorMessage: undefined
    }
}
const branchReducer = (state = BranchState, action: BranchActions): IBranchState => {
    switch (action.type) {
        case LOAD_BRANCHES:
            return {
                ...state,
                branches: action.payload.branches.filter(b => b.is_active == true),
                totalBranchesCount: action.payload.totalBranchesCount
            }
        // case UPDATE_IS_LOADING_BRANCHES:
        //     return {
        //         ...state,
        //         loadingBranches: action.payload
        //     }
        case LOAD_BRANCH:
            return {
                ...state,
                branch: {
                    id: action.payload.branch.id,
                    href: action.payload.branch.href,
                    name: action.payload.branch.name,
                    service_account_id: action.payload.branch.service_account_id,
                    address_id: action.payload.branch.address_id,
                    is_active: action.payload.branch.is_active,
                    longitude: action.payload.branch.longitude,
                    latitude: action.payload.branch.latitude,
                    phone: action.payload.branch.phone,
                    selected: false,
                    users: action.payload.branch.users ? action.payload.branch.users : [],
                    storages: action.payload.branch.storages ? action.payload.branch.storages : [],
                }
            }
        case ADD_NEW_BRANCH:
            return {
                ...state,
                branchAdded: action.payload.branchAdded
            }
        case UPDATE_BRANCH:
            return {
                ...state,
                branchUpdated: action.payload.branchUpdated
            }
        case DELETE_BRANCH:
            return {
                ...state,
                branchDeleted: action.payload.branchDeleted,
                branches: [
                    ...state.branches.filter(b => b.id != action.payload.branchDeleted)
                ]
            }
        case LOAD_COUNTRIES:
            return {
                ...state,
                countries: action.payload.countries
            }
        case LOAD_CITIES:
            return {
                ...state,
                cities: action.payload.cities
            }
        default:
            return state;
    }
}
export default branchReducer;

