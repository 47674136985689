import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { push } from 'connected-react-router';
import { MDBCol, MDBRow } from 'mdbreact';
import Loader from "../../components/loader";
import { loadOrderProductsSaga, updateCartItemSaga, deleteCartItemSaga } from "../../sagas/smart-ordering/types";
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import Input from '../../components/input';
import CartSummary from './CartSummary';
import IProduct from '../../dtos/IProduct';
import deleteIcon from '../../assets/img/svg/delete.svg';
import { BeatLoader } from 'react-spinners';
import QuotationModal from './quotationModal';
import { Image } from 'antd';
import { getCartProductById, getCartSummary } from '../../api/cart';
import { displayErrorMessageProps } from '../../reducers/global/types';
import CheckoutModal from './checkoutModal';
import { Checkbox } from 'antd';

const Checklist = Checkbox.Group;

interface ICartProps { }

export default function Cart(props: ICartProps) {
    const dispatch = useDispatch();
    const globalReducer = useSelector((state: AppState) => state.globalReducer);
    const routerReducer = useSelector((state: AppState) => state.router);
    const cartReducer = useSelector((state: AppState) => state.cartReducer);
    const { sort, page } = queryString.parse(routerReducer.location.search);
    const [view, selectView] = useState(10);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const [isCheckout, setIsCheckout] = useState<boolean>(false);
    const [quotationNewQty, setQuotationNewQty] = useState<number>(1);
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();
    const [productChangedId, setProductChangedId] = useState(0);
    const [isQuotation, setIsQuotation] = useState(false);

    const headers: any = [
        { name: "SKU", width: 1 },
        { name: "", width: 2 },
        { name: "Name", width: 4 },
        { name: "Features", width: 2 },
        { name: "Category", width: 2 },
        { name: "Price", width: 3 },
        { name: "Quantity", width: 2 },
        { name: "", width: 1 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        changePage(page ? parseInt(page.toString()) : 1);
        dispatch(loadOrderProductsSaga("cart", 25, view, sorting));

    }, [routerReducer.location.search, view]);
    const changeStep = (activeTab: string) => { dispatch(push({ search: activeTab })); };

    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return { ...headerColumn, sorted: 'ascending' }
                    case 'descending':
                        return { ...headerColumn, sorted: 'descending' }
                    default:
                        return { ...headerColumn, sorted: undefined }
                }
            } else {
                return { ...headerColumn, sorted: undefined }
            }
        });
    }

    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "" && colName != "name" && colName != "location" && colName != "assigned to") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }

                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }


    const handlePaginationChange = (e: any, { activePage }: any) => { dispatch(push({ search: addQueryString(routerReducer.location.search, 'page', activePage) })); }

    const buildRows = (cartProducts: any[]): IRow[] => {
        return cartProducts?.map((cartProduct: any, i: any) => {
            return {
                value: cartProduct,
                props: [
                    'sku',
                    {
                        edit: (rowValue: IProduct) => {
                            if (rowValue.product_images)
                                return <div className='product-image'><Image src={rowValue?.product_images[0]?.image_path} /></div>
                        }
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            return (<div className="d-flex-col">
                                <p>{rowValue.name}</p>
                                {rowValue.status == "D" ? <p className='not-available'>{`(Not Available, please remove it from cart)`}</p> : <></>}
                            </div>);
                        },
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            if (rowValue?.feature_values)
                                return (rowValue?.feature_values.length > 0 ? <p>{`( ${rowValue?.feature_values.map(({ featureVariant }: any) => featureVariant.variant_name).join(', ')} )`}</p> : <></>)

                        },
                    },
                    {
                        edit: (rowValue: IProduct) => {
                            if (rowValue?.product_categories)
                                return (rowValue?.product_categories.length > 0 ? <p>{`( ${rowValue?.product_categories.map(({ category }: any) => category.name).join(', ')} )`}</p> : <></>)

                        },
                    },
                    {
                        edit: (rowValue: any) => {
                            return rowValue.status == "D" ? <></> : <p>{rowValue.price} LE</p>;
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (
                                rowValue.status == "D" ? <></> : <div>
                                    {
                                        cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId ? <BeatLoader loading={cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId} color="#2D2ACE" /> : <Input
                                            key={rowValue.id}
                                            fieldClassName="request-field w-50"
                                            label=""
                                            type="number"
                                            required={false}
                                            placeholder=""
                                            defaultValue={1}
                                            min={1}
                                            value={rowValue.id == productChangedId ? editQuantity : rowValue.quantity}
                                            onChange={(event: any) => handleChangeQty(event, rowValue)}
                                        />
                                    }
                                </div>
                            );
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId ? <BeatLoader loading={cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId} size={3} color="#2D2ACE" /> : <img onClick={() => handleRemoveCartItem(rowValue)} src={deleteIcon} alt="delete" className='delete-icon' />);
                        }
                    }]
            }
        });
    };

    const rows = buildRows(cartReducer.cartItems);

    const handleRemoveCartItem = (product: IProduct) => {
        setProductChangedId(product.id);
        dispatch(deleteCartItemSaga(product.cart_id, product.id));
    }

    useEffect(() => {
        if (globalReducer.errorMessage == "409" && selectedProduct) {
            getCartProductById(selectedProduct.id, selectedProduct?.cart_id).then((res: IProduct) => {
                setProductChangedId(res.id);
                setSelectedProduct(res);
                setEditQuantity(res.max_quantity);
                setQuotationNewQty(editQuantity - res.max_quantity);
                dispatch(updateCartItemSaga(res.cart_id, res.id, res.max_quantity, res));
            })
            setIsQuotation(true);
            dispatch(displayErrorMessageProps({ message: "" }))
        }
    }, [globalReducer.errorMessage])

    const [timeOutId, setTimeOutId] = useState<any>(null);
    const [editQuantity, setEditQuantity] = useState<number>(1);

    const handleChangeQty = (event: any, product: any) => {
        setEditQuantity(event.target.value);
        setProductChangedId(product.id);
        setSelectedProduct(product);
        clearTimeout(timeOutId);
        const newTimeOut = setTimeout(() => {
            let newQuantity: number = event.target.value;
            dispatch(updateCartItemSaga(product.cart_id, product.id, newQuantity, product));
        }, 1000);
        setTimeOutId(newTimeOut);
    }

    const quotationModalBody = (product: IProduct | undefined) => {
        return <MDBRow className='product-item'>
            <MDBCol sm='2'><img className='product-img' src={product?.product_images[0]?.image_path} /></MDBCol>
            <MDBCol sm='3'><p className='product-name'>{product?.name}</p></MDBCol>
            <MDBCol sm='3'>{product?.feature_values.length as number > 0 ? <p className='product-name'>{`( ${product?.feature_values.map(({ featureVariant }: any) => featureVariant.variant_name).join(', ')} )`}</p> : <></>}</MDBCol>
            <MDBCol sm='4'><Input
                fieldClassName="request-field w-50"
                label=""
                type="number"
                required={false}
                placeholder=""
                min={1}
                value={quotationNewQty}
                onChange={(event: any) => setQuotationNewQty(event.target.value)}
            /></MDBCol>
        </MDBRow>
    }

    const [checkoutOptions, setCheckoutOptions] = useState<any[]>([]);

    const checkoutModalBody = () => {
        return <MDBRow>
            <Checklist onChange={(e) => setCheckoutOptions(e)} className='d-flex-col' options={[{ label: "Place Order", value: "cart" }, { label: "Ask For Quotation", value: "quotation" }]} />
        </MDBRow>
    }

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        changePage(page ? parseInt(page.toString()) : 1);
        if (cartReducer.cartItems?.length == 0 && (cartReducer.totalCountCartItems - view)) {
            dispatch(loadOrderProductsSaga("cart", pageNum, view, sorting));
            return;
        }
        return;
    }, [cartReducer.totalCountCartItems, cartReducer.cartItems?.length]);

    const [cartSummary, setCartSummary] = useState<any>();

    useEffect(() => {
        if (!cartReducer.loadingOrderProducts.isLoadingOrderProducts || !cartReducer.loadingOrderProducts.isLoadingItemQty) {
            getCartSummary('cart')
                .then((res) => setCartSummary(res))
                .catch((err) => console.log(err));
        }
    }, [cartReducer.loadingOrderProducts.isLoadingOrderProducts, cartReducer.loadingOrderProducts.isLoadingItemQty])

    return (
        <>
            {/* <MDBRow start className="m-2 mb-4">
                <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                    <Select name="view"
                        id="view-select"
                        className="mr-2 d-inline"
                        label="View By"
                        options={constants.viewOptions.map((option, i) => {
                            return { text: option.value, value: option.id }
                        })}
                        selectedValue={view}
                        onChange={(e: any) => { selectView(e.target.value); dispatch(push({ search: '' })); }}
                    />
                </MDBCol>
            </MDBRow> */}
            <MDBRow className="m-0 w-100">
                <MDBCol className="text-center">
                    <Loader isLoading={cartReducer.loadingOrderProducts.isLoadingOrderProducts} errorMessage={cartReducer.loadingOrderProducts.errorMessage}>
                        <MDBRow start className="justify-content-center mt-2">
                            <MDBCol className="text-center p-0">
                                {
                                    cartReducer.cartItems?.length > 0 ?
                                        <DataTable
                                            headerColumns={headerColumns}
                                            rows={rows}
                                            headerOnClick={handleSort}
                                            totalPages={cartReducer.totalCountCartItems / view}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                        : <div className="mt-5" >You don't have any products yet.</div>
                                }
                            </MDBCol>
                        </MDBRow>
                    </Loader>
                </MDBCol>
            </MDBRow>
            <QuotationModal quotationQty={quotationNewQty} setQuotationNewQty={setQuotationNewQty} product={selectedProduct} body={quotationModalBody(selectedProduct)} title={`The quantity you entered exceeds our maximum order limit ${selectedProduct?.max_quantity}, the remaining items will be quoted separately.`} isOpen={isQuotation} close={setIsQuotation} savebtnText='Request quotation' />
            <CheckoutModal checkoutOptions={checkoutOptions} body={checkoutModalBody()} title={`Are you sure you would like to proceed with placing an order and asking for quotation.`} isOpen={isCheckout} close={setIsCheckout} savebtnText='Proceed' />
            <CartSummary totalPrice={cartSummary} setIsCheckout={setIsCheckout} />
        </>
    )
}