import INotification from "../dtos/INotification";
import { handlStatusCodeMessage } from "../helpers";
import { axiosInstance } from "../store";

export function fetchNotifications(is_dismissd = false, page = 0, size = 10): Promise<{ notifications: INotification[], totalNotificationsCount: number }> {
    const user_id = localStorage.getItem("user_id");
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/notification?user_id=${user_id}&is_dismissd=${is_dismissd}&page=${page}&size=${size}`)
        .then((res) => {
            if (res.status == 200) return {
                notifications: res.data,
                totalNotificationsCount: res.headers['x-total-count']
            }
            else {
                return {
                    notifications: [], totalNotificationsCount: 0
                }
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'notification' });
            return {
                notifications: [], totalNotificationsCount: 0
            }
        })
}
export function dismissNotification(uuid = ''): Promise<boolean> {
    const user_id = localStorage.getItem("user_id");
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/notification/${uuid}/dismiss?user_id=${user_id}`)
        .then((res => {
            return (res.status == 200);
        }))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'notification' });
            return false
        })
}

export function undismissNotification(uuid = ''): Promise<boolean> {
    const user_id = localStorage.getItem("user_id");
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/notification/${uuid}/undismiss?user_id=${user_id}`)
        .then((res => {
            return (res.status == 200)
        }))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'notification' });
            return false
        })
}