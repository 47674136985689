import React from "react";
import { MDBRow } from 'mdbreact';
import DismissedNotificationsList from "../../containers/dismissedNotificationsList/index"
interface IDismissedNotificationsListPageProps {
}

export default function DismissedNotificationsListPage(props: IDismissedNotificationsListPageProps) {
    return (
        <>
            <MDBRow className="m-0 bg-white">
                <MDBRow className="ml-2 mt-0 mb-0 w-100">
                </MDBRow>
                <DismissedNotificationsList />
            </MDBRow>
        </>
    )
}