import { call, put, takeLatest } from 'redux-saga/effects'
import {
    LOAD_SMO_PREVIEW_SAGA, ILoadSMOPreviewActionSaga
} from "./types";
import { fetchOrdersPreview } from '../../api/smart-ordering'
import { loadSMOPreviewProps, updateIsLoadingSMOPreviewProps } from "../../reducers/smart_ordering_preview/types";
import { displayErrorMessageProps } from "../../reducers/global/types";

function* loadSMOPreview(action: ILoadSMOPreviewActionSaga) {
    try {
        yield put(updateIsLoadingSMOPreviewProps({ isLoadingSMOPreview: true, errorMessage: undefined }));
        const openedOrders = yield call(fetchOrdersPreview);
        yield put(loadSMOPreviewProps({ orders: openedOrders, totalCountPages: 0 }))
        yield put(updateIsLoadingSMOPreviewProps({ isLoadingSMOPreview: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingSMOPreviewProps({ isLoadingSMOPreview: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadSMOPreview() {
    yield takeLatest(LOAD_SMO_PREVIEW_SAGA, loadSMOPreview)
}

export default [
    watchLoadSMOPreview(),
]