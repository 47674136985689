import ICategory from '../../dtos/ICategory';
import {LOAD_CATEGORIES, UPDATE_IS_LOADING_CATEGORIES} from '../../sagas/categories/types';

export interface ILoadCategoriesAction {
    readonly type: typeof LOAD_CATEGORIES;
    payload: {
        categories: ICategory[],
        totalCountPages: number
    }
}

export const loadCategoriesProps = (payload: ILoadCategoriesAction['payload']): ILoadCategoriesAction => {
    return {
        type: LOAD_CATEGORIES,
        payload: payload
    }
};

export interface IUpdateIsLoadingCategoriesAction {
    readonly type: typeof UPDATE_IS_LOADING_CATEGORIES;
    payload:{
        isLoadingCategories: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingCategoriesProps = (payload: IUpdateIsLoadingCategoriesAction['payload']): IUpdateIsLoadingCategoriesAction => {
    return {
        type: UPDATE_IS_LOADING_CATEGORIES,
        payload: payload
    }
};

export type CategoriesActions = ILoadCategoriesAction | IUpdateIsLoadingCategoriesAction;