import { IUpdateIsLoadingAssetsAction, AssetActions } from './types';
import { LOAD_ASSETS, LOAD_ASSET, LOAD_ASSET_USAGE, UPDATE_ASSET, LOAD_ASSETS_STATUS, UPDATE_IS_LOADING_ASSETS } from "../../sagas/asset/types"
import IAsset from "../../dtos/IAsset"
import IAssetTransaction from "../../dtos/IAssetTransaction"

interface IAssetState {
    assets: IAsset[],
    dismissedAssets: IAsset[],
    totalAssetsCount: number,
    asset: IAsset,
    assetTransactions: IAssetTransaction[],
    assetTransactionsCount: number,
    assetUpdated: number,
    assetsStatus: any,
    loadingAssets: IUpdateIsLoadingAssetsAction['payload']
}
const AssetState: IAssetState = {
    assets: [],
    dismissedAssets: [],
    totalAssetsCount: 0,
    asset: {
        id: 0,
        name: '',
        alias: '',
        category: '',
        branch: null,
        is_dismissed: true,
        location: '',
        storage: null,
        status: null,
        assigned_to: null,
        last_used_at: '',
        last_used_by: null,
        late_threshold: '',
        lost_threshold: '',
        selected: false
    },
    assetTransactions: [],
    assetTransactionsCount: 0,
    assetUpdated: 0,
    assetsStatus: [],
    loadingAssets: {
        isLoadingAssets: false,
        errorMessage: undefined
    }
}
const assetReducer = (state = AssetState, action: AssetActions): IAssetState => {
    switch (action.type) {
        case LOAD_ASSETS:
            return {
                ...state,
                assets: action.payload.assets.map((a: any) => { return { ...a, selected: false } }),
                dismissedAssets: action.payload.dismissedAssets.map((a: any) => { return { ...a, selected: false } }),
                totalAssetsCount: action.payload.totalAssetsCount
            }
        // case UPDATE_IS_LOADING_ASSETS:
        //     return {
        //         ...state,
        //         loadingAssets: action.payload
        //     }
        case LOAD_ASSET: {
            return {
                ...state,
                asset: {
                    id: action.payload.asset.id,
                    name: action.payload.asset.name,
                    alias: action.payload.asset.alias,
                    is_dismissed: action.payload.asset.is_dismissed,
                    category: action.payload.asset.category,
                    branch: action.payload.asset.branch,
                    location: action.payload.asset.location,
                    storage: action.payload.asset.storage,
                    status: action.payload.asset.status,
                    assigned_to: action.payload.asset.assigned_to,
                    last_used_at: action.payload.asset.last_used_at,
                    last_used_by: action.payload.asset.last_used_by,
                    late_threshold: action.payload.asset.late_threshold,
                    lost_threshold: action.payload.asset.lost_threshold,
                    selected: false
                }
            }
        }
        case UPDATE_ASSET:
            return {
                ...state,
                assetUpdated: action.payload.assetUpdated
            }
        case LOAD_ASSET_USAGE:
            return {
                ...state,
                assetTransactions: action.payload.assetTransactions,
                assetTransactionsCount: action.payload.assetTransactionsCount
            }
        case LOAD_ASSETS_STATUS:
            return {
                ...state,
                assetsStatus: action.payload.assetsStatus
            }
        default:
            return state;
    }
}
export default assetReducer
