import {IUpdateIsLoadingProductStoragesAction, ProductStoragesActions} from './types'
import {LOAD_PRODUCT_STORAGES_FILTERS, LOAD_PRODUCT_STORAGES, UPDATE_IS_LOADING_PRODUCT_STORAGES} from "../../sagas/product_storages/types";
import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';
import _ from 'lodash';

interface IProductStoragesState {
    filters: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending',
    },
    productStorages: any[],
    totalCountPages: number,
    loadingProductStorages: IUpdateIsLoadingProductStoragesAction['payload'],
}

const ProductStoragesState: IProductStoragesState = {
    filters: {
        sortCol: '',
        sortOrder: 'ascending',
    },
    productStorages: [],
    totalCountPages: 0,
    loadingProductStorages: {
        isLoadingProductStorages: false,
        errorMessage: undefined
    }
};

const productStoragesReducer = (state = ProductStoragesState, action: ProductStoragesActions): IProductStoragesState => {
    switch (action.type) {
        case LOAD_PRODUCT_STORAGES_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        case LOAD_PRODUCT_STORAGES:
            return {
                ...state,
                productStorages: action.payload.productStorages,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_PRODUCT_STORAGES:
            return {
                ...state,
                loadingProductStorages: action.payload,
            };
        default:
            return state;
    }
};

export default productStoragesReducer;
