import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';
import {
    LOAD_PRODUCT_TRANSACTIONS_FILTERS, LOAD_PRODUCT_TRANSACTIONS,
    UPDATE_IS_LOADING_PRODUCT_TRANSACTIONS,
} from '../../sagas/product_transactions/types';

export interface ILoadProductTransactionsFiltersAction {
    readonly type: typeof LOAD_PRODUCT_TRANSACTIONS_FILTERS;
    payload: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending',
        storageList: IDropdown[],
        operationList: IDropdown[],
    }
}

export const loadProductTransactionsFiltersProps = (payload: ILoadProductTransactionsFiltersAction['payload']): ILoadProductTransactionsFiltersAction => {
    return {
        type: LOAD_PRODUCT_TRANSACTIONS_FILTERS,
        payload: payload
    }
};

export interface ILoadProductTransactionAction {
    readonly type: typeof LOAD_PRODUCT_TRANSACTIONS;
    payload: {
        productTransActions: any[],
        totalCountPages: number
    }
}

export const loadProductTransactionsProps = (payload: ILoadProductTransactionAction['payload']): ILoadProductTransactionAction => {
    return {
        type: LOAD_PRODUCT_TRANSACTIONS,
        payload: payload
    }
};

export interface IUpdateIsLoadingProductTransactionsAction {
    readonly type: typeof UPDATE_IS_LOADING_PRODUCT_TRANSACTIONS;
    payload:{
        isLoadingProductTransactions: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingProductTransactionsProps = (payload: IUpdateIsLoadingProductTransactionsAction['payload']): IUpdateIsLoadingProductTransactionsAction => {
    return {
        type: UPDATE_IS_LOADING_PRODUCT_TRANSACTIONS,
        payload: payload
    }
};

export type ProductTransactionsActions = ILoadProductTransactionsFiltersAction | ILoadProductTransactionAction |
 IUpdateIsLoadingProductTransactionsAction;