export const LOAD_INVENTORY_SAGA = "LOAD_INVENTORY_SAGA";
export const LOAD_INVENTORY_PRODUCTS = "LOAD_INVENTORY_PRODUCTS";
export const LOAD_INVENTORY_FILTERS = "LOAD_INVENTORY_FILTERS";
export const UPDATE_IS_LOADING_INVENTORY = "UPDATE_IS_LOADING_INVENTORY";
export interface ILoadInventoryActionSaga {
  readonly type: typeof LOAD_INVENTORY_SAGA;
}

export const loadInventoryPropsSaga = (): ILoadInventoryActionSaga => {
  return {
    type: LOAD_INVENTORY_SAGA,
  };
};

export const FILTER_INVENTORY_PRODUCTS_SAGA = "FILTER_INVENTORY_PRODUCTS_SAGA";
export interface IFilterInventoryProductsActionSaga {
  readonly type: typeof FILTER_INVENTORY_PRODUCTS_SAGA;
  filters: {
    page: number;
    size:number;
    sort: string;
    name: string;
    storage: string;
    status: string;
    category: string;
  };
}

export const filterInventoryProductsPropsSaga = (filters: IFilterInventoryProductsActionSaga['filters']): IFilterInventoryProductsActionSaga => {
  return {
    type: FILTER_INVENTORY_PRODUCTS_SAGA,
    filters: {
      page: filters.page,
      size:filters.size,
      sort: filters.sort,
      name: filters.name,
      storage: filters.storage,
      status: filters.status,
      category: filters.category,
    },
  };
};
