import IProduct from "../../dtos/IProduct";
import {
    LOAD_ORDER_PRODUCTS, UPDATE_ORDER, ADD_ORDER_PRODUCT,
    LOAD_ORDERS_HISTORY, LOAD_ORDER_DETAILS,
    LOAD_FULFILLMENTS_COUNT,
    UPDATE_IS_LOADING_ORDER_PRODUCTS,
    DELETE_CART_ITEM
} from "../../sagas/smart-ordering/types";
import {
    SmartOrderingActions,
    IUpdateIsLoadingOrderProductsAction, IUpdateIsLoadingOrdersHistoryAction
} from "./types";
interface ISmartOrderingState {
    cartItems: any[],
    totalCountCartItems: number,

    orderUpdated: boolean,
    productAdded: boolean

    ordersHistory: any[],
    totalCountOrders: number,

    orderDetails: any,

    fulfillmentsCount: any,

    loadingOrderProducts: IUpdateIsLoadingOrderProductsAction['payload'],
    loadingOrdersHistory: IUpdateIsLoadingOrdersHistoryAction['payload'],
}
const SmartOrderingState: ISmartOrderingState = {
    cartItems: [],
    totalCountCartItems: 0,

    orderUpdated: false,
    productAdded: false,

    ordersHistory: [],
    totalCountOrders: 0,

    orderDetails: null,
    fulfillmentsCount: {
        used: 0,
        limit: 0
    },

    loadingOrderProducts: {
        isLoadingOrderProducts: false,
        isLoadingItemQty: false,
        errorMessage: undefined
    },
    loadingOrdersHistory: {
        isLoadingOrdersHistory: false,
        errorMessage: undefined
    }

}
const smartOrderingReducer = (state = SmartOrderingState, action: SmartOrderingActions): ISmartOrderingState => {
    switch (action.type) {
        case LOAD_ORDER_PRODUCTS:
            return {
                ...state,
                cartItems: action.payload.cartData,
                totalCountCartItems: action.payload.totalCountCartItems
            }
        case UPDATE_ORDER:
            return {
                ...state,
                cartItems: state.cartItems.map((product, idx) => {
                    console.log("vrevebe", action.payload.product.id, product.id)
                    if (action.payload.product.id == product.id) {
                        return { ...product, quantity: action.payload.quantity }
                    } else {
                        return product;
                    }
                })
            }
        case DELETE_CART_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter((product: IProduct) => {
                    return product.id !== action.payload.product_id
                })
            }
        case ADD_ORDER_PRODUCT:
            return {
                ...state,
                productAdded: action.payload.productAdded
            }
        case LOAD_ORDERS_HISTORY:
            return {
                ...state,
                ordersHistory: action.payload.ordersHistory,
                totalCountOrders: action.payload.totalCountOrders
            }
        case LOAD_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: action.payload.orderDetails
            }
        case LOAD_FULFILLMENTS_COUNT:
            return {
                ...state,
                fulfillmentsCount: action.payload.fulfillmentsCount
            }

        case UPDATE_IS_LOADING_ORDER_PRODUCTS:
            return {
                ...state,
                loadingOrderProducts: action.payload
            }
        default:
            return state;
    }
}
export default smartOrderingReducer