import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import Loader from "../../components/loader";
import { loadBranchPropsSaga } from "../../sagas/branch/types";
import { AppState } from "../../store";
import { MDBCol, MDBRow, MDBBtn, MDBCardText, MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import queryString from 'query-string';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import { addQueryString } from '../../helpers';
import IUser from "../../dtos/IUser"

interface IBranchUsersProps { }

export default function BranchUsers(props: IBranchUsersProps) {
    const dispatch = useDispatch();
    const params: any = useParams();
    const routerReducer = useSelector((state: AppState) => state.router)
    const branchReducer = useSelector((state: AppState) => state.branchReducer)
    useEffect(() => {
        dispatch(loadBranchPropsSaga(params.branchId))

    }, []);
    const headers: any = [
        { name: "User Name", width: 2 },
        { name: "Email", width: 3 },
        { name: "Number", width: 2 },
    ].map((header) => {
        return { ...header };
    });
    const buildRows = (users: IUser[]): IRow[] => {
        return users.map((s: IUser, i) => {
            return {
                value: s,
                props: [
                    {
                        edit: (rowValue: IUser) => {
                            return (<MDBRow className="pl-3">{rowValue.first_name ? `${rowValue.first_name} ${rowValue.last_name}` : ''}</MDBRow >);
                        }
                    }
                    , 'email', 'mobile']
            }
        });
    };
    const rows = useMemo(() => buildRows(branchReducer.branch.users), [(branchReducer.branch.users)]);
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    return (
        <MDBRow className="m-0">
            <Loader isLoading={branchReducer.loadingBranches.isLoadingBranches} errorMessage={branchReducer.loadingBranches.errorMessage}>
                <MDBCol className="text-center">
                    {
                        branchReducer.branch.users?.length > 0 ?
                            <div className="mt-3 celled-table">
                                <MDBRow start className="justify-content-center">
                                    <MDBCol className="text-center">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            rows={rows}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </div> : <div>There are no users assigned to this branch.</div>
                    }
                </MDBCol>
            </Loader>
        </MDBRow>
    )
}
