export default interface IUser {
    user_id: number;
    ecommerceId: number;
    first_name: string,
    last_name: string;
    email: string;
    mobile: string;
    subject_id: string;
    creationDate: string;
    status: string;
    access_card: {
        "id": number,
        "tag_number": string,
        "last_changed_by": {
            "user_id": number,
            "first_name": string,
            "last_name": string,
            "email": string,
            "mobile": string
        },
        "change_reason": {
            "id": number,
            "name": string
        },
        "status": {
            "id": number,
            "name": string
        },
        "requested_date": string,
        "last_changed_at": string,
        "activation_date": string
    }
}

export enum ACCESSCARDSTATUS {
    Active = 'Active',
    Disabled = 'Disabled'
}