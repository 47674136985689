import React, { useState } from "react";
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { MDBRow, MDBCol } from 'mdbreact';
import TabsButtons from "../../components/tabsButtons"
import Cart from ".";
import Quotations from "./Quotations";
import CartSummary from "./CartSummary";
import Select from "../../components/select";
import constants from "../../constants"
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const CartContainer = () => {
  let { path } = useRouteMatch();
  const [view, selectView] = useState(10);
  const dispatch = useDispatch();


  const tabs = [
    { title: 'Cart', path: '/cart' },
    { title: 'Quotation', path: '/cart/quotation' }
  ];

  return <>
    <MDBRow className="m-0">
      <MDBCol size={`12`} md={`6`} className={`d-flex`}>
        <TabsButtons tabs={tabs} />
      </MDBCol>
    </MDBRow>
    <Switch>
      <Route path={`${path}/quotation`} component={Quotations} />
      <Route exact path={path} component={Cart} />
    </Switch>
  </>
}

export default CartContainer;