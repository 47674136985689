export const LOGOUT_SAGA = 'LOGOUT_SAGA';
export const LOGOUT = 'LOGOUT';
export interface ILogoutActionSaga {
    readonly type: typeof LOGOUT_SAGA;
}

export const logoutPropsSaga = (): ILogoutActionSaga => {
    return {
        type: LOGOUT_SAGA,
    }
};