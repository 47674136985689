import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import * as Yup from "yup";
import FormModal from "../../components/form"
import IUnit from "../../dtos/IUnit";
import { loadUnitStatusPropsSaga } from "../../sagas/units/types";


interface IUnitsFormProps {
    onSubmit: any,
    unit?: IUnit
}

export default function UnitsForm(props: IUnitsFormProps) {
    const dispatch = useDispatch();
    const unitsReducer = useSelector((state: AppState) => state.unitsReducer)
    useEffect(() => {
        dispatch(loadUnitStatusPropsSaga())
    }, [unitsReducer.unit])
    console.log(unitsReducer.unit)
    const unit: any = {
        id: unitsReducer.unit && unitsReducer.unit.id ? unitsReducer.unit.id : '',
        name: unitsReducer.unit && unitsReducer.unit.name ? unitsReducer.unit.name : '',
        status: unitsReducer.unit && unitsReducer.unit.status.name != "" ? JSON.stringify(unitsReducer.unit.status) : "",
        is_active: true,
        branch_id: localStorage.getItem("branch_id")
    }
    const fields: any = [
        {
            display: 'col',
            type: 'text',
            label: 'Name',
            required: true,
            placeholder: "Unit Name",
            name: "FormValues.unit.name",
            object_name: "unit",
            field_name: "name"
        },
        {
            display: 'col',
            type: 'select',
            label: 'Status',
            required: true,
            containerStyle: "select-container",
            options: unitsReducer.unitStatus.map((option, i) => {
                return { text: option.name, value: JSON.stringify(option) }
            }),
            name: "FormValues.unit.status",
            object_name: "unit",
            field_name: "status"

        }
    ]
    const onSubmit = (values: any) => {
        values.unit.status = JSON.parse(values.unit.status)
        values.unit.branch_id = parseInt(values.unit.branch_id)
        props.onSubmit(values.unit);
    }
    const validationSchema: object = Yup.object({
        FormValues: Yup.object({
            unit: Yup.object({
                name: Yup.string().required("Required!"),
                status: Yup.string().required("Required!"),
            })
        })
    })
    return (
        <FormModal
            id="unit-form"
            initialValues={{ unit }}
            validationSchema={validationSchema}
            fields={fields}
            onSubmit={onSubmit}
        />
    )
}
