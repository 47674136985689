import IProduct from "../../dtos/IProduct";

export const LOAD_PRODUCTS_SAGA = "LOAD_PRODUCTS_SAGA";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PRODUCTS_FILTERS = "LOAD_PRODUCTS_FILTERS";
export const UPDATE_IS_LOADING_PRODUCTS = "UPDATE_IS_LOADING_PRODUCTS";
export interface ILoadProductsActionSaga {
  readonly type: typeof LOAD_PRODUCTS_SAGA;
  filters: {
    page?: number;
    size?: number;
    sort?: string;
    name?: string;
    categoryId?: string;
  }
}

export const loadProductsPropsSaga = (filters: ILoadProductsActionSaga['filters']): ILoadProductsActionSaga => {
  return {
    type: LOAD_PRODUCTS_SAGA,
    filters: {
      page: filters.page,
      size: filters.size,
      sort: filters.sort,
      name: filters.name,
      categoryId: filters.categoryId,
    }
  };
};

export const FILTER_PRODUCTS_SAGA = "FILTER_PRODUCTS_SAGA";
export interface IFilterProductsActionSaga {
  readonly type: typeof FILTER_PRODUCTS_SAGA;
  filters: {
    page?: number;
    size?: number;
    sort?: string;
    name?: string;
    categoryId?: string;
  };
}

export const filterProductsPropsSaga = (filters: IFilterProductsActionSaga['filters']): IFilterProductsActionSaga => {
  return {
    type: FILTER_PRODUCTS_SAGA,
    filters: {
      page: filters.page,
      size: filters.size,
      sort: filters.sort,
      name: filters.name,
      categoryId: filters.categoryId,
    },
  };
};

export const DELETE_PRODUCT_SAGA = "DELETE_PRODUCT_SAGA";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export interface IDeleteProductsActionSaga {
  readonly type: typeof DELETE_PRODUCT_SAGA;
  productId: IProduct['id']
}

export const deleteProductPropsSaga = (productId: IProduct['id']): IDeleteProductsActionSaga => {
  return {
    type: DELETE_PRODUCT_SAGA,
    productId
  };
};

export const UPDATE_PRODUCT_SAGA = "UPDATE_PRODUCT_SAGA";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export interface IUpdateProductsActionSaga {
  readonly type: typeof UPDATE_PRODUCT_SAGA;
  productId: string;
  cart_type: string
}

export const updateProductPropsSaga = (productId: string, cart_type: string): IUpdateProductsActionSaga => {
  return {
    type: UPDATE_PRODUCT_SAGA,
    productId: productId,
    cart_type: cart_type
  };
};