import React from "react";
import './index.scss'
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import Products from "../../containers/products";
import productsIcon from "../../assets/img/pages-icons/products-icon.png";

interface IProductsPageProps {
}

export default function ProductsPage(props: IProductsPageProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol size={`12`} md={`6`}>
                    <img className="page-image d-inline-block" src={productsIcon} />
                    <MDBCardText className="page-title d-inline-block">Catalog</MDBCardText>
                    <span className="page-line d-inline-block" />
                </MDBCol>
            </MDBRow>
            <Products />
        </MDBRow>
    )
}