import React from "react";
import './index.scss'
import Login from '../../containers/login';
import avatarImg from './../../assets/img/avatar.png';

interface ILoginPageProps {
}

export default function LoginPage(props: ILoginPageProps) {
    return (
        <>
            <div className="signin-container" >
                <div className="loginbox">
                    <img
                        src={avatarImg}
                        className="avatar" />
                    <h1>Login Here</h1>
                    <Login />
                </div>
            </div>
        </>
    )
}