import React from "react";
import OperatorsList from '../../containers/operators/operatorsList';

interface IOperatorsPageProps {
}

export default function OperatorsPage(props: IOperatorsPageProps) {
    return (
        <OperatorsList />
    )
}