export const LOAD_QUOTATION_LIST = "LOAD_QUOTATION_LIST"
export const LOAD_QUOTATION_LIST_SAGA = "LOAD_QUOTATION_LIST_SAGA"


export interface ILoadQuotationActionSaga {
  readonly type: typeof LOAD_QUOTATION_LIST_SAGA;
  page: number,
  size: number,
  sort?: string,
}
export const loadQuotationSaga = (page = 0, size = 10, sort = ''): ILoadQuotationActionSaga => {
  return {
    type: LOAD_QUOTATION_LIST_SAGA,
    page: page,
    size: size,
    sort: sort,
  }
};
