import {IUpdateIsLoadingProductTransactionsAction, ProductTransactionsActions} from './types'
import {LOAD_PRODUCT_TRANSACTIONS_FILTERS, LOAD_PRODUCT_TRANSACTIONS, UPDATE_IS_LOADING_PRODUCT_TRANSACTIONS} from "../../sagas/product_transactions/types";
import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';
import _ from 'lodash';

interface IProductTransactionsState {
    filters: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending',
        storageList: IDropdown[],
        operationList: IDropdown[],
    },
    productTransActions: any[],
    totalCountPages: number,
    loadingProductTransactions: IUpdateIsLoadingProductTransactionsAction['payload'],
}

const ProductTransactionsState: IProductTransactionsState = {
    filters: {
        sortCol: '',
        sortOrder: 'ascending',
        storageList: [],
        operationList: [],
    },
    productTransActions: [],
    totalCountPages: 0,
    loadingProductTransactions: {
        isLoadingProductTransactions: false,
        errorMessage: undefined
    }
};

const productTransactionsReducer = (state = ProductTransactionsState, action: ProductTransactionsActions): IProductTransactionsState => {
    switch (action.type) {
        case LOAD_PRODUCT_TRANSACTIONS_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        case LOAD_PRODUCT_TRANSACTIONS:
            return {
                ...state,
                productTransActions: action.payload.productTransActions,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_PRODUCT_TRANSACTIONS:
            return {
                ...state,
                loadingProductTransactions: action.payload,
            };
        default:
            return state;
    }
};

export default productTransactionsReducer;
