import React from "react";
import { MDBCol, MDBRow, MDBBtn } from 'mdbreact';
import { useDispatch, useSelector } from "react-redux";
import NotificationsList from "../../containers/notificationsList/index"
import { push } from 'connected-react-router'
interface INotificationsListPageProps {
}

export default function NotificationsListPage(props: INotificationsListPageProps) {
    const dispatch = useDispatch();
    return (
        <MDBRow className="m-0 bg-white">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol>
                    <MDBRow className="justify-content-end">
                        <MDBBtn className="main-blue-button" color="amber"
                            onClick={() => dispatch(push({ pathname: 'notifications/dismissed' }))}
                        >Dismissed Notifications</MDBBtn>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <NotificationsList />
        </MDBRow>
    )
}