import {
    LOAD_VENDORS, LOAD_VENDOR, ADD_VENDOR, UPDATE_VENDOR
    , UPDATE_IS_LOADING_VENDORS
} from "../../sagas/vendors/types";
import IVendor from "../../dtos/IVendor";

export interface ILoadVendorsAction {
    readonly type: typeof LOAD_VENDORS;
    payload: {
        vendors: IVendor[],
        totalVendorsCount: number
    }
}
export const loadVendorsProps = (payload: ILoadVendorsAction['payload']): ILoadVendorsAction => {
    return {
        type: LOAD_VENDORS,
        payload: payload
    }
};
export interface ILoadVendorAction {
    readonly type: typeof LOAD_VENDOR;
    payload: {
        vendor: IVendor,
    }
}
export const loadVendorProps = (payload: ILoadVendorAction['payload']): ILoadVendorAction => {
    return {
        type: LOAD_VENDOR,
        payload: payload
    }
};

export interface IAddVendorAction {
    readonly type: typeof ADD_VENDOR;
    payload: {
        vendorAdded: boolean,
    }
}

export const addVendorProps = (payload: IAddVendorAction['payload']): IAddVendorAction => {
    return {
        type: ADD_VENDOR,
        payload: payload
    }
};

export interface IUpdateVendorAction {
    readonly type: typeof UPDATE_VENDOR;
    payload: {
        vendorUpdated: boolean,
    }
}
export const updateVendorProps = (payload: IUpdateVendorAction['payload']): IUpdateVendorAction => {
    return {
        type: UPDATE_VENDOR,
        payload: payload
    }
};
export interface IUpdateIsLoadingVendorsAction {
    readonly type: typeof UPDATE_IS_LOADING_VENDORS;
    payload: {
        isLoadingVendors: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingVendorsProps = (payload: IUpdateIsLoadingVendorsAction['payload']): IUpdateIsLoadingVendorsAction => {
    return {
        type: UPDATE_IS_LOADING_VENDORS,
        payload: payload
    }
};
export type VendorsActions = ILoadVendorsAction | ILoadVendorAction | IAddVendorAction | IUpdateVendorAction | IUpdateIsLoadingVendorsAction