import React from 'react'
import "./index.scss";
import { MDBBtn } from 'mdbreact';
import { NavLink } from 'react-router-dom';

interface ITabsButtonsProps {
    tabs: any
}
export default function TabsButtons(props: ITabsButtonsProps) {
    return (
            <div className="tabs-container">
                {props.tabs.map((t: any) => {
                    return <NavLink to={t.path}>
                        <MDBBtn className="pr-2 pl-2 tabs-button text-capitalize"
                            id={window.location.pathname == t.path ? 'active-button' : 'inactive-button'}
                        >
                           {t.title}
                        </MDBBtn>
                    </NavLink>
                })}
            </div>
    )
}
