import React, { useState } from 'react';
import './styles/index.scss'
import { MDBContainer, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { useDispatch, useSelector } from "react-redux";
import { addToCart, placeOrder, placeQuotation } from '../../api/cart';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import { AppState } from '../../store';
import { loadOrderProductsSaga } from '../../sagas/smart-ordering/types';

interface IChcoutModalProps {
  isOpen: boolean,
  close?: any,
  confirm?: () => void,
  title?: string,
  body?: React.ReactNode,
  isInfo?: boolean,
  closebtnText?: string
  savebtnText?: string,
  checkoutOptions: any[]
}


const CheckoutModal = (props: IChcoutModalProps) => {
  const { isOpen, close, confirm, title, body, isInfo, closebtnText, savebtnText, checkoutOptions } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authReducer = useSelector((state: AppState) => state.globalReducer);
  const dispatch = useDispatch();

  const handleQuotationReq = async () => {
    setIsLoading(true);
    for (let i = 0; i < checkoutOptions.length; i++) {
      if (checkoutOptions[i] == "cart") {
        placeOrder(authReducer.authUser.subject).then((res) => {
          toast.success('Order Placed.');
          dispatch(loadOrderProductsSaga("cart"));
          close(!isOpen);
        }).catch((err) => {
          toast.error('Order Not Placed.');
          setIsLoading(false);
        });
      } else {
        placeQuotation(authReducer.authUser.subject).then((res) => {
          toast.success('Quotation Placed.');
          dispatch(loadOrderProductsSaga("cart"));
          close(!isOpen);
        }).catch((err) => {
          toast.error('Quotation Not Placed.');
          setIsLoading(false);
        });
      }
    }
  }

  return (
    <MDBContainer className="confirmation-modal-container">
      <MDBModal isOpen={isOpen} toggle={() => { close(!isOpen); }} inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
        <MDBModalHeader className='modal-header'>
          {title}
        </MDBModalHeader>
        <MDBModalBody>
          {body}
        </MDBModalBody>
        <MDBModalFooter>
          {
            <>
              {isLoading ? <BeatLoader loading={isLoading} color="#2D2ACE" /> : <MDBBtn className="confirm-btn mr-2" onClick={() => handleQuotationReq()}>{savebtnText ? savebtnText : 'Save changes'}</MDBBtn>}
              <MDBBtn className="cancel-btn" onClick={() => { close(!isOpen) }}>{closebtnText ? closebtnText : 'Close'}</MDBBtn>
            </>
          }
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer >
  )
}

export default CheckoutModal;
