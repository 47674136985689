import React, { useEffect } from 'react';
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import UserGroup from '../../containers/user-group';
import userGroupIcon from "../../assets/img/pages-icons/user-group-icon.png";

interface IListUserGroupProps {
}

function ListUserGroup(props: IListUserGroupProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol size={`12`} md={`6`}>
                    <img className="page-image d-inline-block" src={userGroupIcon} />
                    <MDBCardText className="page-title d-inline-block">User Group</MDBCardText>
                    <span className="page-line d-inline-block" />
                </MDBCol>
            </MDBRow>
            <UserGroup />
        </MDBRow>
    )
}

export default ListUserGroup;