import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { push } from 'connected-react-router';
import { MDBCol, MDBRow } from 'mdbreact';
import Loader from "../../components/loader";
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import moment from "moment";
import Select from '../../components/select';
import constants from "../../constants"
import { loadQuotationSaga } from '../../sagas/quotation/types';
import { IQuotation, IQuotationItem } from '../../dtos/IQuotation';
import './styles.scss';
import { statusHandler } from './helpers';
import QuotationDetailsModal from './quotationDetailsModal';

export default function QuotationList() {
  const dispatch = useDispatch();
  const routerReducer = useSelector((state: AppState) => state.router)
  const quotationReducer = useSelector((state: AppState) => state.QuotationReducer)
  const { sort, page } = queryString.parse(routerReducer.location.search);
  const [view, selectView] = useState(10);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [selectedQuotation, setSelectedQuotation] = useState<IQuotation | undefined>(undefined);
  const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
  const handlePaginationChange = (e: any, { activePage }: any) => { dispatch(push({ search: addQueryString(routerReducer.location.search, 'page', activePage) })); }

  useEffect(() => {
    const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
    const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
    updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
    const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
    changePage(page ? parseInt(page.toString()) : 1);
    dispatch(loadQuotationSaga(pageNum, view, sorting));
  }, [routerReducer.location.search, view]);

  const changeStep = (activeTab: string) => { dispatch(push({ search: activeTab })); }

  const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
    return headerColumns.map((headerColumn, i) => {
      if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
        switch (columnSorted) {
          case 'ascending':
            return { ...headerColumn, sorted: 'ascending' }
          case 'descending':
            return { ...headerColumn, sorted: 'descending' }
          default:
            return { ...headerColumn, sorted: undefined }
        }
      } else {
        return { ...headerColumn, sorted: undefined }
      }
    });
  }

  const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
    let sorting = ``;
    headerColumns.map((headerColumn, i) => {
      let colName = headerColumn.name.toLocaleLowerCase()
      if (colName != "" && colName != "name" && colName != "location" && colName != "assigned to") {
        if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
          sorting = `${columnName.toLowerCase()},`;
          switch (columnSorted) {
            case undefined:
              sorting += 'ascending';
              break;
            case 'ascending':
              sorting += 'descending';
              break;
            default:
              sorting = '';
          }

        }
      }
    });
    const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
    dispatch(push({
      search: addQueryString(newQueryString, 'page', 1)
    }));
  }

  const headers: any = [
    { name: "Quotation id", width: 2 },
    { name: "Order id", width: 2 },
    { name: "Status", width: 4 },
    { name: "Created at", width: 4 },
    { name: "Total", width: 3 }
  ].map((header) => {
    return { ...header };
  });
  const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);

  const buildRows = (cartProducts: any[]): IRow[] => {
    return cartProducts?.map((cartProduct: any, i: any) => {
      return {
        value: cartProduct,
        props: [
          {
            edit: (rowValue: IQuotation) => {
              return <p className="pl-3 cursor offblue-text bold" onClick={() => {
                setSelectedQuotation(rowValue);
                setIsDetailsOpen(true);
              }}>#{rowValue.id}</p>
            }
          },
          {
            edit: (rowValue: IQuotation) => {
              return <p className="pl-3 cursor offblue-text bold" onClick={() => {
              }}>{`${rowValue?.order_id ? '#' : ''}`}{rowValue?.order_id}</p>
            }
          },
          {
            edit: (rowValue: IQuotation) => {
              return <div className='quotation-status' style={{ backgroundColor: statusHandler(rowValue.status) }}><p>{rowValue.status}</p></div>
            }
          },
          {
            edit: (rowValue: IQuotation) => {
              return <p>{moment(rowValue.created_at).format("DD/MM/YYYY, hh:mm A")}</p>;
            },
          },
          {
            edit: (rowValue: IQuotation) => {
              return <p>{rowValue.total} LE</p>;
            }
          }
        ]
      }
    });
  };
  const rows = buildRows(quotationReducer.quotationList);

  return (
    <>
      <MDBRow className="m-0 w-100">
        <MDBCol className="text-center">
          {/* <Loader isLoading={quotationReducer.loadingOrderProducts.isLoadingOrderProducts} errorMessage={cartReducer.loadingOrderProducts.errorMessage}> */}
          <MDBRow start className="m-2 mb-4">
            <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
              <Select name="view"
                id="view-select"
                className="mr-2 d-inline"
                label="View By"
                options={constants.viewOptions.map((option, i) => {
                  return { text: option.value, value: option.id }
                })}
                selectedValue={view}
                onChange={(e: any) => { selectView(e.target.value); dispatch(push({ search: '' })); }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow start className="justify-content-center mt-2">
            <MDBCol className="text-center p-0">
              {
                quotationReducer.quotationList?.length > 0 ?
                  <DataTable
                    headerColumns={headerColumns}
                    rows={rows}
                    headerOnClick={handleSort}
                    totalPages={quotationReducer.totalQuotationCount / view}
                    activePage={activePage}
                    onPageChange={handlePaginationChange}
                  />
                  : <div className="mt-5" >You don't have any quotations yet.</div>
              }
            </MDBCol>
          </MDBRow>
          {/* </Loader> */}
        </MDBCol>
      </MDBRow >
      {isDetailsOpen ? <QuotationDetailsModal selectedQuotation={selectedQuotation} isDetailsOpen={isDetailsOpen} setIsDetailsOpen={setIsDetailsOpen} /> : <></>}
    </>
  )
}
