const constants = {
  status: [
    {
      id: "normal",
      name: "Normal",
    },
    {
      id: "warning",
      name: "Warning",
    },
    {
      id: "critical",
      name: "Critical",
    },
  ],
  operations: [
    {
      id: "ADDED",
      name: "ADDED",
    },
    {
      id: "REMOVED",
      name: "REMOVED",
    },
  ],
  pagination: {
    inventoryPerPage: 10,
    userGroupPerPage: 10,
    productsPerPage: 10,
  },
  viewOptions: [
    {
      "id": 2,
      "value": "View 2"
    },
    {
      "id": 5,
      "value": "View 5"
    },
    {
      "id": 10,
      "value": "View 10"
    },
    {
      "id": 20,
      "value": "View 20"
    }
  ],
  storageTypes: [
    {
      id: 'automatic',
      value: 'Automatic'
    }, {
      id: 'manual',
      value: 'Manual'
    }
  ],
  OrderStatus: [
    {
      name: "Open",
    },
    {
      name: "Declined",
    },
    {
      name: "Placed",
    },
    {
      name: "Ordered",
    },
  ],
  toastTimeOut: 5000
};

export default constants;
