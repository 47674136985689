import {CategoriesActions, IUpdateIsLoadingCategoriesAction} from './types'
import ICategory from '../../dtos/ICategory';
import {LOAD_CATEGORIES, UPDATE_IS_LOADING_CATEGORIES} from "../../sagas/categories/types";

interface ICategoriesState {
    categories: ICategory[]
    filters: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    },
    totalCountPages: number,
    loadingCategories: IUpdateIsLoadingCategoriesAction['payload'],
}

const CategoriesState: ICategoriesState = {
    categories: [],
    filters: {
        sortCol: '',
        sortOrder: 'ascending'
    },
    totalCountPages: 0,
    loadingCategories: {
        isLoadingCategories: false,
        errorMessage: undefined
    }
};

const categoriesReducer = (state = CategoriesState, action: CategoriesActions): ICategoriesState => {
    switch (action.type) {
        case LOAD_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_CATEGORIES:
            return {
                ...state,
                loadingCategories: action.payload,
            };
        default:
            return state;
    }
};

export default categoriesReducer;
