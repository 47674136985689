import {SidebarActions} from './types'
import {TOGGLE_SIDEBAR} from "../../sagas/sidebar/types";

interface ISidebarState {
    open: boolean,
}

const SidebarState: ISidebarState = {
    open: window.innerWidth > 767, // if mobile view start app with menu closed
};

const sidebarReducer = (state = SidebarState, action: SidebarActions): ISidebarState => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                open: action.payload.open,
            };
        default:
            return state;
    }
};

export default sidebarReducer;
