import { LOAD_USERS, INVITE_NEW_USER, VALIDATE_INVITATION, UPDATE_IS_LOADING_USERS, LOAD_INVITATIONS, UPDATE_IS_LOADING_INVITATIONS } from "../../sagas/user/types";
import IUser from "../../dtos/IUser";

export interface ILoadUsersAction {
    readonly type: typeof LOAD_USERS;
    payload: {
        users: IUser[],
        totalUsersCount: number
    }
}
export const loadUsersProps = (payload: ILoadUsersAction['payload']): ILoadUsersAction => {
    return {
        type: LOAD_USERS,
        payload: payload
    }
};

export interface IInviteNewUserAction {
    readonly type: typeof INVITE_NEW_USER;
    payload: {
        invitationSent: boolean
    }
}
export const InviteNewUserProps = (payload: IInviteNewUserAction['payload']): IInviteNewUserAction => {
    return {
        type: INVITE_NEW_USER,
        payload: payload
    }
};
export interface IValitateInvitationAction {
    readonly type: typeof VALIDATE_INVITATION;
    payload: {
        valid: boolean
    }
}
export const validateInvitationProps = (payload: IValitateInvitationAction['payload']): IValitateInvitationAction => {
    return {
        type: VALIDATE_INVITATION,
        payload: payload
    }
};

export interface IUpdateIsLoadingUsersAction {
    readonly type: typeof UPDATE_IS_LOADING_USERS;
    payload: {
        isLoadingUsers: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingUsersProps = (payload: IUpdateIsLoadingUsersAction['payload']): IUpdateIsLoadingUsersAction => {
    return {
        type: UPDATE_IS_LOADING_USERS,
        payload: payload
    }
};

export interface ILoadInvitationsAction {
    readonly type: typeof LOAD_INVITATIONS;
    payload: {
        invitations: any[],
        totalInvitationsCount: number
    }
}
export const loadInvitationsProps = (payload: ILoadInvitationsAction['payload']): ILoadInvitationsAction => {
    return {
        type: LOAD_INVITATIONS,
        payload: payload
    }
};

export interface IUpdateIsLoadingInvitationsAction {
    readonly type: typeof UPDATE_IS_LOADING_INVITATIONS;
    payload: {
        isLoadingInvitations: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingInvitationsProps = (payload: IUpdateIsLoadingInvitationsAction['payload']): IUpdateIsLoadingInvitationsAction => {
    return {
        type: UPDATE_IS_LOADING_INVITATIONS,
        payload: payload
    }
};


export type UserActions = ILoadUsersAction | IInviteNewUserAction | IValitateInvitationAction | IUpdateIsLoadingUsersAction |
 ILoadInvitationsAction | IUpdateIsLoadingInvitationsAction;