import { takeLatest } from "redux-saga/effects";
import { LOGOUT_SAGA, ILogoutActionSaga } from "./types";
import { keycloak } from "../../store/keycloak";

function* logOut(action: ILogoutActionSaga) {
  localStorage.clear();
  if (keycloak.authenticated) {
    yield keycloak.logout();
  } else {
    window.location.href = "/";
  }
}

export function* watchLogOut() {
  yield takeLatest(LOGOUT_SAGA, logOut);
}

export default [
  watchLogOut(),
]