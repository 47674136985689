import {
    LOAD_UNITS, LOAD_UNIT, LOAD_UNIT_STATUS,
    ADD_UNIT, UPDATE_UNIT
    , UPDATE_IS_LOADING_UNITS
} from "../../sagas/units/types";
import IUnit from "../../dtos/IUnit";
import IStatus from "../../dtos/IStatus";

export interface ILoadUnitsAction {
    readonly type: typeof LOAD_UNITS;
    payload: {
        units: IUnit[],
        totalUnitsCount: number
    }
}
export const loadUnitsProps = (payload: ILoadUnitsAction['payload']): ILoadUnitsAction => {
    console.log("loadUnitsProps")
    return {
        type: LOAD_UNITS,
        payload: payload
    }
};

export interface ILoadUnitAction {
    readonly type: typeof LOAD_UNIT;
    payload: {
        unit: IUnit,
    }
}
export const loadUnitProps = (payload: ILoadUnitAction['payload']): ILoadUnitAction => {
    return {
        type: LOAD_UNIT,
        payload: payload
    }
};
export interface ILoadUnitStatusAction {
    readonly type: typeof LOAD_UNIT_STATUS;
    payload: {
        unitStatus: IStatus[],
    }
}
export const loadUnitStatusProps = (payload: ILoadUnitStatusAction['payload']): ILoadUnitStatusAction => {
    return {
        type: LOAD_UNIT_STATUS,
        payload: payload
    }
};

export interface IAddUnitAction {
    readonly type: typeof ADD_UNIT;
    payload: {
        unitAdded: boolean,
    }
}
export const addUnitProps = (payload: IAddUnitAction['payload']): IAddUnitAction => {
    return {
        type: ADD_UNIT,
        payload: payload
    }
};

export interface IUpdateUnitAction {
    readonly type: typeof UPDATE_UNIT;
    payload: {
        unitUpdated: boolean,
    }
}
export const updateUnitProps = (payload: IUpdateUnitAction['payload']): IUpdateUnitAction => {
    return {
        type: UPDATE_UNIT,
        payload: payload
    }
};
export interface IUpdateIsLoadingUnitsAction {
    readonly type: typeof UPDATE_IS_LOADING_UNITS;
    payload: {
        isLoadingUnits: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingUnitsProps = (payload: IUpdateIsLoadingUnitsAction['payload']): IUpdateIsLoadingUnitsAction => {
    return {
        type: UPDATE_IS_LOADING_UNITS,
        payload: payload
    }
};
export type UnitsActions = ILoadUnitsAction | ILoadUnitAction | ILoadUnitStatusAction | IAddUnitAction | IUpdateUnitAction | IUpdateIsLoadingUnitsAction