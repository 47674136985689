import { IUpdateIsLoadingDashboardAction, DashboardActions } from './types'
import { LOAD_DASHBOARD, UPDATE_IS_LOADING_DASHBOARD } from "../../sagas/dashboard/types";
interface IDashboardState {
    itemsCount: number,
    productsCount: number,
    warningsCount: number,
    criticalsCount: number,
    loadingDashboard: IUpdateIsLoadingDashboardAction['payload'],
}

const DashboardState: IDashboardState = {
    itemsCount: 0,
    productsCount: 0,
    warningsCount: 0,
    criticalsCount: 0,
    loadingDashboard: {
        isLoadingDashboard: false,
        errorMessage: undefined
    }
};

const dashboardReducer = (state = DashboardState, action: DashboardActions): IDashboardState => {
    switch (action.type) {
        case LOAD_DASHBOARD:
            return {
                ...state,
                itemsCount: action.payload.itemsCount,
                productsCount: action.payload.productsCount,
                warningsCount: action.payload.warningsCount,
                criticalsCount: action.payload.criticalsCount,
            };
        case UPDATE_IS_LOADING_DASHBOARD:
            return {
                ...state,
                loadingDashboard: action.payload,
            };
        default:
            return state;
    }
};

export default dashboardReducer;
