import React, { useState } from 'react';
import {
    MDBRow, MDBCol, MDBIcon,
} from "mdbreact";
import { push } from 'connected-react-router';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from '../../../store';
import SideBarLink from './link';
import Can from '../../can';
import View from "../../view"
import { logoutPropsSaga } from '../../../sagas/header/types';
import { toggleSidebarPropsSaga } from '../../../sagas/sidebar/types';
import storagesIcon from '../../../assets/img/sidemenu_icons/storages.png';
import storagesBlueIcon from '../../../assets/img/pages-icons/storages-icon.png';
import branchesIcon from '../../../assets/img/sidemenu_icons/branches.png';
import branchesBlueIcon from '../../../assets/img/pages-icons/branches-icon.png';
import categoriesIcon from '../../../assets/img/sidemenu_icons/categories.png';
import categoriesBlueIcon from '../../../assets/img/pages-icons/categories-icon.png';
import dashboardIcon from '../../../assets/img/sidemenu_icons/dashboard.png';
import dashboardBlueIcon from '../../../assets/img/pages-icons/dashboard-icon.png';
import inventoryIcon from '../../../assets/img/sidemenu_icons/inventory.png';
import inventoryBlueIcon from '../../../assets/img/pages-icons/inventory-icon.png';
import logoutIcon from '../../../assets/img/sidemenu_icons/log-out.png';
import logoutBlueIcon from '../../../assets/img/pages-icons/logout-icon.png';
import productsIcon from '../../../assets/img/sidemenu_icons/products.png';
import productsBlueIcon from '../../../assets/img/pages-icons/products-icon.png';
import settingsIcon from '../../../assets/img/sidemenu_icons/settings.png';
import settingsBlueIcon from '../../../assets/img/pages-icons/settings-icon.png';
import usersIcon from '../../../assets/img/sidemenu_icons/users.png';
import usersBlueIcon from '../../../assets/img/pages-icons/users-icon.png';
import userAccountIcon from '../../../assets/img/sidemenu_icons/user-account.png';
import userAccountBlueIcon from '../../../assets/img/pages-icons/user-account-icon.png';
import userGroupIcon from '../../../assets/img/sidemenu_icons/user-group.png';
import userGroupBlueIcon from '../../../assets/img/pages-icons/user-group-icon.png';
import vendorsIcon from '../../../assets/img/sidemenu_icons/vendor-icon.png';
import vendorsBlueIcon from '../../../assets/img/pages-icons/vendor-icon.png';
import reportsIcon from "../../../assets/img/sidemenu_icons/reports.png";
import reportsBlueIcon from "../../../assets/img/pages-icons/reports-icon.png";
import ordersIcon from "../../../assets/img/sidemenu_icons/orders.png";
import ordersBlueIcon from "../../../assets/img/pages-icons/orders-icon.png";
import assetsIcon from "../../../assets/img/sidemenu_icons/assets.png";
import assetsBlueIcon from "../../../assets/img/pages-icons/assets-icon.png";


interface ISideBarProps {

}

function SideBar(props: ISideBarProps) {
    const dispatch = useDispatch();
    const globalReducer = useSelector((state: AppState) => state.globalReducer);
    const sidebarReducer = useSelector((state: AppState) => state.sidebarReducer);
    const currentLocation = useSelector((state: AppState) => state.router.location);
    return (
        <>
            <MDBRow className="m-0 p-0 border-radius-tl border-radius-tr border-radius-bl border-radius-br white position-fixed z-index-1">
                <MDBCol md="12" className={`side-header pt-3 pb-3 pr-2 pl-2 border-radius-tl border-radius-tr text-center`}>
                    <div className="bars pt-2 pb-2 cursor" onClick={() => { dispatch(toggleSidebarPropsSaga()) }}>
                        <hr />
                        <hr />
                    </div>
                    <div className="side-header-account">
                        <MDBRow className="m-0 text-left cursor" onClick={() => dispatch(push({ pathname: `/my-account` }))}>
                            <MDBCol className="side-header-account-icon online m-0 p-0" size="3">
                                <MDBIcon icon="user" className="lightGrey-text" />
                            </MDBCol>
                            <MDBCol className="m-0 p-0">
                                <div className="text-capitalize d-inline-block">
                                    <h4 className="offblue-text">
                                        <strong>{globalReducer.authUser.idTokenParsed.name.replace('-', ' ')}</strong>
                                    </h4>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <div className="mobile-side-close cursor white-text" onClick={() => { dispatch(toggleSidebarPropsSaga()) }}>
                        x
                    </div>
                </MDBCol>
                <MDBCol md="12" className={`side-links p-0 border-radius-bl border-radius-br ${sidebarReducer.open ? 'opened' : 'closed text-center'}`}>
                    <SideBarLink exact name="my-account" icon="user"
                        imageIcon={
                            <div className="my-account-img">
                                <img src={currentLocation.pathname.includes('my-account') ? userAccountBlueIcon : userAccountIcon} className={`link-icon-dimensions`} />
                            </div>}
                        className="my-account-side-link online-link" currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />

                    {/* <View view="dashboard">
                        <SideBarLink exact isBase name="dashboard" icon="columns"
                            imageIcon={
                                <img src={currentLocation.pathname == '/' ? dashboardBlueIcon : dashboardIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                    </View> */}

                    {/* <View view="inventory">
                        <Can check="PRODUCT_VIEW">
                            <SideBarLink exact name="inventory" icon="warehouse"
                                imageIcon={
                                    <img src={currentLocation.pathname.includes('inventory') ? branchesBlueIcon : branchesIcon} className={`link-icon-dimensions`} />
                                }
                                currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                        </Can>
                    </View> */}
                    <View view="branches">
                        <Can check="BRANCH_VIEW">
                            <SideBarLink exact name="branches" icon="boxes"
                                imageIcon={
                                    <img src={currentLocation.pathname.includes('branches') ? inventoryBlueIcon : inventoryIcon} className={`link-icon-dimensions`} />
                                }
                                currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                        </Can>
                    </View>
                    <View view="user-group">
                        <Can check="USERGROUP_VIEW">
                            <SideBarLink exact name="user-group" icon="user"
                                imageIcon={
                                    <img src={currentLocation.pathname.includes('user-group') ? userGroupBlueIcon : userGroupIcon} className={`link-icon-dimensions`} />
                                }
                                currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                        </Can>
                    </View>
                    <View view="users">
                        <Can check="USER_VIEW">
                            <SideBarLink exact name="users" icon="users"
                                imageIcon={
                                    <img src={currentLocation.pathname.includes('users') ? usersBlueIcon : usersIcon} className={`link-icon-dimensions`} />
                                }
                                currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                        </Can>
                    </View>
                    {/* <View view="assets">
                        <SideBarLink exact name="assets" icon="box"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('assets') ? assetsBlueIcon : assetsIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                    </View> */}
                    {/* <View view="categories">
                        <SideBarLink exact name="categories" icon="tags"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('categories') ? categoriesBlueIcon : categoriesIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                    </View> */}
                    <View view="catalog">
                        <SideBarLink exact name="catalog" icon="layer-group"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('catalog') ? productsBlueIcon : productsIcon} className={`link-icon-dimensions`} />
                            }
                            isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />
                    </View>
                    {/* <View view="vendors">
                        <SideBarLink exact name="vendors" icon="box"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('vendors') ? vendorsBlueIcon : vendorsIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />

                    </View>
                    <View view="storage">
                        <SideBarLink exact name="storage" icon="box"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('storage') ? storagesBlueIcon : storagesIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />

                    </View> */}
                    <View view="cart">
                        <SideBarLink exact name="cart" icon="warehouse"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('cart') ? ordersBlueIcon : ordersIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />

                    </View>
                    <View view="quotation-list">
                        <SideBarLink exact name="quotation-list" icon="warehouse"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('quotation-list') ? ordersBlueIcon : ordersIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                    </View>
                    <View view="orders">
                        <SideBarLink exact name="orders" icon="warehouse"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('orders') ? ordersBlueIcon : ordersIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                    </View>
                    {/* <View view="reports">
                        <SideBarLink exact name="reports" icon="boxes"
                            imageIcon={
                                <img src={currentLocation.pathname.includes('reports') ? reportsBlueIcon : reportsIcon} className={`link-icon-dimensions`} />
                            }
                            currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />

                    </View> */}
                    {/* 
                    <SideBarLink exact name="settings" icon="cog"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('settings') ? settingsBlueIcon : settingsIcon} className={`link-icon-dimensions`} />
                        }
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open}
                        className="settings-side-link"
                    /> */}

                    <SideBarLink exact name="logout" icon="power-off"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('logout') ? logoutBlueIcon : logoutIcon} className={`link-icon-dimensions`} />
                        }
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open}
                        onClick={() => dispatch(logoutPropsSaga())}
                        className="last-side-link settings-side-link"
                    />
                </MDBCol>
            </MDBRow>
        </>
    )
}

export default SideBar;