import IAsset from "../../dtos/IAsset"

export const LOAD_ASSETS = "LOAD_ASSETS"
export const LOAD_ASSETS_SAGA = "LOAD_ASSETS_SAGA"

export const LOAD_ASSET = "LOAD_ASSET"
export const LOAD_ASSET_SAGA = "LOAD_ASSET_SAGA"

export const UPDATE_ASSET = "UPDATE_ASSET"
export const UPDATE_ASSET_SAGA = "UPDATE_ASSET_SAGA"

export const LOAD_ASSET_USAGE = "LOAD_ASSET_USAGE"
export const LOAD_ASSET_USAGE_SAGA = "LOAD_ASSET_USAGE_SAGA"

export const LOAD_ASSETS_STATUS = "LOAD_ASSETS_STATUS"
export const LOAD_ASSETS_STATUS_SAGA = "LOAD_ASSETS_STATUS_SAGA"

export const UPDATE_IS_LOADING_ASSETS = "UPDATE_IS_LOADING_ASSETS"

export interface ILoadAssetsActionSaga {
    readonly type: typeof LOAD_ASSETS_SAGA;
    active: string,
    page: number,
    size: number,
    sort: string,
    name: string,
    status: string,
    storage: string
}
export const loadAssetsPropsSaga = (active = '', page = 0, size = 0, sort = '', name = '', status = '', storage = ''): ILoadAssetsActionSaga => {
    return {
        type: LOAD_ASSETS_SAGA,
        active: active,
        page: page,
        sort: sort,
        size: size,
        name: name,
        status: status,
        storage: storage
    }
};

export interface ILoadAssetActionSaga {
    readonly type: typeof LOAD_ASSET_SAGA;
    assetId: number
}
export const loadAssetPropsSaga = (assetId = 0): ILoadAssetActionSaga => {
    return {
        type: LOAD_ASSET_SAGA,
        assetId: assetId
    }
};

export interface ILoadAssetUsageActionSaga {
    readonly type: typeof LOAD_ASSET_USAGE_SAGA;
    assetId: number,
    page: number,
    size: number,
    sort: string
}
export const loadAssetUsagePropsSaga = (assetId = 0, page = 0, size = 10, sort= ''): ILoadAssetUsageActionSaga => {
    return {
        type: LOAD_ASSET_USAGE_SAGA,
        assetId: assetId,
        page: page,
        size: size,
        sort: sort
    }
};

export interface IUpdateAssetActionSaga {
    readonly type: typeof UPDATE_ASSET_SAGA;
    assetId: number
    asset: IAsset | any;
}
export const updateAssetPropsSaga = (assetId: number, asset: IAsset): IUpdateAssetActionSaga => {
    return {
        type: UPDATE_ASSET_SAGA,
        assetId: assetId,
        asset: asset
    }
};

export interface ILoadAssetsStatusActionSaga {
    readonly type: typeof LOAD_ASSETS_STATUS_SAGA;
}
export const loadAssetsStatusPropsSaga = (): ILoadAssetsStatusActionSaga => {
    return {
        type: LOAD_ASSETS_STATUS_SAGA,
    }
};