import { filter } from "lodash";
import IProduct from "../../dtos/IProduct";

export const LOAD_PRODUCT_TRANSACTIONS_SAGA = "LOAD_PRODUCT_TRANSACTIONS_SAGA";
export const LOAD_PRODUCT_TRANSACTIONS = "LOAD_PRODUCT_TRANSACTIONS";
export const LOAD_PRODUCT_TRANSACTIONS_FILTERS = "LOAD_PRODUCT_TRANSACTIONS_FILTERS";
export const UPDATE_IS_LOADING_PRODUCT_TRANSACTIONS = "UPDATE_IS_LOADING_PRODUCT_TRANSACTIONS";
export interface ILoadProductTransactionsActionSaga {
  readonly type: typeof LOAD_PRODUCT_TRANSACTIONS_SAGA;
}

export const loadProductTransactionsPropsSaga = (): ILoadProductTransactionsActionSaga => {
  return {
    type: LOAD_PRODUCT_TRANSACTIONS_SAGA,
  };
};

export const FILTER_PRODUCT_TRANSACTIONS_SAGA = "FILTER_PRODUCT_TRANSACTIONS_SAGA";
export interface IFilterProductTransactionsActionSaga {
  readonly type: typeof FILTER_PRODUCT_TRANSACTIONS_SAGA;
  filters: {
    productId: number;
    page: number;
    size: number;
    sort: string;
    operation: string;
    storage: string;
    from: string;
    to: string;
    userId: string
  };
}

export const filterProductTransactionsPropsSaga = (filters: IFilterProductTransactionsActionSaga['filters']): IFilterProductTransactionsActionSaga => {
  return {
    type: FILTER_PRODUCT_TRANSACTIONS_SAGA,
    filters: {
      productId: filters.productId,
      page: filters.page,
      size: filters.size,
      sort: filters.sort,
      operation: filters.operation,
      storage: filters.storage,
      from: filters.from,
      to: filters.to,
      userId: filters.userId,
    },
  };
};