import React from 'react';
import './index.scss'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { useDispatch } from "react-redux";

interface ILoaderProps {
    isOpen: boolean,
    close?: (dispatch: any) => void,
    confirm?: () => void,
    title?: string,
    body?: React.ReactNode,
    isInfo?: boolean,
    closebtnText?: string
    savebtnText?: string
}

const Modal = (props: ILoaderProps) => {
    const dispatch = useDispatch();
    return (
        <MDBContainer className="confirmation-modal-container">
            <MDBModal isOpen={props.isOpen} toggle={() => props.close ? props.close(dispatch) : undefined} inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalBody>
                    {props.body}
                </MDBModalBody>
                <MDBModalFooter>
                    {
                        props.isInfo ? <MDBBtn className="confirm-btn" onClick={() => props.close ? props.close(dispatch) : undefined}>ok</MDBBtn> :
                            <>
                                <MDBBtn className="confirm-btn mr-2" onClick={props.confirm}>{props.savebtnText ? props.savebtnText : 'Save changes'}</MDBBtn>
                                <MDBBtn className="cancel-btn" onClick={() => props.close ? props.close(dispatch) : undefined}>{props.closebtnText ? props.closebtnText : 'Close'}</MDBBtn>
                            </>
                    }
                </MDBModalFooter>
            </MDBModal>
        </MDBContainer>
    )
}

export default Modal;
