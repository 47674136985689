import { axiosInstance } from "../store";
import IStorage from "../dtos/IStorage"
import IStorageTransaction from "../dtos/IStorageTransaction";
import { handlStatusCodeMessage } from "../helpers";

export function fetchStorages(page = 0, size = 0, sort = '', name = '', storageType = ''): Promise<{ storages?: IStorage[], totalStoragesCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/storage?page=${page}&size=${size}&sort=${sort}&name=${name}&type=${storageType}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    storages: res.data
                    , totalStoragesCount: res.headers['x-total-count']
                }
            }
            else {
                return {
                    storages: []
                    , totalStoragesCount: 0
                }
            }

        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return {
                storages: []
                , totalStoragesCount: 0
            }
        })
}

export function fetchStorage(storageId = ''): Promise<IStorage> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/storage/${storageId}`)
        .then((res) => {
            return res.status == 200 ? res.data : {}
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return {}
        })
}
export function fetchStorageTransactions(storageId: string, page = 0, size = 10, sort = '', userId = '', from = '', to = ''): Promise<{ storageTransactions: IStorageTransaction[], totalStorageTransactionsCount: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/storage/${storageId}/transactions?page=${page}&size=${size}&sort=${sort}&user_id=${userId}&from=${from}&to=${to}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    storageTransactions: res.data,
                    totalStorageTransactionsCount: res.headers['x-total-count']
                }
            }
            else {
                return { storageTransactions: [], totalStorageTransactionsCount: 0 }
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return {
                storageTransactions: [], totalStorageTransactionsCount: 0
            }

        })
}