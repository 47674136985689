import {put, takeLatest, select} from 'redux-saga/effects'
import {
    TOGGLE_SIDEBAR_SAGA, IToggleSidebarActionSaga,
} from "./types";
import {toggleSidebarProps} from "../../reducers/sidebar/types";
import { AppState } from '../../store';

function* toggleSidebar(action: IToggleSidebarActionSaga) {
    const getSidebarState = (state: AppState) => state.sidebarReducer;
    const sidebarCurrentState: ReturnType<typeof getSidebarState> = yield select(getSidebarState);
    yield put(toggleSidebarProps({open: !sidebarCurrentState.open}))
}

export function* watchToggleSidebar() {
    yield takeLatest(TOGGLE_SIDEBAR_SAGA, toggleSidebar)
}

export default [
    watchToggleSidebar(),
]