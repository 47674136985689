import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import {
    loadOperatorsPropsSaga, loadOperatorPropsSaga, loadOperatorStatusPropsSaga,
    addOperatorPropsSaga, updateOperatorPropsSaga
} from "../../sagas/operators/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import IOperator from "../../dtos/IOperator"
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton";
import Select from "../../components/select"
import TableMenu from "../../components/tableMenu"
import IconContainer from "../../components/iconContainer"
import constants from "../../constants";
import OperatorsForm from "./operatorsForm"
import { updateGlobalModalPropsSaga } from "../../sagas/global/types";


interface IOperatorsListProps {
}
export default function OperatorsList(props: IOperatorsListProps) {
    const dispatch = useDispatch();
    const operatorsReducer = useSelector((state: AppState) => state.operatorsReducer)
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page, searchkeyword, status } = queryString.parse(routerReducer.location.search);
    const [searchText, updateSearchText] = useState(searchkeyword ? searchkeyword.toString() : '');
    const [selectedStatus, selectStatus] = useState(status ? status.toString() : '');
    //new ui
    const [view, selectView] = useState(10)
    const [isOpen, toggleModal] = useState(false)
    const [operatorModalOpen, toggleOperatorModal] = useState(false)
    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        updateSearchText(searchkeyword ? searchkeyword.toString() : '');
        selectStatus(status ? status.toString() : '');
        dispatch(loadOperatorStatusPropsSaga())
        dispatch(loadOperatorsPropsSaga(pageNum, view, sorting, searchkeyword?.toString(), status?.toString()));
        if (operatorsReducer.operatorUpdated) {
            operatorsReducer.operatorUpdated = false;
            resetOperator()
        }
        if (operatorsReducer.operatorAdded) {
            operatorsReducer.operatorAdded = false;
            resetOperator()
        }
    }, [routerReducer.location.search, operatorsReducer.operatorAdded, operatorsReducer.operatorUpdated, view]);
    useEffect(() => {
        if (operatorModalOpen == false) {
            resetOperator();
        }
    }, [operatorModalOpen])
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }

                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const headers: any = [
        { name: "Id", width: 1 },
        { name: "Name", width: 3 },
        { name: "Title", width: 2 },
        { name: "Status", width: 2 },
        { name: "", width: 1 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }
    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }
    const handleCloseModal = () => {
        dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
    }
    const handleDismissOperator = (operator: IOperator) => {
        operator.is_dismissed = true
        dispatch(updateOperatorPropsSaga(operator.id, operator))
        dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
    }
    const buildRows = (operators: IOperator[]): IRow[] => {
        return operators.map((b: IOperator, i: any) => {
            return {
                value: b,
                props: ['id', {
                    edit: (rowValue: IOperator) => {
                        return (<div onClick={() => {
                            dispatch(loadOperatorPropsSaga(rowValue.id))
                            toggleOperatorModal(!operatorModalOpen)
                        }}>{rowValue.name}</div>);
                    }
                }, 'title', 'status.name',
                    {
                        edit: (rowValue: IOperator) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    }]
            }
        });
    };
    const rows = useMemo(() => buildRows(operatorsReducer.operators), [operatorsReducer.operators]);
    const menuItems = [
        {
            title: "Edit Operator",
            icon: "edit",
            handleChange: async (rowValue: any) => {
                await dispatch(loadOperatorPropsSaga(rowValue.id))
                toggleOperatorModal(!operatorModalOpen)
            }
        },
        {
            title: "Dismiss Operator",
            icon: "trash",
            handleChange: (rowValue: any) => {
                dispatch(updateGlobalModalPropsSaga({
                    isOpen: true,
                    title: 'Dismiss Operator',
                    body: 'Are you  you want to dismiss this operator?',
                    confirm: () => handleDismissOperator(rowValue),
                    close: handleCloseModal,
                }))
            }
        }
    ]
    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}                >
                <MDBModalHeader>Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >

                    <div className="w-100 d-flex mt-3 justify-content-between">
                        <input className="search-input form-control input-rounded w-50" type="text"
                            value={searchText}
                            onChange={(e) => updateSearchText(e.target.value)}
                            placeholder="Search" aria-label="Search" />
                        <Select name="status"
                            label="Select Status"
                            options={operatorsReducer.operatorStatus.map((option: any, i: any) => {
                                return { text: option.name, value: option.id }
                            })}
                            selectedValue={selectedStatus}
                            onChange={(e: any) => selectStatus(e.target.value)}
                        />
                    </div>
                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }
    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (selectedStatus != '') {
            filters.push({ filterName: 'status', value: selectedStatus });
        }
        if (searchText != '') {
            filters.push({ filterName: 'searchkeyword', value: searchText });
        }
        handleFilter(filters);
        toggleModal(false);
    }
    const resetOperator = () => {
        let emptyOperator = {
            id: 0,
            name: "",
            title: "",
            status: {
                id: 0,
                name: ""
            },
            is_dismissed: false
        }
        operatorsReducer.operator = emptyOperator;
        toggleOperatorModal(false);
    }
    const handleUpdateOperator = (values: any) => {
        dispatch(updateOperatorPropsSaga(values.id, values));
    }
    const handleAddOperator = (values: any) => {
        dispatch(addOperatorPropsSaga(values));
    }

    const renderOperatorModal = () => {
        return <MDBModal isOpen={operatorModalOpen} toggle={() => { toggleOperatorModal(!operatorModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="m-0 pt-5 pb-5">
                    <MDBCol size="8" className="text-left offblue-text">
                        <h2 className="ml-2 font-weight-bold">{operatorsReducer.operator.name != "" ? operatorsReducer.operator.name : 'New Operator'}</h2>
                    </MDBCol>
                    <div className="mt-4 mt-md-0 actions-container">
                        <MDBRow className="m-0 p-0">
                            <MainBlueButton title="cancel" className="d-contents"
                                btnClassName="btn-modal-header-cancel"
                                onClick={() => resetOperator()} />
                            <MainBlueButton form="operator-form" type="submit" title="save" className="d-contents"
                                btnClassName="btn-modal-header-save"
                                onClick={() => { }}
                            />
                        </MDBRow>
                    </div>
                </MDBRow>
            </div>
            <MDBModalBody className="text-left">
                <MDBRow className="m-0">
                    {operatorsReducer.operator.id != 0 ? <OperatorsForm key={operatorsReducer.operator.id}
                        operator={operatorsReducer.operator} onSubmit={handleUpdateOperator} /> : <OperatorsForm onSubmit={handleAddOperator} />}
                </MDBRow>
            </MDBModalBody>
        </MDBModal>
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={operatorsReducer.loadingOperators.isLoadingOperators} errorMessage={operatorsReducer.loadingOperators.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="3" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-5 pl-5 mr-2"
                                title="+ Add Operator"
                                onClick={() => {
                                    toggleOperatorModal(true)
                                }}
                            />
                        </MDBCol>
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                id="view-select"
                                className="mr-2 d-inline"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                            <IconContainer className="mr-2 d-inline" onClick={() => toggleModal(true)} >
                                <MDBIcon icon="filter" />
                            </IconContainer>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                operatorsReducer.operators.length > 0 ?
                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        data={operatorsReducer.operators}
                                        headerOnClick={handleSort}
                                        totalPages={operatorsReducer.totalOperatorsCount / view}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                    />
                                    : <div className="mt-5" >You don't have any operators yet.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
                {renderModal()}
                {renderOperatorModal()}
            </MDBCol>
        </MDBRow>
    )
}
