import IArea from "../dtos/IArea";
import ICity from "../dtos/ICity";
import ICountry from "../dtos/ICountry";
import { axiosInstance } from "../store";
import { handlStatusCodeMessage } from "../helpers";

export function fetchShippingCountries(): Promise<ICountry[]> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/account/lookup/country`)
    .then((res) => {
      if (res.status == 200) {
        return res.data;
      } else {
        return [];
      }
    })
    .catch((err) => { return [] });
}
export function fetchCities(country_id: Number): Promise<ICity[]> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}/account/lookup/city?country_id=${country_id}`
    )
    .then((res) => {
      if (res.status == 200) {
        return res.data;
      } else {
        return [];
      }
    }).catch((err) => { return [] });
}
export function fetchAreas(city_id: number): Promise<IArea[]> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}/account/lookup/area?city_id=${city_id}`
    )
    .then((res) => {
      if (res.status == 200) {
        return res.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: 'user', text: err.response.message });
      return [];
    });
}
