import {TOGGLE_SIDEBAR} from '../../sagas/sidebar/types';

export interface IToggleSidebarAction {
    readonly type: typeof TOGGLE_SIDEBAR;
    payload: {
        open: boolean
    }
}

export const toggleSidebarProps = (payload: IToggleSidebarAction['payload']): IToggleSidebarAction => {
    return {
        type: TOGGLE_SIDEBAR,
        payload: payload
    }
};

export type SidebarActions = IToggleSidebarAction;