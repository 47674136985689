import IVendor from "../../dtos/IVendor"

export const LOAD_VENDORS = "LOAD_VENDORS"
export const LOAD_VENDORS_SAGA = "LOAD_VENDORS_SAGA"

export const LOAD_VENDOR = "LOAD_VENDOR"
export const LOAD_VENDOR_SAGA = "LOAD_VENDOR_SAGA"

export const ADD_VENDOR = "ADD_VENDOR"
export const ADD_VENDOR_SAGA = "ADD_VENDOR_SAGA"

export const UPDATE_VENDOR = "UPDATE_VENDOR"
export const UPDATE_VENDOR_SAGA = "UPDATE_VENDOR_SAGA"

export const UPDATE_IS_LOADING_VENDORS = "UPDATE_IS_LOADING_VENDORS";


export interface ILoadVendorsActionSaga {
    readonly type: typeof LOAD_VENDORS_SAGA;
    page: number;
    size: number;
    sort: string;
    name: string;
}
export const loadVendorsPropsSaga = (page = 0, size = 10, sort = '', name = ''): ILoadVendorsActionSaga => {
    return {
        type: LOAD_VENDORS_SAGA,
        page: page,
        size: size,
        sort: sort,
        name: name
    }
};

export interface ILoadVendorActionSaga {
    readonly type: typeof LOAD_VENDOR_SAGA;
    vendorId: number;
}
export const loadVendorPropsSaga = (vendorId: number): ILoadVendorActionSaga => {
    return {
        type: LOAD_VENDOR_SAGA,
        vendorId: vendorId
    }
};

export interface IAddVendorActionSaga {
    readonly type: typeof ADD_VENDOR_SAGA;
    vendor: IVendor
}
export const addVendorPropsSaga = (vendor: IVendor): IAddVendorActionSaga => {
    return {
        type: ADD_VENDOR_SAGA,
        vendor: vendor
    }
};

export interface IUpdateVendorActionSaga {
    readonly type: typeof UPDATE_VENDOR_SAGA;
    vendorId: number
    vendor: IVendor
}
export const updateVendorPropsSaga = (vendorId: number, vendor: IVendor): IUpdateVendorActionSaga => {
    return {
        type: UPDATE_VENDOR_SAGA,
        vendorId: vendorId,
        vendor: vendor
    }
};

