import { all } from "redux-saga/effects";
import watchGlobal from "./global";
import watchDashboard from "./dashboard";
import watchSidebar from './sidebar';
import watchInventory from './inventory';
import watchBranches from './branch'
import watchHeader from './header';
import watchUserGroup from './user-group';
import watchUsers from "./user";
import watchProducts from './products';
import watchAssets from "./asset"
import watchNotifications from "./notifications"
import watchCategories from "./categories"
import watchManualStorages from "./manual-storage"
import watchProductTransactions from "./product_transactions"
import watchProductStorages from "./product_storages"
import watchVendors from "./vendors"
import watchUnits from "./units"
import watchOperators from "./operators"
import watchStorages from "./storage"
import watchMyAccount from "./my_account"
import watchSMOPreview from "./smart_ordering_preview"
import watchSmartOrdering from "./smart-ordering"
import watchQuotation from "./quotation"
import watchOrders from "./orders"
export default function* rootSaga() {
  yield all([
    ...watchGlobal,
    ...watchDashboard,
    ...watchSidebar,
    ...watchInventory,
    ...watchHeader,
    ...watchBranches,
    ...watchUserGroup,
    ...watchUsers,
    ...watchProducts,
    ...watchAssets,
    ...watchNotifications,
    ...watchCategories,
    ...watchManualStorages,
    ...watchProductTransactions,
    ...watchProductStorages,
    ...watchVendors,
    ...watchUnits,
    ...watchOperators,
    ...watchStorages,
    ...watchMyAccount,
    ...watchSMOPreview,
    ...watchSmartOrdering,
    ...watchQuotation,
    ...watchOrders,
  ])
}

