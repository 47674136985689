import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { MDBNavbar, MDBNavbarBrand, MDBIcon, MDBNavbarNav, MDBNavItem, MDBDropdownToggle, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBRow, MDBCol, MDBBadge } from "mdbreact";
import './styles/index.scss'
import { AppState } from "../../../store";
import DClogo from "../../../assets/img/meddi.svg";
import branchIcon from "../../../assets/img/branch-icon.png";
import { toggleSidebarPropsSaga } from '../../../sagas/sidebar/types';
import { loadOrderProductsSaga } from "../../../sagas/smart-ordering/types";
import { Link, NavLink } from 'react-router-dom';
import IBranch from "../../../dtos/IBranch";
import CardNotificationTray from './cardNotificationTray';
import { loadNotificationsPropsSaga } from '../../../sagas/notifications/types';
import MultiSelect from "../../../components/multiSelect"
import { updateSelectedBranchPropsSaga } from '../../../sagas/global/types';
import { partialText } from '../../../helpers';

interface IHeaderProps { }

const Header = (props: IHeaderProps) => {
    const dispatch = useDispatch();
    const my_branches: IBranch[] = useSelector((state: AppState) => state.globalReducer.authUser.my_branches);
    const notificationsReducer = useSelector((state: AppState) => state.notificationsReducer)
    const cartReducer = useSelector((state: AppState) => state.smartOrderReducer)
    const [isOpen, toggleModal] = useState(false)
    const [isNotificationsOpen, toggleNotifications] = useState(false)
    // toggle off notifications on click outside
    const outsideToggle = function (event: any) {
        let target: any = event.target;
        if (target.closest('.notification-popover') == null && isNotificationsOpen) {
            toggleNotifications(false);
        }
    }
    useEffect(() => {
        document.addEventListener('click', outsideToggle);
        // clean event listener
        return () => {
            document.removeEventListener('click', outsideToggle);
        }
    }, [isNotificationsOpen]);

    useEffect(() => {
        dispatch(loadNotificationsPropsSaga(false, 0, 10));
        dispatch(loadOrderProductsSaga());
        const currBranch = localStorage.getItem('branch_id')
        if (currBranch) {
            dispatch(updateSelectedBranchPropsSaga({ selectedBranch: currBranch.toString() }))
        } else {
            toggleModal(true)
        }
    }, []);

    const setBranch = (branch: any) => {
        localStorage.setItem('branch_id', branch.id);
        localStorage.setItem('branch', branch.name);
        toggleModal(false);
        window.location.reload();
    }

    const renderChooseBranchModule = () => {
        return <MDBContainer className="branches-modal">
            <MDBModal size="lg"
                isOpen={isOpen} toggle={() => toggleModal(true)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalHeader toggle={() => toggleModal(false)}>Please Select a Branch to proceed</MDBModalHeader>
                <p className="modal-subtitle" >Please select the branch you want to view your data in</p>
                <MDBModalBody className="p-3">
                    {my_branches.length > 0 ? < div className="d-flex justify-content-center flex-wrap" >
                        {my_branches.map((b: any, index: any) => {
                            return <div onClick={() => setBranch(b)} className="branch-container" key={index} >
                                <div className="branch-image" >
                                    <img src={branchIcon} />
                                </div>
                                <p className="branch-title" title={b.name}>{partialText(b.name, 30)}</p>
                            </div>
                        })}
                    </div> : <div>
                        No branches available
                    </div>}
                </MDBModalBody>
            </MDBModal>
        </MDBContainer >
    }

    return (
        <>
            <MDBNavbar className="border-radius-tl border-radius-tr border-radius-bl border-radius-br
            white box-shadow-none w-100 h-70 mb-0 z-index-1 mb-4" expand="md">
                <MDBIcon icon="bars" className="mobile-header-bars cursor lightGrey-text fa-1_5x mr-2" onClick={() => { dispatch(toggleSidebarPropsSaga()) }} />
                <MDBNavbarBrand className={`d-md-block d-none`}>
                    <NavLink to={"/catalog"}>
                        <img className="dclogo" src={DClogo} />
                    </NavLink>
                </MDBNavbarBrand>
                <MDBNavbarNav className='flex-row' right>
                    <MDBNavItem>
                        <MultiSelect
                            label=""
                            required={false}
                            name="storage"
                            placeholder="Select Branch"
                            options={my_branches.map((option, i) => {
                                return { label: option.name, value: option }
                            })}
                            className="h-auto"
                            onChange={(e: any) => setBranch(e.value)}
                            defaultValue={localStorage.getItem("branch_id") && { label: localStorage.getItem("branch"), value: localStorage.getItem("branch_id") }}
                            multi={false}
                            clearable={false}
                            closeMenuOnSelect={true}
                        />
                    </MDBNavItem>
                    {/* <MDBNavItem className="d-none d-md-block pr-10">
                        <Link to={"/cart"} className="d-none d-md-block">
                            <div className='pos-rel'>
                                <MDBIcon icon="shopping-cart" className='cart-icon cursor lightGrey-text' />
                                {cartReducer.totalCountOrderProducts > 0 && <MDBBadge color='danger' notification pill>
                                    {cartReducer.totalCountOrderProducts}
                                </MDBBadge>}
                            </div>
                        </Link>
                    </MDBNavItem> */}
                    {/* <MDBNavItem className={'d-none d-md-block'}>
                        <MDBDropdownToggle nav>
                            <div className="transparent cursor border-0 lightGrey-text" onClick={() => toggleNotifications(!isNotificationsOpen)}>
                                <div className='pos-rel'>
                                    <MDBIcon icon="bell" />
                                    {notificationsReducer.notifications.length > 0 && <MDBBadge color='danger' notification pill>
                                        {notificationsReducer.notifications.length}
                                    </MDBBadge>}
                                </div>
                                {
                                    (notificationsReducer.notifications.length > 0) && <span className="notification-dot"></span>
                                }
                            </div>
                        </MDBDropdownToggle>
                        <div className={`notification-popover px-5 py-3 ${isNotificationsOpen ? '' : 'd-none'}`}>
                            {
                                _.take(notificationsReducer.notifications, 4).map((notification, i) => {
                                    return (
                                        <CardNotificationTray key={i} title={notification.title}
                                            description={notification.short_description}
                                            date={notification.notification_date} />
                                    )
                                })
                            }
                            <MDBRow className="mt-4">
                                <MDBCol>
                                    <a href={`/notifications`}
                                        target="_blank" className="view-all-link">
                                        view all</a>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBNavItem>
                    <MDBNavItem className={'d-block d-md-none'}>
                        <MDBDropdownToggle nav>
                            <div className="transparent cursor border-0 lightGrey-text" onClick={() => window.location.href = '/notifications'}>
                                <MDBIcon icon="bell" />
                                {
                                    (notificationsReducer.notifications.length > 0) && <span className="notification-dot"></span>
                                }
                            </div>
                        </MDBDropdownToggle>
                    </MDBNavItem> */}
                </MDBNavbarNav>
            </MDBNavbar >
            {/* <div className="header-banner w-100 mb-2">
                <img src={HeaderBanner} />
            </div> */}
            {renderChooseBranchModule()}
        </>
    )
}

export default Header;