import {IUpdateIsLoadingInventoryAction, InventoryActions} from './types'
import {LOAD_INVENTORY_FILTERS, LOAD_INVENTORY_PRODUCTS, UPDATE_IS_LOADING_INVENTORY} from "../../sagas/inventory/types";
import { IDropdown } from '../../dtos/global';
import IProduct from '../../dtos/IProduct';

interface IInventoryState {
    filters: {
        categoryList: IDropdown[],
        storageList: IDropdown[],
        statusList: IDropdown[],
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    },
    products: IProduct[],
    totalCountPages: number,
    loadingInventory: IUpdateIsLoadingInventoryAction['payload'],
}

const InventoryState: IInventoryState = {
    filters: {
        categoryList: [],
        storageList: [],
        statusList: [],
        sortCol: '',
        sortOrder: 'ascending'
    },
    products: [],
    totalCountPages: 0,
    loadingInventory: {
        isLoadingInventory: false,
        errorMessage: undefined
    }
};

const inventoryReducer = (state = InventoryState, action: InventoryActions): IInventoryState => {
    switch (action.type) {
        case LOAD_INVENTORY_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        case LOAD_INVENTORY_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_INVENTORY:
            return {
                ...state,
                loadingInventory: action.payload,
            };
        default:
            return state;
    }
};

export default inventoryReducer;
