import React from 'react'
import "./styles.scss"
interface IIconContainerProps {
    className?:any
    children: any
    onClick?: any,
    onHover?: any
}
export default function IconContainer(props: IIconContainerProps) {
    return (
        <div className={`icon-container ${props.className&&props.className}`}
        onClick={()=>props.onClick&&props.onClick()}
        onMouseOver={()=>props.onHover&&props.onHover()}
        onMouseLeave={()=>props.onHover&&props.onHover()}
        onMouseOverCapture={()=>props.onHover&&props.onHover()}
        
        >
            {props.children}
        </div>
    )
}
