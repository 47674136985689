import {
    LOAD_ORDER_PRODUCTS, UPDATE_ORDER, ADD_ORDER_PRODUCT,
    LOAD_ORDERS_HISTORY, LOAD_ORDER_DETAILS,
    UPDATE_IS_LOADING_ORDER_PRODUCTS, UPDATE_IS_LOADING_ORDERS_HISTORY,
    LOAD_FULFILLMENTS_COUNT,
    DELETE_CART_ITEM
} from "../../sagas/smart-ordering/types";

export interface ILoadOrderProductsAction {
    readonly type: typeof LOAD_ORDER_PRODUCTS;
    payload: {
        cartData: any[],
        totalCountCartItems: number
    }
}
export const loadOrderProductsProps = (payload: ILoadOrderProductsAction['payload']): ILoadOrderProductsAction => {
    return {
        type: LOAD_ORDER_PRODUCTS,
        payload: payload
    }
};

export interface IUpdateCartItemAction {
    readonly type: typeof UPDATE_ORDER;
    payload: {
        // cartUpdated: boolean,
        quantity: number,
        product: any,
    }
}
export const updateCartItemProps = (payload: IUpdateCartItemAction['payload']): IUpdateCartItemAction => {
    return {
        type: UPDATE_ORDER,
        payload: payload
    }
};

export interface IDeleteCartItemAction {
    readonly type: typeof DELETE_CART_ITEM;
    payload: {
        itemDeleted: boolean,
        product_id: number,
    }
}

export const deleteCartItemProps = (payload: IDeleteCartItemAction['payload']): IDeleteCartItemAction => {
    return {
        type: DELETE_CART_ITEM,
        payload: payload
    }
}

export interface IAddOrderProductAction {
    readonly type: typeof ADD_ORDER_PRODUCT;
    payload: {
        productAdded: boolean,
    }
}
export const addOrderProductProps = (payload: IAddOrderProductAction['payload']): IAddOrderProductAction => {
    return {
        type: ADD_ORDER_PRODUCT,
        payload: payload
    }
};

export interface ILoadOrdersHistoryAction {
    readonly type: typeof LOAD_ORDERS_HISTORY;
    payload: {
        ordersHistory: any[],
        totalCountOrders: number
    }
}
export const loadOrdersHistoryProps = (payload: ILoadOrdersHistoryAction['payload']): ILoadOrdersHistoryAction => {
    return {
        type: LOAD_ORDERS_HISTORY,
        payload: payload
    }
};

export interface ILoadOrderDetailsAction {
    readonly type: typeof LOAD_ORDER_DETAILS;
    payload: {
        orderDetails: any
    }
}
export const loadOrderDetailsProps = (payload: ILoadOrderDetailsAction['payload']): ILoadOrderDetailsAction => {
    return {
        type: LOAD_ORDER_DETAILS,
        payload: payload
    }
};

export interface ILoadFulfillmentsCountAction {
    readonly type: typeof LOAD_FULFILLMENTS_COUNT;
    payload: {
        fulfillmentsCount: any
    }
}
export const loadFulfillmentsCountProps = (payload: ILoadFulfillmentsCountAction['payload']): ILoadFulfillmentsCountAction => {
    return {
        type: LOAD_FULFILLMENTS_COUNT,
        payload: payload
    }
};
export interface IUpdateIsLoadingOrderProductsAction {
    readonly type: typeof UPDATE_IS_LOADING_ORDER_PRODUCTS;
    payload: {
        isLoadingOrderProducts: boolean,
        isLoadingItemQty: boolean,
        errorMessage: string | undefined
    }
}
export const updateIsLoadingOrderProductsProps = (payload: IUpdateIsLoadingOrderProductsAction['payload']): IUpdateIsLoadingOrderProductsAction => {
    return {
        type: UPDATE_IS_LOADING_ORDER_PRODUCTS,
        payload: payload
    }
};
export interface IUpdateIsLoadingOrdersHistoryAction {
    readonly type: typeof UPDATE_IS_LOADING_ORDERS_HISTORY;
    payload: {
        isLoadingOrdersHistory: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingOrdersHistoryProps = (payload: IUpdateIsLoadingOrdersHistoryAction['payload']): IUpdateIsLoadingOrdersHistoryAction => {
    return {
        type: UPDATE_IS_LOADING_ORDERS_HISTORY,
        payload: payload
    }
};
export type SmartOrderingActions = ILoadOrderProductsAction | IUpdateCartItemAction | IDeleteCartItemAction | IAddOrderProductAction | ILoadOrdersHistoryAction | ILoadOrderDetailsAction | IUpdateIsLoadingOrderProductsAction | IUpdateIsLoadingOrdersHistoryAction | ILoadFulfillmentsCountAction