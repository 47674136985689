import { put, takeLatest, call } from 'redux-saga/effects';
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_NOTIFICATIONS_SAGA, ILoadNotificationsActionSaga,
    DISMISS_NOTIFICATION_SAGA, IDismissNotificationActionSaga,
    UNDISMISS_NOTIFICATION_SAGA, IUndismissNotificationActionSaga, PUSH_INCOMING_NOTIFICATION_SAGA, IPushIncomingNotificationActionSaga
} from "./types";
import { fetchNotifications, dismissNotification, undismissNotification, } from "../../api/notifications";
import { loadNotificationsProps, dismissNotificationProps, undismissNotificationProps, updateIsLoadingNotificationsProps, pushIncomingNotificationProps } from "../../reducers/notifications/types";
import INotification from "../../dtos/INotification";

function* loadNotifications(action: ILoadNotificationsActionSaga) {
    try {
        yield put(updateIsLoadingNotificationsProps({ isLoadingNotifications: true, errorMessage: undefined }));
        const notificationsData: {
            notifications: INotification[],
            totalNotificationsCount: number
        } = yield call(fetchNotifications, action.isDismissed, action.page, action.size);
        yield put(loadNotificationsProps({
            isDismissed: action.isDismissed,
            notifications: notificationsData.notifications,
            totalNotificationsCount: notificationsData.totalNotificationsCount
        }))
        yield put(updateIsLoadingNotificationsProps({ isLoadingNotifications: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingNotificationsProps({ isLoadingNotifications: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadNotifications() {
    yield takeLatest(LOAD_NOTIFICATIONS_SAGA, loadNotifications)
}

function* dismissNotificationData(action: IDismissNotificationActionSaga) {
    const notificationDismissed: boolean = yield call(dismissNotification, action.uuid);
    yield put(dismissNotificationProps({ notificationId: action.uuid }));
}
export function* watchDismissNotification() {
    yield takeLatest(DISMISS_NOTIFICATION_SAGA, dismissNotificationData)
}

function* undismissNotificationData(action: IUndismissNotificationActionSaga) {
    const notificationUndismissed: boolean = yield call(undismissNotification, action.uuid);
    yield put(undismissNotificationProps({ notificationId: action.uuid }));
}
export function* watchUndismissNotification() {
    yield takeLatest(UNDISMISS_NOTIFICATION_SAGA, undismissNotificationData)
}

function* pushIcomingNotificationData(action: IPushIncomingNotificationActionSaga) {
    yield put(pushIncomingNotificationProps({ notification: action.notification }));
}
export function* watchPushIncomingNotification() {
    yield takeLatest(PUSH_INCOMING_NOTIFICATION_SAGA, pushIcomingNotificationData)
}

export default [
    watchLoadNotifications(),
    watchDismissNotification(),
    watchUndismissNotification(),
    watchPushIncomingNotification(),
]