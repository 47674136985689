export const LOAD_CATEGORIES_SAGA = 'LOAD_CATEGORIES_SAGA';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const UPDATE_IS_LOADING_CATEGORIES = 'UPDATE_IS_LOADING_CATEGORIES';
export interface ILoadCategoriesActionSaga {
    readonly type: typeof LOAD_CATEGORIES_SAGA;
    filters: {
        page: number;
        size: number;
        sort: string;
        firstLoad: boolean;
    };
}

export const loadCategoriesPropsSaga = (filters: ILoadCategoriesActionSaga['filters']): ILoadCategoriesActionSaga => {
    return {
        type: LOAD_CATEGORIES_SAGA,
        filters: {
            page: filters.page,
            size: filters.size,
            sort: filters.sort,
            firstLoad: filters.firstLoad,
        }
    }
};