export const LOAD_ORDER_PRODUCTS = "LOAD_ORDER_PRODUCTS"
export const LOAD_ORDER_PRODUCTS_SAGA = "LOAD_ORDER_PRODUCTS_SAGA"

export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SAGA = "UPDATE_ORDER_SAGA"

export const ADD_ORDER_PRODUCT = "ADD_ORDER_PRODUCT"
export const ADD_ORDER_PRODUCT_SAGA = "ADD_ORDER_PRODUCT_SAGA"

export const DELETE_CART_ITEM = "DELETE_CART_ITEM"
export const DELETE_CART_ITEM_SAGA = "DELETE_CART_ITEM_SAGA"

export const LOAD_ORDERS_HISTORY = "LOAD_ORDERS_HISTORY"
export const LOAD_ORDERS_HISTORY_SAGA = "LOAD_ORDERS_HISTORY_SAGA"

export const LOAD_ORDER_DETAILS = "LOAD_ORDER_DETAILS"
export const LOAD_ORDER_DETAILS_SAGA = "LOAD_ORDER_DETAILS_SAGA"

export const LOAD_FULFILLMENTS_COUNT = "LOAD_FULFILLMENTS_COUNT"
export const LOAD_FULFILLMENTS_COUNT_SAGA = "LOAD_FULFILLMENTS_COUNT_SAGA"

export const UPDATE_IS_LOADING_ORDER_PRODUCTS = "UPDATE_IS_LOADING_ORDER_PRODUCTS"
export const UPDATE_IS_LOADING_ORDERS_HISTORY = "UPDATE_IS_LOADING_ORDERS_HISTORY"

export interface ILoadOrderProductsActionSaga {
    readonly type: typeof LOAD_ORDER_PRODUCTS_SAGA;
    cart_type: string,
    page: number,
    size: number,
    sort?: string,
}
export const loadOrderProductsSaga = (cart_type: string, page = 0, size = 10, sort = ''): ILoadOrderProductsActionSaga => {
    return {
        type: LOAD_ORDER_PRODUCTS_SAGA,
        cart_type: cart_type,
        page: page,
        size: size,
        sort: sort,
    }
};

export interface IUpdateCartItemActionSaga {
    readonly type: typeof UPDATE_ORDER_SAGA;
    cart_id: number;
    productId: number,
    newQuantity: number,
    product: any,
}
export const updateCartItemSaga = (cart_id: number, productId = 0, newQuantity: number, product: any): IUpdateCartItemActionSaga => {
    return {
        type: UPDATE_ORDER_SAGA,
        cart_id: cart_id,
        productId: productId,
        newQuantity: newQuantity,
        product: product,
    }
};


export interface IDeleteCartItemActionSaga {
    readonly type: typeof DELETE_CART_ITEM_SAGA;
    cart_id: number;
    product_id: number;
}

export const deleteCartItemSaga = (cart_id: number, product_id: number): IDeleteCartItemActionSaga => {
    return {
        type: DELETE_CART_ITEM_SAGA,
        cart_id: cart_id,
        product_id: product_id
    }
}



export interface IAddOrderProductActionSaga {
    readonly type: typeof ADD_ORDER_PRODUCT_SAGA;
    product: any,
}
export const addOrderProductSaga = (product = null): IAddOrderProductActionSaga => {
    return {
        type: ADD_ORDER_PRODUCT_SAGA,
        product: product,
    }
};

export interface ILoadOrdersHistoryActionSaga {
    readonly type: typeof LOAD_ORDERS_HISTORY_SAGA;
    page: number,
    size: number,
    sort: string,
    status: string
}
export const loadOrdersHistorySaga = (page = 0, size = 10, sort = '', status = ''): ILoadOrdersHistoryActionSaga => {
    return {
        type: LOAD_ORDERS_HISTORY_SAGA,
        page: page,
        size: size,
        sort: sort,
        status: status
    }
};
export interface ILoadOrderDetailsActionSaga {
    readonly type: typeof LOAD_ORDER_DETAILS_SAGA;
    orderId: number,
}
export const loadOrderDetailsSaga = (orderId = 0): ILoadOrderDetailsActionSaga => {
    return {
        type: LOAD_ORDER_DETAILS_SAGA,
        orderId: orderId,
    }
};

export interface ILoadFulfillmentsCountActionSaga {
    readonly type: typeof LOAD_FULFILLMENTS_COUNT_SAGA;
    currentDate: string
}
export const loadFulfillmentsCountSaga = (currentDate: string): ILoadFulfillmentsCountActionSaga => {
    return {
        type: LOAD_FULFILLMENTS_COUNT_SAGA,
        currentDate: currentDate
    }
};