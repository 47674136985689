import { component } from "../../containers/user-group/namings";
import IUserGroup from "../../dtos/IUserGroup";

export const LOAD_USER_GROUP_SAGA = 'LOAD_USER_GROUP_SAGA';
export const LOAD_USER_GROUP = 'LOAD_USER_GROUP';
export const UPDATE_IS_LOADING_USER_GROUP = 'UPDATE_IS_LOADING_USER_GROUP';
export interface ILoadUserGroupActionSaga {
    readonly type: typeof LOAD_USER_GROUP_SAGA;
    page: number,
    size: number
}

export const loadUserGroupPropsSaga = (page: number = 0, size = 10): ILoadUserGroupActionSaga => {
    return {
        type: LOAD_USER_GROUP_SAGA,
        page: page,
        size: size
    }
};

export const DELETE_USER_GROUP_SAGA = 'DELETE_USER_GROUP_SAGA';
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export interface IDeleteUserGroupActionSaga {
    readonly type: typeof DELETE_USER_GROUP_SAGA;
    id: string
}

export const deleteUserGroupPropsSaga = (id: string): IDeleteUserGroupActionSaga => {
    return {
        type: DELETE_USER_GROUP_SAGA,
        id: id
    }
};

export const CREATE_UPDATE_USER_GROUP_SAGA = 'CREATE_UPDATE_USER_GROUP_SAGA';
export const CREATE_UPDATE_USER_GROUP = 'CREATE_UPDATE_USER_GROUP';
export interface ICreateUpdateUserGroupActionSaga {
    readonly type: typeof CREATE_UPDATE_USER_GROUP_SAGA;
    userGroupData: IUserGroup
}

export const createUpdateUserGroupPropsSaga = (userGroupData: IUserGroup): ICreateUpdateUserGroupActionSaga => {
    return {
        type: CREATE_UPDATE_USER_GROUP_SAGA,
        userGroupData: userGroupData
    }
};

export const LOAD_USER_GROUP_PERMISSIONS_SAGA = 'LOAD_USER_GROUP_PERMISSIONS_SAGA';
export const LOAD_USER_GROUP_PERMISSIONS = 'LOAD_USER_GROUP_PERMISSIONS';
export interface ILoadUserGroupPermissionsActionSaga {
    readonly type: typeof LOAD_USER_GROUP_PERMISSIONS_SAGA;
    component:component;
    
}

export const loadUserGroupPermissionsPropsSaga = (componentName:component): ILoadUserGroupPermissionsActionSaga => {
    return {
        type: LOAD_USER_GROUP_PERMISSIONS_SAGA,
        component:componentName,
    }
};
