import React, { useEffect, useState, useMemo } from 'react';
import './styles/styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import { loadBranchPropsSaga, updateBranchPropsSaga, loadCountriesPropsSaga, loadCitiesPropsSaga } from "../../sagas/branch/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBBtn, MDBCardText, MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import IUser from "../../dtos/IUser"
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import moment from "moment";
import { fetchOrderDeliveryDate, fetchOrderItems } from '../../api/orders';

interface IOrderDetailsProps {
    order: any,
}

export default function OrderDetails(props: IOrderDetailsProps) {
    const authReducer = useSelector((state: AppState) => state.globalReducer.authUser);
    const [items, setItems] = useState<any>([]);
    const [itemsCount, setItemsCount] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [wait, setWait] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState<any>(null);
    useEffect(() => {
        setWait(true);
        fetchOrderItems(props.order.order_id, page - 1, 10).then((res: any) => {
            setWait(false);
            setItems(res.data);
            setItemsCount(res.totalOrderItemsCount);
        })
    }, [props.order.order_id, page])
    useEffect(() => {
        fetchOrderDeliveryDate(props.order.order_id).then((res: any) => {
            setDeliveryDate(res);
        })

    }, [props.order.order_id])
    // users short table
    const headers: any = [
        { name: "ID", width: 2 },
        { name: "Name", width: 4 },
        { name: "Quantity", width: 2 },
        { name: "Price", width: 3 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns] = useState<IHeaderColumn[]>(headers);
    const buildRows = (orderItems: any[]): IRow[] => {
        return orderItems.map((s: any, i) => {
            return {
                value: s,
                props: [
                    {
                        edit: (rowValue: any) => {
                            return (<div className="cursor offblue-text table-content">#{rowValue.product.product_id}</div >);
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (<div className="cursor offblue-text table-content">{rowValue.product.name}</div >);
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (<div className="table-content">{rowValue.quantity}</div >);
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (<div className="table-content">{rowValue.price ? `${rowValue.price?.value} ${rowValue.price?.currency?.name}` : '0'}</div >);
                        }
                    }]
            }
        });
    };
    const rows = useMemo(() => buildRows(items), [items]);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        setPage(activePage);
    }
    return (
        <>
            <MDBRow className="text-left">
                <MDBCol className="mr-2">
                    <label className="font-weight-bold pl-2 pt-3">Creation Date</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={moment(props.order.ordered_at).format("MMMM DD, yyyy")}
                        disabled
                    />
                </MDBCol>
                <MDBCol className="">
                    <label className="font-weight-bold pl-2 pt-3">Delivery Date</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        key={deliveryDate?.next_due}
                        defaultValue={moment(deliveryDate?.next_due).format("MMMM DD, yyyy")}
                        disabled
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow className="text-left">
                <MDBCol className="mr-2">
                    <label className="font-weight-bold pl-2 pt-3">Total</label>
                    <input
                        className="form-control input-rounded pt-4 pb-4 pl-3"
                        defaultValue={`${props.order.total} EGP`}
                        disabled
                    />
                </MDBCol>
                <MDBCol className="mr-2">
                </MDBCol>
            </MDBRow>

            {/* users */}
            <Loader isLoading={wait}>
                <MDBRow start className="mt-5 justify-content-center w-100">
                    <MDBCol size="12" className="text-center transaction-table">
                        {items.length > 0 ? <DataTable
                            headerColumns={headerColumns}
                            rows={rows}
                            data={items}
                            headerOnClick={() => { }}
                            totalPages={itemsCount / 10}
                            activePage={page}
                            onPageChange={handlePaginationChange}
                        /> :
                            <div className="mt-5">No Products Found.</div>

                        }
                    </MDBCol>
                </MDBRow>
            </Loader>
        </>
    )
}
