import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history'
import { useClearCache } from "react-clear-cache";
import i18n from './i18n';
import { useDispatch } from 'react-redux';
//bootstrap styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './assets/scss/mdb-free.scss';
//custom styles
import './assets/styles/app.scss'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DashboardPage from "./pages/dashboard";
import InventoryPage from "./pages/inventory";
import UserGroupPage from "./pages/user-group";
import BranchesPage from "./pages/branches"
import UsersPage from "./pages/users"
import AssetsPage from "./pages/assets";
import VendorsPage from "./pages/vendors";
import StoragesPage from "./pages/storages"
import NotificationsPage from "./pages/notifications"
import ReportsPage from "./pages/reports"
import Header from "./containers/layout/header";
import AuthRoute from './containers/authRoute'
import SideBar from './containers/layout/sidebar';
import { useSelector } from 'react-redux';
import { AppState } from './store';
import { toggleSidebarPropsSaga } from './sagas/sidebar/types';
import Modal from './components/modal';
import ProductsPage from './pages/products';
import Can from './containers/can';
import View from './containers/view';
import ListCategoriesPage from './pages/categories';
import ProductTransactionsPage from './pages/product_transactions';
import ProductStoragesPage from './pages/product_storages';
import MyAccountPage from './pages/my_account';
import constanst from "./constants";

//websocket
import { stompClient, connect, disconnect } from './websocket';
import { updateWebsocketClientPropsSaga } from './sagas/global/types';
import { pushIncomingNotificationPropsSaga } from './sagas/notifications/types';
import Cart from './containers/Cart';
import CartContainer from './containers/Cart/CartContainer';
import QuotationList from './containers/QuotationList';
import OrdersPage from "./pages/orders";
// import { datadogRum } from '@datadog/browser-rum';

interface IAppProps {
    history: History;
}

const App = (props: IAppProps) => {
    const dispatch = useDispatch();
    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    useEffect(() => {
        //datadog initialize
        // datadogRum.init({
        //     applicationId: process.env.REACT_APP_DATADOG_APP_ID || "",
        //     clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
        //     site: 'datadoghq.eu',
        //     service: 'dc-plus',
        //     version: '1.0.0',
        //     env: process.env.REACT_APP_ENVIRONMENT,
        //     sessionSampleRate: 100,
        //     sessionReplaySampleRate: 20,
        //     trackUserInteractions: true,
        //     trackResources: true,
        //     trackLongTasks: true,
        //     defaultPrivacyLevel: 'mask-user-input'
        // });
        // datadogRum.startSessionReplayRecording();
    }, []);

    useEffect(() => {
        if (!isLatestVersion)
            emptyCacheStorage();
    }, [])

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    }

    changeLanguage('en');

    // connect to websocket
    useEffect(() => {
        connect((client) => {
            dispatch(updateWebsocketClientPropsSaga({ websocketClient: client }));
        });
    }, []);

    // toggle sidebar
    const sidebarReducer = useSelector((state: AppState) => state.sidebarReducer);
    const toggleSideBar = () => {
        if (sidebarReducer.open && window.innerWidth <= 767) // only when window width sm or smaller to close menu when click out
            dispatch(toggleSidebarPropsSaga())
    }
    // global reducer access
    const globalReducer = useSelector((state: AppState) => state.globalReducer);
    // listen to notifications
    useEffect(() => {
        if (globalReducer.websocketClient !== undefined) {
            globalReducer.websocketClient.unsubscribe('notification-1');
            globalReducer.websocketClient.subscribe('/topic/notifications', function (data: any) {
                let notification = JSON.parse(data.body);
                if (globalReducer.authUser.user_id.toString() === notification.userId.toString()) {
                    dispatch(pushIncomingNotificationPropsSaga(notification.notification));
                    toast.success(notification.notification.short_description);
                }
            }, { id: 'notification-1' });
        }
    }, [globalReducer.websocketClient, globalReducer.authUser]);
    return (
        <ConnectedRouter history={props.history}>
            <AuthRoute>
                <MDBContainer className="p-4 h-100" fluid>
                    <div className="d-flex flex-column h-100">
                        <MDBRow className="m-0 flex-grow-1">
                            <MDBCol md={sidebarReducer.open ? '2' : '1'}
                                className={`p-0 d-flex flex-column
                             sidebar ${sidebarReducer.open ? 'pr-13-rem' : 'pr-8-rem sidebar-closed'}`}>
                                <SideBar />
                            </MDBCol>
                            <MDBCol
                                className={`p-0`} onClick={() => toggleSideBar()}>
                                <Header />
                                <Switch>
                                    {/* <Route path="/inventory" >
                                        <View view="inventory">
                                            <Can check="PRODUCT_VIEW">
                                                <InventoryPage />
                                            </Can>
                                        </View>
                                    </Route> */}
                                    <Route path="/user-group">
                                        <View view="user-group">
                                            <Can check="USERGROUP_VIEW">
                                                <UserGroupPage />
                                            </Can>
                                        </View>
                                    </Route>
                                    <Route path="/branches">
                                        <View view="branches">
                                            <Can check="BRANCH_VIEW">
                                                <BranchesPage />
                                            </Can>
                                        </View>
                                    </Route>
                                    <Route path="/users">
                                        <View view="users">
                                            <Can check="USER_VIEW">
                                                <UsersPage />
                                            </Can>
                                        </View>
                                    </Route>
                                    {/* <Route path="/categories">
                                        <View view="categories">
                                            <ListCategoriesPage />
                                        </View>
                                    </Route> */}
                                    <Route path={"/catalog"}>
                                        <View view="products">
                                            <Can check="PRODUCT_VIEW">
                                                <ProductsPage />
                                            </Can>
                                        </View>
                                    </Route>
                                    <Route path="/product/:productId/transactions">
                                        <ProductTransactionsPage />
                                    </Route>
                                    <Route path="/product/:productId/storages">
                                        <ProductStoragesPage />
                                    </Route>
                                    <Route path="/assets">
                                        {/* <View view="assets"> */}
                                        {/* <Can check="ASSET_VIEW"> */}
                                        {/* <AssetsPage /> */}
                                        {/* </Can> */}
                                        {/* </View> */}
                                    </Route>
                                    {/* <Route path="/vendors"> */}
                                    {/* <View view="vendors"> */}
                                    {/* <Can check="ASSET_VIEW"> */}
                                    {/* <VendorsPage /> */}
                                    {/* </Can> */}
                                    {/* </View> */}
                                    {/* </Route> */}
                                    {/* <Route path="/storage"> */}
                                    {/* <View view="storage"> */}
                                    {/* <Can check="ASSET_VIEW"> */}
                                    {/* <StoragesPage /> */}
                                    {/* </Can> */}
                                    {/* </View> */}
                                    {/* </Route> */}
                                    <Route path="/cart">
                                        <View view="cart">
                                            <Can check="CART_VIEW">
                                                <CartContainer />
                                            </Can>
                                        </View>
                                    </Route>
                                    <Route path="/orders">
                                        <View view="orders">
                                            <Can check="VIEW_ACCOUNT_SETTINGS">
                                                <OrdersPage />
                                            </Can>
                                        </View>
                                    </Route>
                                    <Route path="/quotation-list">
                                        <View view='quotation-list'>
                                            <Can check="QUOTATION_VIEW">
                                                <QuotationList />
                                            </Can>
                                        </View>
                                    </Route>
                                    {/* <Route path="/reports"> */}
                                    {/* <View view="reports"> */}
                                    {/* <Can check="ASSET_VIEW"> */}
                                    {/* <ReportsPage /> */}
                                    {/* </Can> */}
                                    {/* </View> */}
                                    {/* </Route> */}
                                    <Route path="/notifications">
                                        {/* <Can check="ASSET_VIEW"> */}
                                        <NotificationsPage />
                                        {/* </Can> */}
                                    </Route>
                                    <Route path="/my-account">
                                        <MyAccountPage />
                                    </Route>
                                    {/* <Route exact path="/">
                                        {
                                            globalReducer.selectedBranch !== '' && <DashboardPage />
                                        }
                                    </Route> */}
                                </Switch>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="m-0" style={{ zIndex: -100 }}>
                            <MDBCol md={sidebarReducer.open ? '2' : '1'} className={`p-0 d-flex flex-column
                                    ${sidebarReducer.open ? 'pr-13-rem' : 'pr-8-rem'}`}></MDBCol>
                            <MDBCol className={`text-center p-5`}>
                                <p className={`smaller-text lightGrey-text`}>All Rights Reserved for Dentacarts plus @{new Date().getFullYear()}</p>
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <Modal isOpen={globalReducer.modal.isOpen}
                        close={globalReducer.modal.close}
                        confirm={globalReducer.modal.confirm}
                        title={globalReducer.modal.title}
                        body={globalReducer.modal.body}
                        savebtnText={globalReducer.modal.savebtnText}
                        closebtnText={globalReducer.modal.closebtnText}
                        isInfo={globalReducer.modal.isInfo}
                    />
                    <ToastContainer
                        position="top-center"
                        autoClose={constanst.toastTimeOut}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </MDBContainer>
            </AuthRoute>
        </ConnectedRouter >
    )
}

export default App;