export const LOAD_SMO_PREVIEW_SAGA = 'LOAD_SMO_PREVIEW_SAGA';
export const LOAD_SMO_PREVIEW = 'LOAD_SMO_PREVIEW';
export const UPDATE_IS_LOADING_SMO_PREVIEW = 'UPDATE_IS_LOADING_SMO_PREVIEW';
export interface ILoadSMOPreviewActionSaga {
    readonly type: typeof LOAD_SMO_PREVIEW_SAGA;
}

export const loadSMOPreviewPropsSaga = (): ILoadSMOPreviewActionSaga => {
    return {
        type: LOAD_SMO_PREVIEW_SAGA,
    }
};