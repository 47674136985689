import IOperator from "../../dtos/IOperator"

export const LOAD_OPERATORS = "LOAD_OPERATORS"
export const LOAD_OPERATORS_SAGA = "LOAD_OPERATORS_SAGA"

export const LOAD_OPERATOR_STATUS = "LOAD_OPERATOR_STATUS"
export const LOAD_OPERATOR_STATUS_SAGA = "LOAD_OPERATOR_STATUS_SAGA"

export const LOAD_OPERATOR = "LOAD_OPERATOR"
export const LOAD_OPERATOR_SAGA = "LOAD_OPERATOR_SAGA"

export const ADD_OPERATOR = "ADD_OPERATOR"
export const ADD_OPERATOR_SAGA = "ADD_OPERATOR_SAGA"

export const UPDATE_OPERATOR = "UPDATE_OPERATOR"
export const UPDATE_OPERATOR_SAGA = "UPDATE_OPERATOR_SAGA"

export const UPDATE_IS_LOADING_OPERATORS = "UPDATE_IS_LOADING_OPERATORS";


export interface ILoadOperatorsActionSaga {
    readonly type: typeof LOAD_OPERATORS_SAGA;
    page: number;
    size: number;
    sort: string;
    name: string;
    status: string;
}
export const loadOperatorsPropsSaga = (page = 0, size = 10, sort = '', name = '', status = ''): ILoadOperatorsActionSaga => {
    console.log("loadOperatorsPropsSaga", page, size, sort, name, status)
    return {
        type: LOAD_OPERATORS_SAGA,
        page: page,
        size: size,
        sort: sort,
        name: name,
        status: status
    }
};


export interface ILoadOperatorActionSaga {
    readonly type: typeof LOAD_OPERATOR_SAGA;
    operatorId: number
}
export const loadOperatorPropsSaga = (operatorId: number): ILoadOperatorActionSaga => {
    return {
        type: LOAD_OPERATOR_SAGA,
        operatorId: operatorId
    }
};

export interface ILoadOperatorStatusActionSaga {
    readonly type: typeof LOAD_OPERATOR_STATUS_SAGA;
}
export const loadOperatorStatusPropsSaga = (): ILoadOperatorStatusActionSaga => {
    return {
        type: LOAD_OPERATOR_STATUS_SAGA,
    }
};

export interface IAddOperatorActionSaga {
    readonly type: typeof ADD_OPERATOR_SAGA;
    operator: IOperator
}
export const addOperatorPropsSaga = (operator: IOperator): IAddOperatorActionSaga => {
    return {
        type: ADD_OPERATOR_SAGA,
        operator: operator
    }
};

export interface IUpdateOperatorActionSaga {
    readonly type: typeof UPDATE_OPERATOR_SAGA;
    operatorId: number
    operator: IOperator
}
export const updateOperatorPropsSaga = (operatorId: number, operator: IOperator): IUpdateOperatorActionSaga => {
    return {
        type: UPDATE_OPERATOR_SAGA,
        operatorId: operatorId,
        operator: operator
    }
};

