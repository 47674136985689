import React, { useEffect, useState, useMemo, useCallback } from 'react';
import './styles/styles.scss'
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { loadBranchesPropsSaga, deleteBranchPropsSaga, loadBranchPropsSaga, updateBranchPropsSaga } from "../../sagas/branch/types";
import { updateGlobalModalPropsSaga } from "../../sagas/global/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBModal, MDBModalBody } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import IBranch from "../../dtos/IBranch"
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import TableMenu from "../../components/tableMenu"
import CustomCheckbox from "../../components/customCheckbox"
import MainBlueButton from "../../components/blueButton";
import Select from "../../components/select"
import constants from "../../constants";
import AddBranch from "./addbranch"
import EditBranch from './editbranch';
import BranchDetails from "./branchDetails"

interface IBranchesListProps {
}

function BranchesList(props: IBranchesListProps) {
    const dispatch = useDispatch();
    const branchReducer = useSelector((state: AppState) => state.branchReducer)
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page } = queryString.parse(routerReducer.location.search);
    const [view, selectView] = useState(10)
    const [branchModalOpen, toggleBranchModal] = useState(false)
    const [branchDetailsModalOpen, toggleBranchDetailsModal] = useState(false)

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        dispatch(loadBranchesPropsSaga(pageNum, view, sorting));

    }, [routerReducer.location.search, view]);
    useMemo(() => {
    }, [branchReducer.branchDeleted])
    const handleCloseModal = () => {
        dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
    }
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            if (headerColumn.name.toLowerCase() == 'name' || headerColumn.name.toLowerCase() == 'id') {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const handleDeactivate = (branchId: number | undefined) => {
        if (branchId) {
            dispatch(deleteBranchPropsSaga(branchId))
            dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
        }

    }

    const headers: any = [
        // { name: "check", width: 1 },
        { name: "Id", width: 1 },
        { name: "Name", width: 3 },
        { name: "Number", width: 2 },
        { name: "Address", width: 2 },
        { name: "", width: 1 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }
    const handleCheckbox = (rowValue: any) => {
        rowValue.selected = !rowValue.selected
        dispatch(updateBranchPropsSaga(rowValue.id, rowValue))
    }
    const buildRows = (branches: IBranch[]): IRow[] => {
        return branches.map((b: IBranch, i) => {
            return {
                value: b,
                props: [
                    'id',
                    'name'
                    // {
                    //     edit: (rowValue: IBranch) => {
                    //         return (<div className="cursor offblue-text" onClick={async () => {
                    //             await dispatch(loadBranchPropsSaga(rowValue.id))
                    //             toggleBranchDetailsModal(!branchDetailsModalOpen)
                    //         }}>{rowValue.name}</div >);
                    //     }
                    // }
                    , 'phone', {
                        edit: (rowValue: any) => {
                            return (<div>{`${rowValue.address_id?.building} ${rowValue.address_id?.street}, ${rowValue.address_id?.city?.name}, ${rowValue.address_id?.area?.name}, ${rowValue.address_id?.country?.name}`}</div >);
                        }
                    },
                    {
                        edit: (rowValue: IBranch) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    }]
            }
        });
    };
    const rows = useMemo(() => buildRows(branchReducer.branches), [branchReducer.branches]);
    const menuItems = [
        {
            title: "View Branch",
            icon: "eye",
            handleChange: async (rowValue: any) => {
                await dispatch(loadBranchPropsSaga(rowValue.id))
                toggleBranchDetailsModal(!branchDetailsModalOpen)
            }
        },
        {
            title: "Edit Branch",
            icon: "edit",
            handleChange: async (rowValue: any) => {
                await dispatch(loadBranchPropsSaga(rowValue.id))
                toggleBranchModal(!branchModalOpen)
            }
        },
        {
            title: "Remove Branch",
            icon: "trash",
            handleChange: (rowValue: any) => {
                dispatch(updateGlobalModalPropsSaga({
                    isOpen: true,
                    title: 'Deactivate branch',
                    body: <h3>Are you sure you want to remove this branch?</h3>,
                    confirm: () => handleDeactivate(rowValue.id),
                    close: handleCloseModal,
                }))
            }
        }
    ]
    const resetBranch = () => {
        toggleBranchModal(false);
        let emptyBranch = {
            id: 0,
            href: null,
            name: '',
            service_account_id: 0,
            address_id: null,
            is_active: true,
            longitude: null,
            latitude: null,
            phone: '',
            selected: false,
            users: [],
            storages: []
        }
        branchReducer.branch = emptyBranch;
    }
    const renderBranchModal = () => {
        return <MDBModal isOpen={branchModalOpen} toggle={() => { toggleBranchModal(!branchModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="m-0 pt-5 pb-5">
                    <MDBCol size="12" md="7" className="text-left offblue-text">
                        <h2 className="ml-2 font-weight-bold">{branchReducer.branch.name != "" ? branchReducer.branch.name : 'Create New branch'}</h2>
                    </MDBCol>
                    {/* <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                        <MDBRow className="m-0 p-0">
                            <MainBlueButton title="cancel" className="d-contents"
                                btnClassName="btn-modal-header-cancel"
                                onClick={() => resetBranch()} />
                            <MainBlueButton form="branch-form" type="submit" title="save" className="d-contents"
                                btnClassName="btn-modal-header-save"
                                onClick={() => { }}
                            />
                        </MDBRow>
                    </MDBCol> */}
                </MDBRow>
            </div>
            <MDBModalBody className="text-left">
                {branchReducer.branch.id != 0 ? <EditBranch branchId={branchReducer.branch.id} toggleBranchModal={toggleBranchModal} resetBranch={resetBranch} /> : <AddBranch toggleBranchModal={toggleBranchModal} />}
            </MDBModalBody>
        </MDBModal>
    }
    const renderBranchDetailsModal = () => {
        return <MDBModal isOpen={branchDetailsModalOpen} toggle={() => { toggleBranchDetailsModal(!branchDetailsModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="m-0 pt-5 pb-5">
                    <MDBCol size="12" className="text-left offblue-text">
                        <h2 className="ml-2 font-weight-bold">{branchReducer.branch?.name}</h2>
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBModalBody>
                {branchReducer.branch.id != 0 ? <BranchDetails key={branchReducer.branch.id} branch={branchReducer.branch} /> : undefined}
            </MDBModalBody>
        </MDBModal>
    }

    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={branchReducer.loadingBranches.isLoadingBranches} errorMessage={branchReducer.loadingBranches.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="3" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-5 pl-5 mr-2"
                                title="+ Add Branch"
                                onClick={() => {
                                    resetBranch()
                                    toggleBranchModal(!branchModalOpen)
                                }}
                            />
                        </MDBCol>
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                className="mr-2 d-inline"
                                id="view-select"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                branchReducer.branches.length > 0 ?

                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        data={branchReducer.branches}
                                        headerOnClick={handleSort}
                                        totalPages={branchReducer.totalBranchesCount / view}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                    />
                                    : <div className="mt-5">You don't have any branches.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
                {renderBranchModal()}
                {renderBranchDetailsModal()}
            </MDBCol>
        </MDBRow>

    )
}

export default BranchesList;