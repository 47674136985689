import { axiosInstance } from "../store";
import Constants from '../constants';
import IProduct from "../dtos/IProduct";
import { handlStatusCodeMessage } from "../helpers";

export function fetchDataTableProductTreansactions(productId = 0, page = 0, size = 10, sort = '', operation = '', storage = '', from = '', to = '', userId = ''): Promise<{ productTransactions: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/product/${productId}/transactions?page=${page}&size=${size}&sort=${sort}${operation != '' ? `&operation=${operation}` : ''}${storage != '' ? `&storage_id=${storage}` : ''}${from != '' ? `&from=${from}` : ''}${to != '' ? `&to=${to}` : ''}${userId != '' ? `&user_id=${userId}` : ''}`)
        .then((res) => {
            return {
                productTransactions: res.status === 200 ?
                    res.data.map((p: any) => { return { ...p } })
                    : []
                , totalCountPages: res.headers['x-total-count']
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product transaction' });
            return {
                productTransactions: [],
                totalCountPages: 0
            }
        })
}

export function getOperations(): any[] {
    return Constants.operations;
}