import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_BRANCHES_SAGA, ILoadBranchesActionSaga,
    LOAD_BRANCH_SAGA, ILoadBranchActionSaga,
    ADD_NEW_BRANCH_SAGA, IAddNewBranchActionSaga,
    UPDATE_BRANCH_SAGA, IUpdateBranchActionSaga,
    DELETE_BRANCH_SAGA, IDeleteBranchActionSaga,
    LOAD_COUNTRIES_SAGA, ILoadCountriesActionSaga,
    LOAD_CITIES_SAGA, ILoadCitiesActionSaga
} from "./types";
import { fetchBranches, fetchBranch, addNewBranch, updateBranch, deleteBranch, fetchCountires, fetchCities } from "../../api/branches"
import { loadBranchesProps, loadBranchProps, addNewBranchProps, updateBranchProps, deleteBranchProps, updateIsLoadingBranchesProps, loadCountriesProps, loadCitiesProps } from "../../reducers/branch/types";
import IBranch from "../../dtos/IBranch";
import ICountry from '../../dtos/ICountry';
import ICity from '../../dtos/ICity';

function* loadBranches(action: ILoadBranchesActionSaga) {
    try {
        yield put(updateIsLoadingBranchesProps({ isLoadingBranches: true, errorMessage: undefined }));
        const branchesData: {
            branches: IBranch[],
            totalBranchesCount: number
        } = yield call(fetchBranches, action.page, action.size, action.sort);
        yield put(loadBranchesProps({ branches: branchesData.branches, totalBranchesCount: branchesData.totalBranchesCount }))
        yield put(updateIsLoadingBranchesProps({ isLoadingBranches: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingBranchesProps({ isLoadingBranches: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadBranches() {
    yield takeLatest(LOAD_BRANCHES_SAGA, loadBranches)
}

function* loadBranch(action: ILoadBranchActionSaga) {
    const branch: IBranch = yield call(fetchBranch, action.branchId);
    yield put(loadBranchProps({ branch }))
}
export function* watchLoadBranch() {
    yield takeLatest(LOAD_BRANCH_SAGA, loadBranch)
}

function* addNewBranchData(action: IAddNewBranchActionSaga) {
    const branchAdded: boolean = yield call(addNewBranch, action.branch);
    if(branchAdded){
        window.location.reload();
    }
    yield put(addNewBranchProps({ branchAdded }))
}
export function* watchAddNewBranch() {
    yield takeLatest(ADD_NEW_BRANCH_SAGA, addNewBranchData)
}

function* updateBranchData(action: IUpdateBranchActionSaga) {
    const branchUpdated: boolean = yield call(updateBranch, action.branchId, action.branch);
    yield put(updateBranchProps({ branchUpdated }))
}
export function* watchUpdateBranch() {
    yield takeLatest(UPDATE_BRANCH_SAGA, updateBranchData)
}
function* deleteBranchData(action: IDeleteBranchActionSaga) {
    const branchDeleted: number = yield call(deleteBranch, action.branchId);
    yield put(deleteBranchProps({ branchDeleted }))
}
export function* watchDeleteBranch() {
    yield takeLatest(DELETE_BRANCH_SAGA, deleteBranchData)
}
function* loadCountries(action: ILoadCountriesActionSaga) {
    const countries: ICountry[] = yield call(fetchCountires);
    yield put(loadCountriesProps({ countries }))
}
export function* watchLoadCountries() {
    yield takeLatest(LOAD_COUNTRIES_SAGA, loadCountries)
}
function* loadCities(action: ILoadCitiesActionSaga) {
    const cities: ICity[] = yield call(fetchCities, action.uuid);
    yield put(loadCitiesProps({ cities }))
}
export function* watchLoadCities() {
    yield takeLatest(LOAD_CITIES_SAGA, loadCities)
}

export default [
    watchLoadBranches(),
    watchLoadBranch(),
    watchAddNewBranch(),
    watchUpdateBranch(),
    watchDeleteBranch(),
    watchLoadCountries(),
    watchLoadCities()
]