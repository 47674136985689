import React from "react";
import './index.scss'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import BranchesListPage from "./branchesList";
import BranchStoragesPage from "./branchStorages"
import BranchUsersPage from "./branchUsers"
import UnitsPage from "../units"
import OperatorsPage from "../operators"
import TabsButtons from "../../components/tabsButtons/index"
import { MDBRow, MDBCardText, MDBCol } from 'mdbreact';
import Can from "../../containers/can";
import branchesIcon from "../../assets/img/pages-icons/inventory-icon.png";

interface IBranchesPageProps {
}

export default function BranchesPage(props: IBranchesPageProps) {
    let { path } = useRouteMatch();
    const tabs = [
        {
            title: 'Branches',
            path: '/branches',
        },
        // {
        //     title: 'Units',
        //     path: '/branches/units',
        // },
        // {
        //     title: 'Operators',
        //     path: '/branches/operators',
        // }
    ]
    return (
        <>
            <MDBRow className="m-0">
                <MDBRow className="ml-2 mt-0 mb-0 w-100">
                    <MDBCol size={`12`} md={`6`}>
                        <img className="page-image d-inline-block" src={branchesIcon} />
                        <MDBCardText className="page-title d-inline-block">Branches</MDBCardText>
                        <span className="page-line d-inline-block" />
                    </MDBCol>
                    <MDBCol size={`12`} md={`6`} className={`d-flex justify-content-center justify-content-md-end`}>
                        {!window.location.pathname.includes('usage') && !window.location.pathname.includes('users') && <TabsButtons tabs={tabs} />}
                    </MDBCol>
                </MDBRow>
            </MDBRow>
            <Switch>
                <Route path={`${path}/:branchId/storages`} component={BranchStoragesPage} />
                <Route path={`${path}/:branchId/users`} component={BranchUsersPage} />
                <Route path={`${path}/units`} component={UnitsPage} />
                <Route path={`${path}/operators`} component={OperatorsPage} />
                <Route exact path={path} component={BranchesListPage} />
            </Switch>
        </>
    )
}