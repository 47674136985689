import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_ORDER_PRODUCTS_SAGA, ILoadOrderProductsActionSaga,
    UPDATE_ORDER_SAGA,
    ADD_ORDER_PRODUCT_SAGA, IAddOrderProductActionSaga,
    LOAD_ORDERS_HISTORY_SAGA, ILoadOrdersHistoryActionSaga,
    LOAD_ORDER_DETAILS_SAGA, ILoadOrderDetailsActionSaga,
    LOAD_FULFILLMENTS_COUNT_SAGA, ILoadFulfillmentsCountActionSaga, IUpdateCartItemActionSaga, IDeleteCartItemActionSaga, DELETE_CART_ITEM, DELETE_CART_ITEM_SAGA,
} from "./types"
import { addOrderProduct, fetchOrdersHistory, fetchOrderDetails, fetchFulfillmentsCount, fetchFulfillmentsUsed } from "../../api/smart-ordering"
import {
    loadOrderProductsProps, addOrderProductProps,
    loadOrdersHistoryProps, loadOrderDetailsProps,
    loadFulfillmentsCountProps,
    updateIsLoadingOrderProductsProps, updateIsLoadingOrdersHistoryProps, updateCartItemProps, deleteCartItemProps
} from "../../reducers/smart-ordering/types";
import { deleteCartItem, getCart, updateCartItem } from '../../api/cart';

function* loadOrderProducts(action: ILoadOrderProductsActionSaga) {
    try {
        yield put(updateIsLoadingOrderProductsProps({ isLoadingOrderProducts: true, isLoadingItemQty: false, errorMessage: undefined }));
        const cartData: {
            cartItems: any[],
            totalCountCartItems: number
        } = yield call(getCart, action.cart_type, action.page, action.size);
        yield put(loadOrderProductsProps({ cartData: cartData.cartItems, totalCountCartItems: cartData.totalCountCartItems }))
        yield put(updateIsLoadingOrderProductsProps({ isLoadingOrderProducts: false, isLoadingItemQty: false, errorMessage: undefined }));
    } catch (error: any) {
        yield put(updateIsLoadingOrderProductsProps({ isLoadingOrderProducts: false, isLoadingItemQty: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadOrderProducts() {
    yield takeLatest(LOAD_ORDER_PRODUCTS_SAGA, loadOrderProducts)
}
function* updateCartItemData(action: IUpdateCartItemActionSaga) {
    try {
        yield put(updateIsLoadingOrderProductsProps({ isLoadingItemQty: true, isLoadingOrderProducts: false, errorMessage: undefined }));
        yield call(updateCartItem, action.cart_id, action.productId, action.newQuantity);
        yield put(updateCartItemProps({ quantity: action.newQuantity, product: action.product }))
        yield put(updateIsLoadingOrderProductsProps({ isLoadingItemQty: false, isLoadingOrderProducts: false, errorMessage: undefined }));
    } catch (error: any) {
        yield put(updateIsLoadingOrderProductsProps({ isLoadingItemQty: false, isLoadingOrderProducts: false, errorMessage: undefined }));
        yield put(displayErrorMessageProps({ message: error.status as string }))
    } finally {
        yield put(updateIsLoadingOrderProductsProps({ isLoadingItemQty: false, isLoadingOrderProducts: false, errorMessage: undefined }));
    }
}
export function* watchUpdateOrderData() {
    yield takeLatest(UPDATE_ORDER_SAGA, updateCartItemData)
}

function* deleteCartItemData(action: IDeleteCartItemActionSaga) {
    try {
        yield put(updateIsLoadingOrderProductsProps({ isLoadingItemQty: true, isLoadingOrderProducts: false, errorMessage: undefined }));
        const itemDeleted: boolean = yield call(deleteCartItem, action.cart_id, action.product_id);
        yield put(deleteCartItemProps({ itemDeleted: itemDeleted, product_id: action.product_id }))
        yield put(updateIsLoadingOrderProductsProps({ isLoadingItemQty: false, isLoadingOrderProducts: false, errorMessage: undefined }));
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingOrderProductsProps({ isLoadingItemQty: false, isLoadingOrderProducts: false, errorMessage: undefined }));
    }
}

export function* watchDeleteItemData() {
    yield takeLatest(DELETE_CART_ITEM_SAGA, deleteCartItemData);
}


function* addOrderProductData(action: IAddOrderProductActionSaga) {
    const productAdded: boolean = yield call(addOrderProduct, action.product);
    yield put(addOrderProductProps({ productAdded }))
}
export function* watchAddOrderProductData() {
    yield takeLatest(ADD_ORDER_PRODUCT_SAGA, addOrderProductData)
}

function* loadOrdersHistory(action: ILoadOrdersHistoryActionSaga) {
    try {
        yield put(updateIsLoadingOrdersHistoryProps({ isLoadingOrdersHistory: true, errorMessage: undefined }));
        const ordersHistoryData: {
            ordersHistory: any[],
            totalCountOrders: number
        } = yield call(fetchOrdersHistory, action.page, action.size, action.sort, action.status);
        yield put(loadOrdersHistoryProps({ ordersHistory: ordersHistoryData.ordersHistory, totalCountOrders: ordersHistoryData.totalCountOrders }))
        yield put(updateIsLoadingOrdersHistoryProps({ isLoadingOrdersHistory: false, errorMessage: undefined }));
    } catch (error: any) {
        yield put(updateIsLoadingOrdersHistoryProps({ isLoadingOrdersHistory: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadOrdersHistory() {
    yield takeLatest(LOAD_ORDERS_HISTORY_SAGA, loadOrdersHistory)
}

function* loadOrderDetails(action: ILoadOrderDetailsActionSaga) {
    const orderDetails: {} = yield call(fetchOrderDetails, action.orderId);
    yield put(loadOrderDetailsProps({ orderDetails }))
}
export function* watchLoadOrderDetails() {
    yield takeLatest(LOAD_ORDER_DETAILS_SAGA, loadOrderDetails)
}

function* loadFulfillmentsCount(action: ILoadFulfillmentsCountActionSaga) {
    const used: 0 = yield call(fetchFulfillmentsUsed, action.currentDate);
    const limit: 0 = yield call(fetchFulfillmentsCount);
    const fulfillmentsCount = {
        used: used,
        limit: limit
    }
    yield put(loadFulfillmentsCountProps({ fulfillmentsCount }))
}
export function* watchLoadFulfillmentsCount() {
    yield takeLatest(LOAD_FULFILLMENTS_COUNT_SAGA, loadFulfillmentsCount)
}
export default [
    watchLoadOrderProducts(),
    watchUpdateOrderData(),
    watchDeleteItemData(),
    watchLoadOrdersHistory(),
    watchLoadOrderDetails(),
    watchAddOrderProductData(),
    watchLoadFulfillmentsCount()
]
