import { axiosInstance } from "../store"
import { IQuotation, IQuotationItem } from "../dtos/IQuotation";
import IAddress from "../dtos/IAddress";

export function quotationList(page = 1, size = 10): Promise<{ quotation_list: IQuotation[], total_count: number }> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/quotation?branch_id=${localStorage.getItem('branch_id')}&page=${page}&size=${size}&storefront_id=1`)
      .then((res) => {
        resolve({ quotation_list: res.data, total_count: res.headers['x-total-count'] });
      }).catch((err) => {
        reject({ quotation_list: [], total_count: 0 });
      })
  })
}

export function quotationItem(quotationID: number): Promise<IQuotationItem[]> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/quotation-item/${quotationID}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.response);
      })
  })
}

export function quotations(page: number, size: number): Promise<any> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/quotation?page=${page}&size=${size}`)
      .then((res) => {
        console.log("WEfcwe", res)
        resolve({ quotation_list: res.data, total_count: res.headers['x-total-count'] });
      }).catch((err) => {
        reject(err.response);
      })
  })
}

export function branchAddress(): Promise<IAddress> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/address/branch?branch_id=${localStorage.getItem('branch_id')}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err.data);
      })
  })
}