import { IUpdateIsLoadingMyAccountAction, MyAccountActions } from './types'
import { LOAD_MY_ACCOUNT, UPDATE_IS_LOADING_MY_ACCOUNT } from "../../sagas/my_account/types";
import IUser from '../../dtos/IUser';
interface IMyAccountState {
    user: IUser | undefined,
    loadingMyAccount: IUpdateIsLoadingMyAccountAction['payload'],
}

const MyAccountState: IMyAccountState = {
    user: undefined,
    loadingMyAccount: {
        isLoadingMyAccount: false,
        errorMessage: undefined
    }
};

const myAccountReducer = (state = MyAccountState, action: MyAccountActions): IMyAccountState => {
    switch (action.type) {
        case LOAD_MY_ACCOUNT:
            return {
                ...state,
                user: action.payload.user,
            };
        case UPDATE_IS_LOADING_MY_ACCOUNT:
            return {
                ...state,
                loadingMyAccount: action.payload,
            };
        default:
            return state;
    }
};

export default myAccountReducer;
