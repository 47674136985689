export const orderStatusMap = [
    "Incomplete",
    "Open",
    "Ready For Picking",
    "Packed",
    "Shipping",
    "Complete",
    "Cancel",
    "Returned",
    "Failed",
    "Cancellation In Progress",
    "Draft",
    "Need Approval",
]