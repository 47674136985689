export const permissionNames: any = {
  BRANCH_NOTIFICATION: 'Notification',
  USER_EDIT: 'Edit',
  BRANCH_DELETE: 'Delete',
  IOT_AUTH: 'Auth',
  USERGROUP_DELETE: 'Delete',
  IOT_COUNT_REVISION: 'Count revision',
  ASSET_NOTIFICATION: 'Notification',
  BRANCH_EDIT: 'Edit',
  STORAGE_CREATE: 'Create',
  STORAGE_NOTIFICATION: 'Notification',
  SERVICE_ACCOUNT_EDIT: 'Edit',
  BRANCH_VIEW: 'View',
  INVENTORY_VIEW: 'View',
  PRODUCT_CREATE: 'Create',
  SERVICE_ACCOUNT_DELETE: 'Delete',
  USER_VIEW: 'View',
  USER_CREATE: 'Create',
  SERVICE_ACCOUNT_VIEW: 'View',
  REQUEST_VIEW: 'View',
  SERVICE_ACCOUNT_CREATE: 'Create',
  CATEGORY_EDIT: 'Edit',
  PRODUCT_DELETE: 'Delete',
  CATEGORY_DELETE: 'Delete',
  CATEGORY_VIEW: 'View',
  SERVICE_ACCOUNT_NOTIFICATION: 'Notification',
  REQUEST_CREATE: 'Create',
  BRANCH_CREATE: 'Create',
  IOT_HEART_BEAT: 'Heart Beat',
  CATEGORY_NOTIFICATION: 'Notification',
  ASSET_EDIT: 'Edit',
  STORAGE_VIEW: 'View',
  PRODUCT_VIEW: 'View',
  ASSET_VIEW: 'View',
  INVITATION_VIEW: 'View',
  INVITATION_CREATE: 'Create',
  PRODUCT_NOTIFICATION: 'Notification',
  USERGROUP_UPDATE: 'Update',
  STORAGE_DELETE: 'Delete',
  REQUEST_REVIEW: 'Review',
  USERGROUP_VIEW: 'View',
  STORAGE_EDIT: 'Edit',
  CATEGORY_CREATE: 'Create',
  STOCK_ADJUST: 'Adjust',
  PRODUCT_UPDATE: 'Update',
  VENDOR_VIEW: 'View',
  VENDOR_CREATE: 'Create',
  VENDOR_EDIT: 'Edit',
  VENDOR_DELETE: 'Delete',
  BUSINESS_UNIT_VIEW: 'View',
  BUSINESS_UNIT_CREATE: 'Create',
  BUSINESS_UNIT_EDIT: 'Edit',
  OPERATOR_VIEW: 'View',
  OPERATOR_CREATE: 'Create',
  OPERATOR_EDIT: 'Edit',
  OPERATOR_DELETE: 'Delete',
  CREATE_BRANCH_REPORT: 'Create Report',
  USER_DELETE: 'Delete',
  ACCESS_CARD_DELETE: 'Delete',
  MY_ACCESS_CARD_DELETE: 'Delete my card',
  USER_UPDATE: 'Update',
  REQUEST_ACCESS_CARD: 'Request card',
  ADMIN_SET_TAG_NUM: 'Set tag number',
  ADMIN_LIST_ACCESSCARDS: 'List',
  ADMIN_CHANGE_ACCESSCARD_STATUS: 'Change Status',
  ADMIN_LIST_AVAILABLE_CARD_STATUS: 'List Status',
  ADMIN_SERVICE_ACCOUNT_VIEW: 'view',
  ADMIN_INVITATION_CREATE: 'Create Admin',
  ADMIN_INVITATION_VIEW: 'View Admin',
  ADMIN_ORDER_VIEW: 'view',
  ORDER_VIEW: 'Order View',
  ADMIN_CREATE_SERVICE_ACCOUNT: 'create',
  ADMIN_ORDER_EDIT: 'edit',
  ADMIN_LIST_SETTINGS: 'List settings',
  ADMIN_STORAGE_VIEW: 'view',
  ADMIN_VENDOR_VIEW: 'view',
  ADMIN_STORAGE_CREATE: 'create',
  ADMIN_VIEW_SERVICE_ACCOUNT_BRANCHES: 'View service account branches',
  ADMIN_VIEW_SERVICE_ACCOUNT_USER_GROUP: 'Admin view',
  ADMIN_BRANCH_VIEW: 'view',
  ADMIN_STORAGE_UPDATE: 'update',
  ITEM_VIEW: 'view',
  USERGROUP_ADD: 'Add',
  VIEW_ACCOUNT_SETTINGS: 'View Account Settings',
  PLACE_QUOTATION: 'Place',
  QUOTATION_VIEW: 'View',
  CART_VIEW: 'View',
  ADD_TO_CART: 'Edit',
  PLACE_ORDER: 'Place'
};
export const moduleNames: any = {
  ASSET: "Asset",
  BRANCH: "Branches",
  BUSINESS_UNIT: "Business Unit",
  SERVICE_ACCOUNT: "Service Account",
  VENDOR: "Vendors",
  USER_GROUP: "User Groups",
  INVENTORY: "Inventory",
  INVITATION: "Invitations",
  OPERATOR: "Operator",
  ORDER: "Order",
  STORAGE: "Storage",
  MANUAL_STORAGE_REQEUST: "Manual Storage Requests",
  PRODUCT: "Products",
  USER: "Users",
  PURCHASE_ORDER: "Purchase Orders",
  CATEGORY: "Category",
  ACCESS_CARD: "Access Card",
  ITEM: "Items",
  QUOTATION: "Quotaiton",
  CART: "Cart",
};
export type component = "CUSTOMER_PORTAL" | "ADMIN_PORTAL" | "IOT";

