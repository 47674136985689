import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { push } from 'connected-react-router';
import { MDBCol, MDBRow } from 'mdbreact';
import Loader from "../../components/loader";
import { loadOrderProductsSaga, loadFulfillmentsCountSaga, updateCartItemSaga, deleteCartItemSaga } from "../../sagas/smart-ordering/types";
import { loadVendorsPropsSaga } from "../../sagas/vendors/types"
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import Input from '../../components/input';
import moment from "moment";
import IProduct from '../../dtos/IProduct';
import deleteIcon from '../../assets/img/svg/delete.svg';
import { BeatLoader } from 'react-spinners';
import { Checkbox, Image } from 'antd';
import Select from '../../components/select';
import constants from "../../constants"
import CartSummary from './CartSummary';
import CheckoutModal from './checkoutModal';
const Checklist = Checkbox.Group;

interface ICartProps { }

export default function Quotations(props: ICartProps) {
  const dispatch = useDispatch();
  const routerReducer = useSelector((state: AppState) => state.router)
  const cartReducer = useSelector((state: AppState) => state.cartReducer)
  const vendorsReducer = useSelector((state: AppState) => state.vendorsReducer)
  const { sort, page } = queryString.parse(routerReducer.location.search);
  const [view, selectView] = useState(10);
  const [formModalOpen, toggleFormModal] = useState(false);
  const [isCheckout, setIsCheckout] = useState<boolean>(false);
  const [checkoutOptions, setCheckoutOptions] = useState<any[]>([]);

  useEffect(() => {
    const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
    const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
    updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
    const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
    changePage(page ? parseInt(page.toString()) : 1);
    dispatch(loadOrderProductsSaga("quotation", pageNum, view, sorting));

  }, [routerReducer.location.search, view]);
  const changeStep = (activeTab: string) => { dispatch(push({ search: activeTab })); }

  const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
    return headerColumns.map((headerColumn, i) => {
      if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
        switch (columnSorted) {
          case 'ascending':
            return { ...headerColumn, sorted: 'ascending' }
          case 'descending':
            return { ...headerColumn, sorted: 'descending' }
          default:
            return { ...headerColumn, sorted: undefined }
        }
      } else {
        return { ...headerColumn, sorted: undefined }
      }
    });
  }

  const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
    let sorting = ``;
    headerColumns.map((headerColumn, i) => {
      let colName = headerColumn.name.toLocaleLowerCase()
      if (colName != "" && colName != "name" && colName != "location" && colName != "assigned to") {
        if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
          sorting = `${columnName.toLowerCase()},`;
          switch (columnSorted) {
            case undefined:
              sorting += 'ascending';
              break;
            case 'ascending':
              sorting += 'descending';
              break;
            default:
              sorting = '';
          }

        }
      }
    });
    const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
    dispatch(push({
      search: addQueryString(newQueryString, 'page', 1)
    }));
  }

  const headers: any = [
    { name: "SKU", width: 1 },
    { name: "", width: 1 },
    { name: "Name", width: 3 },
    { name: "Features", width: 3 },
    { name: "Category", width: 3 },
    { name: "Quantity", width: 2 },
    { name: "", width: 1 },
  ].map((header) => {
    return { ...header };
  });

  const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
  const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
  const handlePaginationChange = (e: any, { activePage }: any) => { dispatch(push({ search: addQueryString(routerReducer.location.search, 'page', activePage) })); }

  const removeHandler = (product_id: number) => {
    console.log("product deleted")
  }

  const buildRows = (cartProducts: any[]): IRow[] => {
    return cartProducts?.map((cartProduct: any, i: any) => {
      return {
        value: cartProduct,
        props: [
          'sku',
          {
            edit: (rowValue: IProduct) => {
              if (rowValue.product_images)
                return <div className='product-image'><Image src={rowValue?.product_images[0]?.image_path} /></div>
            }
          },
          {
            edit: (rowValue: IProduct) => {
              return (<div className="d-flex-col">
                <p>{rowValue.name}</p>
                {rowValue.status == "D" ? <p className='not-available'>{`(Not Available, please remove it from cart)`}</p> : <></>}
              </div>);
            },
          },
          {
            edit: (rowValue: IProduct) => {
              if (rowValue?.feature_values)
                return (rowValue?.feature_values.length > 0 ? <p>{`( ${rowValue?.feature_values.map(({ featureVariant }: any) => featureVariant.variant_name).join(', ')} )`}</p> : <></>)

            },
          },
          {
            edit: (rowValue: IProduct) => {
              if (rowValue?.product_categories)
                return (rowValue?.product_categories.length > 0 ? <p>{`( ${rowValue?.product_categories.map(({ category }: any) => category.name).join(', ')} )`}</p> : <></>)

            },
          },
          {
            edit: (rowValue: any) => {
              return (
                rowValue.status == "D" ? <></> : <div>
                  {
                    cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId ? <BeatLoader loading={cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId} color="#2D2ACE" /> : <Input
                      key={rowValue.id}
                      fieldClassName="request-field w-50"
                      label=""
                      type="number"
                      required={false}
                      placeholder=""
                      defaultValue={1}
                      min={1}
                      value={rowValue.id == productChangedId ? editQuantity : rowValue.quantity}
                      onChange={(event: any) => handleChangeQty(event, rowValue)}
                    />
                  }
                </div>
              );
            }
          },
          {
            edit: (rowValue: any) => {
              return (cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId ? <BeatLoader loading={cartReducer.loadingOrderProducts.isLoadingItemQty && rowValue.id == productChangedId} color="#2D2ACE" /> : <img onClick={() => handleRemoveCartItem(rowValue)} src={deleteIcon} alt="delete" className='delete-icon' />);
            }
          }]
      }
    });
  };
  const [productChangedId, setProductChangedId] = useState(0);


  const rows = buildRows(cartReducer.cartItems);

  const handleRemoveCartItem = (product: IProduct) => {
    setProductChangedId(product.id);
    dispatch(deleteCartItemSaga(product.cart_id, product.id));
  }

  const checkoutModalBody = () => {
    return <MDBRow>
      <Checklist onChange={(e) => setCheckoutOptions(e)} className='d-flex-col' options={[{ label: "Place Order", value: "cart" }, { label: "Ask For Quotation", value: "quotation" }]} />
    </MDBRow>
  }
  const [timeOutId, setTimeOutId] = useState<any>(null);
  const [editQuantity, setEditQuantity] = useState<number>(1);

  const handleChangeQty = (event: any, product: any) => {
    setEditQuantity(event.target.value);
    setProductChangedId(product.id);
    clearTimeout(timeOutId);
    const newTimeOut = setTimeout(() => {
      let newQuantity: number = event.target.value;
      dispatch(updateCartItemSaga(product.cart_id, product.id, newQuantity, product));
    }, 1000);
    setTimeOutId(newTimeOut);
  }

  useEffect(() => {
    const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
    const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
    changePage(page ? parseInt(page.toString()) : 1);
    if (cartReducer.cartItems?.length == 0 && (cartReducer.totalCountCartItems - view)) {
      dispatch(loadOrderProductsSaga("quotation", pageNum, view, sorting));
      return;
    }
    return;
  }, [cartReducer.totalCountCartItems, cartReducer.cartItems?.length]);

  return (
    <>
      <MDBRow start className="m-2 mb-4">
        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
          <Select name="view"
            id="view-select"
            className="mr-2 d-inline"
            label="View By"
            options={constants.viewOptions.map((option, i) => {
              return { text: option.value, value: option.id }
            })}
            selectedValue={view}
            onChange={(e: any) => { selectView(e.target.value); dispatch(push({ search: '' })); }}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="m-0 w-100">
        <MDBCol className="text-center">
          <Loader isLoading={cartReducer.loadingOrderProducts.isLoadingOrderProducts} errorMessage={cartReducer.loadingOrderProducts.errorMessage}>
            <MDBRow start className="justify-content-center mt-2">
              <MDBCol className="text-center p-0">
                {
                  cartReducer.cartItems?.length > 0 ?
                    <DataTable
                      headerColumns={headerColumns}
                      rows={rows}
                      headerOnClick={handleSort}
                      totalPages={cartReducer.totalCountCartItems / view}
                      activePage={activePage}
                      onPageChange={handlePaginationChange}
                    />
                    : <div className="mt-5" >You don't have any products yet.</div>
                }
              </MDBCol>
            </MDBRow>
          </Loader>
        </MDBCol>
      </MDBRow>
      <CheckoutModal checkoutOptions={checkoutOptions} body={checkoutModalBody()} title={`Are you sure you would like to proceed with placing an order and asking for quotation.`} isOpen={isCheckout} close={setIsCheckout} savebtnText='Proceed' />
      <CartSummary setIsCheckout={setIsCheckout} />
    </>
  )
}
