import React, { useState } from 'react';
import './styles.scss';
import '../../assets/styles/app.scss'

interface IOption {
    keyValue?: any;
    text: string
    value: any
}

interface ISelectProps {
    name: string,
    id?: any,
    label: string,
    options: IOption[]
    onChange: any
    selectedValue?: any,
    onBlur?: any,
    className?: any,
}

function Select(props: ISelectProps) {
    return (
        <select name={props.name} value={props.selectedValue ? props.selectedValue : 'none'}
            className={`select input-rounded ${props.className}`}
            id={`${props.id == "view-select" && "view-select"}`}
            onBlur={props.onBlur} onChange={props.onChange}>
            <option disabled value="none">{props.label}</option>
            {                //@ts-ignore
                props.options.map((item, i) => <option key={i} value={item.value}
                    data-key={item.keyValue} className="text-capitalize">{item.text}</option>)
            }
        </select >
    );
}

export default Select;
