import { LOAD_NOTIFICATIONS, DISMISS_NOTIFICATION, UNDISMISS_NOTIFICATION,UPDATE_IS_LOADING_NOTIFICATIONS, PUSH_INCOMING_NOTIFICATION } from "../../sagas/notifications/types";
import INotification from "../../dtos/INotification"

export interface ILoadNotificationsAction {
    readonly type: typeof LOAD_NOTIFICATIONS;
    payload: {
        notifications: INotification[],
        totalNotificationsCount: number,
        isDismissed: boolean
    }
}
export const loadNotificationsProps = (payload: ILoadNotificationsAction['payload']): ILoadNotificationsAction => {
    return {
        type: LOAD_NOTIFICATIONS,
        payload: payload
    }
};

export interface IDismissNotificationAction {
    readonly type: typeof DISMISS_NOTIFICATION;
    payload: {
        notificationId: string,
    }
}
export const dismissNotificationProps = (payload: IDismissNotificationAction['payload']): IDismissNotificationAction => {
    return {
        type: DISMISS_NOTIFICATION,
        payload: payload
    }
};

export interface IUndismissNotificationAction {
    readonly type: typeof UNDISMISS_NOTIFICATION;
    payload: {
        notificationId: string,
    }
}
export const undismissNotificationProps = (payload: IUndismissNotificationAction['payload']): IUndismissNotificationAction => {
    return {
        type: UNDISMISS_NOTIFICATION,
        payload: payload
    }
};

export interface IUpdateIsLoadingNotificationsAction {
    readonly type: typeof UPDATE_IS_LOADING_NOTIFICATIONS;
    payload: {
        isLoadingNotifications: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingNotificationsProps = (payload: IUpdateIsLoadingNotificationsAction['payload']): IUpdateIsLoadingNotificationsAction => {
    return {
        type: UPDATE_IS_LOADING_NOTIFICATIONS,
        payload: payload
    }
};

export interface IPushIncomingNotificationAction {
    readonly type: typeof PUSH_INCOMING_NOTIFICATION;
    payload: {
        notification: INotification,
    }
}
export const pushIncomingNotificationProps = (payload: IPushIncomingNotificationAction['payload']): IPushIncomingNotificationAction => {
    return {
        type: PUSH_INCOMING_NOTIFICATION,
        payload: payload
    }
};

export type NotificationActions = ILoadNotificationsAction | IDismissNotificationAction | IUndismissNotificationAction | IUpdateIsLoadingNotificationsAction
| IPushIncomingNotificationAction;