import React from "react";
import './index.scss'
import MyAccount from '../../containers/my_account';
import { MDBRow, MDBCardText } from 'mdbreact';
import UserAccountIcon from "../../assets/img/pages-icons/user-account-icon.png";

interface IMyAccountPageProps {
}

export default function MyAccountPage(props: IMyAccountPageProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <img className="page-image" src={UserAccountIcon} />
                <MDBCardText className="page-title">My Account</MDBCardText>
                <span className="page-line" />
            </MDBRow>
            <MyAccount />
        </MDBRow>
    )
}