import {
    LOAD_OPERATORS, LOAD_OPERATOR, LOAD_OPERATOR_STATUS, ADD_OPERATOR, UPDATE_OPERATOR
    , UPDATE_IS_LOADING_OPERATORS
} from "../../sagas/operators/types";
import IOperator from "../../dtos/IOperator";
import IStatus from "../../dtos/IStatus"

export interface ILoadOperatorsAction {
    readonly type: typeof LOAD_OPERATORS;
    payload: {
        operators: IOperator[],
        totalOperatorsCount: number
    }
}
export const loadOperatorsProps = (payload: ILoadOperatorsAction['payload']): ILoadOperatorsAction => {
    return {
        type: LOAD_OPERATORS,
        payload: payload
    }
};

export interface ILoadOperatorAction {
    readonly type: typeof LOAD_OPERATOR;
    payload: {
        operator: IOperator,
    }
}
export const loadOperatorProps = (payload: ILoadOperatorAction['payload']): ILoadOperatorAction => {
    return {
        type: LOAD_OPERATOR,
        payload: payload
    }
};

export interface ILoadOperatorStatusAction {
    readonly type: typeof LOAD_OPERATOR_STATUS;
    payload: {
        operatorStatus: IStatus[],
    }
}
export const loadOperatorStatusProps = (payload: ILoadOperatorStatusAction['payload']): ILoadOperatorStatusAction => {
    return {
        type: LOAD_OPERATOR_STATUS,
        payload: payload
    }
};

export interface IAddOperatorAction {
    readonly type: typeof ADD_OPERATOR;
    payload: {
        operatorAdded: boolean,
    }
}
export const addOperatorProps = (payload: IAddOperatorAction['payload']): IAddOperatorAction => {
    return {
        type: ADD_OPERATOR,
        payload: payload
    }
};

export interface IUpdateOperatorAction {
    readonly type: typeof UPDATE_OPERATOR;
    payload: {
        operatorUpdated: boolean,
    }
}
export const updateOperatorProps = (payload: IUpdateOperatorAction['payload']): IUpdateOperatorAction => {
    return {
        type: UPDATE_OPERATOR,
        payload: payload
    }
};
export interface IUpdateIsLoadingOperatorsAction {
    readonly type: typeof UPDATE_IS_LOADING_OPERATORS;
    payload: {
        isLoadingOperators: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingOperatorsProps = (payload: IUpdateIsLoadingOperatorsAction['payload']): IUpdateIsLoadingOperatorsAction => {
    return {
        type: UPDATE_IS_LOADING_OPERATORS,
        payload: payload
    }
};
export type OperatorsActions = ILoadOperatorsAction | ILoadOperatorStatusAction | ILoadOperatorAction | IAddOperatorAction | IUpdateOperatorAction | IUpdateIsLoadingOperatorsAction