import React, { useState, useEffect } from 'react';
import {
    MDBIcon, MDBListGroupItem, MDBCollapse, MDBRow, MDBCol
} from "mdbreact";
import './styles/index.scss'
import { NavLink } from 'react-router-dom';

interface ISideBarLink {
    exact: boolean,
    isBase?: boolean,
    name: string,
    breadcrumpName?: string,
    icon?: string,
    imageIcon?: React.ReactNode,
    className?: string,
    currentPathname: string,
    onClick?: Function,
    isSideMenuOpened: boolean
}

interface ISideBarLinkCollaps extends ISideBarLink {
    isCollaps: boolean
    nestedLinkeds: any[]
}

type ISideBarLinkProps = ISideBarLinkCollaps | ISideBarLink;

function SideBarLink(props: ISideBarLinkProps) {
    const [isOpen, toggleCollaps] = useState(false);
    const classes = props.currentPathname.includes(props.name) ||
        (props.breadcrumpName && props.currentPathname.includes(props.breadcrumpName)) ||
        (props.isBase && props.currentPathname == '/') ?
        'offblue-text active-link' : 'lightGrey-text';

    // open collaps if match current route
    useEffect(() => {
        if ("isCollaps" in props && props.isCollaps == true) {
            props.nestedLinkeds.map((nestedLink, i) => {
                if (props.currentPathname.includes((nestedLink.props as ISideBarLinkProps).name)) {
                    toggleCollaps(true);
                }
            });
        }
    }, []);
    
    let icon: React.ReactNode = (<MDBIcon icon={props.icon as string} className="mr-2" />)
    if(props.imageIcon){
        icon = props.imageIcon as React.ReactNode
    }
    // collapsable  
    if ("isCollaps" in props && props.isCollaps == true)
        return (
            <>
                <MDBListGroupItem className={`border-0 pb-3 pt-3 cursor ${classes}`} onClick={() => toggleCollaps(!isOpen)}>
                    <MDBRow className="m-0 p-0 justify-content-center">
                        <MDBCol className="m-0 p-0" size="3">
                            {
                                icon
                            }
                        </MDBCol>
                        <MDBCol className="m-0 p-0 link-text">
                            <div className="text-capitalize transition-04 d-inline-block"><h4>{props.name.replace('-', ' ')}</h4></div>
                            <MDBIcon icon="angle-down" className={`collaps-arrow ${isOpen ? 'up' : ''}`} />
                        </MDBCol>
                    </MDBRow>
                </MDBListGroupItem>
                <MDBCollapse className={`${"isCollaps" in props ? 'nested-link' : ''}`} isOpen={isOpen}>
                    {
                        props.nestedLinkeds.map((link, i) => (
                            <React.Fragment key={i}>{link}</React.Fragment>
                        ))
                    }
                </MDBCollapse>
            </>
        )

    return (
        <NavLink exact={props.exact} to={props.onClick? '#' : `/${props.isBase ? '' : props.name}`}
            className={`transparent border-0 ${props.className}`}
            title={props.name}
             onClick={() => props.onClick? props.onClick(): ''}>
            <MDBListGroupItem className={`border-0 pb-3 pt-3 ${classes}`}>
                <MDBRow className="m-0 p-0 justify-content-center">
                    <MDBCol className="m-0 p-0" size="3">
                        {
                            icon
                        }
                    </MDBCol>
                    <MDBCol className="link-text m-0 p-0">
                        <div className="text-capitalize">
                            <h4>
                                {
                                    props.breadcrumpName ? props.breadcrumpName.replace('-', ' ') : props.name.replace('-', ' ')
                                }
                            </h4>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBListGroupItem>
        </NavLink>
    )
}

export default SideBarLink;