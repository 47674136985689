import React from 'react'
import "./styles.scss"

interface ICustomCheckboxProps {
    key: any,
    id: string,
    handleChange: any,
    checked?: boolean
}

export default function CustomCheckbox(props: ICustomCheckboxProps) {
    return (
        <label key={props.key} className="checkbox-container">
            <input id={props.id} type="checkbox" checked={props.checked ? true : false} />
            <span className={`checkmark ${props.id == "row" && 'row-checkbox'}`}
                onClick={() => props.handleChange()}
            />
        </label>
    )
}
