import { call, takeLatest, put } from "redux-saga/effects";
import { fetchOrders, fetchOrderItems } from "../../api/orders";
import { IOrder } from "../../dtos/order";
import {
    loadOrdersProps, loadOrderProps,
    loadOrderItemsProps,
    updateIsLoadingOrdersProps
} from "../../reducers/orders/types";
import {
    GET_ORDERS_SAGA, IGetOrdersActionSaga,
    GET_ORDER_SAGA, IGetOrderActionSaga,
    GET_ORDER_ITEMS_SAGA, IGetOrderItemsActionSaga,
    RESET_ORDERS_SAGA
} from "./types";

function* getOrdersData(action: IGetOrdersActionSaga) {
    try {
        yield put((updateIsLoadingOrdersProps({ isLoadingOrders: true, errorMessage: undefined })));
        const orders: { data: any[], totalOrdersCount: number } = yield call(fetchOrders, action.orderIds, action.token, action.totalCount);
        yield put(loadOrdersProps(orders))
        yield put((updateIsLoadingOrdersProps({ isLoadingOrders: false, errorMessage: undefined })));
    }
    catch {
        yield put((updateIsLoadingOrdersProps({ isLoadingOrders: false, errorMessage: "Error Updating Products State" })));
    }
}

function* watchGetOrdersData() {
    yield takeLatest(GET_ORDERS_SAGA, getOrdersData);
}


function* getOrderItemsData(action: IGetOrderItemsActionSaga) {
    try {
        yield put((updateIsLoadingOrdersProps({ isLoadingOrders: true, errorMessage: undefined })));
        const orderItems: { data: any, totalOrderItemsCount: number } = yield call(fetchOrderItems, action.orderId, action.page);
        yield put(loadOrderItemsProps(orderItems))
        yield put((updateIsLoadingOrdersProps({ isLoadingOrders: false, errorMessage: undefined })));
    }
    catch {
        yield put((updateIsLoadingOrdersProps({ isLoadingOrders: false, errorMessage: "Error Updating Products State" })));
    }
}

function* watchGetOrderItemsData() {
    yield takeLatest(GET_ORDER_ITEMS_SAGA, getOrderItemsData);
}
//reset
function* resetOrdersData() {
    yield put(loadOrderProps({ order: null }))
    yield put(loadOrderItemsProps({ data: [], totalOrderItemsCount: 0 }))
}
function* watchResetOrderData() {
    yield takeLatest(RESET_ORDERS_SAGA, resetOrdersData);
}
export default [
    watchGetOrdersData(),
    watchGetOrderItemsData(),
    watchResetOrderData()
]