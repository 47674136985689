import IUser from '../../dtos/IUser';
import { LOAD_MY_ACCOUNT, UPDATE_IS_LOADING_MY_ACCOUNT } from '../../sagas/my_account/types';
export interface ILoadMyAccountAction {
    readonly type: typeof LOAD_MY_ACCOUNT;
    payload: {
        user: IUser,
    }
}

export const loadMyAccountProps = (payload: ILoadMyAccountAction['payload']): ILoadMyAccountAction => {
    return {
        type: LOAD_MY_ACCOUNT,
        payload: payload
    }
};

export interface IUpdateIsLoadingMyAccountAction {
    readonly type: typeof UPDATE_IS_LOADING_MY_ACCOUNT;
    payload: {
        isLoadingMyAccount: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingMyAccountProps = (payload: IUpdateIsLoadingMyAccountAction['payload']): IUpdateIsLoadingMyAccountAction => {
    return {
        type: UPDATE_IS_LOADING_MY_ACCOUNT,
        payload: payload
    }
};

export type MyAccountActions = ILoadMyAccountAction | IUpdateIsLoadingMyAccountAction;