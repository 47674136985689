import {put, takeLatest, call} from 'redux-saga/effects'
import {
    LOAD_PRODUCT_STORAGES_SAGA, ILoadProductStoragesActionSaga,
    FILTER_PRODUCT_STORAGES_SAGA, IFilterProductStoragesActionSaga,
} from "./types";
import {loadProductStoragesProps, loadProductStoragesFiltersProps,
    updateIsLoadingProductStoragesProps,
} from "../../reducers/product_storages/types";
import { fetchDataTableProductStorages } from '../../api/product_storages';
import {displayErrorMessageProps} from "../../reducers/global/types";

function* filterProductStorages(action: IFilterProductStoragesActionSaga) {
    try {
        const {productId, page, size, sort} = action.filters; 
        const dataTableProductStorages: {
            productStorages: any[],
            totalCountPages: number
        } = yield call(fetchDataTableProductStorages, productId, page, size, sort);
        yield put(loadProductStoragesProps({productStorages: dataTableProductStorages.productStorages, totalCountPages: dataTableProductStorages.totalCountPages}))
    } catch (error) {
        yield put(displayErrorMessageProps({message: error.message}))
    }
}

export function* watchFilterProductStorages() {
    yield takeLatest(FILTER_PRODUCT_STORAGES_SAGA, filterProductStorages)
}


function* loadProductStorages(action: ILoadProductStoragesActionSaga) {
    try {
        yield put(updateIsLoadingProductStoragesProps({isLoadingProductStorages: true, errorMessage: undefined}));
        yield put(loadProductStoragesFiltersProps({
            sortCol: '',
            sortOrder: 'ascending'
        }));
        yield put(updateIsLoadingProductStoragesProps({isLoadingProductStorages: false, errorMessage: undefined}));
    } catch (error) {
        yield put(updateIsLoadingProductStoragesProps({isLoadingProductStorages: false, errorMessage: error.message}));
        yield put(displayErrorMessageProps({message: error.message}))
    }
}

export function* watchLoadProductStorages() {
    yield takeLatest(LOAD_PRODUCT_STORAGES_SAGA, loadProductStorages)
}

export default [
    watchLoadProductStorages(),
    watchFilterProductStorages(),
]