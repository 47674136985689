import {
  IUpdateIsLoadingInvitationsAction,
  IUpdateIsLoadingUsersAction,
  UserActions,
} from "./types";
import {
  LOAD_USERS,
  INVITE_NEW_USER,
  VALIDATE_INVITATION,
  UPDATE_IS_LOADING_USERS,
  LOAD_INVITATIONS,
  UPDATE_IS_LOADING_INVITATIONS,
} from "../../sagas/user/types";
import IUser from "../../dtos/IUser";
import { faSleigh } from "@fortawesome/free-solid-svg-icons";
interface IUserState {
  users: IUser[];
  totalUsersCount: number;
  invitationSent: boolean;
  valid: boolean;
  loadingUsers: IUpdateIsLoadingUsersAction["payload"];
  invitations: any[];
  totalInvitationsCount: number;
  loadingInvitations: IUpdateIsLoadingInvitationsAction["payload"];
}
const UserState: IUserState = {
  users: [],
  totalUsersCount: 0,
  invitationSent: false,
  valid: false,
  loadingUsers: {
    isLoadingUsers: false,
    errorMessage: undefined,
  },
  invitations: [],
  totalInvitationsCount: 0,
  loadingInvitations: {
    isLoadingInvitations: false,
    errorMessage: undefined,
  },
};
const userReducer = (state = UserState, action: UserActions): IUserState => {
  switch (action.type) {
    case LOAD_USERS:
      return {
        ...state,
        users: action.payload.users,
        totalUsersCount: action.payload.totalUsersCount,
      };
    case UPDATE_IS_LOADING_USERS:
      return {
        ...state,
        loadingUsers: action.payload,
      };
    case INVITE_NEW_USER:
      return {
        ...state,
        invitationSent: action.payload.invitationSent,
      };
    case VALIDATE_INVITATION:
      return {
        ...state,
        valid: action.payload.valid,
      };
    case LOAD_INVITATIONS:
      return {
        ...state,
        invitations: action.payload.invitations,
        totalInvitationsCount: action.payload.totalInvitationsCount,
      };
    case UPDATE_IS_LOADING_INVITATIONS:
      return {
        ...state,
        loadingInvitations: action.payload,
      };
    default:
      return state;
  }
};
export default userReducer;
