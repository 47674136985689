import { axiosInstance } from "../store";
import { toast } from 'react-toastify';
import IBranch from "../dtos/IBranch"
import ICity from "../dtos/ICity"
import ICountry from "../dtos/ICountry"
import { handlStatusCodeMessage } from "../helpers";
const BRANCH_ID = localStorage.getItem("branch_id");

export function fetchOrderIds(page = 0, size = 10): Promise<any> {
    // sort=orderedAt,desc&
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/branch-order/orders?branch_id=${BRANCH_ID}&page=${page}&size=${size}`)
        .then((res) => {
            if (res.status == 200) {
                let ids = res.data.map((o: any) => { return o.order_id }) || [];
                return { ids: ids, totalOrdersCount: parseInt(res.headers['x-total-count']) };
            }
            else {
                return { ids: [], totalOrdersCount: 0 };
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'branch' });
            return { ids: [], totalOrdersCount: 0 };
        })
}
export function fetchOrders(orderIds: any, token = '', totalCount = 0): Promise<any> {
    console.log("fveS", orderIds)
    return fetch(`${process.env.REACT_APP_API_URL}/order/order/order_list`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${!token ? '' : token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ order_ids: orderIds })
    }).then(result => { return result.json() })
        .then((res: any) => {
            return {
                data: res || [],
                totalOrdersCount: totalCount || 0,
            };
        })
        .catch(err => {
            return { data: [], totalOrdersCount: 0 };
        });
}
export function fetchOrderItems(order_id: number, page = 0, size = 10): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/order/order/${order_id}/products?page=${page}&size=${size}&storefront_id=1`)
        .then(res => {
            if (res.status == 200) {
                return {
                    data: res.data,
                    totalOrderItemsCount: parseInt(res.headers['x-total-count']),
                };
            } else {
                return { data: [], totalOrderItemsCount: 0 };
            }
        })
        .catch(err => {
            return { data: [], totalOrderItemsCount: 0 };
        });
}

export function fetchOrderDeliveryDate(order_id: number): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/order-ergency/${order_id}?storefront_id=1`)
        .then(res => {
            if (res.status == 200) {
                return res.data;
            } else {
                return null;
            }
        })
        .catch(err => {
            return null;
        });
}