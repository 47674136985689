import React from 'react';
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBRow } from 'mdbreact';
import './styles/index.scss'

interface ICartSummary {
  setIsCheckout: any;
  totalPrice?: number;
}

const CartSummary = (props: ICartSummary) => {
  const { setIsCheckout, totalPrice } = props;
  return <MDBCard className="card-container">
    <MDBCardBody className='card-body'>
      <div className='summary-item-container'>
        {totalPrice && <>
          <p className='summary-item-title'>Total:</p>
          <p className='summary-item-value summary-total-value'>{totalPrice?.toFixed(2)} EGP</p>
        </>}
      </div>
      <MDBBtn onClick={() => setIsCheckout(true)} className="payment-button">Proceed to payment</MDBBtn>
    </MDBCardBody>
  </MDBCard>
}


export default CartSummary;