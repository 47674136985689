import IUser from "../../dtos/IUser";

export const LOAD_MY_ACCOUNT_SAGA = 'LOAD_MY_ACCOUNT_SAGA';
export const LOAD_MY_ACCOUNT = 'LOAD_MY_ACCOUNT';
export const UPDATE_IS_LOADING_MY_ACCOUNT = 'UPDATE_IS_LOADING_MY_ACCOUNT';


export interface ILoadMyAccountActionSaga {
    readonly type: typeof LOAD_MY_ACCOUNT_SAGA;
    userId: IUser['user_id'];
}

export const loadMyAccountPropsSaga = (userId: IUser['user_id']): ILoadMyAccountActionSaga => {
    return {
        type: LOAD_MY_ACCOUNT_SAGA,
        userId
    }
};
