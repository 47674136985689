
export const statusColorCode = {
  GREEN: "#35CE2A",
  YELLOW: "#FFCA1D",
  RED: "#EC3030",
  FILTER: "#E0E7FF"
}

export const statusHandler = (status: string): string => {
  switch (status) {
    case "Approved": case "Accepted":
      return statusColorCode.GREEN;
    case "PartiallyApproved": case "Active":
      return statusColorCode.YELLOW;
    case "NotApproved": case "Deleted":
      return statusColorCode.RED;
    default:
      return "blue";
  }
}