import React, { useState } from 'react';
import Select from 'react-select';
import "./index.scss";
interface ISelectProps {
    name: string,
    key?: any,
    label: string
    options: any
    onChange: any
    onBlur?: any,
    className?: any,
    required: boolean,
    multi: boolean
    disabled?: boolean
    clearable: boolean
    closeMenuOnSelect: boolean
    defaultValue?: any
    placeholder?: string
    labelClassName?: any
}

function MultiSelect(props: ISelectProps) {
    return (
        <div key={props.key ? props.key : undefined} className={props.className ? props.className : ''}>
            {props.label != "" && <label htmlFor={props.name} className={`${props.labelClassName && props.labelClassName} pl-3 pt-3 font-weight-bold`}>{props.label}
                {props.required && <span className='red-text'>{' *'}</span>}
            </label>}
            <Select
                closeMenuOnSelect={props.closeMenuOnSelect}
                className="multiselect-dropdown"
                name={props.name}
                defaultValue={props.defaultValue ? props.defaultValue : ''}
                placeholder={props.placeholder ? props.placeholder : `Select ${props.label}`}
                options={props.options ? props.options : []}
                onChange={props.onChange}
                isMulti={props.multi}
                isClearable={props.clearable}
                disabled={props.disabled ? props.disabled : false}
            />
        </div>

    );
}

export default MultiSelect;
